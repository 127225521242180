import {
  Button,
  DatePicker,
  Form,
  message,
  Select,
  Space,
  Switch,
  Typography,
} from "antd";
import React, { useRef, useState } from "react";
import StyledContent from "src/common/components/layouts/StyledContent";
import {
  General_Contractor_Billing_Settings_Set_Input,
  GetGeneralContractorByPkDocument,
  GetGeneralContractorByPkQuery,
  Maybe,
  useCreateCustomGcEnterpriseInvoiceMutation,
  useCreateCustomGcProjectInvoiceMutation,
  useGenerateGcSubsBillMutation,
  useStripeCreateAccountGcMutation,
  useUpdateGcBillingSettingsMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import PriceSelector, {
  formatCurrency,
  getPaymentModelDescription,
  PriceSelectorRef,
} from "../../../../../../common/components/tables/PriceSelector";
import SelectStripeEmailModal from "src/common/components/dialogs/SelectStripeEmailModal";
import CreateCustomInvoiceModal from "../subcontractors/CreateCustomInvoiceModal";
import EditInvoiceSettingsModal from "../subcontractors/EditInvoiceSettingsModal";
import BaseTable from "src/common/components/tables/basic/BaseTable";
import { stripeDashboardLink } from "src/common/functions/stripeDashboardUrl";
import SiteAlertPriceSelector, {
  SiteAlertPriceSelectorRef,
} from "../components/SiteAlertPriceSelector";
import SiteBoardPriceSelector, {
  SiteBoardPriceSelectorRef,
} from "../components/SiteBoardPriceSelector";
import dayjs from "dayjs";
import downloadFromUrl from "src/common/functions/downloadFromUrl";

const StripeIdStyle: React.CSSProperties = {
  fontSize: "14px",
  borderRadius: "5px",
  boxShadow: "rgb(235, 238, 241) 0px 0px 0px 1px inset",
  padding: "2px 5px",
  backgroundColor: "rgb(246, 248, 250)",
  fill: "rgb(84, 89, 105)",
  color: "rgb(65, 69, 82)",
};

type ProjectRecord = NonNullable<
  GetGeneralContractorByPkQuery["general_contractor_by_pk"]
>["projects"][number];
type GCSettings = NonNullable<
  GetGeneralContractorByPkQuery["general_contractor_by_pk"]
>["billing_settings"];

//type ProjectRecord = GetGeneralContractorByPkQueryResult[]
export function getProjectGCBillingSettings(
  project: ProjectRecord,
  gc_settings: GCSettings,
) {
  const proj_level = project.billing_settings?.use_project_level_price;
  const settings = proj_level ? project.billing_settings : gc_settings;
  return settings;
}

const GCPriceTypes = ["free", "fixed", "fixed_per_project", "per_worker"];
const SubPriceTypes = ["free", "fixed_per_project", "per_worker"];

export function getProjectPriceDescription(
  project: ProjectRecord,
  gc_settings: GCSettings,
) {
  const settings = getProjectGCBillingSettings(project, gc_settings);
  if (!settings) {
    return "Billing is not enabled";
  }
  switch (settings.payment_model) {
    case "free":
      return "Free";
    case "gc":
    case "sub":
      {
        switch (settings.gc_price_type) {
          case "per_worker":
            return `Per user: ${formatCurrency(
              settings.gc_worker_price_first ?? 0,
            )} USD - first, ${formatCurrency(
              settings.gc_worker_price_others ?? 0,
            )} USD - next`;
          case "fixed_per_project":
            return `Fixed per project: ${formatCurrency(
              settings.gc_project_fixed_price ?? 0,
            )} USD`;
          case "fixed":
            return `Fixed: ${formatCurrency(
              gc_settings?.gc_fixed_price ?? 0,
            )} USD`;
          case "free":
            return "Free";
          default:
            return `Unknown gc_price_type: ${settings.gc_price_type}`;
        }
      }
      break;
    default:
      return `Unknown payment model: ${settings.payment_model}`;
  }
}

interface AdminGeneralContractorProps {
  gc: NonNullable<GetGeneralContractorByPkQuery["general_contractor_by_pk"]>;
}

const AdminGeneralContractorBillingContent: React.FC<
  AdminGeneralContractorProps
> = ({ gc }) => {
  const gcId = gc.id;
  const [generateGcSubsBill, { loading: generatingGcSubsBill }] =
    useGenerateGcSubsBillMutation();
  const [createStripeAccountGc, { loading: creatingStripeAccount }] =
    useStripeCreateAccountGcMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GetGeneralContractorByPkDocument,
          variables: {
            id: gcId,
          },
        },
      ],
    });

  const [updateBillingSettings, { loading: updatingBillingSettings }] =
    useUpdateGcBillingSettingsMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GetGeneralContractorByPkDocument,
          variables: {
            id: gcId,
          },
        },
      ],
    });

  const [createStripeGcEnterpriseInvoice] =
    useCreateCustomGcEnterpriseInvoiceMutation();
  const [createStripeGcProjectInvoice] =
    useCreateCustomGcProjectInvoiceMutation();

  const [priceEditing, setPriceEditing] = useState(false);
  const [paymentModel, setPaymentModel] = useState("");
  const [selectEmailVisible, setSelectEmailVisible] = useState(false);
  const [createInvoiceVisible, setCreateInvoiceVisible] = useState(false);
  const [autoCreateInvoices, setAutoCreateInvoices] = useState(false);
  const [invoiceSettingsVisible, setInvoiceSettingsVisible] = useState(false);

  const siteBoardPriceSelectorRef = useRef<SiteBoardPriceSelectorRef>(null);
  const siteAlertPriceSelectorRef = useRef<SiteAlertPriceSelectorRef>(null);
  const gcPriceSelectorRef = useRef<PriceSelectorRef>(null);
  const subPriceSelectorRef = useRef<PriceSelectorRef>(null);

  const { billing_settings } = gc;

  const currentPaymentModel = priceEditing
    ? paymentModel
    : billing_settings?.payment_model ?? "";

  const applyPrice = async () => {
    try {
      const set: Maybe<General_Contractor_Billing_Settings_Set_Input> = {
        payment_model: paymentModel,
      };
      console.log("save price mode", paymentModel);
      if (siteBoardPriceSelectorRef.current) {
        const currentsiteboardPrices =
          siteBoardPriceSelectorRef.current.getEditedPrice();

        set.siteboard_price = currentsiteboardPrices.price;
        set.raspbery_pi_price =
          currentsiteboardPrices.raspberyDeviceOneTimePrice;
        set.siteboard_price_type = currentsiteboardPrices.priceType;
      }
      if (siteAlertPriceSelectorRef.current) {
        const siteAlertsPrices =
          siteAlertPriceSelectorRef.current.getEditedPrice();
        set.base_sms_pack_count = siteAlertsPrices.baseCount;
        set.base_sms_pack_price = siteAlertsPrices.basePrice;
        set.over_base_sms_count = siteAlertsPrices.overCount;
        set.over_base_sms_price = siteAlertsPrices.overPrice;
        set.text_messaging_price_type = siteAlertsPrices.priceType;
      }
      console.log(set);

      if (paymentModel !== "free") {
        if (gcPriceSelectorRef.current) {
          const gcPrice = gcPriceSelectorRef.current.getEditedPrice();
          console.log("gcPrice", gcPrice);
          set.gc_price_type = gcPrice.type;
          set.gc_project_fixed_price = gcPrice.projectFixedPrice;
          set.gc_worker_price_first = gcPrice.workerPriceFirst;
          set.gc_worker_price_others = gcPrice.workerPriceSecond;
          set.gc_fixed_price = gcPrice.fixedPrice;
        }
        if (subPriceSelectorRef.current) {
          const subPrice = subPriceSelectorRef.current.getEditedPrice();
          console.log("subPrice", subPrice);
          set.sub_price_type = subPrice.type;
          set.sub_project_fixed_price = subPrice.projectFixedPrice;
          set.sub_worker_price_first = subPrice.workerPriceFirst;
          set.sub_worker_price_others = subPrice.workerPriceSecond;
        }
      }
      await updateBillingSettings({
        variables: {
          gcId,
          set,
        },
      }).then(() => {
        setPriceEditing(false);
      });
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    }
  };
  const stripe_account = billing_settings?.stripe_account;
  const invoiceSettings = {
    description: billing_settings?.invoice_description,
    footer: billing_settings?.invoice_footer,
    default_billing_email: billing_settings?.default_billing_email,
  };
  console.log("gc price type = ", billing_settings?.gc_price_type);
  const autoCreateInvoicesValue = updatingBillingSettings
    ? autoCreateInvoices
    : billing_settings?.auto_generate_invoices ?? false;
  const users =
    gc.employees
      .filter((se) => se.user.email)
      .map((se) => ({
        name: se.user.name,
        email: se.user.email!,
      })) ?? [];

  return (
    <>
      {!billing_settings ||
      !stripe_account ||
      !billing_settings.default_billing_email ? (
        <Button
          type="primary"
          style={{ marginTop: "10px" }}
          loading={creatingStripeAccount}
          onClick={() => setSelectEmailVisible(true)}
        >
          Create GC Stripe Customer
        </Button>
      ) : (
        <>
          <div style={{ margin: "20px" }}>
            Default billing email:{" "}
            <span style={StripeIdStyle}>
              {billing_settings.default_billing_email}
            </span>
          </div>
          <Form
            className="flex gap-1"
            onFinish={async (vals) => {
              const selectedMonth = vals.month
                ? dayjs(vals.month) || ""
                : undefined;
              if (!selectedMonth?.isValid()) {
                message.error("Invalid month selected");
                return;
              }
              const { data } = await generateGcSubsBill({
                variables: {
                  input: {
                    gcId,
                    monthCount: 1,
                    startDate: selectedMonth.format("YYYY-MM-DD"),
                  },
                },
              });
              if (data) {
                downloadFromUrl(data.generateGcSubsBill);
              }
            }}
            layout="horizontal"
          >
            <Form.Item
              name="month"
              rules={[{ required: true, message: "Select a month" }]}
              label="Usage Month"
            >
              <DatePicker.MonthPicker />
            </Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              loading={generatingGcSubsBill}
            >
              Download GC Usage
            </Button>
          </Form>
          {billing_settings.gc_price_type === "fixed" && (
            <div style={{ margin: "20px" }}>
              Stripe customer id:{" "}
              <span style={StripeIdStyle}>
                <a
                  target="_blank"
                  href={`${stripeDashboardLink}/customers/${stripe_account?.customer_id}`}
                >
                  {stripe_account?.customer_id}
                </a>
              </span>
            </div>
          )}
          <div style={{ padding: "10px" }}>
            <Button
              onClick={() => {
                setCreateInvoiceVisible(true);
              }}
            >
              Create custom invoice
            </Button>
            &nbsp;
            <Button
              className="ml-1"
              onClick={() => {
                setInvoiceSettingsVisible(true);
              }}
            >
              Edit invoice settings
            </Button>
          </div>
          <div style={{ padding: "10px" }}>
            <Switch
              checked={autoCreateInvoicesValue}
              onChange={(checked) => {
                setAutoCreateInvoices(checked);
                updateBillingSettings({
                  variables: {
                    gcId,
                    set: {
                      auto_generate_invoices: checked,
                    },
                  },
                });
              }}
            />{" "}
            Create invoices automatically at the end of each month
          </div>
          <div style={{ margin: "20px" }}>
            This is default pricing settings for all GC projects. They can be
            overwritten on project level.
          </div>
          <SiteBoardPriceSelector
            ref={siteBoardPriceSelectorRef}
            title={"Project SiteBoard Prices"}
            key={109 + (priceEditing ? 98 : 0)}
            settings={{
              priceType: billing_settings.siteboard_price_type || "default",
              raspberyDeviceOneTimePrice:
                billing_settings.raspbery_pi_price || 34900,
              price: billing_settings.siteboard_price || 3900,
            }}
            priceEditing={priceEditing}
            editing={priceEditing}
            saving={updatingBillingSettings}
            savePrice={applyPrice}
          />
          <SiteAlertPriceSelector
            ref={siteAlertPriceSelectorRef}
            title={
              "SiteAlert Prices( applied only if project is using SiteAlert and gc level prices)"
            }
            settings={{
              baseCount: billing_settings.base_sms_pack_count,
              basePrice: billing_settings.base_sms_pack_price,
              overCount: billing_settings.over_base_sms_count,
              overPrice: billing_settings.over_base_sms_price,
              priceType: billing_settings.text_messaging_price_type,
            }}
            editing={priceEditing}
            saving={updatingBillingSettings}
            savePrice={applyPrice}
          />
          <div style={{ margin: "20px" }}>
            <span
              style={{
                display: "inline-block",
                padding: "5px",
                width: "150px",
                textAlign: "right",
              }}
            >
              Billing model:{" "}
            </span>
            {priceEditing ? (
              <Select
                value={paymentModel}
                onChange={(value) => setPaymentModel(value)}
                style={{ width: "300px" }}
              >
                {["gc", "sub"].map((model) => (
                  <Select.Option key={model} value={model}>
                    {getPaymentModelDescription(model)}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <span>
                {getPaymentModelDescription(billing_settings.payment_model)}
              </span>
            )}
          </div>
          {currentPaymentModel !== "free" && (
            <PriceSelector
              ref={gcPriceSelectorRef}
              key={priceEditing ? 2 : 1}
              title={
                currentPaymentModel === "gc"
                  ? "Project price"
                  : "GC project price"
              }
              allowedPriceTypes={GCPriceTypes}
              price={{
                type: billing_settings.gc_price_type,
                projectFixedPrice: billing_settings.gc_project_fixed_price,
                workerPriceFirst: billing_settings.gc_worker_price_first,
                workerPriceSecond: billing_settings.gc_worker_price_others,
                fixedPrice: billing_settings.gc_fixed_price,
              }}
              editing={priceEditing}
              saving={updatingBillingSettings}
              savePrice={applyPrice}
            />
          )}
          {currentPaymentModel === "sub" && (
            <PriceSelector
              ref={subPriceSelectorRef}
              key={priceEditing ? 4 : 3}
              title="Sub project price"
              allowedPriceTypes={SubPriceTypes}
              price={{
                type: billing_settings.sub_price_type,
                projectFixedPrice: billing_settings.sub_project_fixed_price,
                workerPriceFirst: billing_settings.sub_worker_price_first,
                workerPriceSecond: billing_settings.sub_worker_price_others,
              }}
              editing={priceEditing}
              saving={updatingBillingSettings}
              savePrice={applyPrice}
            />
          )}
          <div style={{ paddingTop: "10px", paddingLeft: "10px" }}>
            {priceEditing ? (
              <Space direction="horizontal">
                <Button onClick={applyPrice}>Apply</Button>
                <Button onClick={() => setPriceEditing(false)}>Cancel</Button>
              </Space>
            ) : (
              <Button
                onClick={() => {
                  setPaymentModel(billing_settings.payment_model);
                  setPriceEditing(true);
                }}
              >
                Edit Price
              </Button>
            )}
          </div>
        </>
      )}
      <SelectStripeEmailModal
        users={users}
        companyName={gc.name}
        visible={selectEmailVisible}
        onCancel={() => {
          setSelectEmailVisible(false);
        }}
        onSelect={(email) => {
          setSelectEmailVisible(false);
          createStripeAccountGc({
            variables: {
              input: {
                gc_id: gcId,
                account_email: email,
              },
            },
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: GetGeneralContractorByPkDocument,
                variables: {
                  id: gcId,
                },
              },
            ],
          })
            .then((res) => {
              const errors =
                res.data?.stripeCreateAccountGC.filter((v) => v.error) || [];
              if (errors.length > 0) {
                throw new Error(
                  errors
                    .slice(0, 3)
                    .map((v) => `${v.project_name ?? ""}: ${v.error}`)
                    .join("\n"),
                );
              }
            })
            .catch((e) => {
              message.error("Failed to create account " + String(e));
            });
        }}
      />
      {createInvoiceVisible && (
        <CreateCustomInvoiceModal
          companyName={gc.name}
          visible={createInvoiceVisible}
          isGC={true}
          hideProjects={gc.billing_settings?.gc_price_type === "fixed"}
          projects={gc.projects
            .filter((p) => {
              const settings = getProjectGCBillingSettings(
                p,
                gc.billing_settings,
              );
              return settings && settings.gc_price_type !== "free";
            })
            .map((p) => ({
              id: p.id,
              name: p.name,
              gc: gc.name,
              price: getProjectPriceDescription(p, gc.billing_settings),
            }))}
          invSettings={invoiceSettings}
          onSelect={async (
            startDate,
            monthCount,
            projectIds,
            finalize,
            description,
            footer,
            excludeZeroLines,
          ) => {
            const promises: Array<Promise<any>> = [];
            if (billing_settings?.gc_price_type === "fixed") {
              promises.push(
                createStripeGcEnterpriseInvoice({
                  variables: {
                    input: {
                      gcId,
                      startDate: startDate.format("YYYY-MM-DD"),
                      monthCount,
                      finalize,
                      description,
                      footer,
                    },
                  },
                }).then(
                  (result) => {
                    setCreateInvoiceVisible(false);
                    const invoiceId =
                      result.data?.stripeCreateGCEnterpriseInvoice
                        .stripeInvoiceId;
                    if (invoiceId)
                      message.success(
                        <span>
                          <a
                            target="_blank"
                            href={`${stripeDashboardLink}/invoices/${invoiceId}`}
                          >
                            Enterprise Invoice(s)
                          </a>{" "}
                          has been created successfully
                        </span>,
                        30,
                      );
                  },
                  (error) => {
                    message.error(String(error));
                  },
                ),
              );
            }
            projectIds.forEach((projectId) => {
              promises.push(
                createStripeGcProjectInvoice({
                  variables: {
                    input: {
                      gcId,
                      projectId,
                      excludeZeroLines,
                      startDate: startDate.format("YYYY-MM-DD"),
                      monthCount,
                      finalize,
                      description,
                      footer,
                    },
                  },
                }).then(
                  (result) => {
                    const invoiceId =
                      result.data?.stripeCreateGCProjectInvoice.stripeInvoiceId;
                    const project = gc.projects.find((p) => p.id === projectId);
                    if (invoiceId)
                      message.success(
                        <span>
                          <a
                            target="_blank"
                            href={`${stripeDashboardLink}/invoices/${invoiceId}`}
                          >
                            {project?.name ?? ""} Invoice(s)
                          </a>{" "}
                          has been created successfully
                        </span>,
                        30,
                      );
                  },
                  (error) => {
                    message.error(String(error));
                  },
                ),
              );
            });
            await Promise.allSettled(promises).then((results) =>
              results.forEach((result) => {
                if (result.status === "fulfilled") {
                  console.log(result.value);
                } else {
                  console.error(result.reason);
                }
              }),
            );
            setCreateInvoiceVisible(false);
          }}
          onCancel={() => {
            setCreateInvoiceVisible(false);
          }}
        />
      )}
      {stripe_account && invoiceSettingsVisible && (
        <EditInvoiceSettingsModal
          companyName={gc.name}
          visible={invoiceSettingsVisible}
          isGC={true}
          users={users}
          onSave={async (values) => {
            await updateBillingSettings({
              variables: {
                gcId,
                set: {
                  invoice_description: values.description,
                  invoice_footer: values.footer,
                  default_billing_email: values.default_billing_email,
                },
              },
            }).then(
              () => setInvoiceSettingsVisible(false),
              (error) => message.error("Error: " + String(error)),
            );
          }}
          onCancel={() => {
            setInvoiceSettingsVisible(false);
          }}
          initValues={invoiceSettings}
        />
      )}
    </>
  );
};

interface CreateAccButtonProps {
  onCreateAccount: () => Promise<void>;
}

const CreateAccButton: React.FC<CreateAccButtonProps> = ({
  onCreateAccount,
}) => {
  const [actionRunning, setActionRunning] = useState(false);
  return (
    <Button
      loading={actionRunning}
      onClick={() => {
        setActionRunning(true);
        onCreateAccount()
          .catch((e) => {
            message.error("Failed to create account " + String(e));
          })
          .finally(() => setActionRunning(false));
      }}
    >
      Create Stripe Customer
    </Button>
  );
};

const AdminGeneralContractorBillingProjects: React.FC<
  AdminGeneralContractorProps
> = ({ gc }) => {
  const gcId = gc.id;
  const [createStripeAccountGc, { loading: creatingStripeAccount }] =
    useStripeCreateAccountGcMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GetGeneralContractorByPkDocument,
          variables: {
            id: gcId,
          },
        },
      ],
    });
  if (!gc.billing_settings?.default_billing_email) {
    return <></>;
  }

  const columns = [
    {
      title: "Project name",
      key: "name",
      dataIndex: ["name"],
      render: (_: any, project: ProjectRecord) => (
        <a href={`/admin/data/projects/${project.id}#billing`}>
          {project.name}
        </a>
      ),
    },
    {
      title: "Pricing",
      key: "pricing",
      render: (_: any, project: ProjectRecord) => {
        const settings = getProjectGCBillingSettings(
          project,
          gc.billing_settings,
        );
        if (!settings) {
          return (
            <a href={"/admin/data/gcs/" + gc.id}>
              <Typography.Text type="danger">
                Not enabled on GC level
              </Typography.Text>
            </a>
          );
        }
        return (
          <div>{getProjectPriceDescription(project, gc.billing_settings)}</div>
        );
      },
    },
    {
      title: "Stripe customer",
      key: "name",
      dataIndex: ["name"],
      render: (_: any, project: ProjectRecord) => {
        const stripe_account = project.billing_settings?.stripe_account;
        if (stripe_account) {
          return (
            <span style={StripeIdStyle}>
              <a
                target="_blank"
                href={`${stripeDashboardLink}/customers/${stripe_account.customer_id}`}
              >
                {stripe_account.customer_id}
              </a>
            </span>
          );
        }
        return (
          <CreateAccButton
            onCreateAccount={() =>
              createStripeAccountGc({
                variables: {
                  input: {
                    gc_id: gcId,
                  },
                },
                awaitRefetchQueries: true,
                refetchQueries: [
                  {
                    query: GetGeneralContractorByPkDocument,
                    variables: {
                      id: gcId,
                    },
                  },
                ],
              }).then((res) => {
                const errors =
                  res.data?.stripeCreateAccountGC.filter((v) => v.error) || [];
                if (errors.length > 0) {
                  throw new Error(
                    errors
                      .slice(0, 3)
                      .map((v) => `${v.project_name ?? ""}: ${v.error}`)
                      .join("\n"),
                  );
                }
              })
            }
          />
        );
      },
    },
  ];
  return <BaseTable columns={columns} dataSource={gc.projects} />;
};

const AdminGeneralContractorBillingSection: React.FC<
  AdminGeneralContractorProps
> = (props) => (
  <>
    <StyledContent padding backgroundColor="white" id="billing">
      <h3 style={{ fontWeight: "bold", fontSize: "17px" }}>Billing settings</h3>
      <AdminGeneralContractorBillingContent {...props} />
    </StyledContent>
    <StyledContent padding backgroundColor="white">
      <AdminGeneralContractorBillingProjects {...props} />
    </StyledContent>
  </>
);

export default AdminGeneralContractorBillingSection;
