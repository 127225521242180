import { IconCheck, IconX } from "@tabler/icons";
import Switcher from "src/common/components/general/Switcher";
import SelectProjectSubcontractorModal from "src/common/components/dialogs/SelectProjectSubcontractorModal";
import {
  ProjDrWithPtpSettingFragmentFragment,
  useUpdateProjPtpSettingMutation,
  useUpdateProjSubPtpSettingMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useState } from "react";
import { message, Button } from "antd";
import compareTwoLists from "src/common/components/ComparingTwoLists";

const AddDRWithPTPSettings: React.FC<{
  addDrWithPtpProjectDataFrag: ProjDrWithPtpSettingFragmentFragment;
}> = ({ addDrWithPtpProjectDataFrag }) => {
  const projectId = addDrWithPtpProjectDataFrag.id;
  const projSubsWithAddDrWithPtpSettings =
    addDrWithPtpProjectDataFrag.project_subcontractors.map((projSub) => ({
      id: projSub.id,
      name: projSub.subcontractor.name,
      selected: projSub.add_daily_work_log_during_ptp,
    }));
  const projAddDrWithPtp =
    addDrWithPtpProjectDataFrag.add_daily_work_log_during_ptp;
  const [
    showSelectProjectSubcontractorModal,
    setShowSelectProjectSubcontractorModal,
  ] = useState(false);
  const selectProjectSubcontractorModalOpen = () => {
    setShowSelectProjectSubcontractorModal(true);
  };
  const selectProjectSubcontractorModalClose = () => {
    setShowSelectProjectSubcontractorModal(false);
  };
  const [updateProj] = useUpdateProjPtpSettingMutation();
  const [updateProjectSub] = useUpdateProjSubPtpSettingMutation();
  const onProjDrWithPTPChange = async (addDrWithPtp: boolean) => {
    const _set = { add_daily_work_log_during_ptp: addDrWithPtp };
    const { data } = await updateProj({
      variables: { projectId, _set },
      optimisticResponse: {
        update_project_by_pk: { __typename: "project", id: projectId, ..._set },
      },
    });
    const value = data?.update_project_by_pk?.add_daily_work_log_during_ptp;
    value ? message.success("On") : message.error("Off");
  };
  const onAddDrWithPtpSelectSubChange = async (projectSubIds: string[]) => {
    const [toSelect, toDeselect] = compareTwoLists(
      projectSubIds,
      projSubsWithAddDrWithPtpSettings.reduce((projSubIds, projSub) => {
        if (projSub.selected) projSubIds.push(projSub.id);
        return projSubIds;
      }, [] as string[]),
    );

    await updateProjectSub({
      variables: {
        updates: [
          {
            where: { id: { _in: toSelect } },
            _set: { add_daily_work_log_during_ptp: true },
          },
          {
            where: { id: { _in: toDeselect } },
            _set: { add_daily_work_log_during_ptp: false },
          },
        ],
      },
      optimisticResponse: {
        update_project_subcontractor_many: [
          {
            returning: toSelect.map((projSubId) => ({
              __typename: "project_subcontractor",
              id: projSubId,
              add_daily_work_log_during_ptp: true,
            })),
          },
          {
            returning: toDeselect.map((projSubId) => ({
              __typename: "project_subcontractor",
              id: projSubId,
              add_daily_work_log_during_ptp: false,
            })),
          },
        ],
      },
    });
  };
  return (
    <div className="flex flex-col gap-1 mt-2">
      <p className="inline-flex flex-row items-center gap-0.5">
        Foremen will submit their Daily Report at the same time as their
        Pre-Task Plan
        <Switcher
          options={[
            { label: "off", icon: IconX },
            { label: "on", icon: IconCheck },
          ]}
          optionIndex={projAddDrWithPtp ? 1 : 0}
          onChange={async (selectedOptionIndex) => {
            const ptpDailyReportOn = selectedOptionIndex === 1;
            await onProjDrWithPTPChange(ptpDailyReportOn);
          }}
        />
      </p>
      {projAddDrWithPtp && (
        <div className="flex flex-col gap-1">
          <p className="text-1.5">Select Subcontractors</p>
          <p style={{ marginBottom: 9, fontWeight: 400 }}>
            You can require all or some Subcontractors to submit their Daily
            Report with their Pre-Task Plan
          </p>
          <div className="-mx-0.25">
            {projSubsWithAddDrWithPtpSettings
              .filter((projSub) => projSub.selected)
              .map((projSub) => (
                <span
                  className="mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-suplementary-1"
                  key={projSub.id}
                >
                  {projSub.name}
                </span>
              ))}
          </div>

          <div className="flex flex-row justify-start">
            <Button
              type="primary"
              style={{ borderRadius: 100 }}
              onClick={() => selectProjectSubcontractorModalOpen()}
              loading={false}
            >
              Select Subcontractor(s)
            </Button>
          </div>
          <SelectProjectSubcontractorModal
            defaultProjectSubs={projSubsWithAddDrWithPtpSettings
              .filter((sub) => sub.selected)
              .map((sub) => sub.id)}
            modalClose={selectProjectSubcontractorModalClose}
            modalVisible={showSelectProjectSubcontractorModal}
            onSubmit={onAddDrWithPtpSelectSubChange}
          />
        </div>
      )}
    </div>
  );
};
export default AddDRWithPTPSettings;
