import dayjs from "dayjs";
import React, { useMemo } from "react";
import {
  GetIncidentChartsDataDocument,
  GetIncidentChartsDataQuery,
  GetIncidentChartsDataQueryVariables,
  GetIncidentReportAndChartOptionsQuery,
  Project_Bool_Exp,
} from "src/common/types/generated/apollo/graphQLTypes";

import { useSuspenseQuery } from "@apollo/client";
import ShowPieChart, {
  groupDataForChart,
} from "src/common/components/charts/pie/ShowPieChart";
import randomHexColor from "src/common/functions/randomHexColor";
import ChartWrapperCard from "../../../../common/components/charts/ChartWrapperCard";
import ChartTitleNumbers from "../../../../common/components/charts/ChartTitleNumbers";
import LineChart from "../../../../common/components/charts/LineChart";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import filterOnProjectAndHierarchy from "src/root/routes/views/general-contractor/hierarchy/utils/filterOnProjectAndHierarchy";
import ColumnChart from "../../../../common/components/charts/ColumnChart";
import colorList from "src/common/components/lists/chartColorList";

export interface GCIncidentChartsProps {
  projWhere: Project_Bool_Exp;
  currentFilters?: {
    projectsFilter?: Set<string>;
    incidentTypesFilter: Set<string>;
    projectStatusFilter?: string | undefined;
    businessUnitsFilter?: Set<string>;
    divisionsFilter?: Set<string>;
    officesFilter?: Set<string>;
    injuryCauseFilter: Set<string>;
    injuryTypesFilter: Set<string>;
    companiesFilter: Set<string>;
    bodyPartsFilter: Set<string>;
    severityFilter: Set<string>;
    dateFilter: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;
  };
  options: GetIncidentReportAndChartOptionsQuery | undefined;
  optionsLoading?: boolean;
}

const GCIncidentCharts: React.FunctionComponent<GCIncidentChartsProps> = ({
  projWhere,
  currentFilters,
  options,
  optionsLoading,
}) => {
  const { data } = useSuspenseQuery<
    GetIncidentChartsDataQuery,
    GetIncidentChartsDataQueryVariables
  >(GetIncidentChartsDataDocument, {
    variables: {
      incidentWhere: {
        project: projWhere,
        deleted_at: { _is_null: true },
      },
    },
  });
  const { userData } = useUserData();
  const emp = userData.employee;
  if (!emp) throw new Error("Employee Data not found");
  const gc = emp.general_contractor;
  const severityIds = useMemo(() => {
    let recordableId: string | undefined,
      firstAidOnlyId: string | undefined,
      medicalTreatmentOnlyId: string | undefined;
    if (options) {
      options.injury_severity.forEach((s) => {
        if (s.name.en === "Recordable") recordableId = s.id;
        if (s.name.en === "First Aid Only") firstAidOnlyId = s.id;
        if (s.name.en === "Medical Treatment Only")
          medicalTreatmentOnlyId = s.id;
      });
    }
    return {
      recordableId,
      medicalTreatmentOnlyId,
      firstAidOnlyId,
    };
  }, [options?.injury_severity]);
  const { filteredIncidents, filteredInjuredUsers, filteredBodyParts } =
    useMemo(() => {
      const filteredBodyParts: NonNullable<
        GetIncidentChartsDataQuery["incident"][number]["injured_users"][number]["injury_detail"]
      >["body_parts_affected"] = [];
      if (!currentFilters) {
        const filteredInjuredUsers = data.incident.flatMap((inci) => {
          filteredBodyParts.push(
            ...inci.injured_users.flatMap(
              (injUser) => injUser.injury_detail?.body_parts_affected || [],
            ),
          );
          return inci.injured_users;
        });
        return {
          filteredIncidents: data.incident,
          filteredInjuredUsers,
          filteredBodyParts,
        };
      }
      const filteredInjuredUsers: GetIncidentChartsDataQuery["incident"][number]["injured_users"] =
        [];
      const filteredIncidents = data.incident.filter((incident) => {
        const time = incident.incident_time
          ? dayjs(incident.incident_time)
          : null;
        const incidentTypeFiltering =
          !currentFilters.incidentTypesFilter.size ||
          !!incident.incident_types.find((p) =>
            currentFilters.incidentTypesFilter.has(p.type_value),
          );
        const dateFiltering =
          !time ||
          !currentFilters.dateFilter ||
          ((!currentFilters.dateFilter[0] ||
            time >= currentFilters.dateFilter[0]) &&
            (!currentFilters.dateFilter[1] ||
              time <= currentFilters.dateFilter[1]));
        const tempFilteredBodyParts: typeof filteredBodyParts = [];
        const incidentFilteredUsers = incident.injured_users.filter(
          (injUser) => {
            if (!injUser.injury_detail) {
              return false;
            }

            const injuredUserBodyPartsAffected = !currentFilters.bodyPartsFilter
              .size
              ? injUser.injury_detail.body_parts_affected
              : injUser.injury_detail.body_parts_affected.filter((bpa) =>
                  currentFilters.bodyPartsFilter.has(bpa.value),
                );
            const injuredUserFiltered =
              (!currentFilters.companiesFilter.size ||
                (injUser.subcontractor_id &&
                  currentFilters.companiesFilter.has(
                    injUser.subcontractor_id,
                  ))) &&
              (!currentFilters.injuryCauseFilter.size ||
                (injUser.injury_detail.injury_cause &&
                  currentFilters.injuryCauseFilter.has(
                    injUser.injury_detail.injury_cause,
                  ))) &&
              (!currentFilters.injuryTypesFilter.size ||
                (injUser.injury_detail.injury_type &&
                  currentFilters.injuryTypesFilter.has(
                    injUser.injury_detail.injury_type,
                  ))) &&
              (!currentFilters.severityFilter.size ||
                (injUser.injury_detail.injury_severity_id &&
                  currentFilters.severityFilter.has(
                    injUser.injury_detail.injury_severity_id,
                  )));
            if (injuredUserFiltered)
              tempFilteredBodyParts.push(...injuredUserBodyPartsAffected);
            return (
              injuredUserFiltered &&
              (!currentFilters.bodyPartsFilter.size ||
                injuredUserBodyPartsAffected.length)
            );
          },
        );

        const incidentFiltered =
          filterOnProjectAndHierarchy(incident.project, currentFilters) &&
          incidentTypeFiltering &&
          dateFiltering;
        if (incidentFiltered) {
          filteredInjuredUsers.push(...incidentFilteredUsers);
          filteredBodyParts.push(...tempFilteredBodyParts);
        }
        return (
          (!currentFilters.bodyPartsFilter.size ||
            tempFilteredBodyParts.length) &&
          incidentFiltered &&
          ((!currentFilters.companiesFilter.size &&
            !currentFilters.injuryCauseFilter.size &&
            !currentFilters.severityFilter.size &&
            !currentFilters.injuryTypesFilter.size) ||
            incidentFilteredUsers.length)
        );
      });
      return { filteredIncidents, filteredInjuredUsers, filteredBodyParts };
    }, [data.incident, currentFilters]);
  const projectsFilter = currentFilters?.projectsFilter;
  const subOptions = useMemo(() => {
    return !options
      ? []
      : !projectsFilter || projectsFilter.size === 0
      ? options?.subcontractor
      : options?.subcontractor.filter((sub) =>
          sub.subcontractor_projects.some((ps) =>
            projectsFilter.has(ps.project_id),
          ),
        );
  }, [options?.subcontractor, projectsFilter]);

  const { pending, open, closed } = useMemo(() => {
    let pending = 0,
      open = 0,
      closed = 0;
    filteredIncidents.forEach((incident) => {
      incident.status === "open"
        ? open++
        : incident.status === "closed"
        ? closed++
        : pending++;
    });
    return { pending, open, closed };
  }, [filteredIncidents]);

  const severityCounts = useMemo(() => {
    const severityCounts = {
      recordable: 0,
      firstAidOnly: 0,
      medicalTreatmentOnly: 0,
    };
    filteredInjuredUsers.forEach((injuredUser) => {
      const detail = injuredUser.injury_detail;
      const severityId = detail?.injury_severity_id;
      if (severityId) {
        if (severityId === severityIds.recordableId)
          severityCounts.recordable += 1;
        else if (severityId === severityIds.firstAidOnlyId)
          severityCounts.firstAidOnly += 1;
        else if (severityId === severityIds.medicalTreatmentOnlyId)
          severityCounts.medicalTreatmentOnly += 1;
      }
    });
    return severityCounts;
  }, [filteredInjuredUsers, severityIds]);
  type DataMapType = {
    [key: string]: {
      id: string;
      value: number;
    };
  };

  const {
    dateData,
    businessUnitsList,
    officesList,
    divisionsList,
    incidentTypesList,
  } = useMemo(() => {
    const dateMap: DataMapType = {};
    const officesMap: DataMapType = {};
    const businessUnitsMap: DataMapType = {};
    const divisionsMap: DataMapType = {};
    const incidentTypesMap: DataMapType = {};

    if (options) {
      filteredIncidents.forEach((incident) => {
        const time = incident.incident_time;
        const date = dayjs(time).format("YYYY-MM-DD");
        if (time) {
          if (dateMap[date]) {
            dateMap[date].value += 1;
          } else {
            dateMap[date] = { id: date, value: 1 };
          }
        }
        if (incident.project.gc_office_id && options.gc_office.length) {
          if (officesMap[incident.project.gc_office_id]) {
            officesMap[incident.project.gc_office_id].value += 1;
          } else {
            const office = options.gc_office.find(
              (office) => office.id === incident.project.gc_office_id,
            );
            if (office) {
              officesMap[incident.project.gc_office_id] = {
                value: 1,
                id: office.name,
              };
            }
          }
        }
        incident.incident_types.forEach(({ type_value }) => {
          if (incidentTypesMap[type_value]) {
            incidentTypesMap[type_value].value += 1;
          } else {
            const type = options.incident_type.find(
              (t) => t.value === type_value,
            );
            if (type) {
              incidentTypesMap[type_value] = {
                id: type.translation.en,
                value: 1,
              };
            }
          }
        });
        if (
          incident.project.gc_business_unit_id &&
          options.gc_business_unit.length
        ) {
          if (businessUnitsMap[incident.project.gc_business_unit_id]) {
            businessUnitsMap[incident.project.gc_business_unit_id].value += 1;
          } else {
            const bu = options.gc_business_unit.find(
              (bu) => bu.id === incident.project.gc_business_unit_id,
            );
            if (bu) {
              businessUnitsMap[incident.project.gc_business_unit_id] = {
                value: 1,
                id: bu.name,
              };
            }
          }
        }

        if (incident.project.gc_division_id && options.gc_division.length) {
          if (divisionsMap[incident.project.gc_division_id]) {
            divisionsMap[incident.project.gc_division_id].value += 1;
          } else {
            const division = options.gc_division.find(
              (division) => division.id === incident.project.gc_division_id,
            );
            if (division) {
              divisionsMap[incident.project.gc_division_id] = {
                value: 1,
                id: division.name,
              };
            }
          }
        }
      });
    }
    return {
      dateData: Object.values(dateMap).sort(
        (a1, a2) => dayjs(a1.id).valueOf() - dayjs(a2.id).valueOf(),
      ),
      businessUnitsList: Object.values(businessUnitsMap).map(
        ({ id, value }, i) => ({
          value,
          name: id,
          color: colorList[i] || randomHexColor(),
        }),
      ),
      officesList: Object.values(officesMap).map(({ id, value }, i) => ({
        value,
        name: id,
        color: colorList[i] || randomHexColor(),
      })),
      divisionsList: Object.values(divisionsMap).map(({ id, value }, i) => ({
        value,
        name: id,
        color: colorList[i] || randomHexColor(),
      })),
      incidentTypesList: Object.values(incidentTypesMap).map(
        ({ id, value }, i) => ({
          value,
          name: id,
          color: colorList[i] || randomHexColor(),
        }),
      ),
    };
  }, [filteredIncidents, options]);
  const { bodyPartsAffectedList } = useMemo(() => {
    const bodyPartsAffectedMap: {
      [key: string]: {
        id: string;
        value: number;
        side?: string | null;
      };
    } = {};
    if (!options) {
      return { bodyPartsAffectedList: [] };
    } else {
      filteredBodyParts.forEach((ibpa) => {
        const key = ibpa.value; //TODO add logic for side selection too
        // + (ibpa.side ? `___${ibpa.side}` : "");
        if (bodyPartsAffectedMap[key]) bodyPartsAffectedMap[key].value += 1;
        else {
          bodyPartsAffectedMap[key] = {
            id: ibpa.value,
            side: ibpa.side,
            value: 1,
          };
        }
      });

      return {
        bodyPartsAffectedList: Object.values(bodyPartsAffectedMap).map(
          (bpaData, i) => {
            const bodyPart = options.injury_body_part_affected.find(
              (type) => type.value === bpaData.id,
            );
            if (!bodyPart) throw new Error("Unidentified injury type found");
            return {
              name: bodyPart.translation.en,
              color: colorList[i] || randomHexColor(),
              value: bpaData.value,
            };
          },
        ),
      };
    }
  }, [filteredBodyParts, options?.injury_body_part_affected]);

  const { causeList, injuryTypeList, severityList, subsList } = useMemo(() => {
    const injuryCauseMap: DataMapType = {};
    const subsMap: DataMapType = {};
    const injuryTypeMap: DataMapType = {};
    const severityMap: DataMapType = {};

    if (!options) {
      return {
        causeList: [],
        injuryTypeList: [],
        severityList: [],
        subsList: [],
      };
    } else {
      filteredInjuredUsers.forEach((injuredUser) => {
        if (injuredUser.subcontractor_id) {
          if (subsMap[injuredUser.subcontractor_id])
            subsMap[injuredUser.subcontractor_id].value += 1;
          else
            subsMap[injuredUser.subcontractor_id] = {
              id: injuredUser.subcontractor_id,
              value: 1,
            };
        }
        const injuryDetail = injuredUser.injury_detail;
        if (injuryDetail) {
          const cause = injuryDetail.injury_cause;
          const severityId = injuryDetail.injury_severity_id;
          const type = injuryDetail.injury_type;
          if (cause) {
            if (injuryCauseMap[cause]) injuryCauseMap[cause].value += 1;
            else injuryCauseMap[cause] = { id: cause, value: 1 };
          }
          if (severityId) {
            if (severityMap[severityId]) severityMap[severityId].value += 1;
            else severityMap[severityId] = { id: severityId, value: 1 };
          }
          if (type) {
            if (injuryTypeMap[type]) injuryTypeMap[type].value += 1;
            else injuryTypeMap[type] = { id: type, value: 1 };
          }
        }
      });

      const subsList: { name: string; value: number; color: string }[] = [];
      Object.values(subsMap).forEach((subData, i) => {
        const sub = subOptions.find((s) => s.id === subData.id);
        if (sub)
          subsList.push({
            name: sub.name,
            value: subData.value,
            color: colorList[i] || randomHexColor(),
          });
      });
      const { newDataSource: topSubs, othersCount } = groupDataForChart(
        subsList,
        70,
      );
      topSubs.sort((sub1, sub2) => sub1.name.localeCompare(sub2.name));
      if (othersCount)
        topSubs.push({
          name: "Others",
          value: othersCount,
          color: colorList[topSubs.length] || randomHexColor(),
        });

      return {
        causeList: Object.values(injuryCauseMap).map((causeData, i) => {
          const causeDetail = options.injury_cause.find(
            (cause) => cause.value === causeData.id,
          );
          if (!causeDetail) throw new Error("Unidentified injury cause found");
          return {
            name: causeDetail?.translation.en || "",
            color: colorList[i] || randomHexColor(),
            value: causeData.value,
          };
        }),
        severityList: Object.values(severityMap).map((severityData, i) => {
          const severityDetail = options.injury_severity.find(
            (severity) => severity.id === severityData.id,
          );
          if (!severityDetail)
            throw new Error("Unidentified injury severity found");
          return {
            name: severityDetail.name.en,
            color: colorList[i] || randomHexColor(),
            value: severityData.value,
          };
        }),
        injuryTypeList: Object.values(injuryTypeMap).map((typeData, i) => {
          const typeDetail = options.injury_type.find(
            (type) => type.value === typeData.id,
          );
          if (!typeDetail) throw new Error("Unidentified injury type found");
          return {
            name: typeDetail.translation.en,
            color: colorList[i] || randomHexColor(),
            value: typeData.value,
          };
        }),
        subsList: topSubs,
      };
    }
  }, [
    filteredInjuredUsers,
    options?.injury_type,
    options?.injury_cause,
    options?.injury_severity,
    subOptions,
  ]);

  const cardsToShow = [
    { title: "Total Incidents", info: filteredIncidents.length },
    { title: "Recordable", info: severityCounts.recordable },
    { title: "First Aid Only", info: severityCounts.firstAidOnly },
    { title: "Med. Trtmnt Only", info: severityCounts.medicalTreatmentOnly },
    { title: "Open", info: open },
    { title: "Pending", info: pending },
    { title: "Closed", info: closed },
  ];
  return (
    <div className="w-full flex flex-col gap-1">
      <ChartTitleNumbers items={cardsToShow} />
      {filteredIncidents.length === 0 ? null : (
        <div className={`flex flex-col gap-1`}>
          <div className={`grid grid-cols-2 gap-1`}>
            {gc.hierarchy_division_name && !!divisionsList.length && (
              <ChartWrapperCard
                title={`Incidents by ${gc.hierarchy_division_name}`}
                loading={optionsLoading}
              >
                <ShowPieChart dataSource={divisionsList} />
              </ChartWrapperCard>
            )}
            {gc.hierarchy_business_unit_name && !!businessUnitsList.length && (
              <ChartWrapperCard
                title={`Incidents by ${gc.hierarchy_business_unit_name}`}
                loading={optionsLoading}
              >
                <ShowPieChart dataSource={businessUnitsList} />
              </ChartWrapperCard>
            )}
            {gc.hierarchy_office_name && !!officesList.length && (
              <ChartWrapperCard
                title={`Incidents by ${gc.hierarchy_office_name}`}
                loading={optionsLoading}
              >
                <ShowPieChart dataSource={officesList} />
              </ChartWrapperCard>
            )}
            <ChartWrapperCard title="Incident Type" loading={optionsLoading}>
              <ShowPieChart
                valueTitle="Incidents"
                dataSource={incidentTypesList}
              />
            </ChartWrapperCard>
            <ChartWrapperCard
              title="Injury Classification"
              loading={optionsLoading}
            >
              <ShowPieChart valueTitle="Injuries" dataSource={severityList} />
            </ChartWrapperCard>
            <ChartWrapperCard title={"Injury Cause"} loading={optionsLoading}>
              <ShowPieChart valueTitle="Injuries" dataSource={causeList} />
            </ChartWrapperCard>
            <ChartWrapperCard title="Injury Type" loading={optionsLoading}>
              <ShowPieChart valueTitle="Injuries" dataSource={injuryTypeList} />
            </ChartWrapperCard>
            <ChartWrapperCard
              title="Body Part Affected"
              loading={optionsLoading}
            >
              <ShowPieChart
                // dataTitle="Body Part"
                dataSource={bodyPartsAffectedList}
              />
            </ChartWrapperCard>
          </div>
          <ChartWrapperCard
            title={`Injured Persons by Company`}
            loading={optionsLoading}
          >
            <ColumnChart
              dataSource={subsList.map((item) => ({
                value: item.value,
                xField: item.name,
                valueTitle: `Incidents`,
              }))}
            />
          </ChartWrapperCard>
          <ChartWrapperCard
            title={`Incidents Over Time`}
            loading={optionsLoading}
          >
            <LineChart
              dataSource={dateData.map((item) => ({
                xField: item.id,
                value: item.value,
              }))}
              valueTitle={`Incidents`}
            />
          </ChartWrapperCard>
        </div>
      )}
    </div>
  );
};
export default GCIncidentCharts;
