import React, { ChangeEventHandler, useState } from "react";
import { Checkbox, Input } from "antd";

const DemoDraft: React.FC = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked);
  };

  const handleInputChange:  ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "start", gap: "0px" }}>
      <Checkbox onChange={handleCheckboxChange} checked={isChecked}>
        Auto translate from English
      </Checkbox>
      <Input
        status="warning"
        prefix={<Checkbox onChange={handleCheckboxChange} checked={isChecked}></Checkbox>}
        placeholder="Enter text here"
        value={inputValue}
        onChange={handleInputChange}
        onClick={() => setIsChecked(false)}
        disabled={isChecked} 
        style={{ width: "300px" }}
      />      
    </div>
  );
};

export default DemoDraft;
