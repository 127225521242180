import { graphql } from "babel-plugin-relay/macro";
import { FC } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import CreateProcessingContent from "src/common/components/CreateProcessingContent";
import { ProcessingCertCardQuery } from "src/common/types/generated/relay/ProcessingCertCardQuery.graphql";
import { certificates_to_verify_bool_exp } from "src/common/types/generated/relay/types";
const ProcessingCertCard: FC<{
  certsToVerifyWhere: certificates_to_verify_bool_exp;
}> = withCustomSuspense(({ certsToVerifyWhere }) => {
  const data = useLazyLoadQuery<ProcessingCertCardQuery>(
    graphql`
      query ProcessingCertCardQuery(
        $certsToVerifyWhere: certificates_to_verify_bool_exp!
      ) {
        certificates_to_verify_connection(
          where: $certsToVerifyWhere
          first: 10000
        )
          @connection(
            key: "ProcessingCertCard_certificates_to_verify_connection"
            filters: ["where"]
          ) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              worker_id
              created_at
              project {
                name
                general_contractor {
                  name
                }
              }
              front_image_id
              front_image {
                url
              }
              uploaded_by_user {
                ...UserTitleFrag @relay(mask: false)
              }
              back_image_id
              back_image {
                url
              }
            }
          }
        }
      }
    `,
    { certsToVerifyWhere },
  );
  return (
    <CreateProcessingContent
      docsToVerify={data.certificates_to_verify_connection.edges.map(
        (p) => p.node,
      )}
    />
  );
});

export default ProcessingCertCard;
