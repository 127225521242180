/**
 * @generated SignedSource<<09c95005cf94a9067abe2593005ac123>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_subcontractor_worker_title_bool_exp, project_subcontractor_worker_title_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProjectSubWorkerTitleViewAndEdit_ChangeProjectSubWorkerTitle_Mutation$variables = {
  objects: ReadonlyArray<project_subcontractor_worker_title_insert_input>;
  toDeleteWhere: project_subcontractor_worker_title_bool_exp;
};
export type ProjectSubWorkerTitleViewAndEdit_ChangeProjectSubWorkerTitle_Mutation$data = {
  readonly delete_project_subcontractor_worker_title: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_project_subcontractor_worker_title: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly project_workers_aggregate: {
        readonly aggregate: {
          readonly count: number;
        } | null | undefined;
      };
      readonly worker_title: {
        readonly id: string;
        readonly translation: {
          readonly en: string;
          readonly id: string;
        };
      };
      readonly worker_title_id: string;
    }>;
  } | null | undefined;
};
export type ProjectSubWorkerTitleViewAndEdit_ChangeProjectSubWorkerTitle_Mutation = {
  response: ProjectSubWorkerTitleViewAndEdit_ChangeProjectSubWorkerTitle_Mutation$data;
  variables: ProjectSubWorkerTitleViewAndEdit_ChangeProjectSubWorkerTitle_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objects"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "toDeleteWhere"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "toDeleteWhere"
      }
    ],
    "concreteType": "project_subcontractor_worker_title_mutation_response",
    "kind": "LinkedField",
    "name": "delete_project_subcontractor_worker_title",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      }
    ],
    "concreteType": "project_subcontractor_worker_title_mutation_response",
    "kind": "LinkedField",
    "name": "insert_project_subcontractor_worker_title",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "project_subcontractor_worker_title",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "where",
                "value": {
                  "deleted_at": {
                    "_is_null": true
                  },
                  "is_last": {
                    "_eq": true
                  }
                }
              }
            ],
            "concreteType": "project_worker_aggregate",
            "kind": "LinkedField",
            "name": "project_workers_aggregate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_worker_aggregate_fields",
                "kind": "LinkedField",
                "name": "aggregate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "project_workers_aggregate(where:{\"deleted_at\":{\"_is_null\":true},\"is_last\":{\"_eq\":true}})"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_title",
            "kind": "LinkedField",
            "name": "worker_title",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "text_translation",
                "kind": "LinkedField",
                "name": "translation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "en",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "worker_title_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectSubWorkerTitleViewAndEdit_ChangeProjectSubWorkerTitle_Mutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProjectSubWorkerTitleViewAndEdit_ChangeProjectSubWorkerTitle_Mutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "6c66c0fad5b86bc35a254458305cccf5",
    "id": null,
    "metadata": {},
    "name": "ProjectSubWorkerTitleViewAndEdit_ChangeProjectSubWorkerTitle_Mutation",
    "operationKind": "mutation",
    "text": "mutation ProjectSubWorkerTitleViewAndEdit_ChangeProjectSubWorkerTitle_Mutation(\n  $toDeleteWhere: project_subcontractor_worker_title_bool_exp!\n  $objects: [project_subcontractor_worker_title_insert_input!]!\n) {\n  delete_project_subcontractor_worker_title(where: $toDeleteWhere) {\n    affected_rows\n  }\n  insert_project_subcontractor_worker_title(objects: $objects) {\n    returning {\n      id\n      project_workers_aggregate(where: {is_last: {_eq: true}, deleted_at: {_is_null: true}}) {\n        aggregate {\n          count\n        }\n      }\n      worker_title {\n        id\n        translation {\n          en\n          id\n        }\n      }\n      worker_title_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "66ec9b76e106595d376083fc1894aa72";

export default node;
