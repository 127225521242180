/**
 * @generated SignedSource<<0c2780a4b5ef54655e8f1e77a3ad40aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { safety_data_sheet_bool_exp, safety_data_sheet_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SCSds_UpdateSdsMutation$variables = {
  _set?: safety_data_sheet_set_input | null | undefined;
  where: safety_data_sheet_bool_exp;
};
export type SCSds_UpdateSdsMutation$data = {
  readonly update_safety_data_sheet: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type SCSds_UpdateSdsMutation = {
  response: SCSds_UpdateSdsMutation$data;
  variables: SCSds_UpdateSdsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "_set"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "safety_data_sheet_mutation_response",
    "kind": "LinkedField",
    "name": "update_safety_data_sheet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SCSds_UpdateSdsMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SCSds_UpdateSdsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ad35f72f23fca77416973869412a1841",
    "id": null,
    "metadata": {},
    "name": "SCSds_UpdateSdsMutation",
    "operationKind": "mutation",
    "text": "mutation SCSds_UpdateSdsMutation(\n  $_set: safety_data_sheet_set_input\n  $where: safety_data_sheet_bool_exp!\n) {\n  update_safety_data_sheet(_set: $_set, where: $where) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "6cb7a05ce421eec0d410fffdf31166e6";

export default node;
