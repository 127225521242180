// @ts-nocheck
import { SubscriptionClient } from "graphql-subscriptions-client";
import { createRef, MutableRefObject } from "react";
import {
  authMiddleware,
  batchMiddleware,
  cacheMiddleware,
  RelayNetworkLayer,
  retryMiddleware,
  urlMiddleware,
} from "react-relay-network-modern";
import {
  ConnectionHandler,
  DefaultHandlerProvider,
  Environment,
  HandleFieldPayload,
  Observable,
  RecordSource,
  RecordSourceSelectorProxy,
  Store,
} from "relay-runtime";
import keys from "src/common/constants/keys";
import { auth } from "src/common/functions/firebase";
import { isDevelopmentBuild } from "../../constants/environment";

const LANG = "en";
const __DEV__ = isDevelopmentBuild;

const ClientTextHandler = {
  update(store: RecordSourceSelectorProxy, payload: HandleFieldPayload) {
    const record = store.get(payload.dataID)!;
    const clientText = record.getValue(LANG);
    record.setValue(clientText, payload.handleKey);
  },
};

const PKHandler = {
  update(store: RecordSourceSelectorProxy, payload: HandleFieldPayload) {
    const record = store.get(payload.dataID)!;
    // TODO
    const rowId = JSON.parse(atob(payload.dataID))[3];
    record.setValue(rowId, payload.handleKey);
  },
};

//let subscriptionClient: MutableRefObject<SubscriptionClient | null> =
//  createRef();

interface RelayEnvironmentResult {
  store: Store;
  //subscriptionClient: SubscriptionClient;
  relayEnvironment: Environment;
}

export const createRelayEnvironment = (): RelayEnvironmentResult => {
  const recordSource = new RecordSource();
  const store = new Store(recordSource);
/*
  const headers: any = {};

  // TODO: change it
  auth.currentUser?.getIdToken().then((token) => {
    headers.authorization = `Bearer ${token}`;
  });

  //  if (subscriptionClient.current) {
  //    subscriptionClient.current.unsubscribeAll();
  //    subscriptionClient.current.close();
  //  }
  // console.log("create subscription client");

  const client = new SubscriptionClient(keys.hasuraRelayWebSocket, {
    reconnect: false,
    connectionParams: {
      headers,
    },
  });
*/
  const network = new RelayNetworkLayer(
    [
      cacheMiddleware({}),
      urlMiddleware({
        url: () => keys.hasuraRelayUrl,
      }),
      batchMiddleware({
        batchUrl: () => keys.hasuraRelayUrl,
        batchTimeout: 10,
        allowMutations: true,
      }),
      authMiddleware({
        allowEmptyToken: true,
        token: async () => {
          const token = await auth.currentUser?.getIdToken(); // TODO remove it
          return token ?? "";
        },
        tokenRefreshPromise: async () => {
          const token = await auth.currentUser?.getIdToken();
          return token ?? "";
        },
      }),
      retryMiddleware({
        fetchTimeout: 16000,
        // fetchTimeout: 1000,
        retryDelays: (attempt) => Math.pow(2, attempt + 4) * 100,
        beforeRetry: ({ abort, attempt }) => {
          // TODO lang
          if (attempt > 2) abort("Retry network error.");
        },
        statusCodes: [408, 500, 503, 504],
      }),
      __DEV__ && false
        ? (next) => (req) => {
            return new Promise((res) => {
              setTimeout(() => {
                res(next(req));
              }, Math.random() * 3000);
            });
          }
        : null,
      __DEV__
        ? (next) => (req) => {
            console.log("RELAY:", req.getID().split(" ")[0]);
            //console.log(req.getQueryString());
            //console.log(req.getBody());
            return next(req);
          }
        : null,
    ],
    {
      // noThrow: true,
/*      subscribeFn: (operation, variables) => {
        // TODO auth token
        //if (!client) return;

        const query = operation.text;

        if (!query) return;

        const req = client.request({
          query,
          variables,
          operationName: operation.name,
        });

        const obs = (req);

        obs.catch((err) => {
          // TODO works?
          if (err.message.includes("JWT")) {
            console.log("RELAY ERR:", err);
            //            client.unsubscribeAll();
            //            client.close();
            //            refreshRelayEnvironment();
          }

          return obs;
        });

        return obs;
      },*/

    },
  );

  const env = new Environment({
    network,
    store,
    handlerProvider: (handle) => {
      switch (handle) {
        case "clientText":
          return ClientTextHandler;
        case "pk":
          return PKHandler;
        case "connection":
          return ConnectionHandler;
        default:
          return DefaultHandlerProvider(handle);
      }
    },
  });
  const result = { relayEnvironment: env, /*subscriptionClient: client,*/ store };
  return result;
};

/*
export const getRelayEnvironment = () => environment;
export const refreshRelayEnvironment = async () => {
  const token = await auth.currentUser?.getIdToken();

  const headers: any = {};

  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  if (subscriptionClient.current) {
    subscriptionClient.current.unsubscribeAll();
    subscriptionClient.current.close();
  }
  console.log("create subscription client");
  subscriptionClient.current = new SubscriptionClient(
    keys.hasuraRelayWebSocket,
    {
      reconnect: false,
      connectionParams: {
        headers,
      },
    }
  );

  environment = createRelayEnvironment();

//  Emitter.emit("RELAY_ENV_UPDATE", environment);
};*/
