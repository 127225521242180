import { message, notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { useLazyLoadQuery, useRelayEnvironment } from "react-relay/hooks";
import { useParams } from "react-router-dom";
import apolloClient from "src/common/api/apollo/apolloClient";
import { CreateGCEmployeeFormValues } from "src/common/components/dialogs/CreateGCEmployeeModal";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import {
  GetNameUsernameDocument,
  GetNameUsernameQuery,
  GetNameUsernameQueryVariables,
  useCreateGeneralContractorEmployeeMutation,
  useUpdateUserHierarchyTeamsMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { GCProjectTeamQuery } from "src/common/types/generated/relay/GCProjectTeamQuery.graphql";
import { GCProjectTeam_updateProjectEmployee_Mutation } from "src/common/types/generated/relay/GCProjectTeam_updateProjectEmployee_Mutation.graphql";
import { GCProjectTeam_deleteProjectEmployee_Mutation } from "src/common/types/generated/relay/GCProjectTeam_deleteProjectEmployee_Mutation.graphql";
import GCProjectTeamUI, { GCProjectTeamUIProps } from "./GCProjectTeamUI";
import sendInvite from "src/utility-features/invitations/sendInvite";
import { getInvitedUserData } from "src/common/functions/invitedUserEmailData";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";

const query = graphql`
  query GCProjectTeamQuery(
    $projectEmpoyeeWhere: project_employee_bool_exp!
    $projectId: uuid!
  ) {
    general_contractor_connection(
      where: { projects: { id: { _eq: $projectId } } }
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name
        }
      }
    }
    project_employee_connection(
      where: $projectEmpoyeeWhere
      order_by: { employee: { user: { name: asc } } }
      first: 10000
    )
      @connection(
        key: "GCProjectTeam_project_employee_connection"
        filters: []
      ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          status
          project {
            id
            name
          }
          employee {
            employee_title {
              id
              name {
                en
              }
            }
            is_corporate_admin
            user {
              id
              pk: id @__clientField(handle: "pk")
              name
              email
              phone_number
              created_password
              terminated_at
              profile_picture {
                id
                url
              }
            }
          }
        }
      }
    }
    project_subcontractor_connection(
      where: { project_id: { _eq: $projectId } }
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          subcontractor {
            id
            pk: id @__clientField(handle: "pk")
            name
          }
        }
      }
    }
  }
`;
const updateProjectEmployeeMutation = graphql`
  mutation GCProjectTeam_updateProjectEmployee_Mutation(
    $id: uuid!
    $status: String!
  ) {
    update_project_employee_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status }
    ) {
      id
      status
    }
  }
`;
const deleteProjectEmployeeMutation = graphql`
  mutation GCProjectTeam_deleteProjectEmployee_Mutation(
    $userId: uuid!
    $projectId: uuid!
  ) {
    delete_project_employee(
      where: { employee_id: { _eq: $userId }, project_id: { _eq: $projectId } }
    ) {
      affected_rows
    }
  }
`;

const GCProjectTeam: React.FC = () => {
  const [createEmployee] = useCreateGeneralContractorEmployeeMutation();
  const [updateUserHierarchyTeams] = useUpdateUserHierarchyTeamsMutation();
  const [fetchKey, setFetchKey] = useState(0);
  const { projectId } = useParams();
  if (!projectId) {
    throw new Error("Project id not found");
  }
  const { userData } = useUserData();
  const environment = useRelayEnvironment();
  const [updateProjectEmployee] =
    useAsyncMutation<GCProjectTeam_updateProjectEmployee_Mutation>(
      updateProjectEmployeeMutation,
    );
  const [deleteProjectEmployee] =
    useAsyncMutation<GCProjectTeam_deleteProjectEmployee_Mutation>(
      deleteProjectEmployeeMutation,
    );

  const data = useLazyLoadQuery<GCProjectTeamQuery>(
    query,
    {
      projectEmpoyeeWhere: {
        project_id: { _eq: projectId },
        _or: [
          { direct_project_assign: { _is_null: true } },
          { direct_project_assign: { _eq: true } },
        ],
        status: { _in: ["Active", "Inactive"] },
      },
      projectId: projectId,
    },
    {
      fetchKey,
      fetchPolicy: "network-only",
    },
  );
  // console.log(data, "subsuhdjnjkdata");

  const refetch = () => {
    setFetchKey((prev) => prev + 1);
  };

  const gcId = data.general_contractor_connection.edges[0].node.pk;
  const gcTeamProps: GCProjectTeamUIProps = {
    projectsToInvite: [projectId],
    onInviteSuccess: (userIds) => {},

    directProjectTeam: true,
    excludeEmployeeWhere: {
      employee_projects: {
        project_id: { _eq: projectId },
        direct_project_assign: { _eq: true },
      },
    },
    gcUsers: data.project_employee_connection.edges.map((gc) => ({
      loading: false,
      userId: gc.node.employee.user.pk,
      generalContractorId: gcId,
      isCorporateAdmin: gc.node.employee.is_corporate_admin,
      dataId: gc.node.id,
      title: gc.node.employee.employee_title?.name.en,
      email: gc.node.employee.user.email ?? undefined,
      phoneNumber: gc.node.employee.user.phone_number ?? undefined,
      type: "project",
      onRemoveFromTeam: userData.employee?.is_corporate_admin
        ? async (userId) => {
            await deleteProjectEmployee({ variables: { userId, projectId } });
            refetch();
          }
        : undefined,
      terminatedAt: gc.node.employee.user.terminated_at ?? undefined,
      isAdmin: false,
      status: {
        active: gc.node.status === "Active",
        onActivate: async () => {
          await updateProjectEmployee({
            variables: {
              id: gc.node.pk,
              status: "Active",
            },
            optimisticResponse: {
              update_project_employee_by_pk: {
                id: gc.node.id,
                status: "Active",
              },
            },
          });
        },
        onDeactivate: async () => {
          await updateProjectEmployee({
            variables: {
              id: gc.node.pk,
              status: "Inactive",
            },
            optimisticResponse: {
              update_project_employee_by_pk: {
                id: gc.node.id,
                status: "Inactive",
              },
            },
          });
        },
      },
      reSendInvite: async () => {
        const toEmail = gc.node.employee.user.email ?? undefined;
        const toName = gc.node.employee.user.name;
        const projectName = gc.node.project.name;
        if (toEmail) {
          const { success, error } = await sendInvite({
            sendTo: toEmail,
            joinProjectId: projectId,
            sendToName: toName,
            claimingAccount: true,
            inviteType: "reSendGCInvite",
          });
          if (success == false) {
            notification.error({
              message: "Error: ",
              description: error,
              duration: null,
            });
          } else {
            message.success("Invite sent");
          }
        }
      },
      name: gc.node.employee.user.name,
      avatarUrl: gc.node.employee.user.profile_picture?.url,
      created_password: gc.node.employee.user.created_password,
    })),
    subs: data.project_subcontractor_connection.edges.map((sub) => ({
      name: sub.node.subcontractor.name,
      id: sub.node.subcontractor.pk,
    })),

    onCreateGCEmployee: async (values: CreateGCEmployeeFormValues) => {
      const invitedUserData = await getInvitedUserData(
        values.email,
        environment,
      );
      if (invitedUserData && invitedUserData.user_connection.edges[0]) {
        if (invitedUserData.user_connection.edges[0].node.created_password) {
          const { success, error } = await sendInvite({
            sendTo: values.email,
            toUid: invitedUserData.user_connection.edges[0].node.pk,
            joinProjectId: projectId,
            claimingAccount: true,
            inviteType: "loginInvite",
          });
          if (success == false) {
            notification.error({
              message: "Error: ",
              description: error,
              duration: null,
            });
          } else {
            message.success("Invite sent");
          }
          notification.info({
            message: "User Already Exists ",
            description:
              "This user already exists in your organization. They have been added to your project. If they are having trouble logging in, they can select Forgot Password on the login screen",
            duration: null,
          });
        } else {
          await sendInvite({
            sendTo: values.email,
            toUid: invitedUserData.user_connection.edges[0].node.pk,
            joinProjectId: projectId,
            claimingAccount: true,
            inviteType: "reSendGCInvite",
          });
          notification.error({
            message: "User Already Exists ",
            description:
              "This user already exists in your organization. They have been added to your project. If they are having trouble logging in, they can select Forgot Password on the login screen",
            duration: null,
          });
        }
        return;
      }
      const { data } = await apolloClient.query<
        GetNameUsernameQuery,
        GetNameUsernameQueryVariables
      >({
        query: GetNameUsernameDocument,
        variables: {
          name: values.name,
        },
      });
      if (data) {
        await createEmployee({
          variables: {
            name: values.name,
            email: values.email,
            phoneNumber: values.phone,
            generalContractorId: gcId!,
            titleId: values.titleId,
            username: data!.getNewUsername!,
            projectIds: [projectId],
            directProjectAssign: true, // phoneNumber: un
          },
        }).catch((error) => {
          message.error("Error : " + error);
        });
        refetch();
      } else {
        console.log("NO DATA");
      }
    },
  };

  return <GCProjectTeamUI {...gcTeamProps} />;
};

const GCProjectTeamWrapper = () => {
  return (
    <BasicWrapper>
      <GCProjectTeam />
    </BasicWrapper>
  );
};

export default withCustomSuspense(GCProjectTeamWrapper);
