import { FunctionComponent, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SiteFeatureLayoutBody from "src/common/components/layouts/steps-navigation-layout/SiteFeatureLayoutBody";
import PtpQrUserBirthDate from "./stepsUI/birth-date/PtpQrUserBirthDate";
import PtpQrUserFullName from "./stepsUI/full-name/PtpQrUserFullName";
import apolloClient from "src/common/api/apollo/apolloClient";
import {
  useSiteFeatureStepsNavigationDirection,
  useSiteFeatureStepsNavigationEffect,
} from "src/common/components/layouts/steps-navigation-layout/siteFeatureRegistrationNavigationHooks";
import withSiteFeatureNavigationWrapper from "src/common/components/layouts/steps-navigation-layout/withSiteFeatureNavigationWrapper";
import { withLanguageProvider } from "src/utility-features/i18n/context/languageContext";
import PtpQrStepsHello from "./stepsUI/hello/PtpQrStepsHello";
import { AVAILABLE_LANGUAGES } from "src/utility-features/i18n/languages";
import { useCurrentLanguageState } from "src/utility-features/i18n/context/languageHooks";
import CrewGroupedWorkerList from "./stepsUI/crew-workers/CrewGroupedWorkerList";
import PtpQrUserEmail from "./stepsUI/email/PtpQrUserEmail";
import TaskSelectionForPtpSubmission from "./stepsUI/task-selection/PtpQrTaskSelection";
import PtpQrCodeTaskSign from "./stepsUI/task-sign/PtpQrTaskSign";
import PtpQrReviewInjuries from "./stepsUI/review-injuries/PtpQrReviewInjuries";
import PtpQrDailyReportInfo from "./stepsUI/daily-report/PtpQrDailyReportInfo";
import PtpQrReviewHazards from "./stepsUI/review-hazards/PtpQrReviewHazards";
import PtpQrUserContext, {
  PtpQrUserContextType,
} from "./../utils/ptpQrUserContext";
import PtpQrHomePage from "./stepsUI/home-page/PtpQrHomePage";
import PtpQrWorkerRole from "./stepsUI/worker-role/PtpQrWorkerRole";
import PtpQrWorkerTrade from "./stepsUI/worker-trade/PtpQrWorkerTrade";
import PtpSubmissionType from "./stepsUI/submission-type/PtpQrSubmissionType";
import PtpQrUserPhoneNumber from "./stepsUI/phone-number/PtpQrUserPhoneNumber";
import PtpQrSubmitPage from "./stepsUI/submit-page/PtpQrSubmitPage";
import PtpQrPhotoUpload from "./stepsUI/photo-upload/PtpQrPhotoUpload";
import PtpQrSubcontractor from "./stepsUI/subcontractor/PtpQrSubcontractor";
import { useSuspenseQuery } from "@apollo/client";
import {
  GetProjectNameDocument,
  GetProjectNameQueryVariables,
  GetProjectNameQuery,
  GetPtpQrCodeDataQuery,
  GetPtpQrCodeDataQueryVariables,
  GetPtpQrCodeDataDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import { auth } from "src/common/functions/firebase";
import { inMemoryPersistence } from "firebase/auth";
import { PtpQrUserState } from "src/domain-features/sitesafety/entryRoutes/qrProjectReports/routes/ptp/utils/ptpQrUserContext";
import PtpQrCodeLocations from "./stepsUI/location/PtpQrCodeLocations";
import PtpQrReviewPendingTBTs from "./stepsUI/tool-box-talk/PtpQrReviewPendingTBTs";

const PtpQrCodeView: FunctionComponent = () => {
  const { projectId } = useParams();
  const localStorageEmail = localStorage.getItem("ptpQrUserEmail");
  const localStorageName = localStorage.getItem("ptpQrUserName");
  if (!projectId) {
    throw new Error("ProjectId not found");
  }
  const { data } = useSuspenseQuery<
    GetProjectNameQuery,
    GetProjectNameQueryVariables
  >(GetProjectNameDocument, {
    variables: { where: { id: { _eq: projectId } } },
    fetchPolicy: "cache-and-network",
  });

  const [locationOptions, setLocationOptions] = useState<
    NonNullable<PtpQrUserContextType>["locationOptions"]
  >([]);
  const [language, setLanguage] = useCurrentLanguageState();

  const [ptpQrState, setPtpQrState] = useState<PtpQrUserState>({
    dailyWorkLog: { description: "", hours: 8 },
    pictures: [],
    projectHazardAndAwareness: { awarenessQuestions: {}, projectHazards: {} },
    reportManagement: {
      safetyReportAwarenessDisabled: false,
      submitDailyReportWithPtp: false,
      requireAllWorkersSign: false,
    },
    selectedLocationIds: [],
    selectedTasks: {},
    signInType: null,
    userInfo: { email: localStorageEmail, name: localStorageName || undefined },
    workerSignMap: {},
    pendingTbts: [],
    selectedProjectCrews: new Set<string>(),
    workersInfo: {
      injuredWorkers: new Set<string>(),
      workersPresent: new Set<string>(),
    },
    timezone: "America/New_York",
    isMarkOnsiteEnabled: false,
    isSubOnsite: false,
  });
  useEffect(() => {
    auth.setPersistence(inMemoryPersistence);
    const alertUser = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = `Are you sure? Your information will be lost`;
    };
    window.addEventListener("beforeunload", alertUser);

    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const { goToNextStep, goToPrevStep, nextStepDirection, clearStepDirection } =
    useSiteFeatureStepsNavigationDirection();
  const projectName = data.project.at(0)?.name || "";
  const onLoginSuccess = async (userId: string) => {
    const { data } = await apolloClient.query<
      GetPtpQrCodeDataQuery,
      GetPtpQrCodeDataQueryVariables
    >({
      query: GetPtpQrCodeDataDocument,
      variables: {
        userId,
        projectId,
      },
    });
    const project = data.project.at(0);
    const fetchedProjWorker = data.project_worker.at(0);

    if (!project) throw new Error("no project found for given projectId");
    if (!fetchedProjWorker)
      throw new Error("No Project Worker relation found on this");
    const worker = fetchedProjWorker.worker;
    if (!worker) throw new Error("fetched user is not a worker");
    const fetchedUser = worker.user;
    if (!fetchedUser)
      throw new Error("no user found for given logged in workerid");

    const userRole = fetchedUser.role;

    //inserting project worker on resolver side if not present
    if (fetchedUser.email)
      localStorage.setItem("ptpQrUserEmail", fetchedUser.email);
    localStorage.setItem("ptpQrUserName", fetchedUser.name);
    const projLocations = project.project_locations;
    const projSettings = project.project_setting;
    const projSubLocationIds = (
      fetchedProjWorker.project_subcontractor
        ?.project_subcontractor_locations || []
    ).map((psl) => psl.project_location_id);
    const subActiveLocations = projLocations.filter((l) =>
      projSubLocationIds.includes(l.id),
    );
    const locationsToSelect =
      subActiveLocations.length === 0 ? projLocations : subActiveLocations;
    if (projLocations.length >= 2 && subActiveLocations.length !== 1)
      setLocationOptions(
        locationsToSelect.map((l) => ({ value: l.id, label: l.name.en })),
      );
    setPtpQrState((prev) => ({
      ...prev,
      selectedLocationIds:
        projLocations.length === 1
          ? [projLocations[0].id]
          : subActiveLocations.length === 1
          ? [subActiveLocations[0].id]
          : [],
      userInfo: {
        id: userId,
        email: fetchedUser.email,
        name: fetchedUser.name,
        birthDate: fetchedUser.birth_date ? fetchedUser.birth_date : undefined,
        role: userRole || "",
        company: fetchedProjWorker.subcontractor_id,
        projectWorkerId: fetchedProjWorker.id,
        projectCrewId: fetchedProjWorker.project_crew_id ?? undefined,
      },
      reportManagement: {
        safetyReportAwarenessDisabled: project.safety_report_awareness_disabled,
        submitDailyReportWithPtp:
          project.add_daily_work_log_during_ptp &&
          !!fetchedProjWorker.project_subcontractor
            ?.add_daily_work_log_during_ptp,
        requireAllWorkersSign: !!projSettings?.ptp_require_all_workers_to_sign,
      },
      signInType: project.safety_report_awareness_disabled
        ? "quick"
        : projSettings?.ptp_require_all_workers_to_sign
        ? "signature"
        : null,
      timezone: project.timezone,
      isSubOnsite:
        (fetchedProjWorker.project_subcontractor
          ?.project_subcontractor_onsite_periods_aggregate.aggregate?.count ??
          0) > 0,
      isMarkOnsiteEnabled: project.mark_sub_onsite_on_report_submission,
    }));
  };

  const steps = [
    {
      id: "welcome",
      title: "Welcome",
      body: <PtpQrStepsHello onNext={goToNextStep} project={projectName} />,
    },
    {
      id: "email",
      title: "Email",
      body: (
        <PtpQrUserEmail
          onNext={goToNextStep}
          onBack={goToPrevStep}
          onLoginSuccess={onLoginSuccess}
        />
      ),
      disabled:
        !!ptpQrState.userInfo?.email &&
        ptpQrState.userInfo.email === localStorageEmail,
    },
    {
      id: "home",
      title: "Pre-Task Plan",
      body: (
        <PtpQrHomePage
          onNext={goToNextStep}
          onBack={goToPrevStep}
          onLoginSuccess={onLoginSuccess}
        />
      ),
      disabled: !ptpQrState.userInfo?.name,
    },
    {
      title: "Full name",
      id: "full_name",
      body: <PtpQrUserFullName onNext={goToNextStep} onBack={goToPrevStep} />,
      disabled: !!ptpQrState.userInfo?.projectWorkerId,
    },
    {
      title: "Birth date",
      id: "birth_date",
      body: (
        <PtpQrUserBirthDate
          onNext={goToNextStep}
          onBack={goToPrevStep}
          onLoginSuccess={onLoginSuccess}
        />
      ),
      disabled: !!ptpQrState.userInfo?.projectWorkerId,
    },
    {
      title: "Company",
      id: "subcontractor",
      body: <PtpQrSubcontractor onNext={goToNextStep} onBack={goToPrevStep} />,
      disabled: !!ptpQrState.userInfo?.projectWorkerId,
    },
    {
      title: "Worker Role",
      id: "role",
      body: <PtpQrWorkerRole onNext={goToNextStep} onBack={goToPrevStep} />,
      disabled: !!ptpQrState.userInfo?.projectWorkerId,
    },
    {
      title: "Worker Trade",
      id: "trade",
      body: <PtpQrWorkerTrade onNext={goToNextStep} onBack={goToPrevStep} />,
      disabled: !!ptpQrState.userInfo?.projectWorkerId,
    },
    {
      title: "Phone Number",
      id: "phone",
      body: (
        <PtpQrUserPhoneNumber
          onNext={goToNextStep}
          onBack={goToPrevStep}
          onLoginSuccess={onLoginSuccess}
        />
      ),
      disabled: !!ptpQrState.userInfo?.projectWorkerId,
    },
    {
      title: "Select JHA(s)",
      id: "jha",
      body: (
        <TaskSelectionForPtpSubmission
          onNext={goToNextStep}
          onBack={goToPrevStep}
        />
      ),
    },
    {
      title: "Select Location(s)",
      id: "locations",
      body: <PtpQrCodeLocations onNext={goToNextStep} onBack={goToPrevStep} />,
      disabled: locationOptions.length === 0,
    },
    {
      title: "Review Injuries",
      id: "reviewInjury",
      body: (
        <PtpQrReviewInjuries
          onNext={goToNextStep}
          onBack={goToPrevStep}
          includeCrew={ptpQrState.userInfo?.role === "worker"}
        />
      ),
    },
    {
      title: "Review Pending TBTs",
      id: "toolBoxTalk",
      body: (
        <PtpQrReviewPendingTBTs onNext={goToNextStep} onBack={goToPrevStep} />
      ),
      disabled: ptpQrState.pendingTbts.length === 0,
    },
    {
      title: "Daily Report Info",
      id: "dailyReportInfo",
      body: (
        <PtpQrDailyReportInfo onNext={goToNextStep} onBack={goToPrevStep} />
      ),
      disabled: !ptpQrState.reportManagement.submitDailyReportWithPtp,
    },
    {
      title: "Review Hazards",
      id: "reviewHazards",
      body: <PtpQrReviewHazards onNext={goToNextStep} onBack={goToPrevStep} />,
      disabled: ptpQrState.reportManagement.safetyReportAwarenessDisabled,
    },
    {
      title: "Roll Call",
      id: "reportType",
      body: <PtpSubmissionType onNext={goToNextStep} onBack={goToPrevStep} />,
      disabled:
        ptpQrState.reportManagement.requireAllWorkersSign ||
        ptpQrState.reportManagement.safetyReportAwarenessDisabled,
    },
    {
      title: "Daily PTP Huddle",
      id: "photo",
      body: <PtpQrPhotoUpload onNext={goToNextStep} onBack={goToPrevStep} />,
      disabled: ptpQrState.signInType === "signature",
    },
    {
      title: "Workers",
      id: "worker",
      body: (
        <CrewGroupedWorkerList onNext={goToNextStep} onBack={goToPrevStep} />
      ),
    },
    {
      title: "Sign-in",
      id: "submitterSign",
      body: (
        <PtpQrCodeTaskSign
          onNext={goToNextStep}
          onBack={goToPrevStep}
          workerId={ptpQrState.userInfo?.projectWorkerId}
          submitterSign
        />
      ),
      disabled: ptpQrState.signInType === "signature",
    },
    {
      title: `Submit Pre-Task Plan`,
      id: "submit",
      body: <PtpQrSubmitPage onNext={goToNextStep} onBack={goToPrevStep} />,
    },
  ];
  const { currentStepId } = useSiteFeatureStepsNavigationEffect(
    nextStepDirection,
    clearStepDirection,
    steps,
  );

  return (
    <PtpQrUserContext.Provider
      value={{
        locationOptions,
        projectId,
        ptpQrUser: ptpQrState,
        setPtpQrUser: setPtpQrState,
      }}
    >
      <SiteFeatureLayoutBody
        steps={steps}
        currentStepId={currentStepId || undefined}
        languagePicker={{
          availableLangs: AVAILABLE_LANGUAGES,
          selectedLang: language,
          onLangeChange: setLanguage,
        }}
        languagePickerHidden={currentStepId === "welcome"}
        loading={false}
      />
    </PtpQrUserContext.Provider>
  );
};

export default withSiteFeatureNavigationWrapper(
  withLanguageProvider(PtpQrCodeView),
);
