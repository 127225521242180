/**
 * @generated SignedSource<<19048399f925c83cfcb6fcdddf7b276b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectWorkerTable_project_worker$data = {
  readonly project_worker_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly archived_at: string | null | undefined;
        readonly archived_by_user: {
          readonly employee: {
            readonly employee_title: {
              readonly name: {
                readonly en: string;
              };
            } | null | undefined;
            readonly general_contractor: {
              readonly name: string;
            };
          } | null | undefined;
          readonly name: string;
          readonly role: string;
        } | null | undefined;
        readonly created_at: string;
        readonly deleted_at: string | null | undefined;
        readonly hard_hat_number: string | null | undefined;
        readonly id: string;
        readonly pk: string;
        readonly project: {
          readonly name: string;
          readonly show_onsite_for_not_oriented_workers: boolean;
        };
        readonly project_crew: {
          readonly lead_foreman_project_worker_id: string | null | undefined;
          readonly pk: string;
        } | null | undefined;
        readonly project_id: string;
        readonly project_workers_aggregate?: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
        readonly subcontractor?: {
          readonly name: string;
        };
        readonly subcontractor_id: string;
        readonly title: {
          readonly translation: {
            readonly clientText: string;
            readonly en: string;
            readonly es: string;
            readonly id: string;
            readonly original: string;
            readonly pk: string;
            readonly pt: string;
          };
        } | null | undefined;
        readonly title_id: string | null | undefined;
        readonly user: {
          readonly allow_mobile_login: boolean;
          readonly created_password: boolean;
          readonly email: string | null | undefined;
          readonly id: string;
          readonly invites: ReadonlyArray<{
            readonly accepted_at: string | null | undefined;
            readonly id: string;
            readonly pk: string;
          }>;
          readonly name: string;
          readonly phone_number: string | null | undefined;
          readonly role: string;
          readonly universal_orientations: ReadonlyArray<{
            readonly universal_orientated_at: string;
            readonly universal_orientation_results: ReadonlyArray<{
              readonly orientation_id: string;
              readonly quiz_results: ReadonlyArray<{
                readonly answer: string | null | undefined;
                readonly lang: string | null | undefined;
                readonly orientation_slide_id: string;
                readonly slide: {
                  readonly answer_items: string | null | undefined;
                  readonly answer_items_es: string | null | undefined;
                  readonly answer_items_fr: string | null | undefined;
                  readonly answer_items_it: string | null | undefined;
                  readonly answer_items_pt: string | null | undefined;
                  readonly answer_items_zh_hans: string | null | undefined;
                  readonly check_correct_answer: boolean;
                  readonly correct_answers: string | null | undefined;
                  readonly question_ui_element: string | null | undefined;
                  readonly title: string;
                };
              }>;
            }>;
          }>;
        } | null | undefined;
        readonly user_orientation?: {
          readonly background_check_done: boolean | null | undefined;
          readonly background_check_done_by: {
            readonly name: string;
          } | null | undefined;
          readonly background_check_done_by_uid: string | null | undefined;
          readonly background_check_updated_at: string | null | undefined;
          readonly completed_at: string | null | undefined;
          readonly drug_test: {
            readonly drug_test_date: string;
            readonly status: string | null | undefined;
          } | null | undefined;
          readonly drug_test_id: string | null | undefined;
          readonly drug_test_updated_at: string | null | undefined;
          readonly in_person_orientated_at: string | null | undefined;
          readonly in_person_signature_id: string | null | undefined;
          readonly orientated_at: string | null | undefined;
          readonly orientation_provided_by_user_id: string | null | undefined;
          readonly signature_id: string | null | undefined;
        } | null | undefined;
        readonly worker: {
          readonly allOrientationProjects: ReadonlyArray<{
            readonly project_id: string;
          }>;
          readonly certificates_to_verify_aggregate: {
            readonly aggregate: {
              readonly count: number;
            } | null | undefined;
          };
          readonly id: string;
          readonly latestDT: ReadonlyArray<{
            readonly drug_test_date: string;
            readonly pk: string;
            readonly status: string | null | undefined;
          }>;
          readonly pk: string;
          readonly subcontractor_id: string | null | undefined;
          readonly validDTPresent?: ReadonlyArray<{
            readonly pk: string;
          }>;
          readonly worker_certifications: ReadonlyArray<{
            readonly certification: {
              readonly name: string;
            };
            readonly expires_on: string | null | undefined;
            readonly pk: string;
          }>;
        };
        readonly worker_id: string;
        readonly worker_role: string;
      };
    }>;
  };
  readonly " $fragmentType": "ProjectWorkerTable_project_worker";
};
export type ProjectWorkerTable_project_worker$key = {
  readonly " $data"?: ProjectWorkerTable_project_worker$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectWorkerTable_project_worker">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "project_worker_connection"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v7 = [
  (v5/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v9 = {
  "kind": "Literal",
  "name": "limit",
  "value": 1
},
v10 = {
  "kind": "Literal",
  "name": "order_by",
  "value": {
    "drug_test_date": "desc_nulls_last"
  }
},
v11 = {
  "_is_null": false
},
v12 = {
  "kind": "Variable",
  "name": "_and.1",
  "variableName": "drugtestWhere"
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "drug_test_date",
  "storageKey": null
},
v15 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v16 = {
  "_is_null": true
},
v17 = {
  "_eq": true
},
v18 = {
  "order": "asc"
},
v19 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "_gte",
      "variableName": "startTime"
    }
  ],
  "kind": "ObjectValue",
  "name": "created_at"
},
v20 = [
  (v19/*: any*/)
],
v21 = [
  {
    "fields": (v20/*: any*/),
    "kind": "ObjectValue",
    "name": "report"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "certsToVerifyWhere"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "drugtestWhere"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeOnSite"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeOrienation"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeSub"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeValidDTs"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order_by"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startTime"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./ProjectWorkerTableRefetchableQuery.graphql')
    }
  },
  "name": "ProjectWorkerTable_project_worker",
  "selections": [
    {
      "alias": "project_worker_connection",
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where"
        }
      ],
      "concreteType": "project_workerConnection",
      "kind": "LinkedField",
      "name": "__ProjectWorkerTable_project_worker_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "project_workerEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "project_worker",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "worker_role",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title_id",
                  "storageKey": null
                },
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hard_hat_number",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "archived_at",
                  "storageKey": null
                },
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "project_crew",
                  "kind": "LinkedField",
                  "name": "project_crew",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lead_foreman_project_worker_id",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "user",
                  "kind": "LinkedField",
                  "name": "archived_by_user",
                  "plural": false,
                  "selections": [
                    (v5/*: any*/),
                    (v6/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "general_contractor_employee",
                      "kind": "LinkedField",
                      "name": "employee",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "general_contractor",
                          "kind": "LinkedField",
                          "name": "general_contractor",
                          "plural": false,
                          "selections": (v7/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "employee_title",
                          "kind": "LinkedField",
                          "name": "employee_title",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "text_translation",
                              "kind": "LinkedField",
                              "name": "name",
                              "plural": false,
                              "selections": [
                                (v8/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deleted_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "worker_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "worker",
                  "kind": "LinkedField",
                  "name": "worker",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": "allOrientationProjects",
                      "args": [
                        {
                          "fields": [
                            {
                              "fields": [
                                {
                                  "fields": [
                                    {
                                      "kind": "Variable",
                                      "name": "_eq",
                                      "variableName": "projectId"
                                    }
                                  ],
                                  "kind": "ObjectValue",
                                  "name": "orientation_project_id"
                                }
                              ],
                              "kind": "ObjectValue",
                              "name": "project"
                            }
                          ],
                          "kind": "ObjectValue",
                          "name": "where"
                        }
                      ],
                      "concreteType": "project_worker",
                      "kind": "LinkedField",
                      "name": "worker_projects",
                      "plural": true,
                      "selections": [
                        (v4/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "worker_certification",
                      "kind": "LinkedField",
                      "name": "worker_certifications",
                      "plural": true,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "expires_on",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "certification",
                          "kind": "LinkedField",
                          "name": "certification",
                          "plural": false,
                          "selections": (v7/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": "latestDT",
                      "args": [
                        (v9/*: any*/),
                        (v10/*: any*/),
                        {
                          "fields": [
                            {
                              "items": [
                                {
                                  "kind": "Literal",
                                  "name": "_and.0",
                                  "value": {
                                    "status": (v11/*: any*/)
                                  }
                                },
                                (v12/*: any*/)
                              ],
                              "kind": "ListValue",
                              "name": "_and"
                            }
                          ],
                          "kind": "ObjectValue",
                          "name": "where"
                        }
                      ],
                      "concreteType": "worker_drug_test",
                      "kind": "LinkedField",
                      "name": "worker_drug_tests",
                      "plural": true,
                      "selections": [
                        (v13/*: any*/),
                        (v2/*: any*/),
                        (v14/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "condition": "includeValidDTs",
                      "kind": "Condition",
                      "passingValue": true,
                      "selections": [
                        {
                          "alias": "validDTPresent",
                          "args": [
                            (v9/*: any*/),
                            (v10/*: any*/),
                            {
                              "fields": [
                                {
                                  "items": [
                                    {
                                      "kind": "Literal",
                                      "name": "_and.0",
                                      "value": {
                                        "status": {
                                          "_eq": "negative"
                                        }
                                      }
                                    },
                                    (v12/*: any*/)
                                  ],
                                  "kind": "ListValue",
                                  "name": "_and"
                                }
                              ],
                              "kind": "ObjectValue",
                              "name": "where"
                            }
                          ],
                          "concreteType": "worker_drug_test",
                          "kind": "LinkedField",
                          "name": "worker_drug_tests",
                          "plural": true,
                          "selections": [
                            (v2/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ]
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "where",
                          "variableName": "certsToVerifyWhere"
                        }
                      ],
                      "concreteType": "certificates_to_verify_aggregate",
                      "kind": "LinkedField",
                      "name": "certificates_to_verify_aggregate",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "certificates_to_verify_aggregate_fields",
                          "kind": "LinkedField",
                          "name": "aggregate",
                          "plural": false,
                          "selections": (v15/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "user",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    },
                    (v6/*: any*/),
                    {
                      "alias": "id",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__id_pk",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "allow_mobile_login",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "phone_number",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "created_password",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        (v9/*: any*/),
                        {
                          "kind": "Literal",
                          "name": "order_by",
                          "value": {
                            "created_at": "desc"
                          }
                        },
                        {
                          "kind": "Literal",
                          "name": "where",
                          "value": {
                            "accepted_at": (v16/*: any*/),
                            "claiming_account": (v17/*: any*/)
                          }
                        }
                      ],
                      "concreteType": "invite",
                      "kind": "LinkedField",
                      "name": "invites",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "accepted_at",
                          "storageKey": null
                        }
                      ],
                      "storageKey": "invites(limit:1,order_by:{\"created_at\":\"desc\"},where:{\"accepted_at\":{\"_is_null\":true},\"claiming_account\":{\"_eq\":true}})"
                    },
                    {
                      "alias": null,
                      "args": [
                        (v9/*: any*/),
                        {
                          "kind": "Literal",
                          "name": "order_by",
                          "value": {
                            "universal_orientated_at": "desc"
                          }
                        },
                        {
                          "kind": "Literal",
                          "name": "where",
                          "value": {
                            "universal_orientated_at": (v11/*: any*/)
                          }
                        }
                      ],
                      "concreteType": "user_universal_orientation",
                      "kind": "LinkedField",
                      "name": "universal_orientations",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "universal_orientated_at",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": [
                            {
                              "kind": "Literal",
                              "name": "order_by",
                              "value": [
                                {
                                  "orientation": (v18/*: any*/)
                                },
                                {
                                  "orientation": {
                                    "name": "asc"
                                  }
                                }
                              ]
                            },
                            {
                              "kind": "Literal",
                              "name": "where",
                              "value": {
                                "project_id": (v16/*: any*/),
                                "status": {
                                  "_eq": "completed"
                                }
                              }
                            }
                          ],
                          "concreteType": "orientation_result",
                          "kind": "LinkedField",
                          "name": "universal_orientation_results",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "orientation_id",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": [
                                {
                                  "kind": "Literal",
                                  "name": "order_by",
                                  "value": {
                                    "slide": (v18/*: any*/)
                                  }
                                }
                              ],
                              "concreteType": "orientation_quiz_result",
                              "kind": "LinkedField",
                              "name": "quiz_results",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "orientation_slide_id",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "answer",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "lang",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "orientation_slide",
                                  "kind": "LinkedField",
                                  "name": "slide",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "title",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "question_ui_element",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "answer_items",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "answer_items_es",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "answer_items_pt",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "answer_items_it",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "answer_items_fr",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "answer_items_zh_hans",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "check_correct_answer",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "correct_answers",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": "quiz_results(order_by:{\"slide\":{\"order\":\"asc\"}})"
                            }
                          ],
                          "storageKey": "universal_orientation_results(order_by:[{\"orientation\":{\"order\":\"asc\"}},{\"orientation\":{\"name\":\"asc\"}}],where:{\"project_id\":{\"_is_null\":true},\"status\":{\"_eq\":\"completed\"}})"
                        }
                      ],
                      "storageKey": "universal_orientations(limit:1,order_by:{\"universal_orientated_at\":\"desc\"},where:{\"universal_orientated_at\":{\"_is_null\":false}})"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "project",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": [
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "show_onsite_for_not_oriented_workers",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "condition": "includeSub",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "subcontractor",
                      "kind": "LinkedField",
                      "name": "subcontractor",
                      "plural": false,
                      "selections": (v7/*: any*/),
                      "storageKey": null
                    }
                  ]
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "worker_title",
                  "kind": "LinkedField",
                  "name": "title",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "text_translation",
                      "kind": "LinkedField",
                      "name": "translation",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v8/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "es",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "pt",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "original",
                          "storageKey": null
                        },
                        (v2/*: any*/),
                        {
                          "alias": "clientText",
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__original_clientText",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "condition": "includeOnSite",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": [
                        {
                          "fields": [
                            {
                              "items": [
                                {
                                  "fields": [
                                    {
                                      "fields": (v21/*: any*/),
                                      "kind": "ObjectValue",
                                      "name": "reports"
                                    }
                                  ],
                                  "kind": "ObjectValue",
                                  "name": "_or.0"
                                },
                                {
                                  "fields": [
                                    {
                                      "fields": (v21/*: any*/),
                                      "kind": "ObjectValue",
                                      "name": "report_injuries"
                                    }
                                  ],
                                  "kind": "ObjectValue",
                                  "name": "_or.1"
                                },
                                {
                                  "fields": [
                                    {
                                      "fields": [
                                        {
                                          "fields": [
                                            {
                                              "fields": [
                                                {
                                                  "kind": "Variable",
                                                  "name": "_gte",
                                                  "variableName": "startDate"
                                                }
                                              ],
                                              "kind": "ObjectValue",
                                              "name": "date"
                                            }
                                          ],
                                          "kind": "ObjectValue",
                                          "name": "daily_work_log"
                                        }
                                      ],
                                      "kind": "ObjectValue",
                                      "name": "daily_work_log_workers"
                                    }
                                  ],
                                  "kind": "ObjectValue",
                                  "name": "_or.2"
                                },
                                {
                                  "fields": [
                                    {
                                      "fields": [
                                        {
                                          "fields": (v20/*: any*/),
                                          "kind": "ObjectValue",
                                          "name": "toolbox_talk"
                                        }
                                      ],
                                      "kind": "ObjectValue",
                                      "name": "toolbox_talks"
                                    }
                                  ],
                                  "kind": "ObjectValue",
                                  "name": "_or.3"
                                },
                                {
                                  "fields": [
                                    {
                                      "fields": [
                                        {
                                          "fields": [
                                            (v19/*: any*/),
                                            {
                                              "kind": "Literal",
                                              "name": "current_status",
                                              "value": {
                                                "_in": [
                                                  "submitted",
                                                  "resubmitted"
                                                ]
                                              }
                                            },
                                            {
                                              "kind": "Literal",
                                              "name": "is_historical",
                                              "value": (v17/*: any*/)
                                            },
                                            {
                                              "kind": "Literal",
                                              "name": "type",
                                              "value": {
                                                "_neq": "ladder"
                                              }
                                            }
                                          ],
                                          "kind": "ObjectValue",
                                          "name": "permit"
                                        }
                                      ],
                                      "kind": "ObjectValue",
                                      "name": "permits"
                                    }
                                  ],
                                  "kind": "ObjectValue",
                                  "name": "_or.4"
                                }
                              ],
                              "kind": "ListValue",
                              "name": "_or"
                            }
                          ],
                          "kind": "ObjectValue",
                          "name": "where"
                        }
                      ],
                      "concreteType": "project_worker_aggregate",
                      "kind": "LinkedField",
                      "name": "project_workers_aggregate",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "project_worker_aggregate_fields",
                          "kind": "LinkedField",
                          "name": "aggregate",
                          "plural": false,
                          "selections": (v15/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "includeOrienation",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "user_orientation",
                      "kind": "LinkedField",
                      "name": "user_orientation",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "orientated_at",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "completed_at",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "in_person_orientated_at",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "signature_id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "in_person_signature_id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "drug_test_updated_at",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "background_check_done",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "background_check_updated_at",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "background_check_done_by_uid",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "user",
                          "kind": "LinkedField",
                          "name": "background_check_done_by",
                          "plural": false,
                          "selections": (v7/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "orientation_provided_by_user_id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "drug_test_id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "worker_drug_test",
                          "kind": "LinkedField",
                          "name": "drug_test",
                          "plural": false,
                          "selections": [
                            (v14/*: any*/),
                            (v13/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ]
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "ae6038dcc884e0c4163401319e66c00e";

export default node;
