import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import DrugtestCardsLayout from "src/common/components/layouts/DrugtestCardsLayout";
import { DrugtestCardQuery } from "src/common/types/generated/relay/DrugtestCardQuery.graphql";
import { DrugtestStatusType } from "src/domain-features/siteorientation/utils/siteOrientationTypes";
import { worker_drug_test_bool_exp } from "src/common/types/generated/relay/types";

const DrugtestCard: FC<{
  drugTestWhere: worker_drug_test_bool_exp;
  loading?: boolean;
  refetch?: () => void;
  onUse?: (
    drugtest: string,
    result: DrugtestStatusType | null,
  ) => Promise<void>;
}> = withCustomSuspense(({ drugTestWhere, loading, refetch, onUse }) => {
  const [fetchKey, setFetchKey] = useState(0);
  const data = useLazyLoadQuery<DrugtestCardQuery>(
    graphql`
      query DrugtestCardQuery($drugTestWhere: worker_drug_test_bool_exp!) {
        worker_drug_test_connection(
          where: $drugTestWhere
          first: 10000
          order_by: { drug_test_date: desc }
        )
          @connection(
            key: "DrugtestCard_worker_drug_test_connection"
            filters: ["where"]
          ) {
          edges {
            node {
              pk: id @__clientField(handle: "pk")
              name
              status
              created_at
              drug_test_date
              worker_id
              project_id
              project {
                name
              }
              entered_through_user {
                ...UserTitleFrag @relay(mask: false)
              }
            }
          }
        }
      }
    `,
    { drugTestWhere },
    { fetchKey, fetchPolicy: "network-only" },
  );
  return (
    <DrugtestCardsLayout
      drugtests={data.worker_drug_test_connection.edges.map((p) => p.node)}
      refetch={() => setFetchKey((i) => i + 1)}
      onUse={onUse}
      loading={loading}
    />
  );
});

export default DrugtestCard;
