import React, { useState } from "react";
import PreTaskPlansTable from "src/common/components/tables/PreTaskPlansTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetReportsDistinctSubContractorsByProjectIdQuery } from "src/common/types/generated/apollo/graphQLTypes";
import LoadingContent from "src/common/components/general/LoadingContent";
import { Subcontractor } from "src/common/components/tables/PreTaskPlansTable";
import { Drawer, Modal } from "antd";
import GCProjectReportsPTPsDetail from "./GCProjectReportsPTPsDetail";
import PtpQrCodeModal from "src/domain-features/sitesafety/entryRoutes/gcProjectReports/routes/preTaskPlans/components/modal/PtpQrCodeModal";
import { IconSettings } from "@tabler/icons";
import PTPSettings from "./modal/PTPSettings";

interface GCProjectReportsPTPsTableProps {
  projectId: string;
  openRow: (params: { reportId: string }) => void;
}

const GCProjectReportsPTPsTable: React.FunctionComponent<
  GCProjectReportsPTPsTableProps
> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const reportId = searchParams.get("reportId");
  const [ptpQrCodeVisible, setPtpQrCodeVisible] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);

  const worker = searchParams.get("worker");
  const navigate = useNavigate();
  const { data, loading, error } =
    useGetReportsDistinctSubContractorsByProjectIdQuery({
      variables: {
        projectId: props.projectId,
      },
    });
  if (loading) {
    return <LoadingContent />;
  }
  if (!data) {
    throw Error(error?.message ?? "An Error occurred");
  }

  const subContractorFilterOptions: Subcontractor[] =
    data.project_subcontractor.map(({ subcontractor }) => {
      return {
        id: subcontractor.id,
        name: subcontractor.name,
      };
    });

  return (
    <div className="flex flex-col items-start gap-0.5 h-full w-full">
      <PtpQrCodeModal
        open={ptpQrCodeVisible}
        projectId={props.projectId}
        onClose={() => setPtpQrCodeVisible(false)}
      />
      <Modal
        open={settingsVisible}
        onClose={() => setSettingsVisible(false)}
        onCancel={() => setSettingsVisible(false)}
        onOk={() => setSettingsVisible(false)}
        cancelText="Close"
        title="PTP Settings"
        width={"60%"}
      >
        {settingsVisible ? <PTPSettings projectId={props.projectId} /> : null}
      </Modal>
      <div className="w-full h-full">
        <Drawer
          width={"85%"}
          open={!!reportId}
          placement="right"
          onClose={() => navigate(-1)}
        >
          {!!reportId && (
            <GCProjectReportsPTPsDetail
              projectId={props.projectId}
              reportId={reportId}
              key={reportId}
            />
          )}
        </Drawer>
        <PreTaskPlansTable
          subContractorFilterOptions={subContractorFilterOptions}
          showDateRangeFilter={true}
          excludedKeys={["project"]}
          onRowClick={(pk) => {
            setSearchParams({ reportId: pk });
          }}
          topBarButtons={[
            {
              icon: IconSettings,
              onClick: () => setSettingsVisible((prev) => !prev),
            },
            {
              label: "PTP Qr Code",
              onClick: () => setPtpQrCodeVisible((prev) => !prev),
            },
          ]}
          where={
            worker
              ? {
                  report_project_workers: {
                    project_worker_id: { _eq: worker },
                  },
                  project_id: {
                    _eq: props.projectId,
                  },
                }
              : {
                  project_id: {
                    _eq: props.projectId,
                  },
                }
          }
        />
      </div>
    </div>
  );
};

export default GCProjectReportsPTPsTable;
