/**
 * @generated SignedSource<<d13623ceee419fa33e7ef5fe11a012da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { email_project_user_bool_exp, email_project_user_insert_input, project_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SelectEmailUserModalMutation$variables = {
  _set?: project_set_input | null | undefined;
  objects: ReadonlyArray<email_project_user_insert_input>;
  projectId: string;
  where: email_project_user_bool_exp;
};
export type SelectEmailUserModalMutation$data = {
  readonly delete_email_project_user: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
  readonly insert_email_project_user: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly pk: string;
      readonly project_id: string;
      readonly type: string | null | undefined;
      readonly user: {
        readonly email: string | null | undefined;
        readonly id: string;
        readonly name: string;
        readonly pk: string;
      };
    }>;
  } | null | undefined;
  readonly update_project_by_pk: {
    readonly siteupdate_time: string;
    readonly timezone: string;
  } | null | undefined;
};
export type SelectEmailUserModalMutation = {
  response: SelectEmailUserModalMutation$data;
  variables: SelectEmailUserModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objects"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v4 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "where"
    }
  ],
  "concreteType": "email_project_user_mutation_response",
  "kind": "LinkedField",
  "name": "delete_email_project_user",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "email_project_user",
      "kind": "LinkedField",
      "name": "returning",
      "plural": true,
      "selections": [
        (v5/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "projectId"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "siteupdate_time",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v15 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v16 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectEmailUserModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "email_project_user_mutation_response",
        "kind": "LinkedField",
        "name": "insert_email_project_user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "email_project_user",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v11/*: any*/),
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": "project",
        "kind": "LinkedField",
        "name": "update_project_by_pk",
        "plural": false,
        "selections": [
          (v13/*: any*/),
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SelectEmailUserModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "email_project_user_mutation_response",
        "kind": "LinkedField",
        "name": "insert_email_project_user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "email_project_user",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v11/*: any*/),
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": "project",
        "kind": "LinkedField",
        "name": "update_project_by_pk",
        "plural": false,
        "selections": [
          (v13/*: any*/),
          (v14/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6b581874aff9f60ead0154a2767cb1ea",
    "id": null,
    "metadata": {},
    "name": "SelectEmailUserModalMutation",
    "operationKind": "mutation",
    "text": "mutation SelectEmailUserModalMutation(\n  $objects: [email_project_user_insert_input!]!\n  $where: email_project_user_bool_exp!\n  $projectId: uuid!\n  $_set: project_set_input\n) {\n  insert_email_project_user(objects: $objects) {\n    returning {\n      id\n      pk: id\n      project_id\n      user {\n        id\n        pk: id\n        name\n        email\n      }\n      type\n    }\n  }\n  delete_email_project_user(where: $where) {\n    returning {\n      id\n    }\n  }\n  update_project_by_pk(pk_columns: {id: $projectId}, _set: $_set) {\n    siteupdate_time\n    timezone\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "942f37d23c0ee74902fa5821115aa734";

export default node;
