import ShowPieChart, {
  ShowPieChartProps,
} from "../../../../../common/components/charts/pie/ShowPieChart";
import { Flex, Form, Select } from "antd";
import getNormalSelectOptionsFilter from "../../../../../common/functions/getNormalSelectOptionsFilter";
import ChartWrapperCard from "../../../../../common/components/charts/ChartWrapperCard";
import React from "react";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import ColumnChart, {
  ColumnChartProps,
} from "../../../../../common/components/charts/ColumnChart";

interface GCObservationChartsUIProps {
  loading: boolean;
  subsList: ShowPieChartProps["dataSource"];
  severityList: ShowPieChartProps["dataSource"];
  statusList: ShowPieChartProps["dataSource"];
  divisionsList: ShowPieChartProps["dataSource"];
  businessUnitsList: ShowPieChartProps["dataSource"];
  officesList: ShowPieChartProps["dataSource"];
  userOptionsList: { name: string; id: string }[];
  dateData: ColumnChartProps["dataSource"];
  subChartSelectedStatus?: string;
  setSubSelectedStatus?: (v: string) => void;
  barChartSelectedPerson?: string;
  setBarChartSelectedPerson?: (v: string | undefined) => void;
}

const GCObservationChartsUI: React.FC<GCObservationChartsUIProps> = ({
  loading,
  ...props
}) => {
  const { userData } = useUserData();
  const emp = userData.employee;
  if (!emp) throw new Error("Employee Data not found");
  const gc = emp.general_contractor;
  return (
    <Flex gap={"small"} vertical>
      <div className={`grid grid-cols-2 gap-1`}>
        {gc.hierarchy_division_name && !!props.divisionsList.length && (
          <ChartWrapperCard
            loading={loading}
            title={`Observations by ${gc.hierarchy_division_name}`}
          >
            <ShowPieChart dataSource={props.divisionsList} />
          </ChartWrapperCard>
        )}
        {gc.hierarchy_business_unit_name &&
          !!props.businessUnitsList.length && (
            <ChartWrapperCard
              loading={loading}
              title={`Observations by ${gc.hierarchy_business_unit_name}`}
            >
              <ShowPieChart dataSource={props.businessUnitsList} />
            </ChartWrapperCard>
          )}
        {gc.hierarchy_office_name && !!props.officesList.length && (
          <ChartWrapperCard
            loading={loading}
            title={`Observations by ${gc.hierarchy_office_name}`}
          >
            <ShowPieChart dataSource={props.officesList} />
          </ChartWrapperCard>
        )}
        <ChartWrapperCard
          // TODO add loading state
          loading={loading}
          title={`Subcontractors`}
          extraTop={
            <Form.Item
              label={`By Status`}
              className={`w-16`}
              layout={`vertical`}
            >
              <Select
                className="w-16"
                value={props.subChartSelectedStatus}
                onChange={props.setSubSelectedStatus}
              >
                <Select.Option key={"all"} value="all">
                  All
                </Select.Option>
                <Select.Option key={"safe"} value="safe">
                  <span className="text-semantic-positive-green">Safe</span>
                </Select.Option>
                <Select.Option key={"unsafe-closed"} value="unsafe-closed">
                  <span className="text-purple">Closed - Unsafe</span>
                </Select.Option>
                <Select.Option key={"unsafe-open"} value="unsafe-open">
                  <span className="text-semantic-negative">Open - Unsafe</span>
                </Select.Option>
              </Select>
            </Form.Item>
          }
        >
          <ShowPieChart dataSource={props.subsList} valueTitle="Observations" />
        </ChartWrapperCard>

        <ChartWrapperCard
          title={`Risk Level`}
          loading={loading}
          // TODO add laoding state
        >
          <ShowPieChart
            dataSource={props.severityList}
            valueTitle="Observations"
          />
        </ChartWrapperCard>
        <ChartWrapperCard
          title={`Status`}
          className={`last:odd:col-span-2`}
          loading={loading}
        >
          <ShowPieChart
            valueTitle="Observations"
            dataSource={props.statusList}
          />
        </ChartWrapperCard>
      </div>
      <ChartWrapperCard
        loading={loading}
        title={`Observations by Date`}
        extraTop={
          <Form.Item
            className={`w-16`}
            label={`Created By`}
            layout={`vertical`}
          >
            <Select
              onChange={props.setBarChartSelectedPerson}
              value={props.barChartSelectedPerson}
              showSearch
              filterOption={getNormalSelectOptionsFilter}
              allowClear
            >
              {props.userOptionsList.map((user) => (
                <Select.Option value={user.id} key={user.id}>
                  {user.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
      >
        <ColumnChart dataSource={props.dateData} />
      </ChartWrapperCard>
    </Flex>
  );
};

export default GCObservationChartsUI;
