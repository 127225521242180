/**
 * @generated SignedSource<<754f289f3036f82dc7fd5e29f23c7937>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { task_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InsertTasksMutation$variables = {
  objects: ReadonlyArray<task_insert_input>;
};
export type InsertTasksMutation$data = {
  readonly insert_task: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly pk: string;
      readonly show_type: string;
      readonly subcontractor: {
        readonly name: string;
      } | null | undefined;
      readonly subcontractor_id: string | null | undefined;
      readonly task_creation_request: {
        readonly subcontractor: {
          readonly name: string;
        };
        readonly subcontractor_id: string;
      } | null | undefined;
      readonly task_edit: ReadonlyArray<{
        readonly edited_at: string;
        readonly task_patch: any | null | undefined;
      }>;
      readonly task_signatures: ReadonlyArray<{
        readonly created_at: string;
        readonly signature_image_id: string | null | undefined;
      }>;
    }>;
  } | null | undefined;
};
export type InsertTasksMutation = {
  response: InsertTasksMutation$data;
  variables: InsertTasksMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affected_rows",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "show_type",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "edited_at": "desc"
    }
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "task_patch",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "edited_at",
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "created_at": "desc"
    }
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signature_image_id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InsertTasksMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "task_mutation_response",
        "kind": "LinkedField",
        "name": "insert_task",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "task",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              },
              (v4/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "add_task_request",
                "kind": "LinkedField",
                "name": "task_creation_request",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "task_edit",
                "kind": "LinkedField",
                "name": "task_edit",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": "task_edit(order_by:{\"edited_at\":\"desc\"})"
              },
              {
                "alias": null,
                "args": (v11/*: any*/),
                "concreteType": "task_signature",
                "kind": "LinkedField",
                "name": "task_signatures",
                "plural": true,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": "task_signatures(order_by:{\"created_at\":\"desc\"})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InsertTasksMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "task_mutation_response",
        "kind": "LinkedField",
        "name": "insert_task",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "task",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": "pk",
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              },
              (v4/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "add_task_request",
                "kind": "LinkedField",
                "name": "task_creation_request",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v14/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "task_edit",
                "kind": "LinkedField",
                "name": "task_edit",
                "plural": true,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": "task_edit(order_by:{\"edited_at\":\"desc\"})"
              },
              {
                "alias": null,
                "args": (v11/*: any*/),
                "concreteType": "task_signature",
                "kind": "LinkedField",
                "name": "task_signatures",
                "plural": true,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": "task_signatures(order_by:{\"created_at\":\"desc\"})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "939c3439ff78498baa317de4cbf2168b",
    "id": null,
    "metadata": {},
    "name": "InsertTasksMutation",
    "operationKind": "mutation",
    "text": "mutation InsertTasksMutation(\n  $objects: [task_insert_input!]!\n) {\n  insert_task(objects: $objects) {\n    affected_rows\n    returning {\n      id\n      pk: id\n      subcontractor_id\n      subcontractor {\n        name\n        id\n      }\n      task_creation_request {\n        subcontractor_id\n        subcontractor {\n          name\n          id\n        }\n        id\n      }\n      show_type\n      task_edit(order_by: {edited_at: desc}) {\n        task_patch\n        edited_at\n        id\n      }\n      task_signatures(order_by: {created_at: desc}) {\n        created_at\n        signature_image_id\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "45a0717250180a06115f8ea5842ea162";

export default node;
