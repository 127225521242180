import * as React from "react";
import GCReportsType from "src/common/types/manual/GCReportsType";
import PermitReportDetail from "../../../../../components/ProjectReportsPermitsDetail";
import GCProjectReportsPermitsTable from "./table/GCProjectReportsPermitsTable";
import ErrorMessage from "src/utility-features/error-handling/components/ErrorMessage";

interface GCProjectReportsPermitsProps extends GCReportsType {
  permitType?: string;
}

const GCProjectReportsPermits: React.FunctionComponent<
  GCProjectReportsPermitsProps
> = (props) => {
  if (!props.reportId)
    return (
      <GCProjectReportsPermitsTable
        projectId={props.projectId}
        openPermit={props.openRow}
      />
    );
  if (
    props.permitType === "dig" ||
    props.permitType === "hot_work" ||
    props.permitType === "confined_space" ||
    props.permitType === "work_above" ||
    props.permitType === "ladder" ||
    props.permitType === "historical_access" ||
    props.permitType === "interior_penetration" ||
    props.permitType === "dig_excavation" ||
    props.permitType === "out_of_basket" ||
    props.permitType === "loto" ||
    props.permitType === "steel_erection" || 
    props.permitType === "ceiling_access"
  )
    return (
      <PermitReportDetail
        showBack
        projectId={props.projectId}
        permitId={props.reportId}
        permitType={props.permitType}
      />
    );
  return <ErrorMessage message="No Valid Permit type found"></ErrorMessage>;
};

export default GCProjectReportsPermits;
