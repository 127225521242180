import FModal from "src/common/components/dialogs/FModal";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

type OptionsList = Array<{ label: string; value: string }>;
export interface IncidentReportFieldsFormVals {
  body_parts_affected: string[];
  incident_statuses: string[];
  incident_types: string[];
  injury_types: string[];
  injury_severities: string[];
  injury_causes: string[];
}
const IncidentReportFields: React.FC<{
  incidentTypeOptions: OptionsList;
  injuryTypeOptions: OptionsList;
  injurySeverityOptions: OptionsList;
  injuryCauseOptions: OptionsList;
  bodyPartOptions: OptionsList;
}> = ({
  incidentTypeOptions,
  injuryTypeOptions,
  injurySeverityOptions,
  injuryCauseOptions,
  bodyPartOptions,
}) => {
  const placeholderText = "All included by default";

  return (
    <>
      <FModal.Select
        name={"incident_statues"}
        label="Incident Status"
        props={{
          mode: "multiple",
          placeholder: "All Status Included",
          allowClear: true,
          options: [
            { label: "Open", value: "open" },
            { label: "In-Progress", value: "pending" },
            { label: "Closed", value: "closed" },
          ],
        }}
      />
      <FModal.Select
        name={"incident_types"}
        label="Incident Types"
        props={{
          mode: "multiple",
          allowClear: true,
          showSearch: true,
          filterOption: getNormalSelectOptionsFilter,
          placeholder: placeholderText,
          options: incidentTypeOptions,
        }}
      />
      <FModal.Select
        name={"injury_types"}
        label="Injury Types"
        props={{
          mode: "multiple",
          allowClear: true,
          showSearch: true,
          filterOption: getNormalSelectOptionsFilter,
          placeholder: placeholderText,
          options: injuryTypeOptions,
        }}
      />{" "}
      <FModal.Select
        name={"injury_severities"}
        label="Injury Severities"
        props={{
          mode: "multiple",
          allowClear: true,
          showSearch: true,
          filterOption: getNormalSelectOptionsFilter,
          placeholder: placeholderText,
          options: injurySeverityOptions,
        }}
      />
      <FModal.Select
        name={"injury_causes"}
        label="Injury Causes"
        props={{
          mode: "multiple",
          allowClear: true,
          showSearch: true,
          filterOption: getNormalSelectOptionsFilter,
          placeholder: placeholderText,
          options: injuryCauseOptions,
        }}
      />
      <FModal.Select
        name={"body_parts_affected"}
        label="Body Part Injured"
        props={{
          mode: "multiple",
          showSearch: true,
          allowClear: true,
          filterOption: getNormalSelectOptionsFilter,
          placeholder: placeholderText,
          options: bodyPartOptions,
        }}
      />
    </>
  );
};

export default IncidentReportFields;
