import MonthDateRange from "src/common/components/general/MonthDateRange";
import {
  General_Contractor_Employee_Bool_Exp,
  Project_Bool_Exp,
  useGenerateGcObsLeaderboardPdfMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useState } from "react";
import { Button, Segmented, Tooltip } from "antd";
import {
  DownloadOutlined,
  PieChartOutlined,
  TableOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import GCObsLeaderboardData from "./leaderboard/GCObsLeaderboardData";
import downloadFromUrl from "src/common/functions/downloadFromUrl";

export interface GCObsLeaderboardProps {
  gcId: string;
  gcEmployeeWhere: General_Contractor_Employee_Bool_Exp;
  projWhere: Project_Bool_Exp;
  organization_level: "gc" | "business_unit" | "division" | "office";
  organization_unit_id: string;
}

type RangeValue = [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;

const GCObsLeaderboard: React.FC<GCObsLeaderboardProps> = ({
  gcId,
  organization_level,
  organization_unit_id,
  gcEmployeeWhere,
  projWhere,
}) => {
  const [startMonth, setStartMonth] = useState<dayjs.Dayjs | null>(dayjs());
  const [endMonth, setEndMonth] = useState<dayjs.Dayjs | null>(dayjs());
  const [generateObsleaderboardPdf, { loading }] =
    useGenerateGcObsLeaderboardPdfMutation();
  const [viewType, setViewType] = useState("table");
  const start = (startMonth || dayjs()).startOf("month");
  const end = (endMonth || dayjs()).add(1, "month").startOf("month");

  return (
    <div>
      <Segmented
        value={viewType}
        options={[
          {
            label: (
              <>
                <TableOutlined /> Table
              </>
            ),
            value: "table",
          },
          {
            label: (
              <>
                <PieChartOutlined /> Charts
              </>
            ),
            value: "charts",
          },
        ]}
        onChange={(value) => setViewType(value)}
      />
      <br />
      <br />
      <MonthDateRange
        endMonth={endMonth}
        startMonth={startMonth}
        onEndMonthChange={setEndMonth}
        onStartMonthChagne={setStartMonth}
      />
      <Tooltip title={`Download PDF`}>
        <Button
          className="ml-1"
          icon={<DownloadOutlined />}
          onClick={async () => {
            const { data } = await generateObsleaderboardPdf({
              variables: {
                input: {
                  gcId,
                  organization_level,
                  organization_unit_id,
                  end_time: end.toISOString(),
                  start_time: start.toISOString(),
                  user_timezone: dayjs.tz.guess(),
                },
              },
            });
            if (data) {
              downloadFromUrl(data.generateGcObsLeaderboardPdf);
            }
          }}
          loading={loading}
        />
      </Tooltip>

      <br />
      <br />
      <GCObsLeaderboardData
        {...{ viewType, start, end, gcEmployeeWhere, gcId, projWhere }}
      />
    </div>
  );
};
export default GCObsLeaderboard;
