import { Column } from "@ant-design/charts";
import React from "react";
import colorList from "../lists/chartColorList";
import ChartEmptyFallback from "./ChartEmptyFallback";

export interface ColumnChartProps {
  dataSource: {
    xField: string;
    value: number;
    valueTitle?: string; // Tooltip label
    color?: string;
  }[];
  labelFontSize?: number;
}

const defaultColor = colorList[0];
const defaultValueTitle = `value`;

const ColumnChart: React.FC<ColumnChartProps> = (props) => {
  if (!props.dataSource || props.dataSource.length === 0) {
    return <ChartEmptyFallback />;
  }
  return (
    <Column
      height={500}
      data={props.dataSource.map((item) => ({
        color: defaultColor,
        valueTitle: defaultValueTitle,
        ...item,
      }))}
      xField="xField"
      yField="value"
      label={{
        text: "value",
        textBaseline: "bottom",
        position: "inside",
        fontSize: props.labelFontSize,
      }}
      percent={false}
      stack={true}
      interaction={{ tooltip: { shared: true } }}
      colorField={`valueTitle`}
      style={{
        fill: "color",
      }}
    />
  );
};

export default ColumnChart;
