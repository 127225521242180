import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useParams } from "react-router-dom";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import { GCProjectCalendarSitedeliverySubBlocksQuery } from "src/common/types/generated/relay/GCProjectCalendarSitedeliverySubBlocksQuery.graphql";
import { GCProjectCalendarSitedeliverySubBlocksTimezoneQuery } from "src/common/types/generated/relay/GCProjectCalendarSitedeliverySubBlocksTimezoneQuery.graphql";
import {
  GCProjectCalendarSitedeliverySubBlocks_deleteFilterCalendar_Mutation,
  GCProjectCalendarSitedeliverySubBlocks_deleteFilterCalendar_Mutation$data,
} from "src/common/types/generated/relay/GCProjectCalendarSitedeliverySubBlocks_deleteFilterCalendar_Mutation.graphql";
import {
  GCProjectCalendarSitedeliverySubBlocks_insertFilterCalendar_Mutation,
  GCProjectCalendarSitedeliverySubBlocks_insertFilterCalendar_Mutation$data,
} from "src/common/types/generated/relay/GCProjectCalendarSitedeliverySubBlocks_insertFilterCalendar_Mutation.graphql";
import { DatesRangeType } from "src/common/types/manual/DatesRange";
import * as uuid from "uuid";
import { SitedeliveryCalendarType } from "../utilities/sitedeliveryTypes";
import GCProjectCalendarSitedeliverySubBlocksUI from "./GCProjectCalendarSitedeliverySubBlocksUI";
import compareStrings from "src/common/functions/compareStrings";

const timezoneQuery = graphql`
  query GCProjectCalendarSitedeliverySubBlocksTimezoneQuery(
    $where: project_bool_exp!
  ) {
    project_connection(where: $where) {
      edges {
        node {
          timezone
        }
      }
    }
  }
`;

const query = graphql`
  query GCProjectCalendarSitedeliverySubBlocksQuery(
    $delivery_where: delivery_bool_exp!
    $delivery_order_by: [delivery_order_by!]!
    $blockout_where: project_delivery_block_out_bool_exp!
    $sub_block_where: project_delivery_sub_block_bool_exp!
    $calendar_where: calendar_bool_exp!
    $filterCalendarWhere: user_project_filter_calendar_bool_exp!
    $projectId: uuid!
  ) {
    delivery_connection(where: $delivery_where, order_by: $delivery_order_by) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          calendars(order_by: { calendar_id: asc }) {
            calendar {
              id
              pk: id @__clientField(handle: "pk")
              name {
                en
              }
              color_hex
            }
          }
          subcontractor {
            id
            name
          }
          detail {
            id
            pk: id @__clientField(handle: "pk")
            en
          }
          start_at
          duration
          user {
            name
          }
          name {
            id
            pk: id @__clientField(handle: "pk")
            en
          }
          created_at
          approved_at
          approved_by {
            name
          }
          remark {
            en
          }
          storage_location {
            id
            pk: id @__clientField(handle: "pk")
            en
          }
        }
      }
    }
    project_delivery_block_out_connection(where: $blockout_where) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          start_time
          end_time
          weekday
        }
      }
    }
    project_delivery_sub_block_connection(
      where: $sub_block_where
      first: 10000
    )
      @connection(
        key: "GCProjectCalendarSitedeliverySubBlocks_project_delivery_sub_block_connection"
        filters: []
      ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          start_time
          end_time
          weekday
          calendars {
            calendar {
              id
              pk: id @__clientField(handle: "pk")
              name {
                en
              }
              color_hex
            }
          }
          subcontractors {
            subcontractor {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
          }
        }
      }
    }
    calendar_connection(
      where: $calendar_where
      order_by: { id: asc }
      distinct_on: id
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name {
            id
            en
          }
          color_hex
        }
      }
    }
    user_project_filter_calendar_connection(
      first: 10000
      where: $filterCalendarWhere
    )
      @connection(
        key: "GCProjectCalendarSitedeliverySubBlocks_user_project_filter_calendar_connection"
        filters: []
      ) {
      edges {
        node {
          id
          calendar {
            id
            pk: id @__clientField(handle: "pk")
            name {
              id
              en
            }
            color_hex
          }
        }
      }
    }
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          general_contractor {
            id
            name
          }
        }
      }
    }
  }
`;

const insertFilterCalendarMutation = graphql`
  mutation GCProjectCalendarSitedeliverySubBlocks_insertFilterCalendar_Mutation(
    $object: user_project_filter_calendar_insert_input!
  ) {
    insert_user_project_filter_calendar_one(
      object: $object
      on_conflict: {
        constraint: user_project_filter_calendar_user_id_project_id_calendar_id_fil
        update_columns: []
      }
    ) {
      id
      calendar {
        id
        name {
          id
          en
        }
        color_hex
      }
    }
  }
`;

const deleteFilterCalendarMutation = graphql`
  mutation GCProjectCalendarSitedeliverySubBlocks_deleteFilterCalendar_Mutation(
    $where: user_project_filter_calendar_bool_exp!
  ) {
    delete_user_project_filter_calendar(where: $where) {
      returning {
        id
      }
    }
  }
`;

interface GCProjectCalendarSitedeliverySubBlocksProps {
  hideNewTimeBlockButton?: boolean;
}

const GCProjectCalendarSitedeliverySubBlocks: React.FC<
  GCProjectCalendarSitedeliverySubBlocksProps
> = (props) => {
  const { projectId } = useParams();

  const projectTimeZone =
    useLazyLoadQuery<GCProjectCalendarSitedeliverySubBlocksTimezoneQuery>(
      timezoneQuery,
      { where: { id: { _eq: projectId } } },
    ).project_connection.edges.at(0)?.node.timezone;

  const [datesRange, setDatesRange] = useState<DatesRangeType>({
    from: dayjs().startOf("week").add(1, "d").tz(projectTimeZone, true),
    to: dayjs().endOf("week").add(1, "d").tz(projectTimeZone, true),
  });
  const [loading, setLoading] = useState(false);

  const userId = auth.currentUser?.uid;
  if (!projectId) {
    throw new Error("project id does not exist");
  }
  if (!userId) {
    throw new Error("user id does not exist");
  }
  const data = useLazyLoadQuery<GCProjectCalendarSitedeliverySubBlocksQuery>(
    query,
    {
      delivery_where: {
        project_id: { _eq: projectId },
        start_at: {
          // TODO: this is wrong... we should fix it
          // for start_at we should use time in ISO format
          // calulate start of date in project timeone.
          _gte: dayjs(datesRange.from).format("YYYY-MM-DD"),
          _lte: dayjs(datesRange.to).format("YYYY-MM-DD"),
        },
        status: { _eq: "Approved" },
      },
      delivery_order_by: [{ start_at: "desc" }],
      blockout_where: {
        project_id: { _eq: projectId },
      },
      sub_block_where: {
        project_id: { _eq: projectId },
      },
      calendar_where: {
        projects: {
          project_id: { _eq: projectId },
          is_archive: { _eq: false },
        },
      },
      filterCalendarWhere: {
        filter_type: { _eq: "web-hidden-calendar" },
        project_id: { _eq: projectId },
        user_id: { _eq: userId },
      },
      projectId: projectId,
    },
  );

  const filterCalendarsData = useMemo(
    () =>
      data.user_project_filter_calendar_connection.edges
        .map((c) => c.node)
        .sort((fc1, fc2) => compareStrings(fc2.calendar.pk, fc1.calendar.pk)),
    [data.user_project_filter_calendar_connection.edges],
  );
  const calendarsData = useMemo(
    () =>
      data.calendar_connection.edges
        .map((c) => c.node)
        .sort((c1, c2) => compareStrings(c2.pk, c1.pk)),
    [data.calendar_connection.edges],
  );
  const deliveryData = data.delivery_connection.edges;
  const blockoutData = data.project_delivery_block_out_connection.edges;
  const subBlockData = data.project_delivery_sub_block_connection.edges;
  const gcData = data.project_connection.edges[0].node.general_contractor;

  // Adding Calendars
  const siteDeliveryData = useMemo<SitedeliveryCalendarType[]>(() => {
    const tempSiteDeliveryData = calendarsData.map(
      (c): SitedeliveryCalendarType => {
        return {
          id: c.pk,
          colorHex: c.color_hex.slice(1, 7),
          title: c.name!.en,
          events: [],
        };
      },
    );

    // Adding deliveries
    for (
      let deliveryIndex = 0;
      deliveryIndex < deliveryData.length;
      deliveryIndex++
    ) {
      const deliveryNode = deliveryData[deliveryIndex].node;
      const deliveryCalendarData = deliveryNode.calendars
        .map((c) => c.calendar)
        .sort((c1, c2) => compareStrings(c2.pk, c1.pk));
      //sort in descending order

      for (
        let deliveryCalendarIndex = 0, siteDeliveryIndex = 0;
        deliveryCalendarIndex < deliveryCalendarData.length;
        deliveryCalendarIndex++
      ) {
        while (
          siteDeliveryIndex < tempSiteDeliveryData.length &&
          tempSiteDeliveryData[siteDeliveryIndex].id >
            deliveryCalendarData[deliveryCalendarIndex].pk
        ) {
          siteDeliveryIndex++;
        }
        while (
          deliveryCalendarIndex < deliveryCalendarData.length &&
          tempSiteDeliveryData[siteDeliveryIndex].id <
            deliveryCalendarData[deliveryCalendarIndex].pk
        ) {
          deliveryCalendarIndex++;
        }
        const deliveryDuration = deliveryNode.duration;
        if (
          siteDeliveryIndex < tempSiteDeliveryData.length &&
          tempSiteDeliveryData[siteDeliveryIndex].id ===
            deliveryCalendarData[deliveryCalendarIndex].pk &&
          !!deliveryDuration
        ) {
          tempSiteDeliveryData[siteDeliveryIndex].events.push({
            type: "delivery",
            title: {
              text: deliveryNode.name.en,
              id: deliveryNode.name.pk,
              relayId: deliveryNode.name.id,
            },
            detail: deliveryNode.detail
              ? {
                  text: deliveryNode.detail.en,
                  id: deliveryNode.detail.pk,
                  relayId: deliveryNode.detail.id,
                }
              : undefined,
            from: dayjs(deliveryNode.start_at).tz(projectTimeZone),
            subcontractor: deliveryNode.subcontractor
              ? {
                  title: deliveryNode.subcontractor.name,
                  id: deliveryNode.subcontractor.id,
                }
              : {
                  title: gcData.name,
                  id: gcData.id,
                },
            id: deliveryNode.pk,
            relayId: deliveryNode.id,
            to: dayjs(deliveryNode.start_at)
              .add(deliveryDuration, "hour")
              .tz(projectTimeZone),
            createdAt: dayjs(deliveryNode.created_at),
            createdBy: deliveryNode.user.name,
            reviewed: deliveryNode.approved_by
              ? {
                  by: deliveryNode.approved_by?.name ?? "",
                  type: "approve",
                  remark: deliveryNode.remark?.en,
                }
              : undefined,
            storageLocation: deliveryNode.storage_location
              ? {
                  text: deliveryNode.storage_location.en,
                  id: deliveryNode.storage_location.pk,
                  relayId: deliveryNode.storage_location.id,
                }
              : undefined,
            calendars: deliveryNode.calendars.map(({ calendar }) => ({
              colorHex: calendar.color_hex,
              id: calendar.id,
              title: calendar.name.en,
            })),
          });
        }
      }
    }

    // Adding blockouts
    for (
      let siteDeliveryIndex = 0;
      siteDeliveryIndex < tempSiteDeliveryData.length;
      siteDeliveryIndex++
    ) {
      for (
        let blockoutIndex = 0;
        blockoutIndex < blockoutData.length;
        blockoutIndex++
      ) {
        for (
          let dateIndex = datesRange.from;
          dateIndex < datesRange.to;
          dateIndex = dayjs(dateIndex).add(1, "d")
        )
          if (
            dayjs(dateIndex).day() === blockoutData[blockoutIndex].node.weekday
          )
            tempSiteDeliveryData[siteDeliveryIndex].events.push({
              type: "blocked out",
              from: dayjs(
                dayjs(dateIndex)
                  .tz(projectTimeZone)
                  // .set("day", blockoutData[blockoutIndex].node.weekday)
                  .format("YYYY-MM-DD ") +
                  blockoutData[blockoutIndex].node.start_time,
              ),
              to: dayjs(
                dayjs(dateIndex)
                  .tz(projectTimeZone)
                  // .set("day", blockoutData[blockoutIndex].node.weekday)
                  .format("YYYY-MM-DD ") +
                  blockoutData[blockoutIndex].node.end_time,
              ),
              id: blockoutData[blockoutIndex].node.pk,
            });
      }
    }

    //Adding sub blocks
    for (
      let subBlockIndex = 0;
      subBlockIndex < subBlockData.length;
      subBlockIndex++
    ) {
      const subBlockCalendarsData = subBlockData[subBlockIndex].node.calendars
        .map((c) => c.calendar)
        .sort((c1, c2) => compareStrings(c2.pk, c1.pk));
      // We can do it for multiple subcontractors.
      const subBlockSubcontractorData =
        subBlockData[subBlockIndex].node.subcontractors[0];
      if (subBlockSubcontractorData.subcontractor) {
        for (
          let subBlockCalendarIndex = 0, siteDeliveryIndex = 0;
          subBlockCalendarIndex < subBlockCalendarsData.length;
          subBlockCalendarIndex++
        ) {
          while (
            siteDeliveryIndex < tempSiteDeliveryData.length &&
            tempSiteDeliveryData[siteDeliveryIndex].id >
              subBlockCalendarsData[subBlockCalendarIndex]!.pk
          ) {
            siteDeliveryIndex++;
          }
          while (
            subBlockCalendarIndex < subBlockCalendarsData.length &&
            siteDeliveryIndex < tempSiteDeliveryData.length &&
            tempSiteDeliveryData[siteDeliveryIndex].id <
              subBlockCalendarsData[subBlockCalendarIndex]!.pk
          ) {
            subBlockCalendarIndex++;
          }
          if (
            siteDeliveryIndex < tempSiteDeliveryData.length &&
            tempSiteDeliveryData[siteDeliveryIndex].id ===
              subBlockCalendarsData[subBlockCalendarIndex]?.pk
          ) {
            for (
              let dateIndex = datesRange.from;
              dateIndex.isBefore(datesRange.to);
              dateIndex = dayjs(dateIndex).add(1, "d")
            )
              if (
                (subBlockData[subBlockIndex].node.weekday &
                  (1 << +dayjs(dateIndex).format("d"))) !=
                0
              ) {
                tempSiteDeliveryData[siteDeliveryIndex].events.push({
                  type: "sub block",
                  id: subBlockData[subBlockIndex].node.pk,
                  from: dayjs(
                    dayjs(dateIndex).format("YYYY-MM-DD ") +
                      subBlockData[subBlockIndex].node.start_time,
                  ).tz(projectTimeZone, true),
                  to: dayjs(
                    dayjs(dateIndex).format("YYYY-MM-DD ") +
                      subBlockData[subBlockIndex].node.end_time,
                  ).tz(projectTimeZone, true),
                  subcontractorExpected: {
                    id: subBlockSubcontractorData.subcontractor.pk,
                    title: subBlockSubcontractorData.subcontractor.name,
                  },
                  calendarIds: subBlockCalendarsData.map((c) => c.pk),
                  weekday: subBlockData[subBlockIndex].node.weekday,
                });
              }
          }
        }
      }
    }

    return tempSiteDeliveryData;
  }, [calendarsData, deliveryData, datesRange, blockoutData, subBlockData]);

  const [insertFilterCalendar] =
    useAsyncMutation<GCProjectCalendarSitedeliverySubBlocks_insertFilterCalendar_Mutation>(
      insertFilterCalendarMutation,
    );

  const [deleteFilterCalendar] =
    useAsyncMutation<GCProjectCalendarSitedeliverySubBlocks_deleteFilterCalendar_Mutation>(
      deleteFilterCalendarMutation,
    );

  const handleOnCalendarHideUpdater = (
    store: RecordSourceSelectorProxy<GCProjectCalendarSitedeliverySubBlocks_insertFilterCalendar_Mutation$data>,
  ) => {
    const insertCalendar = store.getRootField(
      "insert_user_project_filter_calendar_one",
    );
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "GCProjectCalendarSitedeliverySubBlocks_user_project_filter_calendar_connection",
    );
    if (conn && insertCalendar) {
      const edge = store.create(uuid.v4(), "edge");
      edge.setLinkedRecord(insertCalendar, "node");
      ConnectionHandler.insertEdgeAfter(conn, edge);
    }
  };

  const handleOnCalendarHide = async (calendarId: string) => {
    setLoading(true);
    const filterCalendarId = uuid.v4();
    await insertFilterCalendar({
      variables: {
        object: {
          id: filterCalendarId,
          calendar_id: calendarId,
          filter_type: "web-hidden-calendar",
          project_id: projectId,
          user_id: userId,
        },
      },
      optimisticResponse: {
        insert_user_project_filter_calendar_one: {
          id: filterCalendarId,
          calendar: {
            id: calendarsData.find((c) => c.pk === calendarId)!.id,
            name: {
              id: calendarsData.find((c) => c.pk === calendarId)!.name?.id,
              en: calendarsData.find((c) => c.pk === calendarId)!.name?.en,
            },
            color_hex: calendarsData.find((c) => c.pk === calendarId)
              ?.color_hex,
          },
        },
      },
      optimisticUpdater: handleOnCalendarHideUpdater,
      updater: handleOnCalendarHideUpdater,
    });
    setLoading(false);
  };

  const handleOnCalendarShowUpdater = (
    store: RecordSourceSelectorProxy<GCProjectCalendarSitedeliverySubBlocks_deleteFilterCalendar_Mutation$data>,
  ) => {
    const deleteCalendars = store.getRootField(
      "delete_user_project_filter_calendar",
    );
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "GCProjectCalendarSitedeliverySubBlocks_user_project_filter_calendar_connection",
    );
    if (conn) {
      deleteCalendars.getLinkedRecords("returning").forEach((c) => {
        ConnectionHandler.deleteNode(conn, c.getDataID());
      });
    }
  };
  const handleOnCalendarShow = (calendarId: string) => {
    setLoading(true);
    deleteFilterCalendar({
      variables: {
        where: {
          calendar_id: { _eq: calendarId },
          project_id: { _eq: projectId },
          user_id: { _eq: userId },
        },
      },
      optimisticResponse: {
        delete_user_project_filter_calendar: {
          returning: [
            {
              id: filterCalendarsData.find((c) => c.calendar.pk === calendarId)!
                .id,
            },
          ],
        },
      },
      optimisticUpdater: handleOnCalendarShowUpdater,
      updater: handleOnCalendarShowUpdater,
    });
    setLoading(false);
  };

  return (
    <GCProjectCalendarSitedeliverySubBlocksUI
      calendars={siteDeliveryData}
      datesRange={datesRange}
      onDatesRangeChange={function (newDatesRange: DatesRangeType): void {
        setDatesRange(newDatesRange);
      }}
      hiddenCalendarIds={filterCalendarsData.map((c) => c.calendar.pk)}
      onCalendarHideClick={handleOnCalendarHide}
      onCalendarShowClick={handleOnCalendarShow}
      loading={loading}
      timezone={projectTimeZone}
      hideNewTimeBlockButton={props.hideNewTimeBlockButton}
    />
  );
};

export default GCProjectCalendarSitedeliverySubBlocks;
