/**
 * @generated SignedSource<<859c322edfce82d1e57fa450c7c5a4ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { worker_drug_test_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddWorkerDrugTestModalInsertCertsMutation$variables = {
  objects: ReadonlyArray<worker_drug_test_insert_input>;
};
export type AddWorkerDrugTestModalInsertCertsMutation$data = {
  readonly insert_worker_drug_test: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly pk: string;
    }>;
  } | null | undefined;
};
export type AddWorkerDrugTestModalInsertCertsMutation = {
  response: AddWorkerDrugTestModalInsertCertsMutation$data;
  variables: AddWorkerDrugTestModalInsertCertsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affected_rows",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddWorkerDrugTestModalInsertCertsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "worker_drug_test_mutation_response",
        "kind": "LinkedField",
        "name": "insert_worker_drug_test",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_drug_test",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddWorkerDrugTestModalInsertCertsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "worker_drug_test_mutation_response",
        "kind": "LinkedField",
        "name": "insert_worker_drug_test",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_drug_test",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": "pk",
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "101509d9cde290a6689b04905f79c556",
    "id": null,
    "metadata": {},
    "name": "AddWorkerDrugTestModalInsertCertsMutation",
    "operationKind": "mutation",
    "text": "mutation AddWorkerDrugTestModalInsertCertsMutation(\n  $objects: [worker_drug_test_insert_input!]!\n) {\n  insert_worker_drug_test(objects: $objects) {\n    affected_rows\n    returning {\n      id\n      pk: id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9183b18bbe9e93dfe7ced3831488decb";

export default node;
