import GCAdminObservations from "../../component/observation/GCAdminObservations";
import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../withCorporateAdminWrapper";
import { useNavigate } from "react-router-dom";

const GCCoporateObservations: React.FC<CorporateAdminWrapperProps> = ({
  employee,
}) => {
  const projWhere = {
    general_contractor_id: { _eq: employee.general_contractor.id },
  };
  const navigate = useNavigate();
  return (
    <GCAdminObservations
      onSettingsClick={() => navigate(`/gce/corporate/settings/observations`)}
      projWhere={projWhere}
      optionsQueryVars={{
        projWhere,
        gcBuWhere: employee.general_contractor.hierarchy_business_unit_name
          ? {
              general_contractor_id: {
                _eq: employee.general_contractor.id,
              },
            }
          : { id: { _is_null: true } },
        gcDivisionWhere: employee.general_contractor.hierarchy_division_name
          ? {
              general_contractor_id: {
                _eq: employee.general_contractor.id,
              },
            }
          : { id: { _is_null: true } },
        gcOfficeWhere: employee.general_contractor.hierarchy_office_name
          ? {
              general_contractor_id: {
                _eq: employee.general_contractor.id,
              },
            }
          : { id: { _is_null: true } },
      }}
      organizationId={employee.general_contractor.id}
      organizationLevel="gc"
      gcEmployeeWhere={{
        general_contractor_id: { _eq: employee.general_contractor.id },
      }}
    />
  );
};
export default withCorporateAdminWrapper(GCCoporateObservations);
