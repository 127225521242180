import { IconChevronRight, IconFileDescription } from "@tabler/icons";
import React from "react";
import Icon from "src/common/components/general/Icon";

export interface GCProjectReportsRowProps {
  title: string;
  subtitle?: string;
  handleClick: () => void;
}

const GCProjectReportsRow: React.FC<GCProjectReportsRowProps> = ({
  title,
  subtitle,
  handleClick,
}) => {
  return (
    <div
      className="rounded-0.75 border transition-colors p-1 border-interactive-secondary hover:border-interactive-primary cursor-pointer"
      onClick={handleClick}
    >
      <div className="flex justify-between transition-all">
        <div className="flex gap-1">
          <div className="mt-0.25">
            <Icon icon={IconFileDescription} color="interactive" />
          </div>
          <div className="flex flex-col gap-0.25">
            <div className="text-1.25">{title}</div>
            {subtitle && <p className="text-grey">{subtitle}</p>}
          </div>
        </div>
        <Icon icon={IconChevronRight} color="static-secondary" />
      </div>
    </div>
  );
};

export default GCProjectReportsRow;
