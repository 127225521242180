import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
  Space,
  Button,
  Input,
  Popconfirm,
  notification,
  Select,
  InputNumber,
} from "antd";
import {
  GetAdminProjectDocument,
  useRemoveTwilioNumberMutation,
  useCreateNewTwilioNumberMutation,
  useGetAvailableTwilioNumbersQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import Switcher from "src/common/components/general/Switcher";
import { IconCheck, IconX } from "@tabler/icons";
import capitalize from "src/common/functions/capitalize";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";

//type EditState = NonNullable<GetGeneralContractorByPkQuery["general_contractor_by_pk"]>["billing_settings"];

interface ProjectSettings {
  baseCount: number;
  basePrice: number;
  overPrice: number;
  overCount: number;
  enabled?: boolean;
  emergencyMessageEnabled?: boolean;
  priceType: string;
}

export interface SiteAlertPriceSelectorProps {
  title: string;
  settings: ProjectSettings;
  editing: boolean;
  priceEditing?: boolean;
  saving: boolean;
  savePrice: () => void;
  project?: {
    id: string;
    name: string;
    twilio_phone?: {
      phone_number: string;
      verification_status?: string | null;
      phone_number_sid: string;
    };
    twilio_emergency_phone?: {
      phone_number: string;
      verification_status?: string | null;
      phone_number_sid: string;
    };
  };
}

//type ProjectPrice = FreePrice | FixedPrice | UserPrice | StripePrice
export interface SiteAlertPriceSelectorRef {
  getEditedPrice: () => ProjectSettings;
}

const SiteAlertPriceSelector = forwardRef<
  SiteAlertPriceSelectorRef,
  SiteAlertPriceSelectorProps
>(
  (
    { editing, saving, settings, project, title, savePrice, priceEditing },
    ref,
  ) => {
    const [baseCount, setBaseCount] = useState(settings.baseCount);
    const [basePrice, setBasePrice] = useState(settings.basePrice / 100);
    const [overPrice, setOverPrice] = useState(settings.overPrice / 100);
    const [overCount, setOverCount] = useState(settings.overCount);
    const [enabled, setEnabled] = useState(settings.enabled);
    const [emergencyMessageEnabled, setEmergencyMessageEnabled] = useState(
      settings.emergencyMessageEnabled,
    );
    const [priceType, setPriceType] = useState(settings.priceType);
    const [removeTwilioNumber] = useRemoveTwilioNumberMutation();
    const [createNewTwilioNumber, { loading }] =
      useCreateNewTwilioNumberMutation();
    const { data } = useGetAvailableTwilioNumbersQuery({ variables: {} });
    useImperativeHandle(ref, () => ({
      getEditedPrice: (): ProjectSettings => {
        if (
          isNaN(basePrice) ||
          isNaN(overPrice) ||
          isNaN(baseCount) ||
          isNaN(overCount)
        ) {
          throw new Error("Please enter correct number");
        }
        return {
          baseCount,
          enabled,
          emergencyMessageEnabled,
          priceType,
          basePrice: basePrice * 100,
          overCount,
          overPrice: overPrice * 100,
        };
      },
    }));
    
    const twilioNumber = project?.twilio_phone;
    const twilioEmergencyNumber = project?.twilio_emergency_phone;

    return (
      <>
        <div style={{ margin: "20px" }}>
          <>
            {typeof settings.enabled !== "undefined" && (
              <div className="flex mt-1 items-center">
                SiteAlert Feature:
                <div className="ml-2">
                  {priceEditing || saving ? (
                    <Switcher
                      options={[
                        { icon: IconX, label: "OFF" },
                        { icon: IconCheck, label: "ON" },
                      ]}
                      disabled={
                        project &&
                        (!project.twilio_phone?.phone_number ||
                          project.twilio_phone.verification_status !==
                            "twilio_approved") &&
                        !enabled
                      }
                      initialSelectedOptionIndex={enabled ? 1 : 0}
                      onChange={async (index) => setEnabled(!!index)}
                    />
                  ) : (
                    <div>{enabled ? "ON" : "OFF"}</div>
                  )}
                </div>
              </div>
            )}
            <div className="flex mt-1 items-center">
              SiteAlert Feature Pricing:
              {editing || saving ? (
                <Select
                  className="w-8 ml-2"
                  disabled={saving}
                  options={[
                    { value: "default", label: "Default" },
                    { value: "free", label: "Free" },
                  ]}
                  value={priceType}
                  onChange={async (val) => {
                    console.log(val);
                    setPriceType(val);
                  }}
                />
              ) : (
                <div> &nbsp;{capitalize(priceType)}</div>
              )}
            </div>
            {project && (
              <div className="mt-1">
                {twilioNumber ? (
                  <>
                    Twilio Number: {twilioNumber.phone_number},
                    &nbsp;&nbsp;&nbsp;&nbsp;Status: &nbsp;
                    <span
                      className={
                        twilioNumber.verification_status === "twilio_approved"
                          ? `text-semantic-positive-green`
                          : "text-semantic-negative"
                      }
                    >
                      {" "}
                      {twilioNumber.verification_status ?? "Unverified"}
                    </span>
                    {twilioNumber.phone_number_sid &&
                      !enabled &&
                      priceEditing && (
                        <div className="text-semantic-negative">
                          <BPopconfirm
                            onConfirm={async () => {
                              await removeTwilioNumber({
                                variables: {
                                  projectId: project.id,
                                  phoneNumberSid: twilioNumber.phone_number_sid,
                                },
                              });
                            }}
                          >
                            <Button
                              type="dashed"
                              className="text-semantic-negative hover:border-semantic-negative hover:text-semantic-negative"
                              disabled={saving}
                            >
                              Release Number
                            </Button>
                          </BPopconfirm>
                          <div>
                            (NOTE: This action is irreversible, please be
                            careful and confirm if we want to remove the twilio
                            number from project)
                          </div>
                        </div>
                      )}
                  </>
                ) : (
                  <>
                    Available Numbers to Assign:{" "}
                    {(data?.getAvailableTwilioNumbers || []).map((pn) => (
                      <Popconfirm
                        title={
                          "Are you sure you want to assign this number to the project"
                        }
                        onConfirm={async () => {
                          try {
                            await createNewTwilioNumber({
                              variables: {
                                input: {
                                  friendlyName: `${project.name} SiteAlert`,
                                  phoneNumber: pn.phoneNumber,
                                  projectId: project.id,
                                  useType: "site-alert",
                                },
                              },
                              refetchQueries: [
                                {
                                  query: GetAdminProjectDocument,
                                  variables: { projectId: project.id },
                                },
                              ],
                            });
                          } catch (err) {
                            notification.error({
                              message:
                                err instanceof Error
                                  ? err.message
                                  : JSON.stringify(err),
                            });
                          }
                        }}
                      >
                        <Button type="primary" className="m-0.5">
                          {pn.friendlyName}
                        </Button>
                      </Popconfirm>
                    ))}
                  </>
                )}
                {typeof settings.emergencyMessageEnabled !== "undefined" && (
                  <div className="flex mt-1 items-center">
                    SiteAlert Emergency Message:
                    <div className="ml-2">
                      {priceEditing || saving ? (
                        <Switcher
                          options={[
                            { icon: IconX, label: "OFF" },
                            { icon: IconCheck, label: "ON" },
                          ]}
                          initialSelectedOptionIndex={
                            emergencyMessageEnabled ? 1 : 0
                          }
                          onChange={async (index) => {
                            setEmergencyMessageEnabled(!!index);
                          }}
                        />
                      ) : (
                        <div>{emergencyMessageEnabled ? "ON" : "OFF"}</div>
                      )}
                    </div>
                  </div>
                )}
                {emergencyMessageEnabled &&
                  (twilioEmergencyNumber ? (
                    <div className="mt-1">
                      Twilio Emergency Number:{" "}
                      {twilioEmergencyNumber.phone_number},
                      &nbsp;&nbsp;&nbsp;&nbsp;Status: &nbsp;
                      <span
                        className={
                          twilioEmergencyNumber.verification_status ===
                          "twilio_approved"
                            ? `text-semantic-positive-green`
                            : "text-semantic-negative"
                        }
                      >
                        {" "}
                        {twilioEmergencyNumber.verification_status ??
                          "Unverified"}
                      </span>
                      {twilioEmergencyNumber.phone_number_sid &&
                        !enabled &&
                        priceEditing && (
                          <div className="text-semantic-negative">
                            <BPopconfirm
                              onConfirm={async () => {
                                await removeTwilioNumber({
                                  variables: {
                                    projectId: project.id,
                                    phoneNumberSid:
                                      twilioEmergencyNumber.phone_number_sid,
                                  },
                                });
                              }}
                            >
                              <Button
                                type="dashed"
                                className="text-semantic-negative hover:border-semantic-negative hover:text-semantic-negative"
                                disabled={saving}
                              >
                                Release Number
                              </Button>
                            </BPopconfirm>
                            <div>
                              (NOTE: This action is irreversible, please be
                              careful and confirm if we want to remove the
                              twilio number from project)
                            </div>
                          </div>
                        )}
                    </div>
                  ) : (
                    <div className="mt-1">
                      Available Numbers to Assign:{" "}
                      {(data?.getAvailableTwilioNumbers || []).map((pn) => (
                        <Popconfirm
                          title={
                            "Are you sure you want to assign this emergency number to the project"
                          }
                          onConfirm={async () => {
                            await createNewTwilioNumber({
                              variables: {
                                input: {
                                  friendlyName: `${project.name} SiteAlert Emergency`,
                                  phoneNumber: pn.phoneNumber,
                                  projectId: project.id,
                                  useType: "site-alert-emergency",
                                },
                              },
                              refetchQueries: [
                                {
                                  query: GetAdminProjectDocument,
                                  variables: { projectId: project.id },
                                },
                              ],
                            });
                          }}
                        >
                          <Button type="primary" className="m-0.5">
                            {pn.friendlyName}
                          </Button>
                        </Popconfirm>
                      ))}
                    </div>
                  ))}
              </div>
            )}
          </>

          <span className="pt-1 inline-block text-right">{title}:</span>
          <div
            style={{
              margin: "20px",
              marginLeft: "60px",
              marginTop: "5px",
            }}
          >
            <div>
              <Space direction="horizontal">
                Base Price: upto
                {editing || saving ? (
                  <InputNumber
                    disabled={saving}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") savePrice();
                    }}
                    accept=""
                    style={{ width: "100px" }}
                    value={baseCount}
                    onChange={(e) =>
                      typeof e === "number" ? setBaseCount(e) : null
                    }
                  ></InputNumber>
                ) : (
                  baseCount
                )}
                SMS for{" "}
                {editing ? (
                  <InputNumber
                    disabled={saving}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") savePrice();
                    }}
                    style={{ width: "100px" }}
                    value={basePrice}
                    onChange={(e) =>
                      typeof e === "number" ? setBasePrice(e) : null
                    }
                  ></InputNumber>
                ) : emergencyMessageEnabled ? (
                  `${basePrice}(+ 10$ for SiteEmergency)`
                ) : (
                  basePrice
                )}{" "}
                USD
              </Space>
            </div>
            <div>
              <Space direction="horizontal">
                For each additional{" "}
                {editing || saving ? (
                  <InputNumber
                    disabled={saving}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") savePrice();
                    }}
                    style={{ width: "100px" }}
                    value={overCount}
                    onChange={(e) =>
                      typeof e === "number" ? setOverCount(e) : null
                    }
                  ></InputNumber>
                ) : (
                  overCount
                )}
                SMS,{" "}
                {editing ? (
                  <InputNumber
                    disabled={saving}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") savePrice();
                    }}
                    style={{ width: "100px" }}
                    value={overPrice}
                    onChange={(e) =>
                      typeof e === "number" ? setOverPrice(e) : null
                    }
                  ></InputNumber>
                ) : (
                  overPrice
                )}{" "}
                USD each
              </Space>
            </div>
          </div>
        </div>
      </>
    );
  },
);

export default SiteAlertPriceSelector;
