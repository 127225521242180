/**
 * @generated SignedSource<<4411e095ac0c875f939137a83d6346c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { task_admin_bool_exp, task_admin_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TaskInfo_update_task_admin_Mutation$variables = {
  deleteWhere: task_admin_bool_exp;
  objects: ReadonlyArray<task_admin_insert_input>;
};
export type TaskInfo_update_task_admin_Mutation$data = {
  readonly delete_task_admin: {
    readonly returning: ReadonlyArray<{
      readonly general_contractor_employee: {
        readonly employee_title: {
          readonly name: {
            readonly en: string;
          };
        } | null | undefined;
        readonly user: {
          readonly name: string;
          readonly pk: string;
        };
      };
      readonly id: string;
    }>;
  } | null | undefined;
  readonly insert_task_admin: {
    readonly returning: ReadonlyArray<{
      readonly general_contractor_employee: {
        readonly employee_title: {
          readonly name: {
            readonly en: string;
          };
        } | null | undefined;
        readonly user: {
          readonly name: string;
          readonly pk: string;
        };
      };
      readonly id: string;
    }>;
  } | null | undefined;
};
export type TaskInfo_update_task_admin_Mutation = {
  response: TaskInfo_update_task_admin_Mutation$data;
  variables: TaskInfo_update_task_admin_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleteWhere"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objects"
},
v2 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "deleteWhere"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "task_admin",
    "kind": "LinkedField",
    "name": "returning",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "general_contractor_employee",
        "kind": "LinkedField",
        "name": "general_contractor_employee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "employee_title",
            "kind": "LinkedField",
            "name": "employee_title",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "text_translation",
                "kind": "LinkedField",
                "name": "name",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "task_admin",
    "kind": "LinkedField",
    "name": "returning",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "general_contractor_employee",
        "kind": "LinkedField",
        "name": "general_contractor_employee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "employee_title",
            "kind": "LinkedField",
            "name": "employee_title",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "text_translation",
                "kind": "LinkedField",
                "name": "name",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": "pk",
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              },
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskInfo_update_task_admin_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "task_admin_mutation_response",
        "kind": "LinkedField",
        "name": "delete_task_admin",
        "plural": false,
        "selections": (v6/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "task_admin_mutation_response",
        "kind": "LinkedField",
        "name": "insert_task_admin",
        "plural": false,
        "selections": (v6/*: any*/),
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TaskInfo_update_task_admin_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "task_admin_mutation_response",
        "kind": "LinkedField",
        "name": "delete_task_admin",
        "plural": false,
        "selections": (v8/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "task_admin_mutation_response",
        "kind": "LinkedField",
        "name": "insert_task_admin",
        "plural": false,
        "selections": (v8/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4fbc6bcc12643daebe5eb6cdb2875d29",
    "id": null,
    "metadata": {},
    "name": "TaskInfo_update_task_admin_Mutation",
    "operationKind": "mutation",
    "text": "mutation TaskInfo_update_task_admin_Mutation(\n  $objects: [task_admin_insert_input!]!\n  $deleteWhere: task_admin_bool_exp!\n) {\n  delete_task_admin(where: $deleteWhere) {\n    returning {\n      id\n      general_contractor_employee {\n        employee_title {\n          name {\n            en\n            id\n          }\n          id\n        }\n        user {\n          pk: id\n          name\n          id\n        }\n        id\n      }\n    }\n  }\n  insert_task_admin(objects: $objects) {\n    returning {\n      id\n      general_contractor_employee {\n        employee_title {\n          name {\n            en\n            id\n          }\n          id\n        }\n        user {\n          pk: id\n          name\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e890ad8d83fa4d0b7f1f88c5a945dafd";

export default node;
