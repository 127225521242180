import PieChart, {
  PieChartProps,
} from "src/common/components/charts/pie/PieChart";
import { titleCase } from "src/common/functions/stringFormatters";
import ChartEmptyFallback from "../ChartEmptyFallback";

export interface ShowPieChartProps {
  valueTitle?: string;
  dataSource: PieChartProps["dataSource"];

  maxCount?: number;
}

export const groupDataForChart = (
  dataList: PieChartProps["dataSource"],
  maxCount?: number,
) => {
  // Sort dataList in descending order by value (or any sorting criteria)
  const sortedDataList = [...dataList].sort((a, b) => b.value - a.value);
  if (!maxCount || sortedDataList.length <= maxCount)
    return { newDataSource: sortedDataList, othersCount: undefined };
  // Get the top maxCount items
  const topEntries = sortedDataList.slice(0, maxCount);

  const otherEntries = sortedDataList.slice(maxCount);

  // Sum the values of the other entries
  const othersCount = otherEntries.reduce((sum, entry) => sum + entry.value, 0);

  return { newDataSource: topEntries, othersCount };
};

const ShowPieChart: React.FC<ShowPieChartProps> = ({
  dataSource,
  valueTitle,
  maxCount = 10,
}) => {
  if (!dataSource?.some((data) => data.value > 0)) {
    return <ChartEmptyFallback />;
  }

  const { newDataSource, othersCount } = groupDataForChart(
    dataSource,
    maxCount,
  );
  return (
    <PieChart
      key={`chart`}
      othersCount={othersCount}
      singleMetric={false}
      label={[
        "name",
        (name: string) => ({
          content: (record: any) =>
            `${titleCase(name)} - ${Math.round(100 * record.percent)}%`,
        }),
      ]}
      chartCfg={{
        autoFit: true,
        height: 300,
        // width: 350,
        padding: [10, 110, 10, 30],
        defaultInteractions: [],
      }}
      tooltip={{
        config: {
          showTitle: false,
          showMarkers: false,
        },
        displayAs: [
          "name*value",
          (name, value) => ({
            name: titleCase(name),
            value: `${valueTitle ? `${valueTitle}: ` : ``} ${value}`,
          }),
        ],
      }}
      showLegend={true}
      interactions={["tooltip", "element-active"]}
      dataSource={newDataSource}
    />
  );
};

export default ShowPieChart;
