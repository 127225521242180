import { Checkbox, Form } from "antd";
import { useMemo } from "react";
import FModal from "src/common/components/dialogs/FModal";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";
import useSelectedIndicies from "src/common/hooks/useSelectedIndices";

type Option = { label: string; value: string; color_hex?: string };
export interface ObservationReportFieldsFormVals {
  risk_levels: string[];
  statuses: string[];
  category_type_ids: string[];
  category_ids: string[];
}
const ObservationReportFields: React.FC<{
  riskOptions: Array<Option>;
  categoryOptions: Array<{
    label: string;
    value: string;
    subCategories: Array<Option>;
  }>;
  statusOptions: Array<Option>;
}> = ({ riskOptions, categoryOptions, statusOptions }) => {
  const [selectedCategories, handleCategorySelection] =
    useSelectedIndicies<string>();

  const subCategoryOptions = useMemo(
    () =>
      selectedCategories.size === 0
        ? []
        : categoryOptions
            .filter((cateType) => selectedCategories.has(cateType.value))
            .flatMap((cateType) =>
              cateType.subCategories.map((cate) => ({
                value: cate.value,
                label: cate.label,
              })),
            ),
    [categoryOptions, selectedCategories],
  );

  const placeholderText = "All included by default";

  return (
    <>
      <FModal.Select
        name={"risk_levels"}
        label="Risk Levels"
        props={{
          mode: "multiple",
          placeholder: placeholderText,
          options: riskOptions.map((risk) => ({
            value: risk.value,
            allowClear: true,
            label: <div style={{ color: risk.color_hex }}>{risk.label}</div>,
          })),
        }}
      />
      <FModal.Select
        name={"category_type_ids"}
        label="Observation Categories"
        props={{
          mode: "multiple",
          showSearch: true,
          filterOption: getNormalSelectOptionsFilter,
          placeholder: placeholderText,
          onSelect: (val) =>
            typeof val === "string" && handleCategorySelection(val),
          onDeselect: (val) =>
            typeof val === "string" && handleCategorySelection(val),
          options: categoryOptions.map((cateType) => ({
            value: cateType.value,
            label: cateType.label,
          })),
        }}
      />
      <FModal.Select
        className={selectedCategories.size > 0 ? undefined : "hiddent"}
        name={"category_ids"}
        label="Observation Sub-Categories"
        props={{
          mode: "multiple",
          showSearch: true,
          filterOption: getNormalSelectOptionsFilter,
          allowClear: true,
          placeholder: placeholderText,
          options: subCategoryOptions,
        }}
      />

      <Form.Item
        label={`Observation Status`}
        name="statuses"
        rules={[
          { required: true, message: "Please select at least one option!" },
        ]}
      >
        <Checkbox.Group options={statusOptions}></Checkbox.Group>
      </Form.Item>
    </>
  );
};

export default ObservationReportFields;
