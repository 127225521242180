import { Alert, Form, InputNumber, Select } from "antd";
import { useRef, useState } from "react";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import useInsertObsReq, { ObsReqFormVals } from "./utils/useInsertObsReq";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

export const EditingInputNumber: React.FC<{
  itemKey: "audits_required" | "observations_required" | "findings_per_audit";
  initialValue?: number;
  label?: string;
}> = ({ itemKey, initialValue, label }) => (
  <Form.Item
    name={itemKey}
    initialValue={initialValue}
    label={label}
    rules={[{ required: true, message: "Enter 0 if none required" }]}
  >
    <InputNumber inputMode="numeric" type="number" min={0} />
  </Form.Item>
);
export const EmployeeTitleSelectFormItem: React.FC<{
  options: { label: string; value: string; alreadyAddedToOther: boolean }[];
  initialValue?: string[];
  label?: string;
}> = ({ options, initialValue, label }) => {
  const [addedToOthersSelected, setAddedToOthersSelected] = useState<string[]>(
    [],
  );
  return (
    <div className={`flex flex-col `}>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Required at least on title to be selected",
          },
        ]}
        name="employee_titles"
        initialValue={initialValue}
        label={label}
      >
        <Select
          mode="multiple"
          options={options}
          className="w-24"
          showSearch
          filterOption={getNormalSelectOptionsFilter}
          onChange={(_, options) => {
            const selectedLabels: string[] = [];
            if (Array.isArray(options)) {
              options.forEach((op) => {
                if (op.alreadyAddedToOther && op.label) {
                  selectedLabels.push(op.label.toString());
                }
              });
              setAddedToOthersSelected(selectedLabels);
            }
          }}
          placeholder="Select all required positions"
        />
      </Form.Item>
      {!!addedToOthersSelected.length && (
        <Alert
          type="warning"
          description={`A title(s) is already included in another group. If added to this group it will remove it from its current group: ${addedToOthersSelected.join(
            ", ",
          )}`}
        />
      )}
    </div>
  );
};
const CreateNewReqModal: React.FC<{
  titleOptions: {
    label: string;
    value: string;
    alreadyAddedToOther: boolean;
  }[];
  open: boolean;
  onClose: () => void;
  gcId: string;
}> = ({ titleOptions, onClose, open, gcId }) => {
  const fmodalRef = useRef<FModalRef<ObsReqFormVals>>();
  const [insertObsReq] = useInsertObsReq(gcId);

  return (
    <FModal
      ref={fmodalRef}
      onCancel={() => onClose()}
      // confirmLoading={inserting}
      onOk={async () => {
        const vals = await fmodalRef.current?.form
          .validateFields()
          .catch(() => null);
        if (!vals) return;
        await insertObsReq(vals);
        fmodalRef.current?.form.resetFields();
        onClose();
      }}
      open={open}
      okText="Save"
      title="Add a New Observation Quota Goal"
    >
      <FModal.Text
        name="group_name"
        required={true}
        label="Name the Group"
        requiredMessage="Enter the name for the group of titles"
      />
      <EditingInputNumber
        itemKey={"audits_required"}
        label="Audits per Month Goal"
      />
      <EditingInputNumber
        itemKey={"findings_per_audit"}
        label="Findings per Audit Goal"
      />
      <EditingInputNumber
        itemKey={"observations_required"}
        label="One-Off Observations per Month"
      />
      <EmployeeTitleSelectFormItem
        options={titleOptions}
        label="Select Titles to Include"
      />
    </FModal>
  );
};
export default CreateNewReqModal;
