import { Button, notification, Space } from "antd";
import React, { FC, useState } from "react";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";
import AddSubcontractorEmployeeModal from "src/common/components/dialogs/AddSubcontractorEmployeeModal";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import StyledContent from "src/common/components/layouts/StyledContent";
import SubcontractorEmployeesTable from "src/common/components/tables/SubcontractorEmployeesTable";
import useColumnFilter from "src/common/hooks/useColumnFilter";
import useColumnOrderBy from "src/common/hooks/useColumnOrderBy";
import useColumnSearch from "src/common/hooks/useColumnSearch";
import openInNewTab from "src/common/functions/openInNewTab";
import {
  GetSubcontractorEmployeesDocument,
  Subcontractor_Employee_Order_By,
  useCreateSubcontractorEmployeeMutation,
  useDeleteSubcontractorEmployeeMutation,
  useGetSubcontractorEmployeesQuery,
  useGetSubcontractorEmployeeTitlesQuery,
} from "src/common/types/generated/apollo/graphQLTypes";

// TODO limit offset
// TODO move to postmark app
// TODO sub employee create flow? send email?
// TODO cache-and-network policy for other views
const SCEmployees: FC = () => {
  const subcontractorId = useSubcontractorId();
  const [addEmployeeOpen, setAddEmployeeOpen] = useState(false);

  const { orderBy, setOrderBy } =
    useColumnOrderBy<Subcontractor_Employee_Order_By>();
  const nameSearch = useColumnSearch();
  const usernameSearch = useColumnSearch();
  const emailSearch = useColumnSearch();
  const phoneSearch = useColumnSearch();

  const titlesFilterQuery = useGetSubcontractorEmployeeTitlesQuery();

  const titleFilter = useColumnFilter(titlesFilterQuery, {
    arrayIndex: ["subcontractor_employee_title"],
    labelIndex: ["name", "en"],
    valueIndex: ["id"],
  });

  const { data, loading } = useGetSubcontractorEmployeesQuery({
    variables: {
      where: {
        subcontractor_id: { _eq: subcontractorId },
        title_id: { _in: titleFilter.filters },
        user: {
          name: {
            _ilike: nameSearch.value,
          },
          email: {
            _ilike: emailSearch.value,
          },
          username: {
            _ilike: usernameSearch.value,
          },
          phone_number: {
            _ilike: phoneSearch.value,
          },
        },
      },
      order_by: [orderBy],
    },
    fetchPolicy: "cache-and-network",
  });
  const [createSubEmployee, { loading: creatingSubEmployee }] =
    useCreateSubcontractorEmployeeMutation();
  const [deleteSubEmployee, { loading: deletingSubEmployee }] =
    useDeleteSubcontractorEmployeeMutation();

  return (
    <BasicWrapper scrollable>
      <StyledContent padding backgroundColor="white">
        {subcontractorId && (
          <AddSubcontractorEmployeeModal
            visible={addEmployeeOpen}
            onCancel={() => setAddEmployeeOpen(false)}
            onCreate={async (values) => {
              setAddEmployeeOpen(false);
              await createSubEmployee({
                awaitRefetchQueries: true,
                variables: {
                  input: {
                    name: values.name,
                    email: values.email,
                    phone: values.phoneNumber,
                    employeeTitleId: values.titleId,
                    subcontractorId,
                    sendInvite: values.send_invite,
                  },
                },
                refetchQueries: [
                  {
                    query: GetSubcontractorEmployeesDocument,
                    variables: {
                      where: {
                        subcontractor_id: { _eq: subcontractorId },
                        title_id: { _in: titleFilter.filters },
                        user: {
                          name: {
                            _ilike: nameSearch.value,
                          },
                          email: {
                            _ilike: emailSearch.value,
                          },
                          username: {
                            _ilike: usernameSearch.value,
                          },
                          phone_number: {
                            _ilike: phoneSearch.value,
                          },
                        },
                      },
                      order_by: [orderBy],
                    },
                  },
                ],
              });
              notification.open({
                message: `${values.name}'s account was created!`,
                description:
                  "They will receive an email with details of how to log into their account.",
              });
            }}
          />
        )}
        <SubcontractorEmployeesTable
          sortColumns={{
            name: true,
            title: true,
            phone: true,
            username: true,
            email: true,
          }}
          searchColumns={{
            name: nameSearch.setter,
            phone: phoneSearch.setter,
            username: usernameSearch.setter,
            email: emailSearch.setter,
          }}
          filterColumns={{ title: titleFilter.filterOptions }}
          onChange={(_, filters, sorter) => {
            setOrderBy(sorter);
            titleFilter.setFilters(filters.title!);
          }}
          title={() => (
            <Space direction="vertical">
              <h3>Administrators</h3>
              <Space>
                <Button
                  type="primary"
                  loading={creatingSubEmployee}
                  onClick={() => setAddEmployeeOpen(true)}
                >
                  Add Administrator
                </Button>
                <Button
                  onClick={() => {
                    openInNewTab("https://youtu.be/HmzYOCcZKaY");
                  }}
                >
                  Help
                </Button>
              </Space>
            </Space>
          )}
          onDelete={(employee) => {
            deleteSubEmployee({
              awaitRefetchQueries: true,
              variables: {
                userId: employee.user_id,
              },
              refetchQueries: [
                {
                  query: GetSubcontractorEmployeesDocument,
                  variables: {
                    where: {
                      subcontractor_id: { _eq: subcontractorId },
                      title_id: { _in: titleFilter.filters },
                      user: {
                        name: {
                          _ilike: nameSearch.value,
                        },
                        email: {
                          _ilike: emailSearch.value,
                        },
                        username: {
                          _ilike: usernameSearch.value,
                        },
                        phone_number: {
                          _ilike: phoneSearch.value,
                        },
                      },
                    },
                    order_by: [orderBy],
                  },
                },
              ],
            });
          }}
          loading={loading || creatingSubEmployee || deletingSubEmployee}
          dataSource={(data?.subcontractor_employee as any) ?? []}
          pagination={{
            pageSize: 10,
            total: data?.subcontractor_employee.length ?? 0,
          }}
        />
      </StyledContent>
    </BasicWrapper>
  );
};

export default withCustomSuspense(SCEmployees);
