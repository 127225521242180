import dayjs from "dayjs";
import { FC, useState, useEffect, useMemo } from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import {
  useGetCompletionAuditV3Query,
  useGetSiteBoardPtpViewQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import SiteBoardBase from "src/domain-features/siteboard/components/SiteBoardBase";
import { Image } from "antd";
import { useSearchParams } from "react-router-dom";
import LoadingContent from "src/common/components/general/LoadingContent";
interface SiteBoardPtpsViewProps {
  projectId: string;
}

type ImageData = {
  company: string;
  type: "image" | "text";
} & (
  | { type: "image"; url: string; submitter: string; crew?: string }
  | { type: "text"; text: string }
);

const SiteBoardPtpsView: FC<SiteBoardPtpsViewProps> = (props) => {
  const today = dayjs();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageData, setImageData] = useState<ImageData[]>([]);
  const [searchParams] = useSearchParams();
  const {
    data,
    refetch: refetchPtp,
    loading: loadingPtp,
  } = useGetSiteBoardPtpViewQuery({
    variables: {
      projectId: props.projectId,
      startTime: today.startOf("day").format(),
      endTime: today.endOf("day").format(),
    },
  });
  const timezone = useMemo(() => data?.project[0].timezone, []);
  const {
    data: auditData,
    refetch: refetchAudit,
    loading: loadingAudit,
  } = useGetCompletionAuditV3Query({
    variables: {
      input: {
        projectId: props.projectId,
        startDate: today.startOf("day").toISOString(),
        endDate: today.endOf("day").toISOString(),
        timezone,
      },
    },
  });

  useEffect(() => {
    const newImageData: ImageData[] = [];
    data?.report.forEach((report) => {
      const images = [
        ...report.images,
        ...report.submission_images.map((subImage) => ({
          url: subImage.image.url,
          crew: report.project_crew?.name,
          submitter: report.submitter_project_worker.worker.user.name,
        })),
      ];

      images.forEach((image) => {
        newImageData.push({
          type: "image",
          company: report.subcontractor.name,
          url: image.url,
          crew: report.project_crew?.name,
          submitter: report.submitter_project_worker.worker.user.name,
        });
      });
    });

    auditData?.getCompletionAuditV3.forEach((auditItem) => {
      const completion = auditItem.completion.at(0);
      if (
        completion &&
        (completion.is_onsite || completion?.onsite_required) &&
        completion.safety_reports_completion_count === 0 &&
        completion.safety_reports_required
      ) {
        newImageData.push({
          type: "text",
          company: auditItem.name,
          text: "Not Submitted Report Yet",
        });
      }
    });

    setImageData(newImageData);
  }, [data, auditData]);

  const resetTimeLine = () => {
    const totalImages = imageData.length;
    setCurrentIndex((prevIndex) => {
      return totalImages > 0 ? (prevIndex + 1) % totalImages : 0;
    });
  };
  useEffect(() => {
    if (imageData.length === 0) return;

    const intervalId = setInterval(
      () => {
        resetTimeLine();
      },
      searchParams.get("interval")
        ? parseInt(searchParams.get("interval")!) * 1000
        : 3000,
    );

    return () => {
      clearInterval(intervalId);
    };
  }, [imageData, searchParams]);

  useEffect(() => {
    const refetchIntervalId = setInterval(() => {
      refetchPtp();
      refetchAudit();
    }, 60000);

    return () => {
      clearInterval(refetchIntervalId);
    };
  }, []);
  if (loadingPtp || loadingAudit) {
    return <LoadingContent />;
  }
  const currentImage = imageData[currentIndex];

  return (
    <SiteBoardBase autoScroll>
      <div className="h-full w-full">
        <div className="text-3 text-white text-center">PTP Images</div>
        <div className="mt-1 flex flex-col items-center">
          {currentImage && (
            <div className="flex flex-col items-center justify-center">
              <div className="mb-4 flex flex-col items-center">
                {currentImage.type === "image" ? (
                  <>
                    <span className="text-white text-2 mb-4 text-center">
                      {currentImage.company}
                      {currentImage.crew ? ` (${currentImage.crew})` : ``}
                      {currentImage.submitter
                        ? `(${currentImage.submitter})`
                        : ``}
                    </span>

                    <div
                      className="p-2 mb-2 flex justify-center items-center mx-auto border border-spacing-2 border-white"
                      style={{ margin: "10px" }}
                    >
                      <Image
                        src={currentImage.url}
                        className="object-fill max-w-full max-h-48 items-center"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <span className="text-white text-2 mb-1 text-center">
                      {currentImage.company}
                    </span>

                    <span className="text-white h-screen w-screen flex justify-center items-center text-4">
                      {currentImage.text}
                    </span>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </SiteBoardBase>
  );
};

export default withCustomSuspense(SiteBoardPtpsView);
