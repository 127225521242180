import withBusinessUnitId, {
  BusinessUnitProps,
} from "../../utils/withBusinessUnitId";
import GCAdminObservations from "../../component/observation/GCAdminObservations";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
const GCBuObservations: React.FC<BusinessUnitProps> = ({ businessUnitId }) => {
  const { userData } = useUserData();
  const projWhere = { gc_business_unit_id: { _eq: businessUnitId } };
  return (
    <GCAdminObservations
      optionsQueryVars={{
        projWhere,
        gcBuWhere: { id: { _is_null: true } },
        gcDivisionWhere: { id: { _is_null: true } },
        gcOfficeWhere: userData.employee?.general_contractor
          .hierarchy_office_name
          ? { gc_business_unit_id: { _eq: businessUnitId } }
          : { id: { _is_null: true } },
      }}
      projWhere={projWhere}
      organizationId={businessUnitId}
      organizationLevel="business_unit"
      gcEmployeeWhere={{
        _or: [
          {
            gc_business_units: { gc_business_unit_id: { _eq: businessUnitId } },
          },
          {
            gc_offices: {
              gc_office: { gc_business_unit_id: { _eq: businessUnitId } },
            },
          },
          {
            employee_projects: {
              project: { gc_business_unit_id: { _eq: businessUnitId } },
              _or: [
                { direct_project_assign: { _eq: true } },
                { direct_project_assign: { _is_null: true } },
              ],
            },
          },
        ],
      }}
    />
  );
};
export default withBusinessUnitId(GCBuObservations);
