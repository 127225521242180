import { IconEraser } from "@tabler/icons";
import { Form, message, Select, Spin } from "antd";
import { RefSelectProps } from "antd/lib/select";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useEffect, useRef, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import CustomSignatureCanvas from "src/common/components/general/signature-canvas/CustomSignatureCanvas";
import useSignaturePad from "src/common/components/general/signature-canvas/useSignaturePad";
import BackButton from "src/common/components/general/button/BackButton";
import Button from "src/common/components/general/button/Button";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import MobileViewWrapper from "src/common/components/layouts/MobileViewWrapper";
import isNullOrUndefined from "src/common/functions/isNullOrUndefined";
import { useWorkerJhaSignMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { QrProjectSubcontractorTaskSignQuery } from "src/common/types/generated/relay/QrProjectSubcontractorTaskSignQuery.graphql";
import ProjectProps from "src/common/types/manual/ProjectProps";
import SubcontractorProps from "src/common/types/manual/SubcontractorProps";
import TaskProps from "src/common/types/manual/TaskProps";
import * as uuid from "uuid";
import { CustomHeader } from "./QrProjectSubcontractorTask";

const query = graphql`
  query QrProjectSubcontractorTaskSignQuery(
    $taskId: uuid!
    $projectId: uuid!
    $subcontractorId: uuid!
  ) {
    task_connection(where: { id: { _eq: $taskId } }) {
      edges {
        node {
          description {
            en
          }
          subcontractor {
            name
          }
          task_signatures(where: { is_active: { _eq: true } }) {
            user_id
            created_at
          }
          task_edit(order_by: { edited_at: desc }) {
            task_patch
            edited_at
          }
        }
      }
    }
    project_worker_connection(
      where: {
        project_id: { _eq: $projectId }
        subcontractor_id: { _eq: $subcontractorId }
      }
    ) {
      edges {
        node {
          worker_id
          id
          worker {
            user {
              name
              username
              birth_date
            }
          }
        }
      }
    }
  }
`;

const QrProjectSubcontractorTaskSign: FC<
  TaskProps & ProjectProps & SubcontractorProps
> = ({ taskId, projectId, subcontractorId }) => {
  const navigate = useNavigate();
  const [openCanvas, setOpenCanvas] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  const selectRef = useRef<RefSelectProps>(null);
  const data = useLazyLoadQuery<QrProjectSubcontractorTaskSignQuery>(query, {
    taskId,
    projectId,
    subcontractorId,
  });

  const taskNode = data.task_connection.edges[0]?.node;
  if (!taskNode) {
    // we do not expect wrong taskId, and notify about problem.
    throw new Error("Task does not exist");
  }

  const [workerSign, { loading: insertingSign }] = useWorkerJhaSignMutation();

  const searchForWarning = (cur: string) => {
    //will give boolean wether selected person has already signed the JHA or not
    return taskNode.task_signatures.some(
      (previousSign) => previousSign.user_id === cur,
    );
  };

  const handleChange = (newUserId: string) => {
    // console.log(value);
    if (newUserId.length > 0) {
      setOpenDropDown(false);
      if (searchForWarning(newUserId)) {
        message.warning("Selected user has already signed the JHA");
      } else {
        setOpenCanvas(true);
        setOpenDropDown(false);
      }
    }
    if (newUserId.length === 0) {
      setOpenCanvas(false);
      setOpenDropDown(false);
    }
  };
  const { uploadSignature, signaturePadProps, uploading } = useSignaturePad();

  const handleFoucusOut = (e: any) => setOpenDropDown(false);
  useEffect(() => {
    document.addEventListener("focusout", handleFoucusOut);
    return () => document.removeEventListener("focusout", handleFoucusOut);
  }, []);

  const [form] = Form.useForm();
  return (
    <MobileViewWrapper>
      <div
        onClick={() => {
          if (openDropDown) setOpenDropDown(false);
        }}
        className={`flex flex-col gap-1`}
      >
        <p className="text-1.5">{`JHA - ${data.task_connection.edges[0]?.node.description.en}`}</p>
        <CustomHeader
          header={
            !isNullOrUndefined(
              data.task_connection.edges[0]?.node.subcontractor,
            )
              ? !isNullOrUndefined(
                  data.task_connection.edges[0]?.node.subcontractor?.name,
                )
                ? data.task_connection.edges[0]?.node.subcontractor?.name!
                : ""
              : ""
          }
        />
        <Form
          form={form}
          onLoad={() => <Spin />}
          layout="vertical"
          name="form_in_modal"
          onFinish={async (v) => {
            //console.log(v);
            const imgUrl = await uploadSignature();

            if (v.worker && imgUrl) {
              await workerSign({
                variables: {
                  input: {
                    imageUrl: imgUrl,
                    taskId: taskId,
                    workerId: v.worker,
                  },
                },
              }).then(() => {
                navigate(`/qr/p/${projectId}/sub/${subcontractorId}`);
              });
            }
          }}
        >
          <Form.Item
            name="worker"
            label={
              <p>
                Type your <span className="font-accent">LAST name</span>
              </p>
            }
            rules={[
              {
                required: true,
                message: "Name must be selected",
              },
            ]}
          >
            {data.project_worker_connection.edges && (
              <Select
                showSearch
                disabled={openCanvas}
                placeholder="Type your name"
                ref={selectRef}
                onChange={handleChange}
                onSearch={(input) => {
                  setOpenDropDown(input.length >= 3);
                }}
                onFocus={(e) => {
                  window.scrollTo({
                    top:
                      e.target.getBoundingClientRect().top -
                      window.innerHeight / 2,
                    behavior: "smooth",
                  });
                }}
                className="w-full"
                dropdownStyle={{
                  display: `${openDropDown ? "block" : "none"}`,
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") setOpenDropDown(false);
                }}
                filterOption={(input, option) => {
                  if (input.length < 3) {
                    return false;
                  } else if (option?.label) {
                    return (
                      option.label
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  } else {
                    return false;
                  }
                }}
                onSelect={() => {
                  selectRef.current?.blur();
                }}
                options={data.project_worker_connection.edges.map((c) => {
                  const wId = c.node.worker_id;
                  return {
                    value: wId,
                    label: c.node.worker.user.name,
                  };
                })}
              ></Select>
            )}
          </Form.Item>
        </Form>
        {openCanvas && (
          <div>
            <div className="flex flex-row items-center w-full gap-1 mb-1">
              <p className="text-1.5 flex-1">Sign Below</p>
            </div>
            <div className="bg-white border-0.125 rounded-0.5 border-suplementary-3 w-full p-0.125">
              <CustomSignatureCanvas {...signaturePadProps} />
            </div>
          </div>
        )}
        {openCanvas && (
          <div className="flex flex-col gap-0.5">
            <div className="text-grey text0.5 mb-1">
              By signing and submitting you agree to SiteForm's{" "}
              <a
                href="https://www.termsfeed.com/terms-conditions/f73fc5afe6a821990e96daebc0b83d39"
                className="underline text-interactive-primary"
                target="_blank"
              >
                Terms of Service
              </a>{" "}
              and you certify you have reviewed and/or received training on this
              JHA (Job Hazard Analysis). You also certify that you understand
              it, can perform this work safely and you are the person mentioned
              above. If you do not understand any part of this JHA, contact your
              Employer or Supervisor for further explanation, training and
              instructions
            </div>
            <div className="flex flex-row gap-0.5">
              <BackButton fullWidth />
              <Button
                onClick={form.submit}
                loading={uploading || insertingSign}
                label={`Submit`}
                fullWidth
              />
            </div>

            <div className="flex flex-col gap-0.5"></div>
          </div>
        )}
      </div>
    </MobileViewWrapper>
  );
};
export default withCustomSuspense(QrProjectSubcontractorTaskSign);
