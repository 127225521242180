/**
 * @generated SignedSource<<bffe4e60b5a9116c64b4b00a9a374d0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { delivery_calendar_insert_input, delivery_set_input, user_project_filter_calendar_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SitedeliveryDetailsDrawerUpdateDeliveryMutation$variables = {
  deleteCalendarId: ReadonlyArray<string>;
  deleteFilterCalendarWhere: user_project_filter_calendar_bool_exp;
  id: string;
  includeDeleteFilterCalendar: boolean;
  insertDeliveryCalendar: ReadonlyArray<delivery_calendar_insert_input>;
  set: delivery_set_input;
};
export type SitedeliveryDetailsDrawerUpdateDeliveryMutation$data = {
  readonly delete_delivery_calendar: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
  readonly delete_user_project_filter_calendar?: {
    readonly returning: ReadonlyArray<{
      readonly calendar_id: string;
      readonly id: string;
    }>;
  } | null | undefined;
  readonly insert_delivery_calendar: {
    readonly returning: ReadonlyArray<{
      readonly calendar: {
        readonly color_hex: string;
        readonly id: string;
        readonly name: {
          readonly en: string;
        };
        readonly pk: string;
      };
    }>;
  } | null | undefined;
  readonly update_delivery_by_pk: {
    readonly approved_at: string | null | undefined;
    readonly approved_by: {
      readonly id: string;
      readonly name: string;
      readonly pk: string;
    } | null | undefined;
    readonly calendars: ReadonlyArray<{
      readonly calendar: {
        readonly color_hex: string;
        readonly id: string;
        readonly name: {
          readonly en: string;
        };
        readonly pk: string;
      };
    }>;
    readonly created_at: string;
    readonly detail: {
      readonly en: string;
      readonly id: string;
      readonly pk: string;
    } | null | undefined;
    readonly duration: number;
    readonly id: string;
    readonly name: {
      readonly en: string;
      readonly id: string;
      readonly pk: string;
    };
    readonly pk: string;
    readonly remark: {
      readonly en: string;
      readonly id: string;
      readonly pk: string;
    } | null | undefined;
    readonly start_at: string;
    readonly status: string | null | undefined;
    readonly storage_location: {
      readonly en: string;
      readonly id: string;
      readonly pk: string;
    } | null | undefined;
    readonly subcontractor: {
      readonly id: string;
      readonly name: string;
    } | null | undefined;
    readonly user: {
      readonly id: string;
      readonly name: string;
      readonly pk: string;
    };
  } | null | undefined;
};
export type SitedeliveryDetailsDrawerUpdateDeliveryMutation = {
  response: SitedeliveryDetailsDrawerUpdateDeliveryMutation$data;
  variables: SitedeliveryDetailsDrawerUpdateDeliveryMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleteCalendarId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleteFilterCalendarWhere"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeDeleteFilterCalendar"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "insertDeliveryCalendar"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "set"
},
v6 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "calendar_id": "asc"
    }
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color_hex",
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "calendar",
    "kind": "LinkedField",
    "name": "calendar",
    "plural": false,
    "selections": [
      (v7/*: any*/),
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "text_translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": false,
        "selections": [
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      (v11/*: any*/)
    ],
    "storageKey": null
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v14/*: any*/)
  ],
  "storageKey": null
},
v16 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v10/*: any*/)
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start_at",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v19 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v14/*: any*/)
],
v20 = [
  (v7/*: any*/),
  (v10/*: any*/),
  (v8/*: any*/)
],
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approved_at",
  "storageKey": null
},
v23 = {
  "condition": "includeDeleteFilterCalendar",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "deleteFilterCalendarWhere"
        }
      ],
      "concreteType": "user_project_filter_calendar_mutation_response",
      "kind": "LinkedField",
      "name": "delete_user_project_filter_calendar",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "user_project_filter_calendar",
          "kind": "LinkedField",
          "name": "returning",
          "plural": true,
          "selections": [
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "calendar_id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v24 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "insertDeliveryCalendar"
  }
],
v25 = {
  "alias": null,
  "args": [
    {
      "fields": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_in",
              "variableName": "deleteCalendarId"
            }
          ],
          "kind": "ObjectValue",
          "name": "calendar_id"
        },
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_eq",
              "variableName": "id"
            }
          ],
          "kind": "ObjectValue",
          "name": "delivery_id"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "delivery_calendar_mutation_response",
  "kind": "LinkedField",
  "name": "delete_delivery_calendar",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "delivery_calendar",
      "kind": "LinkedField",
      "name": "returning",
      "plural": true,
      "selections": [
        (v7/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v26 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v27 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v28 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "calendar",
    "kind": "LinkedField",
    "name": "calendar",
    "plural": false,
    "selections": [
      (v7/*: any*/),
      (v26/*: any*/),
      (v27/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "text_translation",
        "kind": "LinkedField",
        "name": "name",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      (v11/*: any*/)
    ],
    "storageKey": null
  },
  (v7/*: any*/)
],
v29 = [
  (v7/*: any*/),
  (v26/*: any*/),
  (v27/*: any*/),
  (v10/*: any*/)
],
v30 = [
  (v7/*: any*/),
  (v26/*: any*/),
  (v27/*: any*/),
  (v14/*: any*/)
],
v31 = [
  (v7/*: any*/),
  (v10/*: any*/),
  (v26/*: any*/),
  (v27/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SitedeliveryDetailsDrawerUpdateDeliveryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "delivery",
        "kind": "LinkedField",
        "name": "update_delivery_by_pk",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "delivery_calendar",
            "kind": "LinkedField",
            "name": "calendars",
            "plural": true,
            "selections": (v12/*: any*/),
            "storageKey": "calendars(order_by:{\"calendar_id\":\"asc\"})"
          },
          (v13/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "detail",
            "plural": false,
            "selections": (v16/*: any*/),
            "storageKey": null
          },
          (v17/*: any*/),
          (v18/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v19/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "name",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          (v21/*: any*/),
          (v22/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "approved_by",
            "plural": false,
            "selections": (v19/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "remark",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "storage_location",
            "plural": false,
            "selections": (v16/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v23/*: any*/),
      {
        "alias": null,
        "args": (v24/*: any*/),
        "concreteType": "delivery_calendar_mutation_response",
        "kind": "LinkedField",
        "name": "insert_delivery_calendar",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "delivery_calendar",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": (v12/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v25/*: any*/)
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SitedeliveryDetailsDrawerUpdateDeliveryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "delivery",
        "kind": "LinkedField",
        "name": "update_delivery_by_pk",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "delivery_calendar",
            "kind": "LinkedField",
            "name": "calendars",
            "plural": true,
            "selections": (v28/*: any*/),
            "storageKey": "calendars(order_by:{\"calendar_id\":\"asc\"})"
          },
          (v13/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "detail",
            "plural": false,
            "selections": (v29/*: any*/),
            "storageKey": null
          },
          (v17/*: any*/),
          (v18/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v30/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "name",
            "plural": false,
            "selections": (v31/*: any*/),
            "storageKey": null
          },
          (v21/*: any*/),
          (v22/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "approved_by",
            "plural": false,
            "selections": (v30/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "remark",
            "plural": false,
            "selections": (v31/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "storage_location",
            "plural": false,
            "selections": (v29/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v23/*: any*/),
      {
        "alias": null,
        "args": (v24/*: any*/),
        "concreteType": "delivery_calendar_mutation_response",
        "kind": "LinkedField",
        "name": "insert_delivery_calendar",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "delivery_calendar",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": (v28/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v25/*: any*/)
    ]
  },
  "params": {
    "cacheID": "242cb30f30f99aa9c2357f2ab2329a73",
    "id": null,
    "metadata": {},
    "name": "SitedeliveryDetailsDrawerUpdateDeliveryMutation",
    "operationKind": "mutation",
    "text": "mutation SitedeliveryDetailsDrawerUpdateDeliveryMutation(\n  $id: uuid!\n  $set: delivery_set_input!\n  $deleteFilterCalendarWhere: user_project_filter_calendar_bool_exp!\n  $includeDeleteFilterCalendar: Boolean!\n  $insertDeliveryCalendar: [delivery_calendar_insert_input!]!\n  $deleteCalendarId: [uuid!]!\n) {\n  update_delivery_by_pk(pk_columns: {id: $id}, _set: $set) {\n    id\n    pk: id\n    calendars(order_by: {calendar_id: asc}) {\n      calendar {\n        id\n        pk: id\n        name {\n          en\n          id\n        }\n        color_hex\n      }\n      id\n    }\n    status\n    subcontractor {\n      id\n      name\n    }\n    detail {\n      id\n      pk: id\n      en\n    }\n    start_at\n    duration\n    user {\n      id\n      pk: id\n      name\n    }\n    name {\n      id\n      en\n      pk: id\n    }\n    created_at\n    approved_at\n    approved_by {\n      id\n      pk: id\n      name\n    }\n    remark {\n      id\n      en\n      pk: id\n    }\n    storage_location {\n      id\n      pk: id\n      en\n    }\n  }\n  delete_user_project_filter_calendar(where: $deleteFilterCalendarWhere) @include(if: $includeDeleteFilterCalendar) {\n    returning {\n      id\n      calendar_id\n    }\n  }\n  insert_delivery_calendar(objects: $insertDeliveryCalendar) {\n    returning {\n      calendar {\n        id\n        pk: id\n        name {\n          en\n          id\n        }\n        color_hex\n      }\n      id\n    }\n  }\n  delete_delivery_calendar(where: {calendar_id: {_in: $deleteCalendarId}, delivery_id: {_eq: $id}}) {\n    returning {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "237e1a19a4d37caab6fd2351b4975e8c";

export default node;
