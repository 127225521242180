import apolloClient from "src/common/api/apollo/apolloClient";
import { auth } from "src/common/functions/firebase";
import stripLocalTextTranslation from "src/common/functions/stripLocalTextTranslation";
import {
  GetLadderPermitByPkDocument,
  GetLadderPermitByPkQuery,
  GetLadderPermitByPkQueryVariables,
  GetPermitChecklistQuestionsDataQuery,
  InsertPermitHistoryDataDocument,
  InsertPermitHistoryDataMutation,
  InsertPermitHistoryDataMutationVariables,
  Permit_History_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import * as uuid from "uuid";

const useInsertPermitHistoryWithPermit = () => {
  const insertPermitHistoryWithPermit = async (
    input: InsertPermitHistoryDataMutationVariables,
    closingChecklist?: GetPermitChecklistQuestionsDataQuery["closing_checklist_items"],
  ) => {
    if (!auth.currentUser) {
      throw new Error(
        "Authenticated user not found please go back and sign in",
      );
    }
    const historicalPermitId = uuid.v4();
    const { data } = await apolloClient.query<
      GetLadderPermitByPkQuery,
      GetLadderPermitByPkQueryVariables
    >({
      query: GetLadderPermitByPkDocument,
      variables: { permitId: input.permitId, permitWorkersFilter: {} },
      fetchPolicy: "network-only",
    });
    const permit = data.permit;
    if (!permit) throw new Error("Permit not found");
    if (permit.permit_type !== "ladder")
      throw new Error(
        "Current Inserting History in webapp is only supported for ladder permits",
      );
    const workersToSubmit: Array<any> = [];

    const workerTypes = [
      "supervisorProjectWorkers" as const,
      "workingProjectWorkers" as const,
      "firewatchProjectWorkers" as const,
      "entrantProjectWorkers" as const,
      "attendantProjectWorkers" as const,
      "operatorsPerformingWork" as const,
    ];

    for (const workerType of workerTypes) {
      if (workerType in permit) {
        workersToSubmit.push(...(permit as any)[workerType]);
      }
    }
    const passedHistoryObj = Array.isArray(input.historyObjects)
      ? input.historyObjects[0]
      : input.historyObjects;
    const newStatus = passedHistoryObj.status || input.newStatus;
    const historyObject: Permit_History_Insert_Input = {
      ...passedHistoryObj,
      permit_id: input.permitId,
      status: newStatus, // prioritizing passed on status in history obj
      created_by_user_id: auth.currentUser.uid,
      project_id: permit.project.id,
      subcontractor_id: permit.subcontractor.id,
      historical_permit: {
        data: {
          id: historicalPermitId,
          type: permit.permit_type,
          current_status: newStatus,
          is_historical: true,
          work_location: {
            data: stripLocalTextTranslation(permit.work_location),
          },
          work_start_at: permit.work_start_at,
          work_description: {
            data: stripLocalTextTranslation(permit.work_description),
          },
          applicant_project_worker_id: permit?.applicant_project_worker?.id,
          project_id: permit.project.id,
          subcontractor_id: permit.subcontractor.id,
          images: {
            data: permit.images.map((img, i) => ({
              url: img.url,
              sort_index: i * 10000,
            })),
          },
          permit_sign_in_images: {
            data: permit.permit_sign_in_images.map(({ image }, i) => ({
              image: {
                data: {
                  url: image.url,
                  sort_index: i * 10000,
                },
              },
            })),
          },
          permit_project_workers: {
            //(permit.permit_type === "loto" ? [] : workersToSubmit) TODO: Add this back when support for other permits is added
            data: workersToSubmit.map((p) => ({
              type: p.type,
              project_worker_id: p.project_worker.id,
              ...(p.signature_image
                ? {
                    signature_image: {
                      data: {
                        url: p.signature_image.url,
                      },
                    },
                  }
                : {}),
            })),
          },
          permit_checklist_items: {
            data: [
              ...[
                ...permit.permit_checklist_items,
                ...(closingChecklist ?? []),
              ].map(
                ({
                  __typename,
                  permit_id,
                  general_contractor_id,
                  id,
                  ...item
                }) => ({
                  ...item,
                  description: {
                    data: {
                      ...stripLocalTextTranslation(item.description),
                      id: uuid.v4(),
                    },
                  },
                  ...(item.text_response
                    ? {
                        text_response: {
                          data: {
                            ...stripLocalTextTranslation(item.text_response),
                            id: uuid.v4(),
                          },
                        },
                      }
                    : {
                        text_response: null,
                      }),
                }),
              ),
            ],
          },
        },
      },
    };

    if ("ladder_permit" in permit && permit.ladder_permit) {
      const ladderPermit = permit.ladder_permit!;
      historyObject.historical_permit!.data.ladder_permit = {
        data: {
          //permit_id: historicalPermitId,

          is_access_ladder: ladderPermit.is_access_ladder,
          duration: ladderPermit.duration,
          necessity_description: {
            data: stripLocalTextTranslation(ladderPermit.necessity_description),
          },
          type_of_ladder: ladderPermit.type_of_ladder,
          ladder_capcity: ladderPermit.ladder_capcity,
          ladder_height: ladderPermit.ladder_height,
          ladder_identification: ladderPermit.ladder_identification,
          is_working_over_x_feet: ladderPermit.is_working_over_x_feet,
          permissible_ladder_height: ladderPermit.permissible_ladder_height,
          fall_protection_options: {
            data: ladderPermit.fall_protection_options.map((e) => ({
              permit_option_id: e.permit_option.id,
            })),
          },
          returned_at: ladderPermit.returned_at,
          returned_marked_by_uid: ladderPermit.returned_marked_by_uid,
          has_special_precautions: ladderPermit.has_special_precautions,
          special_precautions_description: {
            data: stripLocalTextTranslation(
              ladderPermit.special_precautions_description,
            ),
          },
        },
      };
    }

    const updates: Array<Promise<any>> = [];
    // WILL BE REQUIRED WHEN WE ADD FACILITY TO ADD A PERMIT IN WEBAPP
    // if (closingChecklist) {
    //   updates.push(
    //     client.mutate<
    //       DeleteAndInsertPermitChecklistItemMutation,
    //       DeleteAndInsertPermitChecklistItemMutationVariables
    //     >({
    //       mutation: DeleteAndInsertPermitChecklistItemDocument,
    //       variables: {
    //         where: {
    //           item_type: {
    //             _eq: "closing",
    //           },
    //           permit_id: {
    //             _eq: permit.id,
    //           },
    //         },
    //         objects: closingChecklist.map(
    //           ({
    //             __typename,
    //             permit_id,
    //             general_contractor_id,
    //             id,
    //             ...item
    //           }) => ({
    //             permit_id: permit.id,
    //             ...item,
    //             description: {
    //               data: {
    //                 ...stripLocalTextTranslation(item.description),
    //                 id: uuid.v4(),
    //               },
    //             },
    //             ...(item.text_response
    //               ? {
    //                   text_response: {
    //                     data: {
    //                       ...stripLocalTextTranslation(item.text_response),
    //                       id: uuid.v4(),
    //                     },
    //                   },
    //                 }
    //               : {
    //                   text_response: null,
    //                 }),
    //           }),
    //         ),
    //       },
    //     }),
    //   );
    // }

    updates.push(
      apolloClient.mutate<
        InsertPermitHistoryDataMutation,
        InsertPermitHistoryDataMutationVariables
      >({
        mutation: InsertPermitHistoryDataDocument,
        variables: {
          ...input,
          historyObjects: [historyObject],
        },
      }),
    );

    await Promise.all(updates);
  };
  return insertPermitHistoryWithPermit;
};

export default useInsertPermitHistoryWithPermit;
