/**
 * @generated SignedSource<<0a9332087e2145b7f9ecc83305dca1d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { email_project_user_bool_exp, email_project_user_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EmailProjectUserSettingsMutation$variables = {
  deleteWhere: email_project_user_bool_exp;
  objects: ReadonlyArray<email_project_user_insert_input>;
};
export type EmailProjectUserSettingsMutation$data = {
  readonly delete_email_project_user: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
  readonly insert_email_project_user: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly pk: string;
      readonly project_id: string;
      readonly type: string | null | undefined;
      readonly user: {
        readonly email: string | null | undefined;
        readonly id: string;
        readonly name: string;
        readonly pk: string;
      };
    }>;
  } | null | undefined;
};
export type EmailProjectUserSettingsMutation = {
  response: EmailProjectUserSettingsMutation$data;
  variables: EmailProjectUserSettingsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleteWhere"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objects"
},
v2 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "deleteWhere"
    }
  ],
  "concreteType": "email_project_user_mutation_response",
  "kind": "LinkedField",
  "name": "delete_email_project_user",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "email_project_user",
      "kind": "LinkedField",
      "name": "returning",
      "plural": true,
      "selections": [
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailProjectUserSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "email_project_user_mutation_response",
        "kind": "LinkedField",
        "name": "insert_email_project_user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "email_project_user",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v9/*: any*/)
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EmailProjectUserSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "email_project_user_mutation_response",
        "kind": "LinkedField",
        "name": "insert_email_project_user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "email_project_user",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v9/*: any*/)
    ]
  },
  "params": {
    "cacheID": "0c333ba42c896208d9a7fcd5dd4c4374",
    "id": null,
    "metadata": {},
    "name": "EmailProjectUserSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation EmailProjectUserSettingsMutation(\n  $objects: [email_project_user_insert_input!]!\n  $deleteWhere: email_project_user_bool_exp!\n) {\n  insert_email_project_user(objects: $objects) {\n    returning {\n      id\n      pk: id\n      project_id\n      user {\n        id\n        pk: id\n        name\n        email\n      }\n      type\n    }\n  }\n  delete_email_project_user(where: $deleteWhere) {\n    returning {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "725dec68e42a32b9fc86269099316d18";

export default node;
