import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  useGetOacDataLazyQuery,
  useGetOacDataQuery,
  useWorkerFormDataQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import SiteOrientationWorkerRegistrationStepsEmail from "../../inPerson/routes/worker/components/steps/email/SiteOrientationWorkerRegistrationStepsEmail";
import SiteOrientationOrienteeContext from "../../../utils/siteOrientationOrienteeContext";
import {
  OacUserType,
  SiteOrientationInPersonRegistrationOptionsType,
  UserType,
  WorkerType,
} from "../../../utils/siteOrientationTypes";
import SiteFeatureLayoutBody from "../../../../../common/components/layouts/steps-navigation-layout/SiteFeatureLayoutBody";
import SiteOrientationInPersonWorkerStepsBirthDate from "../../inPerson/routes/worker/components/steps/birthDate/SiteOrientationInPersonWorkerStepsBirthDate";
import SiteOrientationInPersonWorkerStepsFullName from "../../inPerson/routes/worker/components/steps/fullName/SiteOrientationInPersonWorkerStepsFullName";
import SiteOrientationInPersonWorkerStepsUnion from "../../inPerson/routes/worker/components/steps/union/SiteOrientationInPersonWorkerStepsUnion";
import SiteOrientationInPersonWorkerStepsTrade from "../../inPerson/routes/worker/components/steps/trade/SiteOrientationInPersonWorkerStepsTrade";
import SiteOrientationInPersonWorkerStepsRole from "../../inPerson/routes/worker/components/steps/role/SiteOrientationInPersonWorkerStepsRole";
import SiteOrientationInPersonWorkerStepsEmployer from "../../inPerson/routes/worker/components/steps/employer/SiteOrientationInPersonWorkerStepsEmployer";
import SiteOrientationInPersonWorkerStepsPhoneNumber from "../../inPerson/routes/worker/components/steps/phoneNumber/SiteOrientationInPersonWorkerStepsPhoneNumber";
import { auth } from "src/common/functions/firebase";
import { fetchOrientationData } from "src/domain-features/siteorientation/utils/useGetOrientationData";
import dayjs from "dayjs";
import ErrorMessage from "src/utility-features/error-handling/components/ErrorMessage";
import SiteOrientationInPersonWorkerRegistrationStepsEmergencyContact from "../../inPerson/routes/worker/components/steps/emergencyContact/SiteOrientationInPersonWorkerRegistrationStepsEmergencyContact";
import SiteOrientationInPersonWorkerStepsEthnicity from "../../inPerson/routes/worker/components/steps/ethnicity/SiteOrientationInPersonWorkerStepsEthnicity";
import SiteOrientationInPersonWorkerStepsGender from "../../inPerson/routes/worker/components/steps/gender/SiteOrientationInPersonWorkerStepsGender";
import SiteOrientationInPersonWorkerStepsHardHatNumber from "../../inPerson/routes/worker/components/steps/hard-hat-number/SiteOrientationInPersonWorkerStepsHardHatNumber";
import SiteOrientationInPersonWorkerStepsResidence from "../../inPerson/routes/worker/components/steps/residence/SiteOrientationInPersonWorkerStepsResidence";
import SiteOrientationInPersonWorkerStepsVeteranType from "../../inPerson/routes/worker/components/steps/veteranType/SiteOrientationInPersonWorkerStepsVeteranType";
import SiteOrientationInPersonWorkerStepsWorkYears from "../../inPerson/routes/worker/components/steps/workYears/SiteOrientationInPersonWorkerStepsWorkYears";
import SiteOrientationInPersonWorkerStepsEmployerYears from "../../inPerson/routes/worker/components/steps/yearsWithEmployer/SiteOrientationInPersonWorkerStepsEmployerYears";
import SiteOrientationInPersonWorkerStepsProfilePhoto from "../../inPerson/routes/worker/components/steps/profilePhoto/SiteOrientationInPersonWorkerStepsProfilePhoto";
import SiteOrientationInPersonWorkerStepsDrugTests from "../../inPerson/routes/worker/components/steps/drugTests/SiteOrientationInPersonWorkerStepsDrugTests";
import { useGetOrientationDataQuery$data } from "src/common/types/generated/relay/useGetOrientationDataQuery.graphql";
import setUserForOrientation from "src/domain-features/siteorientation/utils/setUserForOrientation";
import registrationOptionsFromProjectData from "../../../utils/registrationOptionsFromProjectData";
import OnlineOrientationCompletion from "src/domain-features/siteorientation/components/OnlineOrientationCompletion";
import SiteOrientationInPersonWorkerStepsAge from "../../inPerson/routes/worker/components/steps/age/SiteOrientationInPersonWorkerStepsAge";
import SiteOrientationInPersonWorkerStepsUserType from "../../inPerson/routes/worker/components/steps/userType/SiteOrientationInPersonWorkerStepsUserType";
import SiteOrientationInPersonWorkerStepsCertifications from "../../inPerson/routes/worker/components/steps/certifications/SiteOrientationInPersonWorkerStepsCertifications";
import SiteOrientationInPersonWorkerStepsOrientationViewer from "../../inPerson/routes/worker/components/steps/oreintationViewer/SiteOrientationInPersonWorkerStepsOrientationViewer";
import { OrientationType } from "src/common/types/manual/OrientationType";
import userLeavesOrStaysNotification from "src/domain-features/siteorientation/utils/userLeavesOrStaysNotification";
import SiteOrientationInPersonWorkerRegistrationStepsSignature from "../../inPerson/routes/worker/components/steps/signature/SiteOrientationInPersonWorkerRegistrationStepsSignature";
import SiteOrientationInPersonWorkerRegistrationStepsCompleted from "../../inPerson/routes/worker/components/steps/completed/SiteOrientationInPersonWorkerRegistrationStepsCompleted";
import {
  useSiteFeatureStepsNavigationDirection,
  useSiteFeatureStepsNavigationEffect,
} from "../../../../../common/components/layouts/steps-navigation-layout/siteFeatureRegistrationNavigationHooks";
import withSiteFeatureNavigationWrapper from "../../../../../common/components/layouts/steps-navigation-layout/withSiteFeatureNavigationWrapper";
import { NULL_ID } from "src/common/functions/nullId";
import { useRelayEnvironment } from "react-relay/hooks";
import { withLanguageProvider } from "src/utility-features/i18n/context/languageContext";
import SiteOrientationInPersonWorkerRegistrationStepsHello from "../../inPerson/routes/worker/components/steps/hello/SiteOrientationInPersonWorkerRegistrationStepsHello";
import { AVAILABLE_LANGUAGES } from "../../../../../utility-features/i18n/languages";
import { useCurrentLanguageState } from "../../../../../utility-features/i18n/context/languageHooks";
import identifyUserForTracking from "../../../../../utility-features/event-tracking/identifyUserForTracking";
import { inMemoryPersistence } from "firebase/auth";

const SiteOrientationRemoteView: FunctionComponent = () => {
  const [userIdSelected, setUserIdSelect] = useState<string | null>(null);
  const [data, setData] = useState<useGetOrientationDataQuery$data | null>(
    null,
  );
  const [language, setLanguage] = useCurrentLanguageState();
  const [searchParams] = useSearchParams();
  const email64 = searchParams.get("worker");
  const email = email64 ? window.atob(email64) : undefined;
  const [user, setUser] = useState<WorkerType | OacUserType | UserType | null>({
    email,
  });
  const projectId = useParams().projectId as string;
  const type = useParams().type as OrientationType;
  const inPersonType = type === "inperson" || type === "inpersonComplete";
  const { data: formData, loading: formDataLoading } = useWorkerFormDataQuery({
    variables: { input: { projectId } },
  });
  const {
    data: oacData,
    loading: oacDataLoading,
    refetch: refetchOacData,
  } = useGetOacDataQuery({
    variables: { input: { projectId, userId: user?.id } },
  });
  const workerFormData = formData?.workerFormQuery;
  const environment = useRelayEnvironment();
  const loading = formDataLoading || oacDataLoading;
  // console.log(user);
  const [registrationOptions, setRegistrationOptions] =
    useState<SiteOrientationInPersonRegistrationOptionsType>({
      hardHatNumber: undefined,
      emergencyContactRequired: false,
      ethnicityRequired: false,
      genderRequired: false,
      hasInPerson: false,
      hasUniversal: false,
      hasSlides: false,
      profilePhotoRequired: false,
      residenceRequired: false,
      veteranTypeRequired: false,
      yearsInConstructionRequired: false,
      yearsWithEmployerRequired: false,
      mainGCId: "",
      certificationsRequired: workerFormData?.requireUploadCertificate ?? false,
      drugTest: {
        completionRequired: false,
        validityDays: 0,
        performingDrugTest: workerFormData?.performDrugtest ?? false,
        uploadRequired: workerFormData?.requireDrugtestUpload ?? false,
      },
      ageRequired: false,
      dobRequired: workerFormData?.requireDob ?? false,
      phoneRequired: workerFormData?.requirePhone ?? false,
      unionLabor: workerFormData?.requireUnion ?? false,
    });

  useEffect(() => {
    if (workerFormData)
      setRegistrationOptions((prev) => ({
        ...prev,
        certificationsRequired: workerFormData.requireUploadCertificate,
        drugTest: {
          ...prev.drugTest,
          performingDrugTest: workerFormData.performDrugtest,
          uploadRequired: workerFormData.requireDrugtestUpload,
        },
        dobRequired: workerFormData.requireDob,
        phoneRequired: workerFormData.requirePhone,
        unionLabor: workerFormData.requireUnion,
      }));
  }, [formData]);

  useEffect(() => {
    if (auth.currentUser)
      auth.currentUser?.getIdTokenResult().then((d) => {
        if (d.claims?.role !== "employee")
          auth.setPersistence(inMemoryPersistence);
      });
    else auth.setPersistence(inMemoryPersistence);
  }, []);

  const { goToNextStep, goToPrevStep, nextStepDirection, clearStepDirection } =
    useSiteFeatureStepsNavigationDirection();

  const resetUser = () => {
    setUser(null);
    setUserIdSelect(NULL_ID);
  };

  const steps = [
    {
      id: "welcome",
      title: "Welcome",
      body: (
        <SiteOrientationInPersonWorkerRegistrationStepsHello
          onNext={goToNextStep}
          gcLogoUrl={workerFormData?.logoToShow}
          showLangSelector
          projectName={workerFormData?.projectName}
          gcName={workerFormData?.gcName}
        />
      ),
    },
    {
      id: "email",
      title: "Email",
      body: (
        <SiteOrientationWorkerRegistrationStepsEmail
          onNext={goToNextStep}
          onBack={goToPrevStep}
          onLoginSuccess={async (userId) => {
            if (auth.currentUser?.uid) {
              const returnData = await fetchOrientationData(
                environment,
                userId,
                projectId,
              );
              if (returnData.user_connection.edges[0].node.employee) {
                await refetchOacData({ input: { projectId, userId: userId } });
              }
              setData(returnData);
              setUser(
                setUserForOrientation(
                  returnData.user_connection.edges[0].node,
                  userId,
                  projectId,
                  returnData,
                  oacData?.getOACDataQuery,
                ),
              );
              setRegistrationOptions(
                registrationOptionsFromProjectData(returnData),
              );
            }
            // navigate(
            //   `/testingRemoteNew/${projectId}/new/${workerId}/employer/${language.languageCode}`
            // );
          }}
          onNoEmailPress={() => {
            goToNextStep();
            resetUser();
          }}
        />
      ),
    },
    {
      title: "Full name",
      id: "full_name",
      body: (
        <SiteOrientationInPersonWorkerStepsFullName
          onNext={goToNextStep}
          onBack={goToPrevStep}
        />
      ),
      disabled: !!user?.name?.old,
    },
    {
      title: "Birth date",
      id: "birth_date",
      body: (
        <SiteOrientationInPersonWorkerStepsBirthDate
          onNext={goToNextStep}
          onBack={goToPrevStep}
          onLoginSuccess={async (userId) => {
            if (auth.currentUser?.uid) {
              const returnData = await fetchOrientationData(
                environment,
                userId,
                projectId,
              );
              if (returnData.user_connection.edges[0]?.node.employee) {
                await refetchOacData({ input: { projectId, userId: userId } });
              }
              setData(returnData);
              setUser(
                setUserForOrientation(
                  returnData.user_connection.edges[0].node,
                  userId,
                  projectId,
                  returnData,
                  oacData?.getOACDataQuery,
                ),
              );
              setRegistrationOptions(
                registrationOptionsFromProjectData(returnData),
              );
            }
          }}
        />
      ),
      disabled:
        (!workerFormData?.requireDob || !!user?.birthDate?.old) &&
        !!user?.email,
    },
    {
      title: "User Type",
      id: "user_type",
      body: (
        <SiteOrientationInPersonWorkerStepsUserType
          onNext={goToNextStep}
          onBack={goToPrevStep}
        />
      ),
      disabled: !!user?.id,
    },
    {
      title: "Employer",
      id: "employer",
      body: (
        <SiteOrientationInPersonWorkerStepsEmployer
          onNext={goToNextStep}
          onBack={goToPrevStep}
          workerOptions={workerFormData?.projectSubs || []}
          oacCompanyOptions={oacData?.getOACDataQuery?.oacCompanies ?? []}
          gcOptions={oacData?.getOACDataQuery?.generalContractors ?? []}
        />
      ),
    },
    {
      title: "Role (level)",
      id: "role",
      body: (
        <SiteOrientationInPersonWorkerStepsRole
          onNext={goToNextStep}
          onBack={goToPrevStep}
          options={workerFormData?.roles || []}
        />
      ),
      disabled: user?.userType === "oacUser",
    },
    {
      title: "Trade (title)",
      id: "trade",
      body: (
        <SiteOrientationInPersonWorkerStepsTrade
          onNext={goToNextStep}
          onBack={goToPrevStep}
          oacTrades={oacData?.getOACDataQuery?.oacTitles || []}
          employeeTrades={oacData?.getOACDataQuery?.employeeTitles || []}
          suggestedTrades={(workerFormData?.titles || []).filter(
            (p) =>
              user &&
              user.company?.companyWorkerTitles.find((x) => x.id === p.id),
          )}
          otherTrades={(workerFormData?.titles || []).filter(
            (p) =>
              !(
                user &&
                (user.company?.companyWorkerTitles || []).find(
                  (x) => x.id === p.id,
                )
              ),
          )}
        />
      ),
    },
    {
      title: "Union",
      id: "union",
      body: (
        <SiteOrientationInPersonWorkerStepsUnion
          onNext={goToNextStep}
          onBack={goToPrevStep}
          unions={workerFormData?.laborUnions || []}
        />
      ),
      disabled:
        user?.userType !== "worker" ||
        !workerFormData?.requireUnion ||
        !!user.unionChapterId,
    },
    {
      title: "Phone number",
      id: "phone_number",
      body: (
        <SiteOrientationInPersonWorkerStepsPhoneNumber
          onNext={goToNextStep}
          onBack={goToPrevStep}
          orientations={data?.orientation_connection?.edges}
          onLoginSuccess={async (userId) => {
            if (auth.currentUser?.uid) {
              const returnData = await fetchOrientationData(
                environment,
                userId,
                projectId,
              );
              if (returnData.user_connection.edges[0]?.node.employee) {
                await refetchOacData({ input: { projectId, userId: userId } });
              }
              setData(returnData);
              setUser(
                setUserForOrientation(
                  returnData.user_connection.edges[0].node,
                  userId,
                  projectId,
                  returnData,
                  oacData?.getOACDataQuery,
                ),
              );
              setRegistrationOptions(
                registrationOptionsFromProjectData(returnData),
              );
            }
            // navigate(
            //   `/testingRemoteNew/${projectId}/new/${workerId}/employer/${language}`
            // );
          }}
        />
      ),
    },
    ...(user?.id
      ? [
          ...(user?.userType === "worker"
            ? [
                {
                  title: "Residence",
                  id: "residence",
                  body: (
                    <SiteOrientationInPersonWorkerStepsResidence
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  disabled: !registrationOptions.residenceRequired,
                },
                {
                  title: `Gender`,
                  id: `gender`,
                  body: (
                    <SiteOrientationInPersonWorkerStepsGender
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                      options={[
                        { name: "Male", value: "M" },
                        { name: "Female", value: "F" },
                        { name: "Other", value: "O" },
                      ]}
                    />
                  ),
                  disabled:
                    !registrationOptions.genderRequired || !!user.gender?.old,
                },
                {
                  title: `Ethnicity`,
                  body: (
                    <SiteOrientationInPersonWorkerStepsEthnicity
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  id: `ethnicity`,
                  disabled:
                    !registrationOptions.ethnicityRequired ||
                    !!user.ethnicity?.old,
                },
                {
                  title: `Veteran type`,
                  body: (
                    <SiteOrientationInPersonWorkerStepsVeteranType
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  id: `veteran_type`,
                  disabled:
                    !registrationOptions.veteranTypeRequired ||
                    !!user?.veteranType?.old,
                },
                {
                  title: `Age`,
                  body: (
                    <SiteOrientationInPersonWorkerStepsAge
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  id: `age`,
                  disabled:
                    !registrationOptions.ageRequired ||
                    !!user.birthDate?.old ||
                    !!user?.age?.old?.val,
                },
                {
                  title: "Emergency contact",
                  body: (
                    <SiteOrientationInPersonWorkerRegistrationStepsEmergencyContact
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  id: `emergency_contact`,
                  disabled: !registrationOptions.emergencyContactRequired,
                },
                {
                  title: "Years in construction",
                  body: (
                    <SiteOrientationInPersonWorkerStepsWorkYears
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  id: `work_years`,
                  disabled:
                    !registrationOptions.yearsInConstructionRequired ||
                    (user.workYears?.old?.enteredAt
                      ? user.workYears.old.enteredAt
                          .add(3, "years")
                          .isAfter(dayjs())
                      : false),
                },
                {
                  title: "Years with employer",
                  body: (
                    <SiteOrientationInPersonWorkerStepsEmployerYears
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  id: `employer_years`,
                  disabled: !registrationOptions.yearsWithEmployerRequired,
                },
              ]
            : []),
          {
            title: "Your photo",
            id: "photo",
            body: (
              <SiteOrientationInPersonWorkerStepsProfilePhoto
                onNext={goToNextStep}
                onBack={goToPrevStep}
              />
            ),
            disabled:
              user?.userType === "oacUser" &&
              !registrationOptions.profilePhotoRequired,
          },
          ...(user?.userType === "worker"
            ? [
                {
                  title: "Certifications",
                  id: "certifications",
                  body: (
                    <SiteOrientationInPersonWorkerStepsCertifications
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  disabled: !registrationOptions.certificationsRequired,
                },
                {
                  title: "Drug test",
                  id: "drug_test",
                  body: (
                    <SiteOrientationInPersonWorkerStepsDrugTests
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  disabled:
                    !(
                      registrationOptions.drugTest.performingDrugTest &&
                      registrationOptions.drugTest.uploadRequired
                    ) ||
                    // if user has DT result present on  all linked projects
                    (Object.keys(user.projectsToRegister ?? {}).length > 0 &&
                      Object.keys(user.projectsToRegister ?? {}).every(
                        (p) =>
                          user.drugTests && user.drugTests?.dtPassedProjects[p],
                      )),
                },
              ]
            : []),
          ...(registrationOptions.hasSlides || registrationOptions.hasUniversal
            ? [
                {
                  title: "Orientation",
                  body: (
                    <SiteOrientationInPersonWorkerStepsOrientationViewer
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  id: "orientation",
                },
              ]
            : []),
          ...(!user.viewerShown && inPersonType
            ? [
                {
                  title: `Signature`,
                  body: (
                    <SiteOrientationInPersonWorkerRegistrationStepsSignature
                      onNext={goToNextStep}
                      onBack={goToPrevStep}
                    />
                  ),
                  id: `signature`,
                },
              ]
            : []),
          {
            title: "Hard hat number",
            body:
              user?.id && projectId ? (
                <SiteOrientationInPersonWorkerStepsHardHatNumber
                  onNext={() => {
                    const userName = user.name?.new || user.name?.old;
                    if (userName && user?.id && inPersonType) {
                      userLeavesOrStaysNotification(
                        userName,
                        user.id,
                        registrationOptions.hasUniversal,
                        (registrationOptions.hasUniversal
                          ? user?.universalCompleted
                          : user?.corporateOrientationPassed) || false,
                      );
                    }
                    goToNextStep();
                  }}
                  userId={user.id}
                  projectId={projectId}
                  onBack={goToPrevStep}
                />
              ) : (
                <ErrorMessage />
              ),
            id: "hard_hat_number",
            disabled:
              !!user?.hardHatNumber ||
              !registrationOptions.hardHatNumber?.required ||
              (type === "slides"
                ? !registrationOptions.hardHatNumber.addHHDuringRegistration
                : inPersonType
                ? !registrationOptions.hardHatNumber.addHHDuringInPerson
                : true),
          },

          {
            title: "Completed",
            body:
              !user?.viewerShown && inPersonType ? (
                <SiteOrientationInPersonWorkerRegistrationStepsCompleted />
              ) : (
                <OnlineOrientationCompletion
                  userId={user.id}
                  projectId={projectId}
                  lang={language}
                  type={type}
                  hasUniversal={registrationOptions.hasUniversal}
                  canLeaveWhenPrompted={
                    registrationOptions.hasUniversal
                      ? user?.universalCompleted
                      : user?.corporateOrientationPassed
                  }
                />
              ),
            id: "completed",
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (userIdSelected) {
      identifyUserForTracking({
        id: userIdSelected,
        fullName: user?.name?.new || user?.name?.old,
        role: user?.userType,
        email: user?.email ?? undefined,
      });
    }
  }, [userIdSelected, user]);

  const { currentStepId } = useSiteFeatureStepsNavigationEffect(
    nextStepDirection,
    clearStepDirection,
    steps,
  );

  return (
    <SiteOrientationOrienteeContext.Provider
      value={{
        type: type,
        projectId,
        orientee: user ?? undefined,
        updateSelectedUserId: (newUserId) => {
          setUserIdSelect(newUserId);
        },
        updateOrientee: setUser,
        registrationOptions,
      }}
    >
      <SiteFeatureLayoutBody
        steps={steps}
        currentStepId={currentStepId || undefined}
        languagePicker={{
          availableLangs: AVAILABLE_LANGUAGES,
          selectedLang: language,
          onLangeChange: setLanguage,
        }}
        languagePickerHidden={currentStepId === "welcome"}
        loading={loading}
      />
    </SiteOrientationOrienteeContext.Provider>
  );
};

export default withSiteFeatureNavigationWrapper(
  withLanguageProvider(SiteOrientationRemoteView),
);
