/**
 * @generated SignedSource<<129cf3282698a48241ccb08b14b3c515>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { delivery_set_input, text_translation_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useManageDeliveryTextsInsertMutation$variables = {
  id: string;
  object: text_translation_insert_input;
  set: delivery_set_input;
};
export type useManageDeliveryTextsInsertMutation$data = {
  readonly insert_text_translation_one: {
    readonly en: string;
    readonly id: string;
    readonly original: string;
  } | null | undefined;
  readonly update_delivery_by_pk: {
    readonly approved_at: string | null | undefined;
    readonly approved_by: {
      readonly id: string;
      readonly name: string;
      readonly pk: string;
    } | null | undefined;
    readonly calendars: ReadonlyArray<{
      readonly calendar: {
        readonly color_hex: string;
        readonly id: string;
        readonly name: {
          readonly en: string;
        };
        readonly pk: string;
      };
    }>;
    readonly created_at: string;
    readonly detail: {
      readonly en: string;
      readonly id: string;
      readonly pk: string;
    } | null | undefined;
    readonly duration: number;
    readonly id: string;
    readonly name: {
      readonly en: string;
      readonly id: string;
      readonly pk: string;
    };
    readonly pk: string;
    readonly remark: {
      readonly en: string;
      readonly id: string;
      readonly pk: string;
    } | null | undefined;
    readonly start_at: string;
    readonly status: string | null | undefined;
    readonly storage_location: {
      readonly en: string;
      readonly id: string;
      readonly pk: string;
    } | null | undefined;
    readonly subcontractor: {
      readonly id: string;
      readonly name: string;
    } | null | undefined;
    readonly user: {
      readonly id: string;
      readonly name: string;
      readonly pk: string;
    };
  } | null | undefined;
};
export type useManageDeliveryTextsInsertMutation = {
  response: useManageDeliveryTextsInsertMutation$data;
  variables: useManageDeliveryTextsInsertMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "object"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "set"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "object",
      "variableName": "object"
    }
  ],
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "insert_text_translation_one",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "original",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v7 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "calendar_id": "asc"
    }
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color_hex",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v11/*: any*/)
  ],
  "storageKey": null
},
v13 = [
  (v3/*: any*/),
  (v7/*: any*/),
  (v4/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start_at",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v16 = [
  (v3/*: any*/),
  (v7/*: any*/),
  (v11/*: any*/)
],
v17 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v7/*: any*/)
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approved_at",
  "storageKey": null
},
v20 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v21 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v22 = [
  (v3/*: any*/),
  (v20/*: any*/),
  (v21/*: any*/),
  (v4/*: any*/)
],
v23 = [
  (v3/*: any*/),
  (v20/*: any*/),
  (v21/*: any*/),
  (v11/*: any*/)
],
v24 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v20/*: any*/),
  (v21/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useManageDeliveryTextsInsertMutation",
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "delivery",
        "kind": "LinkedField",
        "name": "update_delivery_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "delivery_calendar",
            "kind": "LinkedField",
            "name": "calendars",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "calendar",
                "kind": "LinkedField",
                "name": "calendar",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "calendars(order_by:{\"calendar_id\":\"asc\"})"
          },
          (v10/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "detail",
            "plural": false,
            "selections": (v13/*: any*/),
            "storageKey": null
          },
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v16/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "name",
            "plural": false,
            "selections": (v17/*: any*/),
            "storageKey": null
          },
          (v18/*: any*/),
          (v19/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "approved_by",
            "plural": false,
            "selections": (v16/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "remark",
            "plural": false,
            "selections": (v17/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "storage_location",
            "plural": false,
            "selections": (v13/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "useManageDeliveryTextsInsertMutation",
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "delivery",
        "kind": "LinkedField",
        "name": "update_delivery_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "delivery_calendar",
            "kind": "LinkedField",
            "name": "calendars",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "calendar",
                "kind": "LinkedField",
                "name": "calendar",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": "calendars(order_by:{\"calendar_id\":\"asc\"})"
          },
          (v10/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "detail",
            "plural": false,
            "selections": (v22/*: any*/),
            "storageKey": null
          },
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v23/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "name",
            "plural": false,
            "selections": (v24/*: any*/),
            "storageKey": null
          },
          (v18/*: any*/),
          (v19/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "approved_by",
            "plural": false,
            "selections": (v23/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "remark",
            "plural": false,
            "selections": (v24/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "storage_location",
            "plural": false,
            "selections": (v22/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "253631330044db7dd85e611b27fc8bcd",
    "id": null,
    "metadata": {},
    "name": "useManageDeliveryTextsInsertMutation",
    "operationKind": "mutation",
    "text": "mutation useManageDeliveryTextsInsertMutation(\n  $object: text_translation_insert_input!\n  $id: uuid!\n  $set: delivery_set_input!\n) {\n  insert_text_translation_one(object: $object) {\n    id\n    en\n    original\n  }\n  update_delivery_by_pk(pk_columns: {id: $id}, _set: $set) {\n    id\n    pk: id\n    calendars(order_by: {calendar_id: asc}) {\n      calendar {\n        id\n        pk: id\n        name {\n          en\n          id\n        }\n        color_hex\n      }\n      id\n    }\n    status\n    subcontractor {\n      id\n      name\n    }\n    detail {\n      id\n      pk: id\n      en\n    }\n    start_at\n    duration\n    user {\n      id\n      pk: id\n      name\n    }\n    name {\n      id\n      en\n      pk: id\n    }\n    created_at\n    approved_at\n    approved_by {\n      id\n      pk: id\n      name\n    }\n    remark {\n      id\n      en\n      pk: id\n    }\n    storage_location {\n      id\n      pk: id\n      en\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bf68533d8af71c22197797097439b103";

export default node;
