import React from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { useUpdateGcDivisionEmployeesMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import GCHierarchyTeam from "../../component/GCHierarchyTeam";
import useAuthUser from "src/common/hooks/useAuthUser";
import withDivisionId, { DivisionProps } from "../../utils/withDivisionId";
import { useParams } from "react-router-dom";
import { useUpdateUserHierarchyTeamsMutation } from "src/common/types/generated/apollo/graphQLTypes";

const GCDivisionTeam: React.FC<DivisionProps> = ({ divisionId }) => {
  const { userData } = useUserData();
  const employee = userData.employee;
  if (!employee) throw new Error("Loggedā in user is not employee");
  const [updateDivisionEmp] = useUpdateGcDivisionEmployeesMutation();
  const authUser = useAuthUser();
  const { section } = useParams();

  return (
    <GCHierarchyTeam
      {...{
        getRemainingGcTeamVariables: {
          projectsToInviteWhere: {
            gc_division_id: { _eq: divisionId },
          },
          gcEmployeeWhere: {
            general_contractor_id: { _eq: employee.general_contractor.id },
            ...(section === "admins"
              ? {
                  gc_divisions: { gc_division_id: { _eq: divisionId } },
                }
              : {
                  _or: [
                    { gc_divisions: { gc_division_id: { _eq: divisionId } } },
                    {
                      gc_offices: {
                        gc_office: {
                          gc_business_unit: {
                            gc_division_id: { _eq: divisionId },
                          },
                        },
                      },
                    },
                    {
                      gc_business_units: {
                        gc_business_unit: {
                          gc_division_id: { _eq: divisionId },
                        },
                      },
                    },
                    {
                      employee_projects: {
                        project: {
                          gc_division_id: { _eq: divisionId },
                        },
                      },
                    },
                  ],
                }),
          },
        },
        title: "Division",
        onInviteSuccess: async (userIds) => {
          await updateDivisionEmp({
            variables: {
              deleteProjEmpWhere: { id: { _is_null: true } },
              //IF added to higher level remove from lower level
              deleteBuEmpWhere: {
                gc_business_unit: { gc_division_id: { _eq: divisionId } },
                user_id: { _in: userIds },
              },
              deleteOfficeEmpWhere: {
                gc_office: { gc_division_id: { _eq: divisionId } },
                user_id: { _in: userIds },
              },
              deleteDivEmpWhere: { id: { _is_null: true } },
              insertDivObjs: userIds.map((u) => ({
                gc_division_id: divisionId,
                created_by_uid: authUser.uid,
                user_id: u,
              })),
            },
          });
        },

        isAdmin: (employee) =>
          employee.gc_divisions.some(
            (division) => division.gc_division_id === divisionId,
          ),

        onRemoveFromTeam: async (userId, projects) => {
          await updateDivisionEmp({
            variables: {
              deleteDivEmpWhere: {
                user_id: { _eq: userId },
                gc_division_id: { _eq: divisionId },
              },
              deleteBuEmpWhere: { id: { _is_null: true } },
              deleteOfficeEmpWhere: { id: { _is_null: true } },
              deleteProjEmpWhere: {
                project_id: { _in: projects },
                employee_id: { _eq: userId },
                direct_project_assign: { _eq: false },
              },
              insertDivObjs: [],
            },
          });
        },

        excludeEmployeeWhere: {
          _or: [
            { is_corporate_admin: { _eq: true } },
            { gc_divisions: { gc_division_id: { _eq: divisionId } } },
          ],
        },
      }}
    />
  );
};

export default withCustomSuspense(withDivisionId(GCDivisionTeam));
