import dayjs from "dayjs";
import {
  GetObservationRequirementsDataQuery,
  useUpdateObservationRequirementMutation,
} from "src/common/types/generated/apollo/graphQLTypes";

const useRemoveObsReq = () => {
  const [updateObservationRequirement, { loading: updating }] =
    useUpdateObservationRequirementMutation();
  const removeObsReq = async (
    obsReq: GetObservationRequirementsDataQuery["observation_requirement"][number],
  ) => {
    const toSet = { archived_at: dayjs().toISOString() };
    return await updateObservationRequirement({
      variables: { _set: toSet, id: obsReq.id },
      optimisticResponse: {
        update_observation_requirement_by_pk: { ...obsReq, ...toSet },
      },
      update: (cache, result) => {
        const updatedItem = result.data?.update_observation_requirement_by_pk;
        if (!updatedItem) throw new Error("Failed to update obs requirement");
        cache.modify<GetObservationRequirementsDataQuery>({
          fields: {
            observation_requirement: (currList = [], { readField }) => {
              const updatedItemId = updatedItem.id;
              if (!updatedItemId) return currList;
              return currList.filter(
                (item) => readField("id", item) !== updatedItemId,
              );
            },
          },
        });
      },
    });
  };
  return [removeObsReq, updating] as const;
};
export default useRemoveObsReq;
