import React, { useEffect } from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import NavigationWrapper, {
  NavigationMenuProps,
} from "src/common/components/layouts/NavigationWrapper";
import {
  useLocation,
  useNavigate,
  useParams,
  useOutlet,
  Outlet,
} from "react-router-dom";
import NavigationPlaceholder from "src/common/components/NavigationPlaceholder";
import ContactLink from "src/common/components/general/ContactLink";
import GCAddNewLevelButton, {
  GCAddNewLevelButtonProps,
} from "./GCAddNewLevelButton";

const GCLevelsList: React.FC<{
  levels: Array<{ name: string; id: string }>;
  levelName: string;

  isCorporateAdmin: boolean;
  redirectTo: "divisions" | "business-units" | "offices";
  insertModalProps: Omit<GCAddNewLevelButtonProps, "levelName">;
  selectedIdName: "divisionId" | "businessUnitId" | "officeId";
}> = ({
  levels,
  levelName,
  insertModalProps,
  isCorporateAdmin,
  redirectTo,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = useParams()["selectedIdName"];
  const outlet = useOutlet();

  const menu: NavigationMenuProps = {
    navigationItems: levels.map((level) => ({
      label: level.name,
      rootPath: level.id,

      onClick: () => {
        const currentPath = location.pathname;
        const basePathRegex = new RegExp(`/gce/${redirectTo}/[^/]*`);
        const basePath = `/gce/${redirectTo}`;
        if (currentPath === basePath) {
          navigate(`${basePath}/${level.id}`);
          return;
        }
        const remainingPath = currentPath.replace(basePathRegex, "");
        const search = location.search;

        navigate(`${basePath}/${level.id}${remainingPath}${search}`);
      },
    })),
    utilityItems:
      isCorporateAdmin && levelName && levels.length
        ? [
            {
              component: (
                <GCAddNewLevelButton
                  levelName={levelName}
                  {...insertModalProps}
                />
              ),
            },
          ]
        : undefined,
  };

  useEffect(() => {
    const defaultLevelRedirect = async () => {
      // if (levels.length && levels[0]) {
      //   navigate(`${levels[0].id}`);
      // }
    };

    if (!selectedId) {
      defaultLevelRedirect();
    }
  }, []);
  if (levels.length && levels.find((l) => l.id === selectedId)) return null;
  return !isCorporateAdmin && levels.length === 1 ? (
    <Outlet />
  ) : levels.length === 0 ? (
    <div className={`w-full h-full flex items-center justify-center`}>
      <div className="flex flex-col items-center gap-1.5">
        {isCorporateAdmin && (
          <GCAddNewLevelButton levelName={levelName} {...insertModalProps} />
        )}
        <p className="text-1.5 text-center items-center flex justify-center flex-col">
          {!isCorporateAdmin
            ? `Your Corporate Admin has not assigned you to a ${levelName} yet!`
            : ""}{" "}
          <br />
          <div className="w-4/6 font-normal">
            {isCorporateAdmin
              ? `The ${levelName} is a layer in your organization’s hierarchy. 
              There may be multiple ${levelName}s. To start, add your first ${levelName} by selecting “Add New ${levelName}” 
              and enter the details. Then add each additional ${levelName} in the left-side menu`
              : ""}
          </div>
          <br />
          Please email us if you have any questions.
        </p>
        <div className="flex flex-row gap-1">
          <ContactLink type="email" value="support@siteform.io" />
        </div>
      </div>
    </div>
  ) : (
    <NavigationWrapper menu={menu}>
      {(!selectedId || levels.find((p) => p.id === selectedId)) && outlet ? (
        <Outlet />
      ) : !!levels.length ? (
        <NavigationPlaceholder message={`Select a ${levelName}`} />
      ) : null}
    </NavigationWrapper>
  );
};

export default withCustomSuspense(GCLevelsList);
