import React, { useEffect, useRef, useState } from "react";
import { Chart } from "@antv/g2";
import DataSet from "@antv/data-set";
import { ChartCfg, TextOption, TooltipCfg } from "@antv/g2/lib/interface";
import colorList from "../../lists/chartColorList";
import randomHexColor from "src/common/functions/randomHexColor";
import { useResizeObserver } from "usehooks-ts";

type DataSourceType = Array<{ name: string; color: string; value: number }>;

export interface PieChartProps {
  title?: React.ReactNode; // TODO deprecate
  dataSource: DataSourceType;
  children?: React.ReactNode;
  label?: string | [string, (...args: any[]) => any];
  tooltip?:
    | false
    | {
        config: TooltipCfg;
        displayAs: [string, (...args: any[]) => any];
      };
  color?: {
    field: string;
    colors: string[];
  };
  chartCfg?: Omit<ChartCfg, "container">;
  showLegend?: boolean;
  annotations?: TextOption[];
  singleMetric?: boolean;
  interactions?: string[];
  othersCount?: number;
}

const PieChart: React.FC<PieChartProps> = (props) => {
  const containerRef = useRef<null | HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  useResizeObserver({
    ref: containerRef,
    onResize: ({ width }) => {
      setContainerWidth(width || 0);
    },
  });

  // a function to create a chart that returns the chart element
  const createChart: (
    container: HTMLDivElement,
    dataSource: DataSourceType,
  ) => Chart = (container, dataSource) => {
    const chart = new Chart({
      container,
      ...props.chartCfg,
    });

    const view = chart.createView({
      region: {
        start: { x: 0, y: 0 },
        end: { x: 1, y: 1 },
      },
    });

    // TODO change the legend to ABC, while the chart pie - to value sorted
    const dataSortedAbc = [...dataSource];
    const dataSortedByValue = [...dataSource];

    dataSortedAbc.sort((a, b) => (a.name > b.name ? 1 : -1));
    dataSortedByValue.sort((a, b) => b.value - a.value);
    if (props.othersCount) {
      const othersData = {
        name: "Others",
        value: props.othersCount,
        color: colorList[dataSortedByValue.length] || randomHexColor(),
      };
      dataSortedAbc.push(othersData);
      dataSortedByValue.push(othersData);
    }
    let color = props.color ?? {
      field: "name",
      colors: dataSortedByValue.map((s) => s.color),
    };

    let data: any[];
    if (props.singleMetric) {
      // Add dummy to represent second half of pie
      data = [
        ...dataSortedByValue,
        { name: "other", value: 100 - dataSource[0].value },
      ];
      color.colors = ["#95DE64", "#eceef100"];
    } else {
      const dataSet = new DataSet();
      const dataView = dataSet.createView();
      dataView.source(dataSortedByValue).transform({
        type: "percent",
        field: "value",
        dimension: "name",
        as: "percent",
      });
      data = dataView.rows;
    }

    // register data
    view.data(data);

    // define plotting as circular
    view.coordinate("theta", { radius: 0.75, innerRadius: 0.5 });

    // View configurations
    chart.legend({
      show: !!props.showLegend,
      position: "right",
      items: dataSortedAbc.map((item) => ({
        name: item.name,
        value: item.value,
        marker: {
          symbol: "circle",
          style: {
            fill: item.color,
            r: 5,
          },
        },
      })),
    });

    if (props.tooltip) chart.tooltip(props.tooltip.config);

    props.interactions?.forEach((interaction) =>
      chart.interaction(interaction),
    );

    props.annotations?.forEach((textOpts) => chart.annotation().text(textOpts));

    // Generate view params
    const geometry = view
      .interval()
      .adjust("stack")
      .position("value")
      .color(color.field, color.colors);

    // Configure Geometry object
    if (Array.isArray(props.label)) {
      geometry.label(props.label[0], props.label[1]);
    } else {
      if (props.label) {
        geometry.label(props.label);
      }
    }

    if (props.tooltip) {
      geometry.tooltip(props.tooltip.displayAs[0], props.tooltip.displayAs[1]);
    }
    return chart;
  };

  useEffect(() => {
    if (containerRef.current && containerWidth) {
      const chart = createChart(containerRef.current, props.dataSource);
      // Render the chart to the dom
      chart.render();
      //    setTimeout(() => chart.forceFit(), 200);

      return () => chart.destroy();
    }
  }, [containerWidth, createChart, props.dataSource]);

  return (
    <div className={`w-full h-full overflow-hidden`}>
      <div
        ref={containerRef}
        style={{ width: "100%", display: "inline-block" }}
      />
    </div>
  );
};

export default PieChart;
