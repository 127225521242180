import React from "react";
import IncidentDocUploader from "./IncidentDocUploader";
import IncidentDocViewer from "./IncidentDocViewer";
import {
  Document_Insert_Input,
  DocumentFragmentFragment,
} from "src/common/types/generated/apollo/graphQLTypes";
import { DeletedDocument } from "./IncidentDocViewer";
import { Card } from "antd";

interface IncidentDocViewAndUploadProps {
  groupId: string;
  type: string;
  uploadDocument: (objects: Document_Insert_Input[]) => Promise<void>;
  deleteDocument: (doc: DeletedDocument) => Promise<void>;
  documents?: Array<DocumentFragmentFragment>;
}

const IncidentDocViewAndUpload: React.FunctionComponent<
  IncidentDocViewAndUploadProps
> = ({ groupId, uploadDocument, deleteDocument, documents, type }) => {
  return (
    <>
      <Card className="w-4/5">
        {documents && (
          <IncidentDocViewer
            documents={documents.filter((file) => !!file.image)}
            deleteDocument={deleteDocument}
          />
        )}
        <IncidentDocUploader
          acceptedFiles=".png,.jpg,.jpeg"
          insertDoc={uploadDocument}
          docType="image"
          type={type}
          title="Upload Images (area of incident, property damage, etc.)"
          groupId={groupId}
        />
      </Card>
      <Card className="w-4/5">
        {documents && (
          <IncidentDocViewer
            documents={documents.filter((file) => !file.image)}
            deleteDocument={deleteDocument}
          />
        )}
        <IncidentDocUploader
          acceptedFiles="video/*, audio/*, .xlsx, .docx, .doc, application/pdf"
          insertDoc={uploadDocument}
          docType="other"
          type={type}
          title="Add Any Related Documents (police/incident reports, etc.)"
          groupId={groupId}
        />
      </Card>
    </>
  );
};

export default IncidentDocViewAndUpload;
