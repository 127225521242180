import { Card, Divider } from "antd";
import ChartEmptyFallback from "./ChartEmptyFallback";

export interface ChartWrapperCardProps {
  title?: React.ReactNode;
  extraTop?: React.ReactNode;
  extraBottom?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  loading?: boolean;
}

const ChartWrapperCard: React.FC<ChartWrapperCardProps> = (props) => {
  return (
    <Card
      title={props.title}
      className={props.className}
      loading={props.loading}
    >
      {!!props.extraTop && (
        <>
          {props.extraTop}
          <Divider />
        </>
      )}

      <div className="flex justify-center w-full items-center">
        {props.children ?? <ChartEmptyFallback />}
      </div>
      {!!props.extraBottom && (
        <>
          <Divider />
          {props.extraBottom}
        </>
      )}
    </Card>
  );
};

export default ChartWrapperCard;
