/**
 * @generated SignedSource<<f9d7688897099d73bb56fd4d5bbbb78a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { image_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InsertImagesMutation$variables = {
  objects: ReadonlyArray<image_insert_input>;
};
export type InsertImagesMutation$data = {
  readonly insert_image: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type InsertImagesMutation = {
  response: InsertImagesMutation$data;
  variables: InsertImagesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      }
    ],
    "concreteType": "image_mutation_response",
    "kind": "LinkedField",
    "name": "insert_image",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InsertImagesMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InsertImagesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ed40fabff97c0d3df9d849c0bce34179",
    "id": null,
    "metadata": {},
    "name": "InsertImagesMutation",
    "operationKind": "mutation",
    "text": "mutation InsertImagesMutation(\n  $objects: [image_insert_input!]!\n) {\n  insert_image(objects: $objects) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "52c78a4ba28ab6d0e8a3c828d3237353";

export default node;
