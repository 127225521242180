import { Button, Form, message, Modal, notification, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useEffect, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import AddSubcontractorEmployeeModal from "src/common/components/dialogs/AddSubcontractorEmployeeModal";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import {
  GetSubcontractorEmployeesDocument,
  Order_By,
  useCreateSubcontractorEmployeeMutation,
  useGetSubcontractorEmployeesQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import { AddSubcontractorProjectTeamModalQuery } from "src/common/types/generated/relay/AddSubcontractorProjectTeamModalQuery.graphql";
import {
  AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation,
  AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation$data,
} from "src/common/types/generated/relay/AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation.graphql";
import { handleInsertProjSubEmpForGcSubcontractorPage } from "src/root/routes/views/general-contractor/projects/subcontractors/sub-info/GCSubcontractorDetail";
import * as uuid from "uuid";

const query = graphql`
  query AddSubcontractorProjectTeamModalQuery(
    $projectSubcontractorEmployeeWhere: project_subcontractor_employee_bool_exp!
  ) {
    project_subcontractor_employee_connection(
      where: $projectSubcontractorEmployeeWhere
      first: 10000
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
        }
      }
    }
  }
`;

export const insertProjectSubcontractorEmployeeMutation = graphql`
  mutation AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation(
    $objects: [project_subcontractor_employee_insert_input!]!
  ) {
    insert_project_subcontractor_employee(
      objects: $objects
      on_conflict: {
        constraint: project_subcontractor_employe_project_id_subcontractor_empl_key
        update_columns: []
      }
    ) {
      returning {
        ...ProjectSubcontractorEmployeeFrag @relay(mask: false)
      }
    }
  }
`;

interface AddSubcontractorProjectTeamFormValues {
  subcontractorEmployeeIds: Array<string>;
}

interface AddSubcontractorProjectTeamModalProps {
  modalClose: () => void;
  modalVisible: boolean;
  fetchKey?: number;
  subcontractorId: string;
  projectId: string;
  onSubmit: () => void;
  refetchTables?: () => void;
}

const AddSubcontractorProjectTeamModal: React.FC<
  AddSubcontractorProjectTeamModalProps
> = (props) => {
  const [addTeamMemberForm] = Form.useForm();
  const [addEmployeeOpen, setAddEmployeeOpen] = useState(false);
  const [creatingSubcontractorEmployee, setCreatingSubcontractorEmployee] =
    useState(false);
  const [createSubEmployee, { loading: creatingSubEmployee }] =
    useCreateSubcontractorEmployeeMutation();
  const [insertProjectSubcontractorEmployee, inserting] =
    useAsyncMutation<AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation>(
      insertProjectSubcontractorEmployeeMutation,
    );
  const [
    selectedSubcontractorEmployeeIdsCopy,
    setSelectedSubcontractorEmployeeIdsCopy,
  ] = useState<Array<string> | null>(null);
  const { data: subcontractorEmployeeData, refetch } =
    useGetSubcontractorEmployeesQuery({
      variables: {
        where: {
          subcontractor_id: { _eq: props.subcontractorId },
          _not: {
            project_subcontractor_employees: {
              project_id: { _eq: props.projectId },
            },
          },
        },
        order_by: [
          {
            user: {
              name: Order_By.Asc,
            },
          },
        ],
      },
    });
  const data = useLazyLoadQuery<AddSubcontractorProjectTeamModalQuery>(
    query,
    {
      projectSubcontractorEmployeeWhere: {
        project_id: { _eq: props.projectId },
      },
    },
    {
      fetchKey: props.fetchKey ?? 0,
      fetchPolicy: "store-and-network",
      networkCacheConfig: {
        force: true,
      },
    },
  );

  const [allSUbcontractorEmployees, setAllSUbcontractorEmployees] = useState<
    Array<{ name: string; id: string; title: string }>
  >(
    subcontractorEmployeeData?.subcontractor_employee.map((itr) => {
      return {
        name: itr.user.name,
        id: itr.user_id,
        title: itr.employee_title?.name.en ?? "",
      };
    }) ?? [],
  );

  useEffect(() => {
    setAllSUbcontractorEmployees((prev) => {
      return (
        subcontractorEmployeeData?.subcontractor_employee.map((itr) => {
          return {
            name: itr.user.name,
            id: itr.user_id,
            title: itr.employee_title?.name.en ?? "",
          };
        }) ?? []
      );
    });
    if (selectedSubcontractorEmployeeIdsCopy) {
      const newSubcontractorEmployeeId =
        subcontractorEmployeeData?.subcontractor_employee.find(
          (subcontractorEmployee) => {
            return !selectedSubcontractorEmployeeIdsCopy?.includes(
              subcontractorEmployee.user_id,
            );
          },
        )?.user_id;
      if (newSubcontractorEmployeeId) {
        const selectedSubcontractorEmployeeIds: Array<string> =
          addTeamMemberForm.getFieldValue("subcontractorEmployeeIds") ?? [];
        selectedSubcontractorEmployeeIds.push(newSubcontractorEmployeeId);
        addTeamMemberForm.setFieldsValue({
          subcontractorEmployeeIds: selectedSubcontractorEmployeeIds,
        });
      }
    }
    setSelectedSubcontractorEmployeeIdsCopy((prev) => {
      return (
        subcontractorEmployeeData?.subcontractor_employee.map(
          (employee) => employee.user_id,
        ) ?? []
      );
    });
  }, [subcontractorEmployeeData]);

  useEffect(() => {
    addTeamMemberForm.setFieldsValue({
      subcontractorEmployeeIds: undefined,
    });
  }, [props.modalVisible]);

  const handleInsertProjectSubcontractorEmployeeUpdater = (
    store: RecordSourceSelectorProxy<AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation$data>,
  ) => {
    const insertProjectSubcontractorEmployees = store.getRootField(
      "insert_project_subcontractor_employee",
    );
    const conn1 = ConnectionHandler.getConnection(
      store.getRoot(),
      "SubProjectMobilizationView_project_subcontractor_employee_connection",
    );
    if (conn1) {
      insertProjectSubcontractorEmployees
        .getLinkedRecords("returning")
        ?.forEach((projsubEmp) => {
          const edge = store.create(uuid.v4(), "edge");
          edge.setLinkedRecord(projsubEmp, "node");
          ConnectionHandler.insertEdgeAfter(conn1, edge);
        });
    }
    handleInsertProjSubEmpForGcSubcontractorPage(
      store,
      props.subcontractorId,
      props.projectId,
    );
  };

  const handleAddSubcontractorProjectTeamSubmit = async () => {
    addTeamMemberForm
      .validateFields()
      .then(async (values: AddSubcontractorProjectTeamFormValues) => {
        const selectedSubcontractorEmployees = allSUbcontractorEmployees.filter(
          (subcontractorEmployee) =>
            values.subcontractorEmployeeIds.includes(subcontractorEmployee.id),
        );
        await insertProjectSubcontractorEmployee({
          variables: {
            objects: selectedSubcontractorEmployees.map(
              (subcontractorEmployee, index) => ({
                subcontractor_employee_id: subcontractorEmployee.id,
                project_id: props.projectId,
                subcontractor_id: props.subcontractorId,
                emergency_contact:
                  data.project_subcontractor_employee_connection.edges.length ==
                    0 && index == 0
                    ? true
                    : false,
              }),
            ),
          },
          updater: handleInsertProjectSubcontractorEmployeeUpdater,
        })
          .then((d) => {
            props.onSubmit();
            addTeamMemberForm.resetFields();
            message.success("Uploaded To SiteForm");
          })
          .catch((e) => {
            notification.error({
              message: "Upload error",
              description: e.message,
              duration: null,
            });
            console.log(e);
          });
      });
  };

  return (
    <>
      <AddSubcontractorEmployeeModal
        visible={addEmployeeOpen}
        onCancel={() => setAddEmployeeOpen(false)}
        onCreate={async (values) => {
          try {
            setAddEmployeeOpen(false);
            setCreatingSubcontractorEmployee(true);
            await createSubEmployee({
              variables: {
                input: {
                  name: values.name,
                  email: values.email,
                  phone: values.phoneNumber,
                  projectId: props.projectId,
                  employeeTitleId: values.titleId,
                  subcontractorId: props.subcontractorId,
                  sendInvite: values.send_invite,
                },
              },
            });
            refetch();
            props.onSubmit();
            props.refetchTables?.();
            if (values.send_invite)
              notification.open({
                message: `${values.name}'s account was created!`,
                description:
                  "They will be receiving an email containing their username and password to login to their account.",
              });
          } finally {
            setCreatingSubcontractorEmployee(false);
          }
        }}
      />
      <Modal
        title={`Add Team Members`}
        open={props.modalVisible}
        onCancel={props.modalClose}
        onOk={handleAddSubcontractorProjectTeamSubmit}
        okButtonProps={{ hidden: false }}
        footer={
          <div className="flex flex-1 flex-row">
            <div className="flex flex-col items-start flex-1">
              <Button
                className="text-interactive-primary bg-none border-interactive-secondary hover:border-interactive-primary"
                loading={creatingSubcontractorEmployee}
                onClick={() => {
                  // setFetchKey((prev) => prev + 1);
                  setAddEmployeeOpen(true);
                }}
              >
                {"Invite New +"}
              </Button>
            </div>
            <Button onClick={props.modalClose}>{"Cancel"}</Button>
            <Button
              className="text-white bg-interactive-primary border-interactive-primary ml-0"
              onClick={handleAddSubcontractorProjectTeamSubmit}
              loading={inserting}
            >
              {"OK"}
            </Button>
          </div>
        }
      >
        <Form
          form={addTeamMemberForm}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: "public" }}
        >
          <Form.Item
            name="subcontractorEmployeeIds"
            label="Subcontractor Employees"
            initialValue={[]}
            rules={[
              { required: true, message: "Select Subcontractor Employees" },
            ]}
          >
            <Select
              optionFilterProp="label"
              listItemHeight={10}
              mode="multiple"
              style={{
                width: "100%",
              }}
              options={allSUbcontractorEmployees.map((subcontractor) => ({
                value: subcontractor.id,
                key: subcontractor.id,
                label: subcontractor.title
                  ? `${subcontractor.name}, ${subcontractor.title}`
                  : `${subcontractor.name}`,
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddSubcontractorProjectTeamModal;
