import { DownloadOutlined } from "@ant-design/icons";
import { Button, Drawer, Form } from "antd";
import dayjs from "dayjs";
import { useMemo } from "react";
import FModal from "src/common/components/dialogs/FModal";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";
import useSelectedIndicies from "src/common/hooks/useSelectedIndices";
import {
  GetAdminObservationOptionsQuery,
  useGenerateObservationsSummaryPdfMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import ObservationReportFields, {
  ObservationReportFieldsFormVals,
} from "src/domain-features/observation/components/ObservationReportFields";
import { OrganizationLevelName } from "src/root/routes/views/general-contractor/hierarchy/utils/hierarchyTypes";

interface FormVals extends ObservationReportFieldsFormVals {
  projectIds: Array<string>;
  subIds: Array<string>;
  endDate: dayjs.Dayjs;
  startDate?: dayjs.Dayjs;
  includeShortSummary: boolean;
}
const ObservationReportCreator: React.FC<{
  reportModal: boolean;
  handleClose: () => void;
  organizationId: string;
  organizationLevel: OrganizationLevelName;
  options: GetAdminObservationOptionsQuery;
}> = ({
  reportModal,
  handleClose,
  options,
  organizationId,
  organizationLevel,
}) => {
  const [selectedCategories, handleCategorySelection] =
    useSelectedIndicies<string>();
  const [selectedProjects, handleProjectSelection] =
    useSelectedIndicies<string>();
  const [generateObsSummaryPdf, { loading }] =
    useGenerateObservationsSummaryPdfMutation();
  const [form] = Form.useForm<FormVals>();
  const subOptions = useMemo(
    () =>
      (organizationLevel === "project"
        ? options.subcontractor
        : options.subcontractor.filter(
            (sub) =>
              selectedProjects.size === 0 ||
              sub.subcontractor_projects.some((ps) =>
                selectedProjects.has(ps.project_id),
              ),
          )
      ).map((sub) => ({ label: sub.name, value: sub.id })),
    [organizationLevel, selectedProjects, options],
  );
  const showCompleted =
    organizationLevel !== "project" ||
    options.project[0]?.project_setting
      ?.require_gc_to_mark_observation_complete;
  const statusCheckboxOptions = useMemo(() => {
    const statusOptions = [
      { label: "Open", value: "open" },
      { label: "Corrected", value: "corrected" },
    ];
    if (showCompleted) {
      statusOptions.push({ label: "Completed", value: "completed" });
    }

    statusOptions.push({ label: "Safe", value: "safe" });
    statusOptions.push({ label: "All", value: "all" });
    return statusOptions;
  }, [showCompleted]);
  const handleDownload = async () => {
    const vals = await form.validateFields();
    console.log(vals);
    const projectIds = vals.projectIds?.length
      ? vals.projectIds
      : options.project.map((p) => p.id);

    const statuses = !vals.statuses
      ? ["open", "closed", "safe", ...(showCompleted ? ["completed"] : [])]
      : vals.statuses.includes("all")
      ? []
      : vals.statuses;
    const timezone = dayjs.tz.guess() || "America/New_York";

    const startTime = vals.startDate
      ? dayjs.tz(vals.startDate.format("YYYY-MM-DD"), timezone).toISOString()
      : null;
    const endTime = vals.endDate
      ? dayjs.tz(vals.endDate.format("YYYY-MM-DD"), timezone).toISOString()
      : null;
    console.log(startTime, endTime);
    const { data: res } = await generateObsSummaryPdf({
      variables: {
        input: {
          organizationId,
          organizationLevel,
          timezone: dayjs.tz.guess(),
          projectIds,
          subcontractorIds: vals.subIds,
          riskLevels: vals.risk_levels,
          statuses,
          categoryIds: vals.category_type_ids,
          categoryTypeIds: vals.category_type_ids,
          endTime,
          startTime,
          includeShortSummaryList: vals.includeShortSummary || false,
        },
      },
    });
    if (res) downloadFromUrl(res.generateObservationsSummaryPdf);

    handleClose();
  };
  const initialVals: FormVals = {
    projectIds: [],
    subIds: [],
    risk_levels: [],
    statuses: ["open"],
    category_type_ids: [],
    category_ids: [],
    endDate: dayjs(),
    includeShortSummary: true,
  };
  return (
    <Drawer
      title="Report Creator"
      width={"60%"}
      open={reportModal}
      onClose={handleClose}
      destroyOnClose
      footer={
        <div className="flex gap-1">
          <Button loading={loading} onClick={handleClose}>
            Close
          </Button>
          <Button
            type={"primary"}
            loading={loading}
            icon={<DownloadOutlined />}
            onClick={handleDownload}
          >
            Download
          </Button>
        </div>
      }
    >
      <Form form={form} layout={`vertical`} initialValues={initialVals}>
        <FModal.Select
          name={"projectIds"}
          className={organizationLevel === "project" ? "hidden" : undefined}
          label="Projects"
          props={{
            mode: "multiple",
            showSearch: true,
            filterOption: getNormalSelectOptionsFilter,
            placeholder: "All Projects Included",
            onSelect: (val) =>
              typeof val === "string" && handleProjectSelection(val),
            onDeselect: (val) =>
              typeof val === "string" && handleProjectSelection(val),
            options: options.project.map((proj) => ({
              value: proj.id,
              label: proj.name,
            })),
          }}
        />
        <FModal.Select
          name={"subIds"}
          label="Company"
          props={{
            mode: "multiple",
            placeholder: "All Companies Included",
            allowClear: true,
            showSearch: true,
            filterOption: getNormalSelectOptionsFilter,
            options: subOptions,
          }}
        />
        <ObservationReportFields
          riskOptions={options.risk_level.map((risk) => ({
            value: risk.value,
            label: risk.name,
            color_hex: risk.color_hex,
          }))}
          categoryOptions={options.observation_category_type.map(
            (cateType) => ({
              value: cateType.id,
              label: cateType.name.en,
              subCategories: cateType.observation_categories.map((cate) => ({
                value: cate.id,
                label: cate.name.en,
              })),
            }),
          )}
          statusOptions={statusCheckboxOptions}
        />
        <FModal.Date name="startDate" label="Date Range Start"></FModal.Date>
        <FModal.Date name="endDate" label="Date Range End"></FModal.Date>
        <FModal.Checkbox name="includeShortSummary">
          Include Short Summary List
        </FModal.Checkbox>
      </Form>
    </Drawer>
  );
};
export default ObservationReportCreator;
