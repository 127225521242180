import { Navigate, Outlet } from "react-router-dom";
import RequireRoleWrapper from "src/common/components/ReqiureRoleWrapper";
import NestedRoute from "src/common/types/manual/NestedRoute";
import GCCorporate from "./GCCorporate";
import GCCorporateTeam from "./team/GCCorporateTeam";
import gcSettingsNavigationRoute from "./settings/gcSettingsNavigationRoute";
import GCCorporateProjects from "./projects/GCCorporateProjects";
import GCCorporateWorkers from "./worker/GCCorporateWorkers";
import GCCorporateObservations from "./observation/GCCorporateObservations";
import GCCorporateIncidents from "./incident/GCCorporateIncidents";
import searchParamsProvider from "src/common/functions/searchParamsProvider";

const gcCorporateRoute: NestedRoute = {
  element: (
    <RequireRoleWrapper requiredRole="employee">
      <Outlet />
    </RequireRoleWrapper>
  ),
  children: [
    {
      path: "",
      element: <GCCorporate />,
      children: [
        { path: "", element: <Navigate to="settings" /> },
        { path: "projects", element: <GCCorporateProjects /> },
        {
          path: "teams/:section",
          element: searchParamsProvider(GCCorporateTeam),
        },
        // {
        //   path: "employees/:subview",
        //   element: searchParamsProvider(GCCorporateTeam),
        // },
        { path: "workers", element: <GCCorporateWorkers /> },
        { path: "observations", element: <GCCorporateObservations /> },
        { path: "incidents", element: <GCCorporateIncidents /> },
        {
          path: "settings",
          ...gcSettingsNavigationRoute,
        },
      ],
    },
  ],
};
export default gcCorporateRoute;
