import withDivisionId, { DivisionProps } from "../../utils/withDivisionId";
import GCAdminObservations from "../../component/observation/GCAdminObservations";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
const GCDivisionObservations: React.FC<DivisionProps> = ({ divisionId }) => {
  const { userData } = useUserData();
  const projWhere = { gc_division_id: { _eq: divisionId } };
  const emp = userData.employee;
  if (!emp) throw new Error("Employee not found ");
  return (
    <GCAdminObservations
      optionsQueryVars={{
        projWhere,
        gcDivisionWhere: { id: { _is_null: true } },
        gcBuWhere: emp.general_contractor.hierarchy_business_unit_name
          ? { gc_division_id: { _eq: divisionId } }
          : { id: { _is_null: true } },
        gcOfficeWhere: emp.general_contractor.hierarchy_office_name
          ? { gc_division_id: { _eq: divisionId } }
          : { id: { _is_null: true } },
      }}
      projWhere={projWhere}
      organizationId={divisionId}
      organizationLevel="division"
      gcEmployeeWhere={{
        _or: [
          { gc_divisions: { gc_division_id: { _eq: divisionId } } },
          {
            gc_business_units: {
              gc_business_unit: { gc_division_id: { _eq: divisionId } },
            },
          },
          {
            gc_offices: {
              gc_office: { gc_division_id: { _eq: divisionId } },
            },
          },
          {
            employee_projects: {
              project: { gc_division_id: { _eq: divisionId } },
              _or: [
                { direct_project_assign: { _eq: true } },
                { direct_project_assign: { _is_null: true } },
              ],
            },
          },
        ],
      }}
    />
  );
};
export default withDivisionId(GCDivisionObservations);
