import { useSuspenseQuery } from "@apollo/client";
import {
  GetObsLeaderboardDataQuery,
  GetObsLeaderboardDataQueryVariables,
  GetObsLeaderboardDataDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useMemo } from "react";

import dayjs from "dayjs";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import LeaderboardTable from "./LeaderboardTable";
import LeaderboardCharts from "./LeaderboardCharts";
import { GCObsLeaderboardProps } from "../GCObsLeaderboard";

interface GCObsLeaderboardDataProps
  extends Omit<
    GCObsLeaderboardProps,
    "organization_level" | "organization_unit_id"
  > {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
  viewType: string;
}
const GCObsLeaderboardData: React.FC<GCObsLeaderboardDataProps> = ({
  start,
  end,
  viewType,
  gcEmployeeWhere,
  gcId,
  projWhere,
}) => {
  const variables: GetObsLeaderboardDataQueryVariables = {
    projWhere,
    gcEmployeeWhere,
    timestampComparisonExp: {
      _lt: end.toISOString(),
      _gte: start.toISOString(),
    },
    obsReqWhere: {
      general_contractor_id: { _eq: gcId },
      archived_at: { _is_null: true },
    },
  };
  const monthsCount = dayjs(end).diff(dayjs(start), "month");
  const { data } = useSuspenseQuery<
    GetObsLeaderboardDataQuery,
    GetObsLeaderboardDataQueryVariables
  >(GetObsLeaderboardDataDocument, {
    variables: variables,
  });
  const titleReqMap = useMemo(
    () =>
      new Map<
        string,
        GetObsLeaderboardDataQuery["observation_requirement"][number]
      >(
        data.observation_requirement.flatMap((req) =>
          req.employee_titles.map(({ employee_title_id }) => [
            employee_title_id,
            req,
          ]),
        ),
      ),
    [data.observation_requirement],
  );
  const props = { users: data.user, monthsCount, titleReqMap };
  return viewType === "table" ? (
    <LeaderboardTable {...props} />
  ) : (
    <LeaderboardCharts {...props} />
  );
};
export default withCustomSuspense(GCObsLeaderboardData);
