import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import {
  GetGcTeamQueryVariables,
  Project_Bool_Exp,
  useGetGcTeamLazyQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";

export interface GCObservationSettingsWithoutOptionsProps {
  levelName:
    | "project_id"
    | "gc_id"
    | "gc_office_id"
    | "gc_business_unit_id"
    | "gc_division_id";
  levelId: string;
  projWhere?: Project_Bool_Exp;
  withOptions?: undefined;
  gcEmployeeWhere: GetGcTeamQueryVariables["gcEmployeeWhere"];
}

export interface GCObservationSettingsWithOptionsProps {
  levelName:
    | "project_id"
    | "gc_id"
    | "gc_office_id"
    | "gc_business_unit_id"
    | "gc_division_id";
  levelId: string;
  projWhere?: Project_Bool_Exp;
  withOptions: true;
  employeeOptions: Array<{ label: string; value: string }>;
}

export type GCObservationSettingsProps =
  | GCObservationSettingsWithoutOptionsProps
  | GCObservationSettingsWithOptionsProps;
const GCObservationSettings: React.FC<GCObservationSettingsProps> = ({
  levelId,
  levelName,
  projWhere,
  ...props
}) => {
  const {
    userData: { employee },
  } = useUserData();
  if (!employee) throw new Error("Logged in user is not an employee");
  // const [getGcTeam, { data: teamData, error, loading: teamLoading }] =
  //   useGetGcTeamLazyQuery();
  // useEffect(() => {
  //   if (!props.withOptions)
  //     getGcTeam({
  //       variables: {
  //         gcId: employee.general_contractor_id,
  //         projectsToInviteWhere: { id: { _is_null: true } },
  //         gcEmployeeWhere: {
  //           general_contractor_id: { _eq: employee.general_contractor_id },
  //           ...props.gcEmployeeWhere,
  //         },
  //       },
  //     });
  // }, [props.withOptions]);
  // const { data } = useSuspenseQuery<
  //   GetObservationGcSettingsQuery,
  //   GetObservationGcSettingsQueryVariables
  // >(GetObservationGcSettingsDocument, {
  //   variables: {
  //     obsRequirementWhere: { [levelName]: { _eq: levelId } },
  //     obsNotifSettingsWhere: { [levelName]: { _eq: levelId } },
  //     obsNotifyeeWhere: { [levelName]: { _eq: levelId } },
  //   },
  // });

  // if (error) throw error;
  // const employees = useMemo(
  //   () =>
  //     props.withOptions
  //       ? props.employeeOptions
  //       : (teamData?.general_contractor_employee || []).map((emp) => ({
  //           label: emp.user.name,
  //           value: emp.uid,
  //         })),
  //   [props, teamData],
  // );

  return (
    <></> // <Space direction={"vertical"}>
    //   <ObservationAutoNotificationTable
    //     dataSource={data.risk_level}
    //     employees={employees}
    //     levelId={levelId}
    //     levelName={levelName}
    //     teamLoading={teamLoading}
    //   />

    // </Space>
  );
};
export default withCustomSuspense(GCObservationSettings);
