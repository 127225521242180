/**
 * @generated SignedSource<<7ae7e3c35e1a0c9c3bedc577cf0d720f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { worker_previous_subcontractor_bool_exp, worker_previous_subcontractor_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useUpsertAndDeletePreviousSubMutation$variables = {
  deleteWhere: worker_previous_subcontractor_bool_exp;
  objects: ReadonlyArray<worker_previous_subcontractor_insert_input>;
};
export type useUpsertAndDeletePreviousSubMutation$data = {
  readonly delete_worker_previous_subcontractor: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_worker_previous_subcontractor: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type useUpsertAndDeletePreviousSubMutation = {
  response: useUpsertAndDeletePreviousSubMutation$data;
  variables: useUpsertAndDeletePreviousSubMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleteWhere"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objects"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "deleteWhere"
      }
    ],
    "concreteType": "worker_previous_subcontractor_mutation_response",
    "kind": "LinkedField",
    "name": "delete_worker_previous_subcontractor",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "worker_previous_subcontractor_subcontractor_id_worker_id_key",
          "update_columns": ([]/*: any*/)
        }
      }
    ],
    "concreteType": "worker_previous_subcontractor_mutation_response",
    "kind": "LinkedField",
    "name": "insert_worker_previous_subcontractor",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpsertAndDeletePreviousSubMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useUpsertAndDeletePreviousSubMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "4c7d2d10ead70ec8c4addb2f045f5e52",
    "id": null,
    "metadata": {},
    "name": "useUpsertAndDeletePreviousSubMutation",
    "operationKind": "mutation",
    "text": "mutation useUpsertAndDeletePreviousSubMutation(\n  $objects: [worker_previous_subcontractor_insert_input!]!\n  $deleteWhere: worker_previous_subcontractor_bool_exp!\n) {\n  delete_worker_previous_subcontractor(where: $deleteWhere) {\n    affected_rows\n  }\n  insert_worker_previous_subcontractor(objects: $objects, on_conflict: {constraint: worker_previous_subcontractor_subcontractor_id_worker_id_key, update_columns: []}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "c61f52474920de3743ec8cd955cee68d";

export default node;
