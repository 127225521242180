/**
 * @generated SignedSource<<9c1c0c14677b899e12a3695e5b67cc76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_subcontractor_crew_onsite_period_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type usePermanentOnsiteSettingInsertCrewOnsiteMutation$variables = {
  object: project_subcontractor_crew_onsite_period_insert_input;
};
export type usePermanentOnsiteSettingInsertCrewOnsiteMutation$data = {
  readonly insert_project_subcontractor_crew_onsite_period_one: {
    readonly id: string;
  } | null | undefined;
};
export type usePermanentOnsiteSettingInsertCrewOnsiteMutation = {
  response: usePermanentOnsiteSettingInsertCrewOnsiteMutation$data;
  variables: usePermanentOnsiteSettingInsertCrewOnsiteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "object",
        "variableName": "object"
      }
    ],
    "concreteType": "project_subcontractor_crew_onsite_period",
    "kind": "LinkedField",
    "name": "insert_project_subcontractor_crew_onsite_period_one",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePermanentOnsiteSettingInsertCrewOnsiteMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePermanentOnsiteSettingInsertCrewOnsiteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3a8625ee5c10d3ade6c5ee9c306b6333",
    "id": null,
    "metadata": {},
    "name": "usePermanentOnsiteSettingInsertCrewOnsiteMutation",
    "operationKind": "mutation",
    "text": "mutation usePermanentOnsiteSettingInsertCrewOnsiteMutation(\n  $object: project_subcontractor_crew_onsite_period_insert_input!\n) {\n  insert_project_subcontractor_crew_onsite_period_one(object: $object) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6fda58a0ce26e0f8dc492d76074bfc53";

export default node;
