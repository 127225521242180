import { graphql } from "babel-plugin-relay/macro";
import { useManageDeliveryTextsUpdateMutation } from "src/common/types/generated/relay/useManageDeliveryTextsUpdateMutation.graphql";
import { useManageDeliveryTextsInsertMutation } from "src/common/types/generated/relay/useManageDeliveryTextsInsertMutation.graphql";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { ConnectionHandler } from "relay-runtime";
import { TextField } from "./sitedeliveryTypes";
import * as uuid from "uuid";

const updateDeliveryTextsMutation = graphql`
  mutation useManageDeliveryTextsUpdateMutation(
    $id: uuid!
    $_set: text_translation_set_input!
  ) {
    update_text_translation_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
      en
      original
    }
  }
`;

const insertDeliveryTextsMutation = graphql`
  mutation useManageDeliveryTextsInsertMutation(
    $object: text_translation_insert_input!
    $id: uuid!
    $set: delivery_set_input!
  ) {
    insert_text_translation_one(object: $object) {
      id
      en
      original
    }
    update_delivery_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...DeliveryFrag @relay(mask: false)
    }
  }
`;

const useManageDeliveryTexts = () => {
  const [updateDeliveryTexts] =
    useAsyncMutation<useManageDeliveryTextsUpdateMutation>(
      updateDeliveryTextsMutation,
    );
  const [insertDeliveryTexts] =
    useAsyncMutation<useManageDeliveryTextsInsertMutation>(
      insertDeliveryTextsMutation,
    );

  const manageDeliveryTexts = async (
    currentText: TextField | undefined,
    newText: string,
    fieldName: string,
    deliveryId: string,
  ) => {
    if (!currentText && newText) {
      const newId = uuid.v4();
      return insertDeliveryTexts({
        variables: {
          object: {
            id: newId,
            original: newText,
            en: newText,
          },
          id: deliveryId,
          set: { [`${fieldName}_id`]: newId },
        },
        updater: (store) => {
          const updatedDelivery = store.getRootField("update_delivery_by_pk");
          const deliveryConn = ConnectionHandler.getConnection(
            updatedDelivery,
            "GCProjectCalendarSitedeliveryDeliveries_delivery_connection",
          );
          const field = updatedDelivery.getLinkedRecord(fieldName);
          if (deliveryConn) {
            deliveryConn.setLinkedRecord(field, fieldName);
          }
        },
      });
    } else if (currentText && currentText.text !== newText) {
      return updateDeliveryTexts({
        variables: {
          id: currentText.id,
          _set: {
            original: newText,
            en: newText,
          },
        },
        optimisticResponse: {
          update_text_translation_by_pk: {
            id: currentText.relayId,
            original: newText,
            en: newText,
          },
        },
      });
    }
  };

  return { manageDeliveryTexts };
};

export default useManageDeliveryTexts;
