import { message } from "antd";
import useAuthUser from "src/common/hooks/useAuthUser";
import {
  GetObservationRequirementsDataDocument,
  GetObservationRequirementsDataQuery,
  GetObservationRequirementsDataQueryVariables,
  useInsertObservationRequirementMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import dayjs from "dayjs";
import * as uuid from "uuid";

export type ObsReqFormVals = {
  group_name: string;
  audits_required: number;
  findings_per_audit: number;
  observations_required: number;
  employee_titles: string[];
};
const useInsertObsReq = (gcId: string) => {
  const authUser = useAuthUser();
  const [insertObsRequirement, { loading: inserting }] =
    useInsertObservationRequirementMutation();
  const insertObsReq = async (vals: ObsReqFormVals) => {
    if (!vals.group_name.trim()) {
      message.error("Group name cannot be empty");
      return;
    }
    const id = uuid.v4();
    const obj = {
      id,
      group_name: vals.group_name,
      audits_required: vals.audits_required,
      observations_required: vals.observations_required,
      findings_per_audit: vals.findings_per_audit,
      employee_titles: {
        data: vals.employee_titles.map((titleId) => ({
          employee_title_id: titleId,
          id: uuid.v4(),
        })),
      },
    };
    return await insertObsRequirement({
      variables: {
        object: {
          ...obj,
          general_contractor_id: gcId,
          created_by_uid: authUser.uid,
        },
        deleteObsEmpTitleWhere: {
          employee_title_id: { _in: vals.employee_titles },
          observation_requirement: {
            archived_at: { _is_null: true },
            id: { _neq: obj.id },
          },
        },
      },
      optimisticResponse: {
        insert_observation_requirement_one: {
          __typename: "observation_requirement",
          ...obj,
          employee_titles: obj.employee_titles.data.map((em) => ({
            __typename: "observation_requirement_employee_title",
            ...em,
          })),
        },
        delete_observation_requirement_employee_title: { affected_rows: 0 },
      },
      update: (cache, result) => {
        const insertedItem = result.data?.insert_observation_requirement_one;
        if (!insertedItem)
          throw new Error("Server return null for inserting new requirement");
        const currData = cache.readQuery<
          GetObservationRequirementsDataQuery,
          GetObservationRequirementsDataQueryVariables
        >({
          query: GetObservationRequirementsDataDocument,
          variables: {
            where: {
              general_contractor_id: { _eq: gcId },
              archived_at: { _is_null: true },
            },
          },
        });
        console.log(currData);
        if (currData) {
          currData.observation_requirement.forEach((req) => {
            if (req.id !== obj.id)
              cache.modify<typeof req>({
                id: cache.identify(req),
                fields: {
                  employee_titles: (currList, { readField }) =>
                    currList.filter((reqTitle) => {
                      const empTitleId = readField(
                        "employee_title_id",
                        reqTitle,
                      );
                      return (
                        typeof empTitleId === "string" &&
                        !vals.employee_titles.includes(empTitleId)
                      );
                    }),
                },
              });
          });
        }
        cache.modify<GetObservationRequirementsDataQuery>({
          fields: {
            observation_requirement: (currList = [], { toReference }) => {
              const insertedItemRef = toReference(insertedItem);
              if (!insertedItemRef) return currList;
              return [insertedItemRef, ...currList];
            },
          },
        });
      },
    });
  };
  return [insertObsReq, inserting] as const;
};
export default useInsertObsReq;
