import { DownloadOutlined } from "@ant-design/icons";
import { Button, DatePicker, Drawer, Form, Select } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import MorningManPowerEmail from "src/common/components/MorningManPowerReportEmail";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import { useManPowerReportDownloadMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { GCProjectReportsDownloadQuery$data } from "src/common/types/generated/relay/GCProjectReportsDownloadQuery.graphql";
import MorningManPowerReportDownloadAuto from "../MorningManPowerReportDownloadAuto";

const MorningManPowerReportCreator: React.FC<{
  visible: boolean;
  handleClose: () => void;
  projectId: string;
  data: GCProjectReportsDownloadQuery$data;
}> = ({ visible, handleClose, projectId, data }) => {
  const [form] = Form.useForm();
  const [date, setDate] = useState<dayjs.Dayjs | null>(dayjs());
  const today = dayjs();

  const [manPowerReportDownload, { loading: manPowerReportLoading }] =
    useManPowerReportDownloadMutation();

  const selectedUsers = data.email_project_user_connection.edges;
  const projectEmployees = data.project_employee_connection.edges;
  const projectData = data.project_connection.edges[0].node;

  const subcontractorData = data.project_subcontractor_connection.edges;
  const subIds = new Array<string>();
  subcontractorData.map((t) => {
    subIds.push(t.node.subcontractor_id);
  });

  const disabledDate = (current: Dayjs) => {
    return current > today;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDate(null);
    }
  };

  const handleDownload = async () => {
    const values = await form.validateFields();
    let subIdsToSend = new Array<string>();
    if (values.subId === "all") subIdsToSend = [...subIds];
    else {
      subIdsToSend.push(values.subId);
    }
    if (values.downloadDate) {
      const res = await manPowerReportDownload({
        variables: {
          input: {
            projectId,
            subcontractorIds: subIdsToSend,
            onsiteDate: values.downloadDate,
          },
        },
      });

      if (res.data?.manPowerReportDownload) {
        downloadFromUrl(res.data?.manPowerReportDownload);
      }

      handleClose();
    }
  };

  return (
    <Drawer
      title="Report Creator"
      width={"60%"}
      open={visible}
      onClose={handleClose}
      destroyOnClose
      footer={
        <div className="flex gap-1">
          <Button loading={manPowerReportLoading} onClick={handleClose}>
            Close
          </Button>
          <Button
            type={"primary"}
            icon={<DownloadOutlined />}
            loading={manPowerReportLoading}
            onClick={handleDownload}
          >
            Download
          </Button>
        </div>
      }
    >
      <MorningManPowerEmail />
      <br />
      <MorningManPowerReportDownloadAuto
        defaultUsers={selectedUsers.map((user) => {
          return {
            userId: user.node.user?.pk ?? "No user id",
            emailProjectUserId: user.node.pk,
            name: user.node.user?.name,
          };
        })}
        projectEmployee={projectEmployees.map((employee) => ({
          value: employee.node.employee.user.pk,
          key: employee.node.employee.user.pk,
          label: employee.node.employee.user.name ?? "UNKNOWN",
        }))}
        projectData={projectData.morning_man_power_report_email_time}
      />

      <Form
        form={form}
        initialValues={{
          subId: "all",
          downloadDate: dayjs(),
        }}
      >
        <Form.Item
          name="downloadDate"
          label={
            <div className="text-1">Select a Date to download Report.</div>
          }
          rules={[
            { required: true, message: "Select a Date to Download Report" },
          ]}
        >
          <DatePicker
            className="min-w-12"
            value={date}
            disabledDate={disabledDate}
            onChange={(e) => {
              setDate(e);
            }}
            onOpenChange={onOpenChange}
          />
        </Form.Item>
        <Form.Item
          name="subId"
          label={
            <div className="text-1">
              Select Subcontractor to Download Report
            </div>
          }
        >
          <Select className="w-full">
            <Select.Option key="all" value="all">
              All
            </Select.Option>
            {(subcontractorData || []).map((t) => (
              <Select.Option
                key={t.node.subcontractor_id}
                value={t.node.subcontractor_id}
              >
                {t.node.subcontractor.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
export default MorningManPowerReportCreator;
