import {
  Button,
  Checkbox,
  Descriptions,
  Form,
  Input,
  message,
  Modal,
  Popover,
  Radio,
  Select,
  Space,
  Upload,
} from "antd";
import React, {
  FC,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import StyledContent from "src/common/components/layouts/StyledContent";
import { GCProjectOrientationDetailQuery } from "src/common/types/generated/relay/GCProjectOrientationDetailQuery.graphql";

import {
  AlibabaOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { UploadRequestOption } from "rc-upload/lib/interface";

import * as uuid from "uuid";
import Item from "antd/lib/descriptions/Item";
import { UploadFile } from "antd/lib/upload/interface";
import { graphql } from "babel-plugin-relay/macro";
import JoditEditor from "jodit-react";
import dayjs from "dayjs";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SelectorStoreUpdater } from "relay-runtime";
import apolloClient from "src/common/api/apollo/apolloClient";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import uploadFile from "src/common/functions/upload-utility/uploadFile";
import {
  GetSpeechFromTextDocument,
  GetSpeechFromTextQuery,
  GetSpeechFromTextQueryVariables,
  TtsVoiceGender,
} from "src/common/types/generated/apollo/graphQLTypes";
import {
  GCProjectOrientationDetail_DeleteSlide_Mutation,
  GCProjectOrientationDetail_DeleteSlide_Mutation$data,
} from "src/common/types/generated/relay/GCProjectOrientationDetail_DeleteSlide_Mutation.graphql";
import { GCProjectOrientationDetail_DeleteOrientation_Mutation } from "src/common/types/generated/relay/GCProjectOrientationDetail_DeleteOrientation_Mutation.graphql";
import {
  GCProjectOrientationDetail_InsertSlide_Mutation,
  GCProjectOrientationDetail_InsertSlide_Mutation$data,
} from "src/common/types/generated/relay/GCProjectOrientationDetail_InsertSlide_Mutation.graphql";
import {
  GCProjectOrientationDetail_UpdateSlide_Mutation,
  GCProjectOrientationDetail_UpdateSlide_Mutation$data,
} from "src/common/types/generated/relay/GCProjectOrientationDetail_UpdateSlide_Mutation.graphql";
import { agcCompanyId } from "src/common/hooks/useCheckAGCUser";
import { getAllTranslations } from "src/common/functions/getTranslations";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import getVideoFrameUrl from "../../../../../../common/components/media-players/video-player/utils/getVideoFrameUrl";
import { Language } from "../../../../../../utility-features/i18n/language-utils/i18nTypes";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import Icon from "src/common/components/general/Icon";
import { IconPlayerPlay } from "@tabler/icons";

interface OrientationHeaderProps {
  name: string;
  editable: boolean;
  created_at: number | string;
  created_by: string;
  lang: Language;
  isDeletingOrientation: boolean;
  qr_code_link: string;
  onLanguageChange: (lang: Language) => void;
  onDelete: () => void;
}

const Languages: Array<{ label: string; value: Language }> = [
  { label: "English", value: "en" },
  { label: "Spanish", value: "es" },
  { label: "Portuguese", value: "pt" },
  { label: "Italian", value: "it" },
  { label: "French", value: "fr" },
  { label: "Mandarin", value: "zh_hans" },
];

const LanguageList: Language[] = ["en", "es", "pt", "it", "fr", "zh_hans"];
type LanguageSuffix = "" | `_${Exclude<Language, 'en'>}`;

const LangToLangSuffix = (lang: Language): LanguageSuffix => {
  return lang === "en" ? "" : `_${lang}`;
}


let linkToQRCode = new Map<string, string>();

const OrientationHeader: FC<OrientationHeaderProps> = ({
  name,
  editable,
  created_at,
  created_by,
  lang,
  qr_code_link,
  isDeletingOrientation,
  onLanguageChange,
  onDelete,
}) => {
  const [qrImage, setQrImage] = useState<string>(
    linkToQRCode.get(qr_code_link) || "",
  );

  return (
    <StyledContent padding backgroundColor="white">
      <Descriptions
        size="middle"
        title={
          "Orientation details" +
          (editable
            ? ""
            : " (you do not have permissions to modify this Orientation module)")
        }
        bordered
      >
        <Item label="Name">{name}</Item>
        <Item label="Created at">
          {dayjs(created_at).format("MMMM Do YYYY")}
        </Item>
        <Item label="Created by">{created_by}</Item>
        <Item label="Language">
          <Radio.Group
            options={Languages}
            onChange={(e) => onLanguageChange(e.target.value)}
            value={lang}
            optionType="button"
            buttonStyle="solid"
          />
        </Item>
        <Item>
          <div style={{ width: 180 }}>
            <a target="blank" href={qr_code_link} className="underline">
              {qrImage ? (
                <img src={qrImage} />
              ) : (
                <div className="flex flex-row">
                  Test Orientation Module <Icon icon={IconPlayerPlay} />
                </div>
              )}
            </a>
          </div>
        </Item>
        {editable && (
          <Item>
            <BPopconfirm
              onConfirm={onDelete}
              title={
                <div className="w-16">
                  This will delete this Orientation module and related slides.
                  Workers will no longer see these slides.
                  <br /> Are you sure?
                </div>
              }
              cancelText="Cancel"
            >
              <Button type="primary" danger loading={isDeletingOrientation}>
                Delete orientation
              </Button>
            </BPopconfirm>
          </Item>
        )}
      </Descriptions>
    </StyledContent>
  );
};

type ListDragState = {
  id: string;
  from: number;
  to: number;
} | null;

function getDeltaY(dragState: ListDragState, index: number, height: number) {
  if (dragState === null) return 0;
  if (index == dragState.from) return (dragState.to - dragState.from) * height;
  if (dragState.from < dragState.to) {
    return dragState.from < index && index <= dragState.to ? -height : 0;
  } else {
    return dragState.to <= index && index < dragState.from ? height : 0;
  }
}

function getPlaceholderStyle(
  dragState: ListDragState,
  height: number,
): React.CSSProperties {
  if (dragState === null) return { position: "absolute" };
  return {
    borderLeft: "2px solid #1890ff",
    backgroundColor: "#e6f7ff",
    width: "100%",
    position: "absolute",
    height: height,
    transform: `translate(0px, ${height * dragState.to}px)`,
  };
}

type SlideArray =
  GCProjectOrientationDetailQuery["response"]["orientation_connection"]["edges"][number]["node"]["slides"];
type SlideRecord = SlideArray[number];

interface SlideListProps {
  slides: SlideArray;
  selectedSlideId?: string | null;
  lang: Language;
  editable: boolean;
  onOrderChange: (from: number, to: number, newSlides: SlideArray) => void;
  onSlideDelete: (id: string, slideId: string) => void;
  onSlideEdit: (slide: SlideRecord) => void;
}

const SlideList: FC<SlideListProps> = ({
  slides,
  selectedSlideId,
  lang,
  editable,
  onOrderChange,
  onSlideDelete,
  onSlideEdit,
}) => {
  const [dragState, setDragState] = useState<ListDragState>(null);
  const itemHeight = useRef(22);

  const getStyle = (id: string) => {
    if (dragState) {
      if (dragState.id === id)
        return { backgroundColor: "#bae7ff", paddingLeft: 2, zIndex: 2000 };
      return { transition: "transform 0.5s", paddingLeft: 2 };
    }
    if (selectedSlideId === id) {
      return {
        borderLeft: "2px solid #1890ff",
        backgroundColor: "#e6f7ff",
      };
    }
    return { paddingLeft: 2 };
  };

  const moveItem = (from: number, to: number) => {
    if (from === to) return;
    const item = slides[from];

    const newItems = slides.filter((i) => i !== item);
    newItems.splice(to, 0, item);
    onOrderChange?.(from, to, newItems);
  };

  const onStart = (
    e: DraggableEvent,
    d: DraggableData,
    item: (typeof slides)[number],
    index: number,
  ) => {
    const rect = d.node.getBoundingClientRect();
    itemHeight.current = rect.height || rect.bottom - rect.top;
    setDragState({ id: item.slide_id, from: index, to: index });
  };

  const onDrag = (e: DraggableEvent, d: DraggableData, index: number) => {
    const newIndex = Math.min(
      (Math.max(d.y + index * itemHeight.current, 0) / itemHeight.current) | 0,
      slides.length - 1,
    );
    if (
      dragState !== null &&
      (dragState.from !== index || dragState.to !== newIndex)
    ) {
      setDragState({ id: dragState.id, from: index, to: newIndex });
    }
  };
  const onStop = (e: DraggableEvent) => {
    //    console.log("Stopped");
    if (dragState !== null) {
      setDragState({ ...dragState, id: "" });
      setTimeout(() => {
        setDragState(null);
        moveItem(dragState.from, dragState.to);
      }, 500);
    }
  };

  console.log("rerender ", dragState, editable);
  const listItems = slides.map((item, index) => {
    return editable ? (
      <Draggable
        key={item.pk}
        position={{ x: 0, y: getDeltaY(dragState, index, itemHeight.current) }}
        onStart={(e, d) => onStart(e, d, item, index)}
        onDrag={(e, d) => onDrag(e, d, index)}
        onStop={onStop}
        handle=".anticon"
      >
        <div style={getStyle(item.slide_id)}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {item.content_type === "question" ? (
              <QuestionCircleOutlined
                style={{ display: "flex", marginLeft: 4 }}
              />
            ) : item.content_type === "signature" ? (
              <AlibabaOutlined style={{ display: "flex", marginLeft: 4 }} />
            ) : (
              <MenuOutlined style={{ display: "flex", marginLeft: 4 }} />
            )}
            <span
              style={{
                padding: "0px 10px",
                flexGrow: 1,
                cursor: "pointer",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              onClick={() => onSlideEdit(item)}
            >
              {lang === "en" ? item.title : item[`title_${lang}`] || item.title}
            </span>
            {/*  <Button type="link" style={{paddingRight: 5}} onClick={() => onSlideDelete(item.pk)}>delete</Button>*/}
            <Button
              type="link"
              style={{ paddingRight: 10 }}
              onClick={() => onSlideEdit(item)}
            >
              edit
            </Button>
          </div>
        </div>
      </Draggable>
    ) : (
      <div style={getStyle(item.slide_id)}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {item.content_type === "question" ? (
            <QuestionCircleOutlined
              style={{ display: "flex", marginLeft: 4 }}
            />
          ) : item.content_type === "signature" ? (
            <AlibabaOutlined style={{ display: "flex", marginLeft: 4 }} />
          ) : (
            <MenuOutlined style={{ display: "flex", marginLeft: 4 }} />
          )}
          <span
            style={{
              padding: "0px 10px",
              flexGrow: 1,
              cursor: "pointer",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            onClick={() => onSlideEdit(item)}
          >
            {lang === "en" ? item.title : item[`title_${lang}`] || item.title}
          </span>
          {/*  <Button type="link" style={{paddingRight: 5}} onClick={() => onSlideDelete(item.pk)}>delete</Button>*/}
          <Button
            type="link"
            style={{ paddingRight: 10 }}
            onClick={() => onSlideEdit(item)}
          >
            edit
          </Button>
        </div>
      </div>
    );
  });
  return (
    <div style={{ position: "relative", width: "350px" }}>
      <div style={getPlaceholderStyle(dragState, itemHeight.current)}>
        &#8203;
      </div>
      {listItems}
    </div>
  );
};

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

interface ImageSelectorProps {
  imageUrl?: string;
  readonly: boolean;
  onChange?: (url: string) => void;
}

const ImageSelector: FC<ImageSelectorProps> = ({
  imageUrl,
  onChange,
  readonly,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>(
    imageUrl
      ? [
          {
            uid: "1",
            size: 100,
            type: "png",
            name: "slide.png",
            status: "done",
            url: imageUrl,
          },
        ]
      : [],
  );

  return (
    <Upload.Dragger
      disabled={readonly}
      customRequest={async (options: UploadRequestOption) => {
        // console.log('req ', options);
        //        const buf = await getBase64(param.file);
        const optionsFile = options.file;
        if (
          typeof optionsFile !== "object" ||
          !(
            "size" in optionsFile &&
            "type" in optionsFile &&
            "name" in optionsFile
          )
        )
          return;

        setFileList([
          {
            uid: "1",
            size: optionsFile.size,
            type: optionsFile.type,
            name: optionsFile.name,
            status: "uploading",
          },
        ]);
        // console.log(options);
        const file = await uploadFile(optionsFile);
        const imgUrl = file?.url;
        setFileList([
          {
            uid: "1",
            size: optionsFile.size,
            type: optionsFile.type,
            name: optionsFile.name,
            status: "done",
            url: imgUrl,
          },
        ]);
        onChange?.(imgUrl || "");
      }}
      listType="picture"
      accept=".png,.jpg,.jpeg"
      //    iconRender={() => <AntCloudOutlined />}
      fileList={fileList}
      maxCount={1}
      onChange={(param) => {
        setFileList(param.fileList);
      }}
      name="slide_image"
    >
      {fileList.length === 0 || !fileList[0].url ? (
        <>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p>Click or drag into this area to upload slide image</p>
        </>
      ) : (
        <img style={{ maxWidth: "100%" }} src={fileList[0].url} />
      )}
    </Upload.Dragger>
  );
};

interface VideoSelectorProps {
  videoFieldName: `video_url${LanguageSuffix}`;
  readonly: boolean;
}

const VideoSelector: FC<VideoSelectorProps> = ({
  videoFieldName,
  readonly,
}) => {
  return (
    <>
      <Form.Item
        name={videoFieldName}
        label="Vimeo or Youtube video url"
        rules={[{ required: true, message: "Video url is required" }]}
      >
        {readonly ? (
          <div className="ml-2">Video Link Here</div>
        ) : (
          <Input />
        )}
      </Form.Item>
      <Form.Item noStyle dependencies={[videoFieldName]}>
        {({ getFieldValue }) => {
          const url = getFieldValue(videoFieldName);
          if (!url) return null;
          // console.log("playbackId = ", playbackId);
          return (
            <>
              {!readonly && (
                <iframe
                  id="ytplayer"
                  width="640"
                  height="360"
                  src={getVideoFrameUrl(url)}
                  frameBorder="0"
                ></iframe>
              )}
              <Form.Item name="watch_full_video" valuePropName="checked">
                <Checkbox disabled={readonly}>
                  User must watch full video before continue
                </Checkbox>
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
    </>
  );
};

function hasModifiedFields(
  newObj: { readonly [key: string]: any },
  prevObj: { readonly [key: string]: any },
) {
  for (let key in newObj) {
    if (newObj[key] !== prevObj[key]) {
      // console.log(`field ${key} is modified prev = ${prevObj[key]}, new = ${newObj[key]}`);
      return true;
    }
  }
  return false;
}

const speechCache = new Map<string, string>();

const langCodes: { [key: string]: string } = {
  en: "en-US",
  es: "es-ES",
  pt: "pt-PT",
  it: "it-IT",
  fr: "fr-FR",
  zh_Hans: "yue-HK",
};

async function getSpeechFromText(
  text: string,
  lang: string,
  gender: TtsVoiceGender,
  voice_name?: string,
) {
  if (!text) return undefined;
  const speechKey = `${lang}_${gender}_${voice_name}_${text}`;
  const speechUrl = speechCache.get(speechKey);
  if (speechUrl) return speechUrl;
  const res = await apolloClient.query<
    GetSpeechFromTextQuery,
    GetSpeechFromTextQueryVariables
  >({
    query: GetSpeechFromTextDocument,
    variables: {
      input: {
        text,
        lang: langCodes[lang],
        voice_gender: gender,
        voice_name,
      },
    },
  });
  const url = res.data?.getSpeechFromText?.url;
  if (url) {
    speechCache.set(speechKey, url);
  }
  return url;
}

const SlideTypes = [
  { label: "Image", value: "image" },
  { label: "Video", value: "video" },
  { label: "Text", value: "text" },
  // { label: "Signature", value: "signature" },
];

const buttons = [
  "bold",
  "strikethrough",
  "underline",
  "italic",
  "|",
  "ul",
  "ol",
  "|",
  "outdent",
  "indent",
  "|",
  "paragraph",
  "font",
  "fontsize",
  "brush",
  "\n",
  "align",
  "undo",
  "redo",
  "|",
  "image",
  "video",
  "table",
  "link",
  "|",
  "hr",
  "eraser",
  "|",
  "symbol",
  "fullsize",
];

export const JoditConfig = {
  readonly: false,
  extraButtons: [],
  toolbar: true,
  statusbar: false,
  buttonsMD: buttons,
  buttonsSM: buttons,
  buttonsXS: buttons,
  buttons: buttons,
  /*    */
};

// https://cloud.google.com/text-to-speech/docs/voices
const EsVoices = {
  /*
  'es-ES-Standard-A': 'Standard Voice A (Female)', // FEMALE
  'es-ES-Standard-B': 'Standard Voice B (Male)',   // MALE
  'es-ES-Standard-C': 'Standard Voice C (Female)', // FEMALE
  'es-ES-Standard-D': 'Standard Voice D (Female)', // FEMALE
*/
  "es-ES-Wavenet-B": "Wavenet Voice B (Male)", // MALE
  "es-ES-Wavenet-C": "Wavenet Voice C (Female)", // FEMALE
  "es-ES-Wavenet-D": "Wavenet Voice D (Female)", // FEMALE
  /*
  'es-US-Neural2-A': 'Neural2 (Male)',                // FEMALE
  'es-US-Standard-A': 'Standard Voice A (US Female)', // FEMALE
  'es-US-Standard-B': 'Standard Voice B (US Male)',   // MALE
  'es-US-Standard-C': 'Standard Vocie C (US Male)',   // MALE
*/
  "es-US-Wavenet-A": "Wavenet Voice A (US Female)", // FEMALE
  "es-US-Wavenet-B": "Wavenet Voice B (US Male)", // MALE
  "es-US-Wavenet-C": "Wavenet Voice C (US Male)", // MALE
};
const PtVoices = {
  "pt-PT-Wavenet-A": "Wavenet Voice A (PT Female)", //	FEMALE
  "pt-PT-Wavenet-B": "Wavenet Voice B (PT Male)", //	MALE
  "pt-PT-Wavenet-C": "Wavenet Voice C (PT Male)", //	MALE
  "pt-PT-Wavenet-D": "Wavenet Voice D (PT Female)", //	FEMALE
};
const ItVoices = {
  "it-IT-Neural2-A": "Neural Voice A (IT Female)", //	FEMALE
  "it-IT-Neural2-C": "Neural Voice C (IT Male)", //	MALE
  "it-IT-Wavenet-A": "Wavenet Voice A (IT Female)", //	FEMALE
  "it-IT-Wavenet-B": "Wavenet Voice B (IT Male)", //	MALE
  "it-IT-Wavenet-C": "Wavenet Voice C (IT Female)", //	FEMALE
  "it-IT-Wavenet-D": "Wavenet Voice D (IT Male)", //	MALE
  "it-IT-Standard-A": "Standard Voice A (IT Female)", //	FEMALE
  "it-IT-Standard-C": "Standard Voice C (IT Male)", //	MALE
};
const FrVoices = {
  "fr-FR-Neural2-A": "Neural Voice A (FR Female)", //	FEMALE
  "fr-FR-Neural2-B": "Neural Voice B (FR Male)", //	MALE
  "fr-FR-Neural2-C": "Neural Voice C (FR Female)", //	FEMALE
  "fr-FR-Neural2-D": "Neural Voice D (FR Male)", //	MALE
  "fr-FR-Wavenet-A": "Wavenet Voice A (FR Female)", //	FEMALE
  "fr-FR-Wavenet-B": "Wavenet Voice B (FR Male)", //	MALE
  "fr-FR-Wavenet-C": "Wavenet Voice C (FR Female)", //	FEMALE
  "fr-FR-Wavenet-D": "Wavenet Voice D (FR Male)", //	MALE
};
const ZhHansVoices = {
  "yue-HK-Standard-A": "Standard Voice A (ZH Female)", //	FEMALE
  "yue-HK-Standard-B": "Standard Voice B (ZH Male)", //	MALE
  "yue-HK-Standard-C": "Standard Voice C (ZH Female)", // FEMALE
  "yue-HK-Standard-D": "Standard Voice D (ZH Male)", //	MALE
};

const EnVoices = {
  /*  'en-US-Neural2-A': 'Neural2 A (Male)',           // FEMALE
  'en-US-Standard-A': 'Standard Voice A (Male)',   // MALE
  'en-US-Standard-B': 'Standard Voice B (Male)',   // MALE
  'en-US-Standard-C': 'Standard Voice C (Female)', // FEMALE
  'en-US-Standard-D': 'Standard Voice D (Male)',   // MALE
  'en-US-Standard-E': 'Standard Voice E (Female)', // FEMALE
  'en-US-Standard-F': 'Standard Voice F (Female)', // FEMALE
  'en-US-Standard-G': 'Standard Voice G (Female)', // FEMALE
  'en-US-Standard-H': 'Standard Voice H (Female)', // FEMALE
  'en-US-Standard-I': 'Standard Voice I (Male)',   // MALE
  'en-US-Standard-J': 'Standard Voice J (Male)',   // MALE
*/
  "en-US-Wavenet-A": "Wavenet Voice A (Male)", // MALE
  "en-US-Wavenet-B": "Wavenet Voice B (Male)", // MALE
  "en-US-Wavenet-C": "Wavenet Voice C (Female)", // FEMALE
  "en-US-Wavenet-D": "Wavenet Voice D (Male)", // MALE
  "en-US-Wavenet-E": "Wavenet Voice E (Female)", // FEMALE
  "en-US-Wavenet-F": "Wavenet Voice F (Female)", // FEMALE
  "en-US-Wavenet-G": "Wavenet Voice G (Female)", // FEMALE
  "en-US-Wavenet-H": "Wavenet Voice H (Female)", // FEMALE
  "en-US-Wavenet-I": "Wavenet Voice I (Male)", // MALE
  "en-US-Wavenet-J": "Wavenet Voice J (Male)", // MALE
};

const DefaultVoiceEn = "en-US-Wavenet-J";
const DefaultVoiceEs = "es-US-Wavenet-C";
const DefaultVoicePt = "pt-PT-Wavenet-B";
const DefaultVoiceIt = "it-IT-Wavenet-B";
const DefaultVoiceFr = "fr-FR-Wavenet-B";
const DefaultVoiceZhHans = "yue-HK-Standard-B";

interface SlideFieldsProps {
  lang: Language;
  readonly: boolean;
}

const getSpeakButtonCaption = (lang: Language) => {
  switch (lang) {
    case "es": return "Speak (Spanish)";
    case "pt": return "Speak (Portuguese)";
    case "it": return "Speak (Italian)";
    case "fr": return "Speak (French)";
    case "zh_hans": return "Speak (Mandarin)";
    default: return "Speak";  
  }
}

const getLangVoices = (lang: Language) => {
  switch (lang) {
    case "en": return EnVoices;
    case "es": return EsVoices;
    case "pt": return PtVoices;
    case "it": return ItVoices;
    case "fr": return FrVoices;
    case "zh_hans": return ZhHansVoices;
  }
}

const SlideFields: FC<SlideFieldsProps> = ({
  lang,
  readonly,
}) => {
  const [speechState, setSpeechState] = useState<"" | "loading" | "playing">("");
  const music = useRef<HTMLAudioElement | null>(null);
  const langSuffix = LangToLangSuffix(lang);

  return (
    <div>
      <Form.Item
        name={`title${langSuffix}`}
        label="Slide title"
        rules={[{ required: true, message: "Slide title is required" }]}
      >
        <Input
          disabled={readonly}
        />
      </Form.Item>
      <Form.Item
        name="content_type"
        label="Content Type"
        rules={[{ required: true, message: "Please select content type" }]}
      >
        <Radio.Group
          options={SlideTypes}
          optionType="button"
          disabled={readonly}
        />
      </Form.Item>
      <Form.Item noStyle dependencies={["content_type"]}>
        {(form) => {
          const type = form.getFieldValue("content_type");
          if (type === "image") {
            return (
              <Form.Item
                name={`image_url${langSuffix}`}
                label="Slide Image"
                valuePropName="imageUrl"
              >
                <ImageSelector readonly={readonly} />
              </Form.Item>
            );
          } else if (type === "video") {
            return (
              <VideoSelector
                videoFieldName={`video_url${langSuffix}`}
                readonly={readonly}
              />
            );
          } else if (type === "text" || type === "signature") {
            return (
              <Form.Item
                name={`text${langSuffix}`}
                label={type === "signature" ? "Text on signature page" : "Text"}
              >
                <JoditEditor
                  value=""
                  config={{
                    ...JoditConfig,
                    readonly,
                  }}
                />
              </Form.Item>
            );
          }
        }}
      </Form.Item>
      <Form.Item name={`text_to_speak${langSuffix}`} label="Text to speak">
        <Input.TextArea rows={6} disabled={readonly} />
      </Form.Item>
      <div>
        <Form.Item noStyle dependencies={[`text_to_speak${langSuffix}`]}>
          {(form) => (
            <Button
              loading={speechState === "loading"}
              disabled={!form.getFieldValue(`text_to_speak${langSuffix}`)}
              //icon={ <CaretRightOutlined />}
              onClick={async () => {
                if (speechState === "loading") return;
                if (music.current) {
                  music.current.pause();
                  music.current = null;
                  return;
                }
                  try {
                    const text = form.getFieldValue(
                      "text_to_speak" + langSuffix,
                    );
                    const gender =
                      form.getFieldValue("voice_gender") ?? TtsVoiceGender.Neutral;
                    const voice_name = form.getFieldValue(
                      "voice_name" + langSuffix,
                    );
                    // console.log("speak text", text);
                    setSpeechState("loading");
                    const url =
                      (await getSpeechFromText(
                        text,
                        lang,
                        gender,
                        voice_name,
                      )) || "";
                    form.setFieldsValue({ [`voice_url${langSuffix}`]: url });
                    // console.log("audio url = ", url);

                    music.current = new Audio(url);
                    music.current.addEventListener("loadeddata", () => {
                      setSpeechState("playing");
                    }, { once: true });
                    await music.current.play();
                  } finally {
                    music.current = null;
                    setSpeechState("");
                  }
              }}
            >
              {getSpeakButtonCaption(lang)}
            </Button>
          )}
        </Form.Item>
        {/* <Form.Item noStyle name={"voice_gender"}>
              <Select
                style={{
                  width: 100,
                  marginLeft: '10px',
                }}
              >
                <Select.Option value={TtsVoiceGender.Female}>Female</Select.Option>
                <Select.Option value={TtsVoiceGender.Male}>Male</Select.Option>
                <Select.Option value={TtsVoiceGender.Neutral}>Neutral</Select.Option>
              </Select>
              </Form.Item>*/}
        <Form.Item noStyle name={"voice_name" + langSuffix}>
          <Select
            style={{
              width: 240,
              marginLeft: "10px",
            }}
            disabled={readonly}
          >
            {Object.entries(getLangVoices(lang)).map(([k, v]) => (
              <Select.Option key={k} value={k}>
                {v}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
    </div>
  );
};

interface QuestionFieldsProps {
  lang: Language;
  readonly: boolean;
}

const QuestionUIControlTypes = [
  { label: "Text", value: "text" },
  { label: "Checkboxes", value: "checkboxes" },
  { label: "Dropdown", value: "dropdown" },
  { label: "Buttons", value: "radio" },
];

const DefaultAnswerList = "answer 1\n" + "answer 2\n" + "answer 3\n";

const QuestionFields: FC<QuestionFieldsProps> = ({
  lang,
  readonly,
}) => {
  const langSuffix = LangToLangSuffix(lang);
  const titleField = `title${langSuffix}`;
  return (
    <div>
      <Form.Item
        name={titleField}
        label="Question text"
        rules={[{ required: true, message: "Question text is required" }]}
      >
        <Input disabled={readonly} />
      </Form.Item>
      <Form.Item
        name="question_ui_element"
        label="Answer UI element"
        rules={[{ required: true, message: "Please select answer type" }]}
      >
        <Radio.Group
          options={QuestionUIControlTypes}
          optionType="button"
          disabled={readonly}
        />
      </Form.Item>
      <Form.Item noStyle dependencies={["question_ui_element"]}>
        {(form) => {
          const el_type = form.getFieldValue("question_ui_element");
          if (el_type === "text") return null;
          return (
            <Form.Item
              name={`answer_items${langSuffix}`}
              label="Answers"
              rules={[{ required: true, message: "Please select answer type" }]}
            >
              <Input.TextArea rows={4} disabled={readonly} />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item name="answer_required" valuePropName="checked">
        <Checkbox disabled={readonly}>Answer is required</Checkbox>
      </Form.Item>
      <Form.Item noStyle dependencies={["question_ui_element"]}>
        {(form) => {
          //const el_type = form.getFieldValue("question_ui_element");
          //if (el_type === "text")
          //  return null;
          return (
            <Form.Item name="check_correct_answer" valuePropName="checked">
              <Checkbox disabled={readonly}>
                Specify correct answer (provide additional learning dayjs by
                explaining why the answer is correct or incorrect - and - log
                quiz performance if a minimum score is required)
              </Checkbox>
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item noStyle dependencies={["check_correct_answer"]}>
        {(form) => {
          const check_answer = form.getFieldValue("check_correct_answer");
          //  console.log("check_answer", check_answer, `correct_answer_note${langSuffix}`, form.getFieldValue(`correct_answer_note${langSuffix}`), form.getFieldsValue());
          if (!check_answer) return null;
          return (
            <Form.Item
              name={`correct_answer_note${langSuffix}`}
              label="Explain correct answer:"
              style={{ display: check_answer ? "block" : "none" }}
            >
              <Input.TextArea disabled={readonly} />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item
        noStyle
        dependencies={[
          "question_ui_element",
          "answer_items",
          "answer_items_es",
          titleField,
          "check_correct_answer",
          "correct_answer_index",
          "correct_answers",
        ]}
      >
        {(form) => {
          const el_type = form.getFieldValue("question_ui_element");

          const items_as_text: string =
            form.getFieldValue(`answer_items${langSuffix}`) || "";

          //          console.log("render form", items_as_text);

          const items = items_as_text.split("\n").filter((s) => !!s);
          const question = form.getFieldValue(titleField);
          //const answer_req = form.getFieldValue("answer_required");
          const check_correct_answer = form.getFieldValue(
            "check_correct_answer",
          );
          //         const errors = form.getFieldError("correct_answers");
          //          console.log("errors", errors);
          //         if (errors?.length > 0) {
          //            form.validateFields(["correct_answers"]).catch(e => e);
          //          }
          //const correct_answers = form.getFieldValue("correct_answers");
          return (
            <div>
              <div style={{ padding: "5px" }}>Question preview:</div>
              <div
                style={{
                  border: "1px solid gray",
                  padding: "10px",
                  width: "100%",
                }}
              >
                <Form.Item
                  name={
                    el_type === "text"
                      ? "correct_answers"
                      : "correct_answer_index"
                  }
                  label={question}
                  dependencies={["check_correct_answer"]}
                  rules={[
                    {
                      required: check_correct_answer,
                      message: "The correct answer is required",
                    },
                  ]}
                >
                  {el_type === "radio" ? (
                    <Radio.Group
                      disabled={readonly}
                      options={items.map((a, idx) => ({
                        label: a,
                        value: idx,
                      }))}
                      optionType="button"
                    />
                  ) : el_type === "checkboxes" ? (
                    <Radio.Group>
                      <Space direction="vertical">
                        {items.map((answer, idx) => (
                          <Radio
                            disabled={readonly}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              whiteSpace: "normal",
                            }}
                            key={idx}
                            value={idx}
                          >
                            {answer}
                          </Radio>
                        ))}
                      </Space>
                    </Radio.Group>
                  ) : el_type === "dropdown" ? (
                    <Select style={{ width: "100%" }} disabled={readonly}>
                      {items.map((a, i) => (
                        <Select.Option key={i} value={i}>
                          {a}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : el_type === "text" ? (
                    <Input />
                  ) : null}
                </Form.Item>
                {check_correct_answer && "Select the correct answer"}
              </div>
            </div>
          );
        }}
      </Form.Item>
      {/*<Form.Item
      name="check_answer"
        valuePropName="checked"
      >
        <Checkbox>Check answer for correctness</Checkbox>
    </Form.Item>*/}
    </div>
  );
};

const slidesFieldParams = {
  where: { archived_at: { _is_null: true } },
  order_by: { order: "asc" },
};


type SlideTranslatableFields = {
  title: string | undefined;
  text: string | undefined;
  text_to_speak: string | undefined;
  video_url: string | undefined;
  image_url: string | undefined;
  answer_items: string | undefined;
  correct_answer_note: string | undefined;
}

type SlideNonTranslatableFields = {
  question_ui_element: "text" | "checkboxes" | "dropdown" | "radio";
  correct_answer_value: string;
}

type Localize<U, L> = { [K in keyof U as `${string & K}_${string & L}`]: U[K] }

type SlideForm =  SlideNonTranslatableFields & SlideTranslatableFields & Localize<SlideTranslatableFields, Exclude<Language, "en">>;

interface SlideEditorProps {
  slide: SlideRecord;
  editable: boolean;
  lang: Language;
  onSave: (slide: SlideRecord) => Promise<void>;
  onModified?: (dirty: boolean) => void;
  onDelete: (id: string, slideId: string) => void;
}

interface SlideEditorRef {
  isModified: () => boolean;
}

const SlideEditor = forwardRef<SlideEditorRef, SlideEditorProps>(
  ({ slide, lang, onSave, onModified, onDelete, editable }, ref) => {
    const [form] = Form.useForm<SlideForm>();
    const [saving, setSaving] = useState<boolean>(false);
    // convert null fields to default for existing records.
    // new records are create with default..
    // so remove useMemo when all old records will be converted to non-null

    const translateFromEnToLang = async (toLang: Language[]) => {
      const title = form.getFieldValue("title") || "";
      const text_to_speak =
        form.getFieldValue("text_to_speak") || "";
      const text = form.getFieldValue("text") || "";
      const answer_items =
        form.getFieldValue("answer_items") || "";
      const correct_answer_note =
        form.getFieldValue("correct_answer_note") || "";
      const translations = await getAllTranslations(
        [title, text_to_speak, text, answer_items, correct_answer_note],
        true,
      );
      const video_url_en = form.getFieldValue(`video_url`) || "";
      const image_url_en = form.getFieldValue(`image_url`) || "";
      for (lang of toLang) {
        if (lang === "en")
          continue;
        const langSuffix = LangToLangSuffix(lang);
        const video_url = form.getFieldValue(`video_url${langSuffix}`);
        if (!video_url)
          form.setFieldsValue({ [`video_url${langSuffix}`]: video_url_en });
        const image_url = form.getFieldValue(`image_url${langSuffix}`);
        if (!image_url)
          form.setFieldsValue({ [`image_url${langSuffix}`]: image_url_en });
        if (translations) {
          const title_cur_lang = translations[0][lang];
          const text_to_speak_cur_lang = translations[1][lang];
          const text_cur_lang = translations[2][lang];
          const answer_items_cur_lang = translations[3][lang];
          const correct_answer_note_cur_lang = translations[4][lang];
          form.setFieldsValue({
            [`title${langSuffix}`]: title_cur_lang,
            [`text_to_speak${langSuffix}`]: text_to_speak_cur_lang,
            [`text${langSuffix}`]: text_cur_lang,
            [`answer_items${langSuffix}`]: answer_items_cur_lang,
            [`correct_answer_note${langSuffix}`]: correct_answer_note_cur_lang,
          });
        } // TODO: else, display translation errror
      }
    };
    const currentSlide = useMemo(() => {
      if (slide.content_type === "question") {
        const correct_answer_index =
          slide.question_ui_element === "text"
            ? ""
            : (slide.answer_items || "")
                .split("\n")
                .indexOf((slide.correct_answers || "").trimEnd());

        return {
          ...slide,
          correct_answer_index,
        };
      }
      const {
        voice_name,
        voice_name_es,
        voice_name_pt,
        voice_name_fr,
        voice_name_it,
        voice_name_zh_hans,
      } = slide;
      if (
        voice_name &&
        voice_name_es &&
        voice_name_pt &&
        voice_name_fr &&
        voice_name_it &&
        voice_name_zh_hans
      )
        return slide;
      return {
        ...slide,
        voice_name: voice_name ?? DefaultVoiceEn,
        voice_name_es: voice_name_es ?? DefaultVoiceEs,
        voice_name_pt: voice_name_pt ?? DefaultVoicePt,
        voice_name_it: voice_name_it ?? DefaultVoiceIt,
        voice_name_fr: voice_name_fr ?? DefaultVoiceFr,
        voice_name_zh_hans: voice_name_zh_hans ?? DefaultVoiceZhHans,
      };
    }, [slide]);
    // console.log("slide.order = ", slide.order);

    useImperativeHandle(ref, () => ({
      isModified: () => hasModifiedFields(form.getFieldsValue(), currentSlide),
    }));
    const { pk, id, order, ...initialValues } = currentSlide;
    return (
      <div>
        <Form form={form} layout="vertical" initialValues={initialValues}>
          {editable && (
            <div
              style={{
                display: "flex",
                flexDirection: "row" /*, justifyContent: 'flex-end'*/,
                marginBottom: "10px",
              }}
            >
              <Form.Item noStyle shouldUpdate={true}>
                {(form) => {
                  // console.log("cur vaues = ", currentSlide);
                  const hasChanges = hasModifiedFields(
                    form.getFieldsValue(),
                    currentSlide,
                  );
                  onModified?.(hasChanges);
                  const onSaveChangesClick = async (
                    updateOtherLangsToo?: boolean,
                  ) => {
                      setSaving(true);
                      try {
                        // correct_answers_es is not stored to db.
                        // so exclude it from list of fields
                        if (lang === "en" && updateOtherLangsToo)
                          await translateFromEnToLang(LanguageList);

                        // this error depends on user...  and form notifes user by highliting required user
                        const fields = await form.validateFields().catch(v => null);
                        if (!fields) 
                            return;// TODO if required field is missing in another language... switch to that language..

                        // console.log(fields);

                        const { correct_answer_index = 0, ...values } = {
                          ...initialValues,
                          ...fields,
                        };
                        console.log(values);
                        if (
                          values.content_type === "question" &&
                          values.question_ui_element !== "text"
                        ) {
                          values.correct_answers =
                            (values.answer_items || "").split("\n")[
                              correct_answer_index
                            ] || "";
                        }

                        const voice_gender_en =
                          values["voice_gender"] || TtsVoiceGender.Neutral;
                        const speachFromTextTasks =  LanguageList.map((language) => {
                          const langSuffix = LangToLangSuffix(language);
                          const text = values[`text_to_speak${langSuffix}`] || "";
                          const voice_name = form.getFieldValue(
                            `voice_name${langSuffix}`,
                          );
                          return getSpeechFromText(
                            text,
                            language,
                            voice_gender_en,
                            voice_name,
                          );
                        });
                        const [
                          voice_url,
                          voice_url_es,
                          voice_url_pt,
                          voice_url_it,
                          voice_url_fr,
                          voice_url_zh_hans,
                        ] = await Promise.all(speachFromTextTasks);
                        console.log({
                          pk,
                          id,
                          order,
                          ...values,
                          voice_url,
                          voice_url_es,
                          voice_url_pt,
                          voice_url_it,
                          voice_url_fr,
                          voice_url_zh_hans,
                        });
                        // return;
                        await onSave({
                          pk,
                          id,
                          order,
                          ...values,
                          voice_url,
                          voice_url_es,
                          voice_url_pt,
                          voice_url_it,
                          voice_url_fr,
                          voice_url_zh_hans,
                        });
                      } finally {
                        setSaving(false);
                      }
                  };
                  return (
                    <div>
                      <Button
                        type="primary"
                        loading={saving}
                        style={{ marginRight: "10px" }}
                        disabled={!hasChanges || saving}
                        onClick={() => onSaveChangesClick(true)}
                      >
                        Save changes
                      </Button>
                      <Button
                        type="text"
                        style={{ marginRight: "10px" }}
                        disabled={!hasChanges}
                        onClick={() => {
                          form.resetFields();
                        }}
                      >
                        Reset changes
                      </Button>
                    </div>
                  );
                }}
              </Form.Item>

              {lang !== "en" && (
                <Button
                  type="link"
                  onClick={async () => {
                    await translateFromEnToLang([lang]);
                  }}
                >
                  Translate from eng
                </Button>
              )}
              <div style={{ flexGrow: 1 }} />

              <Button
                type="primary"
                danger
                style={{ marginLeft: "10px" }}
                onClick={() => onDelete(slide.pk, slide.slide_id)}
              >
                {slide.content_type === "question"
                  ? "Delete question"
                  : "Delete slide"}
              </Button>
            </div>
          )}
          {slide.content_type === "question"
            ? LanguageList.map((mapLang) => (
                <div key={mapLang} className={mapLang !== lang ? "hidden" : ""}>
                  <QuestionFields
                    readonly={!editable}
                    lang={mapLang}
                  />
                </div>
              ))
            : LanguageList.map((mapLang) => (
                <div key={mapLang} className={mapLang !== lang ? "hidden" : ""}>
                  <SlideFields
                    readonly={!editable}
                    lang={mapLang}
                  />
                </div>
              ))}
        </Form>
      </div>
    );
  },
);

const GetFullID = (table: string, id: string) =>
  btoa(`[1, "public", "${table}", "${id}"]`);

const insertSlideUpdater: SelectorStoreUpdater<
  GCProjectOrientationDetail_InsertSlide_Mutation$data
> = (store, payload) => {
  // console.log("insertSlideUpdater", payload);
  if (!payload || !payload.insert_orientation_slide_one) return;
  const orientationId = payload.insert_orientation_slide_one.orientation_id;
  const orientation = store.get(GetFullID("orientation", orientationId));
  if (!orientation) return;
  const node = store.getRootField("insert_orientation_slide_one");
  const slides = orientation.getLinkedRecords("slides", slidesFieldParams);
  orientation.setLinkedRecords(
    [...(slides || []), node],
    "slides",
    slidesFieldParams,
  );
};

const updateSlideUpdater: SelectorStoreUpdater<
  GCProjectOrientationDetail_UpdateSlide_Mutation$data
> = (store, payload) => {
  //  console.log(`${new Date().toISOString()} insertSlideUpdater`, payload);
  const newSlide = payload?.insert_orientation_slide_one;
  if (!newSlide) return;
  const orientationId = newSlide.orientation_id;
  const orientation = store.get(GetFullID("orientation", orientationId));
  if (!orientation) return;
  const node = store.getRootField("insert_orientation_slide_one");
  const slides = (
    orientation.getLinkedRecords("slides", slidesFieldParams) || []
  ).filter((slide) => slide.getValue("slide_id") !== newSlide.slide_id);
  slides.push(node);
  orientation.setLinkedRecords(slides, "slides", slidesFieldParams);
};

const deleteSlideUpdater: SelectorStoreUpdater<
  GCProjectOrientationDetail_DeleteSlide_Mutation$data
> = (store, payload) => {
  // console.log("deleteSlideUpdater", payload);
  const returning = payload?.update_orientation_slide?.returning;
  if (!returning) return;
  for (const slide of returning) {
    const orientationId = slide.orientation_id;
    const orientation = store.get(GetFullID("orientation", orientationId));
    if (!orientation) return;
    const slides = (
      orientation.getLinkedRecords("slides", slidesFieldParams) || []
    ).filter((s) => s.getDataID() !== slide.id);
    orientation.setLinkedRecords(slides, "slides", slidesFieldParams);
  }
};

const { confirm } = Modal;

const slideLostChangesConfirmation =
  "Unsaved changes will be lost.\n" + "Are you sure you want to continue?";

const confirmAction = (title: string, message: string): Promise<boolean> =>
  new Promise((resolve) => {
    confirm({
      title,
      icon: <ExclamationCircleOutlined />,
      content: message,
      onOk() {
        resolve(true);
      },
      onCancel() {
        resolve(false);
      },
    });
  });

interface OrientationDetailProps {
  orientationId: string;
  projectId?: string;
}

const GCProjectOrientationDetail: FC<OrientationDetailProps> = ({
  projectId,
  orientationId,
}) => {
  const data = useLazyLoadQuery<GCProjectOrientationDetailQuery>(
    graphql`
      query GCProjectOrientationDetailQuery(
        $orientationId: uuid!
        $agcCompanyId: uuid!
      ) {
        agc_employee: general_contractor_employee_connection(
          where: { general_contractor_id: { _eq: $agcCompanyId } }
        ) {
          edges {
            node {
              uid
              id
            }
          }
        }
        orientation_connection(where: { id: { _eq: $orientationId } }) {
          edges {
            node {
              pk: id @__clientField(handle: "pk")
              id
              name
              created_at
              project_id
              type
              user {
                name
              }
              slides(
                where: { archived_at: { _is_null: true } }
                order_by: { order: asc }
              ) {
                ...SlideFrag @relay(mask: false)
              }
            }
          }
        }
      }
    `,
    { orientationId, agcCompanyId },
  );

  const [insertSlideMutation] =
    useAsyncMutation<GCProjectOrientationDetail_InsertSlide_Mutation>(graphql`
      mutation GCProjectOrientationDetail_InsertSlide_Mutation(
        $slide: orientation_slide_insert_input!
      ) {
        insert_orientation_slide_one(object: $slide) {
          ...SlideFrag @relay(mask: false)
        }
      }
    `);

  const [updateSlideMutation] =
    useAsyncMutation<GCProjectOrientationDetail_UpdateSlide_Mutation>(graphql`
      mutation GCProjectOrientationDetail_UpdateSlide_Mutation(
        $slideId: uuid!
        $newId: uuid!
        $now: timestamptz!
        $slide: orientation_slide_insert_input!
      ) {
        insert_orientation_slide_one(object: $slide) {
          ...SlideFrag @relay(mask: false)
        }
        update_orientation_slide(
          # asume that slide editor is open in multiple web pages
          # we can't archive slide by ID (because it can be already be archived by other page, and we create multiple pages with null)
          # we assume that id of current slide may change, and update all slides <> new slide id
          where: {
            id: { _neq: $newId }
            slide_id: { _eq: $slideId }
            archived_at: { _is_null: true }
          }
          _set: { archived_at: $now }
        ) {
          affected_rows
          returning {
            id
            pk: id @__clientField(handle: "pk")
            archived_at
          }
        }
      }
    `);

  const [deleteSlideMutation] =
    useAsyncMutation<GCProjectOrientationDetail_DeleteSlide_Mutation>(graphql`
      mutation GCProjectOrientationDetail_DeleteSlide_Mutation(
        $slideId: uuid!
        $now: timestamptz!
      ) {
        update_orientation_slide(
          where: {
            slide_id: { _eq: $slideId } # use slide_id instead of id because if concurent edits
            archived_at: { _is_null: true }
          }
          _set: { archived_at: $now }
        ) {
          affected_rows
          returning {
            id
            pk: id @__clientField(handle: "pk")
            orientation_id
          }
        }
      }
    `);
  const [deleteOrientationMutation, isDeletingOrientation] =
    useAsyncMutation<GCProjectOrientationDetail_DeleteOrientation_Mutation>(graphql`
      mutation GCProjectOrientationDetail_DeleteOrientation_Mutation(
        $orientationId: uuid!
        $_set: orientation_set_input!
      ) {
        update_orientation_by_pk(
          pk_columns: { id: $orientationId }
          _set: $_set
        ) {
          id
          pk: id @__clientField(handle: "pk")
          deleted_at
        }
      }
    `);
  const [searchParams] = useSearchParams();
  const agc = searchParams.get("universal-orientation-setting");
  const { userData } = useUserData();
  const orientation = (data?.orientation_connection.edges || [])[0]?.node;
  if (!orientation) {
    // TODO: redirect to list of orientations
    throw new Error("Orientation does not exist, id = " + orientationId);
  }
  const editable =
    orientation.type === "universal"
      ? !!data.agc_employee.edges.find(
          (p) => p.node.uid === auth.currentUser?.uid,
        )
      : userData.employee?.is_corporate_admin ||
        orientation.type !== "corporate";
  const slides = useMemo(
    () => [...orientation.slides].sort((a, b) => a.order - b.order),
    [orientation.slides],
  );
  const [editSlideId, setEditSlideId] = useState<string>();
  const [lang, setLang] = useState<Language>("en");
  const navigate = useNavigate();
  const slideEditor = useRef<SlideEditorRef>(null);

  const canCloseEdit = async () => {
    if (editSlideId && slideEditor.current?.isModified()) {
      return await confirmAction(
        "Unsaved changes",
        slideLostChangesConfirmation,
      );
    } else return true;
  };

  // usePrompt(canCloseEdit, true);

  const goToSlideList = async (replace?: boolean) => {
    if (searchParams.get("csr")) {
      navigate(-1);
    }
    if (agc) {
      navigate(`/agc/orientation`);
    } else if (projectId)
      navigate(`/gce/projects/${projectId}/orientation/settings#table`, {
        replace,
      });
    else if (userData.employee?.is_corporate_admin)
      navigate("/gce/corporate/settings/orientations");
  };

  const onDeleteOrientation = async () => {
    await deleteOrientationMutation({
      variables: {
        orientationId,
        _set: {
          deleted_at: dayjs().toISOString(),
          deleted_by_uid: auth.currentUser?.uid,
        },
      },
    });
    goToSlideList(true);
    //history.goBack();
  };

  const onEditSlide = async (slide: SlideRecord) => {
    if (slide.slide_id !== editSlideId && (await canCloseEdit())) {
      setEditSlideId(slide.slide_id);
    }
  };

  const onCancelEdit = () => {
    setEditSlideId(undefined);
  };

  const onSaveSlide = async (slide: SlideRecord) => {
    const { pk, id, order: _, ...slideData } = slide;
    const prevSlide = slides.find((s) => s.slide_id === slide.slide_id);
    const newId = uuid.v4();
    const order = prevSlide?.order ?? 0;
    // console.log("slideData = ", slideData);
    //    const newSlide = {...slide, pk: newId, order };
    //    setEditSlide(newSlide);
    await updateSlideMutation({
      variables: {
        newId,
        now: new Date().toISOString(),
        slideId: slide.slide_id,
        slide: {
          ...slideData,
          order,
          id: newId,
        },
      },
      updater: updateSlideUpdater,
    }).catch((error) => {
      message.error("Faled to save slide: " + error?.message);
    });
  };

  const onDeleteSlide = async (id: string, slideId: string) => {
    const slide = slides.find((s) => s.slide_id === slideId);
    if (!slide) return; // TODO: report error
    const slideOrQuestion =
      slide.content_type === "question" ? "question" : "slide";
    if (
      !(await confirmAction(
        "Delete confirmation",
        `Are you sure you want to delete the ${slideOrQuestion}?`,
      ))
    ) {
      return;
    }

    setEditSlideId(undefined);
    const fullId = GetFullID("orientation_slide", id);
    const now = new Date().toDateString();
    await deleteSlideMutation({
      variables: {
        slideId,
        now: now,
      },
      optimisticResponse: {
        update_orientation_slide: {
          affected_rows: 1,
          returning: [
            {
              id: fullId,
              pk: fullId,
              orientation_id: orientationId,
            },
          ],
        },
      },
      updater: deleteSlideUpdater,
    });
  };

  const onOrderChange = async (
    from: number,
    to: number,
    newItems: SlideArray,
  ) => {
    const now = new Date().toISOString();
    const prev_order = to > 0 ? newItems[to - 1].order : 0;
    let p = to + 1;
    while (p < newItems.length && prev_order + p - to >= newItems[p].order) p++;
    const gaps = p - to + 1;
    const last_order =
      p < newItems.length ? newItems[p].order : prev_order + 100 * gaps;
    const queries = [];
    for (let i = to; i < p; i++) {
      const order =
        (prev_order + ((last_order - prev_order) * (i - to + 1)) / gaps) | 0;
      //console.log(
      //  `change item i = ${i} slide_id = ${newItems[i].slide_id} order = ${newItems[i].order} -> ${order}`
      //);
      const { pk, id, ...fields } = newItems[i];
      const newId = uuid.v4();
      const slide = {
        ...fields,
        id: newId,
        order,
      };
      const fullId = GetFullID("orientation_slide", newId);

      queries.push(
        updateSlideMutation({
          variables: {
            slideId: newItems[i].slide_id,
            newId,
            now,
            slide,
          },
          optimisticResponse: {
            insert_orientation_slide_one: {
              ...slide,
              id: fullId,
              pk: fullId,
            },
            update_orientation_slide: {
              affected_rows: 1,
              returning: [
                {
                  id,
                  pk: id,
                  archived_at: now,
                },
              ],
            },
          },
          optimisticUpdater: updateSlideUpdater,
          updater: updateSlideUpdater,
        }),
      );
    }
    await Promise.all(queries);
  };

  const editSlide = slides.find((s) => s.slide_id === editSlideId);

  return (
    <BasicWrapper scrollable>
      <Button
        style={{ width: "100px" }}
        onClick={() => {
          goToSlideList(false);
        }}
      >
        Back
      </Button>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <OrientationHeader
          editable={editable}
          name={orientation.name}
          created_at={orientation.created_at}
          created_by={orientation.user?.name || ""}
          lang={lang}
          qr_code_link={`${
            document.location.origin
          }/orientations/test/${projectId}/${
            auth.currentUser?.uid || ""
          }/slides/slides?isTestLink=true#${orientationId}`}
          onLanguageChange={setLang}
          isDeletingOrientation={isDeletingOrientation}
          onDelete={onDeleteOrientation}
        />
        <StyledContent padding backgroundColor="white">
          {editable && (
            <h3 style={{ marginBottom: 10 }}>
              Here you can add slides and quiz questions to the orientation.
            </h3>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              marginLeft: "-40px",
            }}
          >
            <div style={{ marginLeft: "40px" }}>
              {editable && (
                <Space direction="horizontal">
                  <Button
                    type="primary"
                    style={{ display: "inline" }}
                    onClick={async () => {
                      if (!(await canCloseEdit())) return;
                      const id = uuid.v4();
                      const res = await insertSlideMutation({
                        variables: {
                          slide: {
                            id,
                            orientation_id: orientationId,
                            title: `New Slide ${slides.length + 1}`,
                            title_es: `Nuevo tobogán ${slides.length + 1}`,
                            title_fr: `Nouvelle diapositive ${
                              slides.length + 1
                            }`,
                            title_it: `Nuova diapositiva ${slides.length + 1}`,
                            title_pt: `Novo slide ${slides.length + 1}`,
                            title_zh_hans: `新幻灯片 ${slides.length + 1}`,
                            order:
                              slides.length > 0
                                ? slides[slides.length - 1].order + 100
                                : 0,
                            content_type: "image",
                            image_url: "",
                            video_url: "",
                            answer_items: DefaultAnswerList,
                            answer_items_es: DefaultAnswerList,
                            answer_items_pt: DefaultAnswerList,
                            voice_name: DefaultVoiceEn,
                            voice_name_es: DefaultVoiceEs,
                            voice_name_pt: DefaultVoicePt,
                            voice_name_it: DefaultVoiceIt,
                            voice_name_fr: DefaultVoiceFr,
                            voice_name_zh_hans: DefaultVoiceZhHans,
                          },
                        },
                        updater: insertSlideUpdater,
                      });
                      setEditSlideId(
                        res.insert_orientation_slide_one?.slide_id,
                      );
                    }}
                  >
                    Add Slide
                  </Button>
                  <Button
                    type="primary"
                    style={{ display: "inline" }}
                    onClick={async () => {
                      if (!(await canCloseEdit())) return;
                      const id = uuid.v4();
                      const res = await insertSlideMutation({
                        variables: {
                          slide: {
                            id: id,
                            orientation_id: orientationId,
                            title: `Enter question text`,
                            order:
                              slides.length > 0
                                ? slides[slides.length - 1].order + 100
                                : 0,
                            content_type: "question",
                            question_ui_element: "text",
                            image_url: "",
                            video_url: "",
                          },
                        },
                        updater: insertSlideUpdater,
                      });
                      setEditSlideId(
                        res.insert_orientation_slide_one?.slide_id,
                      );
                    }}
                  >
                    Add Question
                  </Button>
                </Space>
              )}

              {slides.length > 0 && (
                <div
                  style={{
                    border: "1px solid gray",
                    marginTop: "10px",
                    marginBottom: "20px",
                  }}
                >
                  <SlideList
                    slides={slides}
                    editable={editable}
                    lang={lang}
                    selectedSlideId={editSlideId}
                    onOrderChange={onOrderChange}
                    onSlideDelete={onDeleteSlide}
                    onSlideEdit={onEditSlide}
                  />
                </div>
              )}
            </div>
            {editSlide && (
              <div
                style={{
                  border: "2px black",
                  marginLeft: "40px",
                  width: "640px",
                }}
              >
                <SlideEditor
                  ref={slideEditor}
                  editable={editable}
                  key={editSlideId}
                  lang={lang}
                  slide={editSlide}
                  onSave={onSaveSlide}
                  onDelete={onDeleteSlide}
                />
              </div>
            )}
          </div>
        </StyledContent>
      </Space>
    </BasicWrapper>
  );
};

export default withCustomSuspense(GCProjectOrientationDetail);
