import FolderOutlined from "@ant-design/icons/FolderOutlined";
import { TreeSelect } from "antd";
import { DataNode } from "rc-tree-select/lib/interface";
import React, { useEffect, useMemo } from "react";

import {
  ProcoreProjectFolder,
  useGetProcoreProjectFoldersQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import LoadingContent from "./general/LoadingContent";
import StyledContent from "./layouts/StyledContent";
import compareStringsIgnoreCase from "../functions/compareStringsIgnoreCase";

interface FolderSelectProps {
  GCId: string;
  projectId: string;
  procoreProjectId: string;
  disabled?: boolean;
  value?: string;
  style?: any;
  onChange?: (value?: string) => void;
}

const folderToListItem = (folder: ProcoreProjectFolder): DataNode => ({
  title: folder.name,
  icon: <FolderOutlined />,
  id: folder.id,
  pId: folder.parent_id,
  value: folder.id,
  expanded: folder.has_children_folders,
  isLeaf: !folder.has_children_folders,
});

interface FolderTree {
  rootNodes: DataNode[];
  keyMap: { [key: number]: DataNode };
}

const getFullPath = (tree: FolderTree, nodeId: number): string => {
  let res = "";
  let node = tree.keyMap[nodeId];
  while (node && res.length < 1024) {
    res = node.title + (res.length > 0 ? "/" + res : "");
    node = tree.keyMap[node.pId];
  }
  return res;
};

const getNodeByName = (nodes: DataNode[], name: string): DataNode | null => {
  let index = nodes.findIndex((node) => node.title === name);
  return index >= 0 ? nodes[index] : null;
};

function getPathIds(tree: FolderTree, path: string[]): number[] {
  let res = [];
  let nodes = tree.rootNodes;
  for (let name of path) {
    let node = getNodeByName(nodes, name);
    if (node === null) break;
    res.push(node.id);
    nodes = node.children || [];
  }
  return res;
}

const getFolderNodeById = (
  tree: FolderTree,
  folder_id: number,
): DataNode | null => {
  const node = tree.rootNodes[folder_id];
  return node || null;
};

function buildFolderTree(folders: ProcoreProjectFolder[]): FolderTree {
  const keyMap: { [key: string]: DataNode } = {};
  const rootNodes: DataNode[] = [];

  // Fill in the map
  const nodeList = folders.map((item) => {
    const node = folderToListItem(item);
    const key = node.id;
    keyMap[key] = node;
    node.key = node.key || key;
    return node;
  });

  // Connect tree
  nodeList.forEach((node) => {
    const parentKey = node.pId;
    if (parentKey === null) rootNodes.push(node);
    else {
      const parent = keyMap[parentKey];
      // Fill parent
      if (parent) {
        parent.children = parent.children || [];
        parent.children.push(node);
      }
    }
  });
  // second pas - sort
  nodeList.forEach((node) => {
    if (node.children)
      node.children.sort((a, b) => {
        if (typeof a.title === "string" && typeof b.title === "string")
          return compareStringsIgnoreCase(a.title, b.title);
        else {
          return Number(typeof a.title === "string") - Number(typeof b.title === "string")
        }
      }
      );
  });

  return { keyMap, rootNodes };
}

const ProcoreFolderSelect = ({
  procoreProjectId,
  GCId,
  projectId,
  disabled,
  value,
  onChange,
  ...props
}: FolderSelectProps) => {
  const { data, loading, error, fetchMore } = useGetProcoreProjectFoldersQuery({
    variables: {
      procoreProjectId,
      projectId,
    },
    fetchPolicy: "cache-and-network",
  });

  const folders = data?.procoreGetProjectFolders.folders || [];
  const fetchAllParents = async (folderId: string) => {
    const { data: newData } = await fetchMore({
      variables: {
        procoreProjectId,
        projectId,
        folderId,
      },
    });
    const folder = newData.procoreGetProjectFolders.folders[0];
    if (folder && folder.parent_id) {
      await fetchAllParents(folder.parent_id);
    }
  };
  useEffect(() => {
    if (value && !folders.find((f) => f.id === value)) {
      fetchAllParents(value);
    }
  }, [value]);

  const folderTree = useMemo(() => buildFolderTree(folders), [folders]);

  const currentFolder = folders.find((f) => f.id === value);
  let path = undefined;
  if (value) {
    if (currentFolder) {
      path = currentFolder.path;
    } else if (loading) {
      path = "loading...";
    } else if (error) {
      path = error;
    } else {
      path = "loading..."; // fetchMore is loading //value;
    }
  }
  const treeValue = value && { value, label: path };

  return (
    <TreeSelect
      disabled={disabled}
      treeData={folderTree.rootNodes}
      treeIcon
      loading={loading}
      dropdownRender={(children) =>
        folderTree.rootNodes.length == 0 && loading ? (
          <StyledContent align="center">
            <LoadingContent />
          </StyledContent>
        ) : (
          children
        )
      }
      dropdownStyle={{ maxHeight: 650, overflow: "auto" }}
      treeDefaultExpandedKeys={folderTree.rootNodes.map((v) => v.id)}
      placeholder="Select procore folder"
      loadData={async (node) => {
        return fetchMore({
          variables: {
            procoreProjectId,
            projectId,
            folderId: node.key,
          },
        });
      }}
      onTreeExpand={(exandedKeys) => {
        console.log("onTreeExpand = ", exandedKeys);
      }}
      value={treeValue}
      {...props}
      onChange={(item) => {
        if (typeof (item) === "string") {
          let id = item;
          if (onChange) {
            onChange(id);
          }
        }
      }}
      allowClear
      style={{ width: "100%" }}
    ></TreeSelect>
  );
};
export default ProcoreFolderSelect;
