import { IconSettings } from "@tabler/icons";
import { Button, message, Modal, notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import Icon from "src/common/components/general/Icon";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import GetFullID from "src/common/functions/GetFullId";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { GCProjectReportsDownload_updateProjects_Mutation } from "src/common/types/generated/relay/GCProjectReportsDownload_updateProjects_Mutation.graphql";
import { GCProjectReportsDownloadQuery } from "src/common/types/generated/relay/GCProjectReportsDownloadQuery.graphql";
import { SwitchWithText } from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/settings/components/SiteOrientationSettingSwitchCards";
import MorningManPowerReportWidget from "./MorningManPowerReportWidget";
import WorkerHourCountReportWidget from "./WorkerHourCountReportWidget";
import MonthlyReportWidget from "./MonthlyReportWidget";

interface GCProjectReportDownloadProps {
  projectId: string;
}

const GCProjectReportDownload: React.FunctionComponent<
  GCProjectReportDownloadProps
> = ({ projectId }) => {
  const data = useLazyLoadQuery<GCProjectReportsDownloadQuery>(
    graphql`
      query GCProjectReportsDownloadQuery($projectId: uuid!) {
        project_subcontractor_connection(
          where: { project_id: { _eq: $projectId } }
        ) {
          edges {
            node {
              subcontractor_id
              subcontractor {
                name
              }
            }
          }
        }
        email_project_user_connection(
          first: 10000
          where: {
            project_id: { _eq: $projectId }
            type: { _eq: "morning_man_power_report" }
          }
        )
          @connection(
            key: "MorningManPowerReport_email_project_user_connection"
            filters: []
          ) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              user {
                id
                pk: id @__clientField(handle: "pk")
                name
                email
              }
            }
          }
        }
        project_employee_connection(
          where: { project: { id: { _eq: $projectId } } }
          order_by: { employee: { user: { name: asc } } }
        ) {
          edges {
            node {
              employee {
                user {
                  id
                  pk: id @__clientField(handle: "pk")
                  name
                  email
                }
              }
            }
          }
        }
        project_connection(where: { id: { _eq: $projectId } }) {
          edges {
            node {
              id
              morning_man_power_report_email_time
              timezone
              project_setting {
                id
                send_weekly_email
                require_gc_to_mark_observation_complete
              }
            }
          }
        }
        injury_body_part_affected_connection(
          order_by: { translation: { en: asc } }
        ) {
          edges {
            node {
              value
              translation {
                en
              }
            }
          }
        }
        incident_type_connection(order_by: { translation: { en: asc } }) {
          edges {
            node {
              value
              translation {
                en
              }
            }
          }
        }
        injury_cause_connection(order_by: { translation: { en: asc } }) {
          edges {
            node {
              value
              translation {
                en
              }
            }
          }
        }
        injury_type_connection(order_by: { translation: { en: asc } }) {
          edges {
            node {
              value
              translation {
                en
              }
            }
          }
        }
        incident_type_connection(order_by: { translation: { en: asc } }) {
          edges {
            node {
              value
              translation {
                en
              }
            }
          }
        }
        injury_severity: incident_severity_connection(
          where: { severity_type: { _eq: "injury" } }
          order_by: { name: { en: asc } }
        ) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              name {
                en
              }
            }
          }
        }
        risk_level_connection(order_by: { weight: desc }) {
          edges {
            node {
              name
              value
              color_hex
            }
          }
        }
        observation_category_type_connection(order_by: { name: { en: asc } }) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              name {
                en
              }
              observation_categories(order_by: { name: { en: asc } }) {
                id
                pk: id @__clientField(handle: "pk")
                name {
                  en
                }
              }
            }
          }
        }
      }
    `,
    {
      projectId,
    },
  );

  const [updateProject] =
    useAsyncMutation<GCProjectReportsDownload_updateProjects_Mutation>(graphql`
      mutation GCProjectReportsDownload_updateProjects_Mutation(
        $id: uuid!
        $_set: project_setting_set_input!
      ) {
        update_project_setting_by_pk(
          pk_columns: { project_id: $id }
          _set: $_set
        ) {
          id
          send_weekly_email
        }
      }
    `);

  const subcontractorData = data.project_subcontractor_connection.edges;
  const subIds = new Array<string>();
  subcontractorData.map((t) => {
    subIds.push(t.node.subcontractor_id);
  });

  const projectData = data.project_connection.edges[0].node;

  const [openSettings, setOpenSettings] = useState(false);
  return (
    <div>
      <Modal
        open={openSettings}
        onCancel={() => setOpenSettings(false)}
        footer={() => (
          <Button onClick={() => setOpenSettings(false)}>Close</Button>
        )}
      >
        <SwitchWithText
          //disabled={!has_auth_data}
          text="Turn ON weekly project email"
          onChange={(checked) => {
            updateProject({
              variables: {
                id: projectId,
                _set: { send_weekly_email: checked },
              },
              optimisticResponse: {
                update_project_setting_by_pk: {
                  id: GetFullID("project_setting", projectId),
                  send_weekly_email: checked,
                },
              },
            })
              .then(() => message.success(checked ? "ON" : "OFF"))
              .catch((e) => {
                notification.error({
                  message: "Error: Please try again",
                  description: e?.message,
                });
              });
          }}
          checked={!!projectData.project_setting?.send_weekly_email}
        />
      </Modal>

      <div className="-mx-0.25" style={{ marginBottom: "20px" }}></div>

      <div className="flex justify-between align-middle">
        <div>
          <p className="text-1.5">Report Templates</p>
          <p className="text-grey text-1">
            Select a template to generate your report
          </p>
        </div>
        <div className="right-2">
          <div
            className="opacity-20"
            onClick={() => {
              setOpenSettings(true);
            }}
          >
            <Icon color="static" icon={IconSettings} size="small" />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-1.5 mt-2">
        <MonthlyReportWidget projectId={projectId} data={data} />
        <MorningManPowerReportWidget projectId={projectId} data={data} />
        <WorkerHourCountReportWidget projectId={projectId} data={data} />
      </div>
    </div>
  );
};

export default withCustomSuspense(GCProjectReportDownload);
