import {
  IconCalendarTime,
  IconEye,
  IconForklift,
  IconPackage,
  IconPencil,
  IconThumbDown,
  IconThumbUp,
  IconTruckDelivery,
} from "@tabler/icons";
import React from "react";
import Icon, { IconType } from "src/common/components/general/Icon";
import Popover from "src/common/components/general/Popover";
import capitalize from "src/common/functions/capitalize";
import hexToRgba from "src/common/functions/hexToRgba";
import { DeliveryType } from "../../utilities/sitedeliveryTypes";
import TimeRangeSpan from "./TimeRangeSpan";

const SitedeliveryCalendarEventDeliveryPopover: React.FC<
  DeliveryType & { children: React.ReactElement }
> = (props) => {
  const details: { icon: IconType; text: string }[] = [];
  if ("subcontractor" in props && !!props.subcontractor) {
    details.push({
      icon: IconTruckDelivery,
      text: props.title.text,
    });
  }
  if (props.detail) {
    details.push({
      icon: IconPackage,
      text: props.detail.text,
    });
  }
  if (props.storageLocation) {
    details.push({
      text: `${capitalize(props.storageLocation.text)}`,
      icon: IconForklift,
    });
  }

  if (props.createdBy) {
    details.push({
      text: `Created by ${props.createdBy}`,
      icon: IconPencil,
    });
  }
  if (props.createdAt) {
    details.push({
      icon: IconCalendarTime,
      text: `Created on ${props.createdAt.format("dddd MMMM Do YYYY, h:mm a")}`,
    });
  }

  if (props.reviewed) {
    let textBeginning = "Reviewed";
    let icon = IconEye;
    switch (props.reviewed.type) {
      case "approve":
        textBeginning = `Approved`;
        icon = IconThumbUp;
        break;
      case "reject":
        textBeginning = "Rejected";
        icon = IconThumbDown;
        break;
    }
    details.push({
      text: `${textBeginning} by ${props.reviewed.by}${
        props.reviewed.remark ? `:\n${props.reviewed.remark}` : ""
      }${
        !!props.reviewed.date
          ? ` on ${props.reviewed.date.format("MMM Do @ h:mm a")}`
          : ""
      }`,
      icon,
    });
  }

  return (
    <Popover
      notInteractive
      className="relative w-full h-full"
      delay={false}
      content={
        <div className="flex flex-col w-16 gap-1 p-1">
          <div className="flex flex-col w-full gap-0">
            <p className="font-accent text-1.5">
              {"subcontractor" in props && !!props.subcontractor
                ? props.subcontractor.title
                : props.title.text}{" "}
            </p>
            <TimeRangeSpan
              className="text-1 text-static-secondary"
              {...{ from: props.from, to: props.to }}
            />
          </div>
          {!!details.length && (
            <div className="flex flex-col gap-0.5">
              {details.map((detail, i) => (
                <div key={i} className="flex flex-row gap-0.5">
                  <Icon icon={detail.icon} />
                  <p>{detail.text}</p>
                </div>
              ))}
            </div>
          )}
          {!!props.calendars?.length && (
            <div className="block -m-0.125 text-0.75">
              {props.calendars.map((calendar) => (
                <div
                  key={calendar.id}
                  className="inline-block m-0.125 rounded-0.5 px-0.5"
                  style={{ backgroundColor: hexToRgba(calendar.colorHex, 0.4) }}
                >
                  {calendar.title}
                </div>
              ))}
            </div>
          )}
        </div>
      }
    >
      {props.children}
    </Popover>
  );
};

export default SitedeliveryCalendarEventDeliveryPopover;
