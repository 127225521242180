/**
 * @generated SignedSource<<dffce0ce5009dace9ad66c868d7864f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_subcontractor_daily_report_not_require_period_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type usePermanentReportRequireSettingsSub_DRNotRequire_InsertMutation$variables = {
  object: project_subcontractor_daily_report_not_require_period_insert_input;
};
export type usePermanentReportRequireSettingsSub_DRNotRequire_InsertMutation$data = {
  readonly insert_project_subcontractor_daily_report_not_require_period_one: {
    readonly id: string;
  } | null | undefined;
};
export type usePermanentReportRequireSettingsSub_DRNotRequire_InsertMutation = {
  response: usePermanentReportRequireSettingsSub_DRNotRequire_InsertMutation$data;
  variables: usePermanentReportRequireSettingsSub_DRNotRequire_InsertMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "object",
        "variableName": "object"
      }
    ],
    "concreteType": "project_subcontractor_daily_report_not_require_period",
    "kind": "LinkedField",
    "name": "insert_project_subcontractor_daily_report_not_require_period_one",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePermanentReportRequireSettingsSub_DRNotRequire_InsertMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePermanentReportRequireSettingsSub_DRNotRequire_InsertMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ba3bd47f4cb6311d41479d7298ae9d7a",
    "id": null,
    "metadata": {},
    "name": "usePermanentReportRequireSettingsSub_DRNotRequire_InsertMutation",
    "operationKind": "mutation",
    "text": "mutation usePermanentReportRequireSettingsSub_DRNotRequire_InsertMutation(\n  $object: project_subcontractor_daily_report_not_require_period_insert_input!\n) {\n  insert_project_subcontractor_daily_report_not_require_period_one(object: $object) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "362f6ab2dd438e4aaddf254c4dac6077";

export default node;
