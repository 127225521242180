import { DatePicker } from "antd";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import React, { useState } from "react";

const { RangePicker } = DatePicker;

export type RangeValue = [Dayjs | null, Dayjs | null] | null;
type PresetIds =
  | "this_month"
  | "this_year"
  | "last_3_months"
  | "last_6_months"
  | "last_12_months";
const rangePresets: { id: PresetIds; label: string; value: [Dayjs, Dayjs] }[] =
  [
    {
      id: "this_month",
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      id: "this_year",
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("month")],
    },

    {
      id: "last_3_months",
      label: "Last 3 Months",
      value: [
        dayjs().subtract(2, "month").startOf("month"),
        dayjs().endOf("month"),
      ],
    },
    {
      id: "last_6_months",
      label: "Last 6 Months",
      value: [
        dayjs().subtract(5, "month").startOf("month"),
        dayjs().endOf("month"),
      ],
    },
    {
      id: "last_12_months",
      label: "Last 12 Months",
      value: [
        dayjs().subtract(11, "month").startOf("month"),
        dayjs().endOf("month"),
      ],
    },
  ];
interface MonthDateRangeProps {
  startMonth: dayjs.Dayjs | null;
  endMonth: dayjs.Dayjs | null;
  onStartMonthChagne: (newValue: dayjs.Dayjs | null) => void;
  onEndMonthChange: (newValue: dayjs.Dayjs | null) => void;
  maxMonthsNumber?: number;
  presetsShown?: Array<PresetIds>;
}

const MonthDateRange: React.FC<MonthDateRangeProps> = ({
  presetsShown,
  ...props
}) => {
  const [dates, setDates] = useState<RangeValue>(null);
  const [hackValue, setHackValue] = useState<RangeValue>(null);
  const today = dayjs();

  const disabledDate = (current: Dayjs) => {
    if (!dates) return false;

    if (current > today) return true;

    if (!props.maxMonthsNumber) return false;

    const tooLate =
      dates[0] && current.diff(dates[0], "months") >= props.maxMonthsNumber - 1;
    const tooEarly =
      dates[1] && dates[1].diff(current, "months") >= props.maxMonthsNumber - 1;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setHackValue(null);
    }
  };

  return (
    <RangePicker
      className="min-w-12"
      picker="month"
      format={"MMMM YYYY"}
      presets={
        presetsShown
          ? rangePresets.filter((r) => presetsShown.includes(r.id))
          : undefined
      }
      value={hackValue || [props.startMonth, props.endMonth]}
      disabledDate={disabledDate}
      onCalendarChange={(val) => setDates(val)}
      onChange={(val) => {
        const start = val ? val[0] : null;
        const end = val ? val[1] : null;
        props.onStartMonthChagne(start);
        props.onEndMonthChange(end);
      }}
      onOpenChange={onOpenChange}
    />
  );
};

export default MonthDateRange;
