/**
 * @generated SignedSource<<22ac355a0f00d5a54176fb197aa6eab9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { task_bool_exp, task_edit_bool_exp, task_edit_insert_input, task_set_input, task_signature_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type JHAReviewedAndAcceptedMutation$variables = {
  _set: task_set_input;
  deleteTaskWhere: task_bool_exp;
  taskEditObjects: ReadonlyArray<task_edit_insert_input>;
  taskId: string;
  taskSignObjects: ReadonlyArray<task_signature_insert_input>;
  updateTaskEditWhere: task_edit_bool_exp;
};
export type JHAReviewedAndAcceptedMutation$data = {
  readonly delete_task: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_task_edit: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_task_signature: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly update_task_by_pk: {
    readonly id: string;
  } | null | undefined;
  readonly update_task_edit: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type JHAReviewedAndAcceptedMutation = {
  response: JHAReviewedAndAcceptedMutation$data;
  variables: JHAReviewedAndAcceptedMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleteTaskWhere"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskEditObjects"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskSignObjects"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "updateTaskEditWhere"
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v7 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "task_id",
            "variableName": "taskId"
          }
        ],
        "kind": "ObjectValue",
        "name": "_set"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "updateTaskEditWhere"
      }
    ],
    "concreteType": "task_edit_mutation_response",
    "kind": "LinkedField",
    "name": "update_task_edit",
    "plural": false,
    "selections": (v6/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "taskId"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "task",
    "kind": "LinkedField",
    "name": "update_task_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "taskEditObjects"
      }
    ],
    "concreteType": "task_edit_mutation_response",
    "kind": "LinkedField",
    "name": "insert_task_edit",
    "plural": false,
    "selections": (v6/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "deleteTaskWhere"
      }
    ],
    "concreteType": "task_mutation_response",
    "kind": "LinkedField",
    "name": "delete_task",
    "plural": false,
    "selections": (v6/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "taskSignObjects"
      }
    ],
    "concreteType": "task_signature_mutation_response",
    "kind": "LinkedField",
    "name": "insert_task_signature",
    "plural": false,
    "selections": (v6/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "JHAReviewedAndAcceptedMutation",
    "selections": (v7/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "JHAReviewedAndAcceptedMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "808a9c7dea279b0c64aef5fb7764acfd",
    "id": null,
    "metadata": {},
    "name": "JHAReviewedAndAcceptedMutation",
    "operationKind": "mutation",
    "text": "mutation JHAReviewedAndAcceptedMutation(\n  $taskId: uuid!\n  $_set: task_set_input!\n  $deleteTaskWhere: task_bool_exp!\n  $updateTaskEditWhere: task_edit_bool_exp!\n  $taskEditObjects: [task_edit_insert_input!]!\n  $taskSignObjects: [task_signature_insert_input!]!\n) {\n  update_task_edit(where: $updateTaskEditWhere, _set: {task_id: $taskId}) {\n    affected_rows\n  }\n  update_task_by_pk(pk_columns: {id: $taskId}, _set: $_set) {\n    id\n  }\n  insert_task_edit(objects: $taskEditObjects) {\n    affected_rows\n  }\n  delete_task(where: $deleteTaskWhere) {\n    affected_rows\n  }\n  insert_task_signature(objects: $taskSignObjects) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "b8cb7b1895b36d1e81b015245d51c280";

export default node;
