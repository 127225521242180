import React, { FC } from "react";
import Button from "./general/button/Button";
import { Popconfirm } from "antd";
import {
  isDevelopmentBuild,
  isDevelopmentEnvironment,
} from "../constants/environment";
import {
  PROCORE_CLIENT_ID_DEV,
  PROCORE_CLIENT_ID_PROD,
  PROCORE_SERVER_DEV,
  PROCORE_SERVER_PROD,
} from "../constants/procore";

export const procoreCompanyRedirectUrl = `${document.location.origin}/gce/corporate/settings/corporate-settings`;
export const procoreProjectRedirectUrl = `${document.location.origin}/gce/procore-project-setup`;

const getProcoreAuthUrl = (sandbox: boolean, projectId?: string) => {
  const redirect_uri = projectId
    ? procoreProjectRedirectUrl
    : procoreCompanyRedirectUrl;
  const sandbox_tag = sandbox ? "sbx" : "";
  const state = projectId ? `${projectId}|${sandbox_tag}` : sandbox_tag;
  if (sandbox)
    return `https://${PROCORE_SERVER_DEV}/oauth/authorize?response_type=code&client_id=${PROCORE_CLIENT_ID_DEV}&redirect_uri=${redirect_uri}${
      state ? `&state=${state}` : ""
    }`;
  else
    return `https://${PROCORE_SERVER_PROD}/oauth/authorize?response_type=code&client_id=${PROCORE_CLIENT_ID_PROD}&redirect_uri=${redirect_uri}&state=${state}${
      state ? `&state=${state}` : ""
    }`;
};

interface ConnectButtonProps {
  title: string;
  projectId?: string;
}

export const ConnectAsUserButtonProd: FC<ConnectButtonProps> = ({
  title,
  projectId,
}) => {
  return (
    <Button
      onClick={() => {
        document.location.href = getProcoreAuthUrl(false, projectId);
      }}
      label={title}
    />
  );
};
export const ConnectAsUserButtonDev: FC<ConnectButtonProps> = ({
  title,
  projectId,
}) => {
  return (
    <Popconfirm
      title="Do you want to use sandbox or production account?"
      onConfirm={() => {
        document.location.href = getProcoreAuthUrl(false, projectId);
      }}
      onCancel={() => {
        document.location.href = getProcoreAuthUrl(true, projectId);
      }}
      okText="production"
      cancelText="sandbox"
    >
      <Button label={title} onClick={() => {}} />
    </Popconfirm>
  );
};

const ConnectAsUserButton: FC<ConnectButtonProps> = (props) => {
  const show_sandbox =
    isDevelopmentEnvironment ||
    isDevelopmentBuild ||
    document.location.host === "siteform-web-dev.firebaseapp.com" ||
    document.location.host === "siteform-web-dev.web.app" ||
    document.location.host === "localhost";
  return show_sandbox ? (
    <ConnectAsUserButtonDev {...props} />
  ) : (
    <ConnectAsUserButtonProd {...props} />
  );
};

export default ConnectAsUserButton;
