import { message, notification } from "antd";
import React from "react";
import { useRelayEnvironment } from "react-relay/hooks";
import apolloClient from "src/common/api/apollo/apolloClient";
import { CreateGCEmployeeFormValues } from "src/common/components/dialogs/CreateGCEmployeeModal";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import {
  GetNameUsernameDocument,
  GetNameUsernameQuery,
  GetGcTeamQuery,
  GetNameUsernameQueryVariables,
  useCreateGeneralContractorEmployeeMutation,
  GetGcTeamDocument,
  GetGcTeamQueryVariables,
  GetGcTeamQueryResult,
} from "src/common/types/generated/apollo/graphQLTypes";
import sendInvite from "src/utility-features/invitations/sendInvite";
import { getInvitedUserData } from "src/common/functions/invitedUserEmailData";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import GCProjectTeamUI, {
  GCProjectTeamUIProps,
} from "../../projects/team/GCProjectTeamUI";
import { general_contractor_employee_bool_exp } from "src/common/types/generated/relay/AddGCProjectTeamModalQuery.graphql";
import { useSuspenseQuery } from "@apollo/client";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";


const GCHierarchyTeam: React.FC<{
  title: string;
  onRemoveFromTeam?: (userId: string, projects: string[]) => Promise<void>;
  excludeEmployeeWhere: general_contractor_employee_bool_exp;
  onInviteSuccess: (userId: string[]) => Promise<void> | void;
  getRemainingGcTeamVariables: Omit<GetGcTeamQueryVariables, "gcId">;
  isAdmin: (
    gcEmployee: NonNullable<
      GetGcTeamQueryResult["data"]
    >["general_contractor_employee"][number],
  ) => boolean;
}> = ({
  title,
  onRemoveFromTeam,
  excludeEmployeeWhere,
  onInviteSuccess,
  getRemainingGcTeamVariables,
  isAdmin,
}) => {
  const [createEmployee] = useCreateGeneralContractorEmployeeMutation();
  const { userData } = useUserData();
  const employee = userData.employee;
  if (!employee) throw new Error("Logged in user is not employee");
  const environment = useRelayEnvironment();
  const { data, refetch } = useSuspenseQuery<
    GetGcTeamQuery,
    GetGcTeamQueryVariables
  >(GetGcTeamDocument, {
    variables: {
      ...getRemainingGcTeamVariables,
      gcId: employee.general_contractor.id,
    },
  });

  const gc = data.general_contractor_by_pk;
  if (!gc) throw new Error("GC not found for logged in user");
  const projectsToInvite = gc.gc_projects.map((p) => p.project.id);

  const gcTeamProps: GCProjectTeamUIProps = {
    title,
    projectsToInvite,
    excludeEmployeeWhere,
    directProjectTeam: false,
    gcUsers: data.general_contractor_employee.map((emp) => ({
      loading: false,
      userId: emp.uid,
      generalContractorId: employee.general_contractor.id,
      isCorporateAdmin: emp.is_corporate_admin,
      title: emp.employee_title?.name.en,
      email: emp.user.email ?? undefined,
      phoneNumber: emp.user.phone_number ?? undefined,
      terminatedAt: emp.user.terminated_at ?? undefined,
      reSendInvite: async () => {
        const toEmail = emp.user.email ?? undefined;
        const toName = emp.user.name;
        if (toEmail) {
          const { success, error } = await sendInvite({
            sendTo: toEmail,
            sendToName: toName,
            claimingAccount: true,
            inviteType: "reSendGCInvite",
          });
          if (success == false) {
            notification.error({
              message: "Error: ",
              description: error,
              duration: null,
            });
          } else {
            message.success("Invite sent");
          }
        }
      },
      name: emp.user.name,
      avatarUrl: emp.user.profile_picture?.url,
      created_password: emp.user.created_password,
      type: "hierarchy",
      isAdmin: isAdmin(emp),
      onRemoveFromTeam: async (userId) => {
        await onRemoveFromTeam?.(userId, projectsToInvite);
        refetch();
      },
    })),
    onInviteSuccess: async (userIds) => {
      await onInviteSuccess(userIds);
      refetch();
    },

    onCreateGCEmployee: async (values: CreateGCEmployeeFormValues) => {
      const invitedUserData = await getInvitedUserData(
        values.email,
        environment,
      );
      if (invitedUserData && invitedUserData.user_connection.edges[0]) {
        if (invitedUserData.user_connection.edges[0].node.created_password) {
          const { success, error } = await sendInvite({
            sendTo: values.email,
            toUid: invitedUserData.user_connection.edges[0].node.pk,
            claimingAccount: true,
            inviteType: "loginInvite",
          });
          if (success == false) {
            notification.error({
              message: "Error: ",
              description: error,
              duration: null,
            });
          } else {
            message.success("Invite sent");
          }
          notification.info({
            message: "User Already Exists ",
            description:
              "This user already exists in your organization. They have been added to your project. If they are having trouble logging in, they can select Forgot Password on the login screen",
            duration: null,
          });
        } else {
          await sendInvite({
            sendTo: values.email,
            toUid: invitedUserData.user_connection.edges[0].node.pk,
            claimingAccount: true,
            inviteType: "reSendGCInvite",
          });
          notification.error({
            message: "User Already Exists ",
            description:
              "This user already exists in your organization. They have been added to your project. If they are having trouble logging in, they can select Forgot Password on the login screen",
            duration: null,
          });
        }
        return;
      }
      const { data } = await apolloClient.query<
        GetNameUsernameQuery,
        GetNameUsernameQueryVariables
      >({
        query: GetNameUsernameDocument,
        variables: {
          name: values.name,
        },
      });
      if (data.getNewUsername) {
        const { data: createEmployeeData } = await createEmployee({
          variables: {
            name: values.name,
            email: values.email,
            phoneNumber: values.phone,
            generalContractorId: employee.general_contractor.id,
            titleId: values.titleId,
            username: data.getNewUsername,
            projectIds: projectsToInvite,
            directProjectAssign: false,
            // phoneNumber: un
          },
        });
        if (createEmployeeData?.createEmployee)
          onInviteSuccess([createEmployeeData.createEmployee]);
      } else {
        console.log("NO DATA");
      }
    },
  };

  return (
    <BasicWrapper>
      <GCProjectTeamUI {...gcTeamProps} />
    </BasicWrapper>
  );
};

export default withCustomSuspense(GCHierarchyTeam);
