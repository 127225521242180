/**
 * @generated SignedSource<<c8c9591c6424438b12b9f546dcf674e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { inspection_result_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InspectionResultTableTotalRefetchableQuery$variables = {
  where: inspection_result_bool_exp;
};
export type InspectionResultTableTotalRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"InspectionResultTable_total">;
};
export type InspectionResultTableTotalRefetchableQuery = {
  response: InspectionResultTableTotalRefetchableQuery$data;
  variables: InspectionResultTableTotalRefetchableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InspectionResultTableTotalRefetchableQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "InspectionResultTable_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InspectionResultTableTotalRefetchableQuery",
    "selections": [
      {
        "alias": "allInstancesConnection",
        "args": (v1/*: any*/),
        "concreteType": "inspection_resultConnection",
        "kind": "LinkedField",
        "name": "inspection_result_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "inspection_resultEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "inspection_result",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8f7f9e4a4a65b44d71b11963b7895b7e",
    "id": null,
    "metadata": {},
    "name": "InspectionResultTableTotalRefetchableQuery",
    "operationKind": "query",
    "text": "query InspectionResultTableTotalRefetchableQuery(\n  $where: inspection_result_bool_exp!\n) {\n  ...InspectionResultTable_total_3FC4Qo\n}\n\nfragment InspectionResultTable_total_3FC4Qo on query_root {\n  allInstancesConnection: inspection_result_connection(where: $where) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e30b1b8b4323196caed70e6e94b47dc3";

export default node;
