import withOfficeId, { OfficeProps } from "../../utils/withOfficeId";
import GCAdminObservations from "../../component/observation/GCAdminObservations";
const GCOfficeObservations: React.FC<OfficeProps> = ({ officeId }) => {
  const projWhere = { gc_office_id: { _eq: officeId } };
  return (
    <GCAdminObservations
      optionsQueryVars={{
        projWhere,
        gcBuWhere: { id: { _is_null: true } },
        gcOfficeWhere: { id: { _is_null: true } },
        gcDivisionWhere: { id: { _is_null: true } },
      }}
      projWhere={projWhere}
      organizationId={officeId}
      organizationLevel="office"
      gcEmployeeWhere={{
        _or: [
          { gc_offices: { gc_office_id: { _eq: officeId } } },
          {
            employee_projects: {
              project: { gc_office_id: { _eq: officeId } },
              _or: [
                { direct_project_assign: { _eq: true } },
                { direct_project_assign: { _is_null: true } },
              ],
            },
          },
        ],
      }}
    />
  );
};
export default withOfficeId(GCOfficeObservations);
