/**
 * @generated SignedSource<<54ff612eb151cb8b0a367ba3d92ab1f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { orientation_result_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SiteOrientationSlidesViewer_UpsertOrientationResult_Mutation$variables = {
  objects: ReadonlyArray<orientation_result_insert_input>;
};
export type SiteOrientationSlidesViewer_UpsertOrientationResult_Mutation$data = {
  readonly insert_orientation_result: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type SiteOrientationSlidesViewer_UpsertOrientationResult_Mutation = {
  response: SiteOrientationSlidesViewer_UpsertOrientationResult_Mutation$data;
  variables: SiteOrientationSlidesViewer_UpsertOrientationResult_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "orientation_result_pkey",
          "update_columns": [
            "total_slides",
            "viewed_slides",
            "signature_url",
            "status",
            "expired_at",
            "completed_at",
            "group_id",
            "result_inserted_by_uid"
          ]
        }
      }
    ],
    "concreteType": "orientation_result_mutation_response",
    "kind": "LinkedField",
    "name": "insert_orientation_result",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteOrientationSlidesViewer_UpsertOrientationResult_Mutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteOrientationSlidesViewer_UpsertOrientationResult_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "275a37265ceb1e7025b120558b2d9565",
    "id": null,
    "metadata": {},
    "name": "SiteOrientationSlidesViewer_UpsertOrientationResult_Mutation",
    "operationKind": "mutation",
    "text": "mutation SiteOrientationSlidesViewer_UpsertOrientationResult_Mutation(\n  $objects: [orientation_result_insert_input!]!\n) {\n  insert_orientation_result(objects: $objects, on_conflict: {constraint: orientation_result_pkey, update_columns: [total_slides, viewed_slides, signature_url, status, expired_at, completed_at, group_id, result_inserted_by_uid]}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "2ac4e406d8ef9eb648a95c4e87ec88e1";

export default node;
