import React from "react";
import { PermitSettingsComponentProps } from "../../utils/PermitSettingsTypes";
import PermitGcProjectSettingCheckbox from "./basic/PermitGcProjectSettingCheckbox";
import PermitChecklistsSettingTables from "./basic/PermitChecklistsSettingTables";

const CeilingAccessSettings: React.FC<PermitSettingsComponentProps> = ({
  gcPermitCheckListItems,
  queryVariables,
  project,
  data,
  checklistGc,
  mainGc,
}) => {
  return (
    <>
      <PermitGcProjectSettingCheckbox
        {...{
          mainGc,
          project_setting: project?.project_setting,
          field: 'only_submitter_sign_the_ceiling_access_permit'
        }}
      />
      <PermitChecklistsSettingTables
        {...{
          checklistGc,
          data,
          project,
          gcPermitCheckListItems,
          queryVariables,
          checklistItemsType: "ceiling_access",
        }}
      />
    </>
  );
};
export default CeilingAccessSettings;
