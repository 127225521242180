/**
 * @generated SignedSource<<ad9157dc4cc515d7d17b3f69a9895fbd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_crew_bool_exp, project_crew_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CrewDetails_UpdateCrewLeadMutation$variables = {
  _setLeadCrew?: project_crew_set_input | null | undefined;
  where: project_crew_bool_exp;
};
export type CrewDetails_UpdateCrewLeadMutation$data = {
  readonly update_project_crew: {
    readonly returning: ReadonlyArray<{
      readonly deleted_at: string | null | undefined;
      readonly id: string;
      readonly lead_foreman_project_worker: {
        readonly id: string;
        readonly pk: string;
        readonly subcontractor_worker: {
          readonly id: string;
        } | null | undefined;
        readonly user: {
          readonly id: string;
          readonly name: string;
          readonly pk: string;
          readonly role: string;
          readonly username: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly name: string;
      readonly pk: string;
      readonly project: {
        readonly id: string;
        readonly name: string;
        readonly pk: string;
      };
      readonly project_subcontractor_crew_onsite_periods: ReadonlyArray<{
        readonly id: string;
      }>;
      readonly project_workers: ReadonlyArray<{
        readonly hard_hat_number: string | null | undefined;
        readonly id: string;
        readonly pk: string;
        readonly title: {
          readonly translation: {
            readonly clientText: string;
            readonly en: string;
            readonly es: string;
            readonly id: string;
            readonly original: string;
            readonly pk: string;
            readonly pt: string;
          };
        } | null | undefined;
        readonly user: {
          readonly email: string | null | undefined;
          readonly name: string;
        } | null | undefined;
        readonly worker_role: string;
      }>;
      readonly project_workers_aggregate: {
        readonly aggregate: {
          readonly count: number;
        } | null | undefined;
      };
      readonly subcontractor: {
        readonly id: string;
        readonly name: string;
        readonly pk: string;
      };
    }>;
  } | null | undefined;
};
export type CrewDetails_UpdateCrewLeadMutation = {
  response: CrewDetails_UpdateCrewLeadMutation$data;
  variables: CrewDetails_UpdateCrewLeadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_setLeadCrew"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v2 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "_setLeadCrew"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deleted_at",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "user": {
        "name": "asc"
      }
    }
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "subcontractor_worker": {}
    }
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worker_role",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hard_hat_number",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "es",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pt",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "original",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v15 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
],
v16 = {
  "_is_null": true
},
v17 = [
  (v3/*: any*/)
],
v18 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "where",
      "value": {
        "closed_at": (v16/*: any*/)
      }
    }
  ],
  "concreteType": "project_subcontractor_crew_onsite_period",
  "kind": "LinkedField",
  "name": "project_subcontractor_crew_onsite_periods",
  "plural": true,
  "selections": (v17/*: any*/),
  "storageKey": "project_subcontractor_crew_onsite_periods(where:{\"closed_at\":{\"_is_null\":true}})"
},
v19 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "where",
      "value": {
        "deleted_at": (v16/*: any*/),
        "subcontractor_worker": {}
      }
    }
  ],
  "concreteType": "project_worker_aggregate",
  "kind": "LinkedField",
  "name": "project_workers_aggregate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "project_worker_aggregate_fields",
      "kind": "LinkedField",
      "name": "aggregate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "project_workers_aggregate(where:{\"deleted_at\":{\"_is_null\":true},\"subcontractor_worker\":{}})"
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "worker",
  "kind": "LinkedField",
  "name": "subcontractor_worker",
  "plural": false,
  "selections": (v17/*: any*/),
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v23 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v24 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v25 = [
  (v3/*: any*/),
  (v23/*: any*/),
  (v24/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CrewDetails_UpdateCrewLeadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "project_crew_mutation_response",
        "kind": "LinkedField",
        "name": "update_project_crew",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_crew",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "project_workers",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker_title",
                    "kind": "LinkedField",
                    "name": "title",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "text_translation",
                        "kind": "LinkedField",
                        "name": "translation",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": "clientText",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__original_clientText",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "project_workers(order_by:{\"user\":{\"name\":\"asc\"}},where:{\"subcontractor_worker\":{}})"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "subcontractor",
                "plural": false,
                "selections": (v15/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": (v15/*: any*/),
                "storageKey": null
              },
              (v18/*: any*/),
              (v19/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "lead_foreman_project_worker",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v20/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CrewDetails_UpdateCrewLeadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "project_crew_mutation_response",
        "kind": "LinkedField",
        "name": "update_project_crew",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_crew",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "project_workers",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker_title",
                    "kind": "LinkedField",
                    "name": "title",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "text_translation",
                        "kind": "LinkedField",
                        "name": "translation",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/),
                          {
                            "alias": "clientText",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "original",
                            "storageKey": null
                          },
                          {
                            "alias": "clientText",
                            "args": null,
                            "filters": null,
                            "handle": "clientText",
                            "key": "",
                            "kind": "ScalarHandle",
                            "name": "original"
                          }
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v14/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "project_workers(order_by:{\"user\":{\"name\":\"asc\"}},where:{\"subcontractor_worker\":{}})"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "subcontractor",
                "plural": false,
                "selections": (v25/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": (v25/*: any*/),
                "storageKey": null
              },
              (v18/*: any*/),
              (v19/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "lead_foreman_project_worker",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v20/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/),
                      (v5/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3703e478472167869e834c4a9becf474",
    "id": null,
    "metadata": {},
    "name": "CrewDetails_UpdateCrewLeadMutation",
    "operationKind": "mutation",
    "text": "mutation CrewDetails_UpdateCrewLeadMutation(\n  $where: project_crew_bool_exp!\n  $_setLeadCrew: project_crew_set_input\n) {\n  update_project_crew(where: $where, _set: $_setLeadCrew) {\n    returning {\n      id\n      pk: id\n      name\n      deleted_at\n      project_workers(where: {subcontractor_worker: {}}, order_by: {user: {name: asc}}) {\n        id\n        pk: id\n        worker_role\n        hard_hat_number\n        title {\n          translation {\n            id\n            en\n            es\n            pt\n            original\n            pk: id\n            clientText: original\n          }\n          id\n        }\n        user {\n          name\n          email\n          id\n        }\n      }\n      subcontractor {\n        id\n        pk: id\n        name\n      }\n      project {\n        id\n        pk: id\n        name\n      }\n      project_subcontractor_crew_onsite_periods(where: {closed_at: {_is_null: true}}) {\n        id\n      }\n      project_workers_aggregate(where: {subcontractor_worker: {}, deleted_at: {_is_null: true}}) {\n        aggregate {\n          count\n        }\n      }\n      lead_foreman_project_worker {\n        id\n        subcontractor_worker {\n          id\n        }\n        pk: id\n        user {\n          id\n          pk: id\n          name\n          username\n          role\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4283d0e709cdbc34509a208ebc08abbd";

export default node;
