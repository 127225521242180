import clsx from "clsx";
import React, { useState, useMemo, useEffect } from "react";
import { DeliveryType } from "../utilities/sitedeliveryTypes";
import GCProjectCalendarSitedeliveryDeliveriesBlock from "./GCProjectCalendarSitedeliveryDeliveriesBlock";
import layoutBlocks, {
  PositionedDeliveryType,
} from "../utilities/layoutBlocks";
import dayjs from "dayjs";

export interface GCProjectCalendarSitedeliveryDeliveriesColumnProps {
  deliveries: DeliveryType[];
  date: dayjs.Dayjs;
  keepTimeLineInView: boolean;
  handleDeliverySelect?: (deliveryId: string) => void;
}

const GCProjectCalendarSitedeliveryDeliveriesColumn: React.FC<
  GCProjectCalendarSitedeliveryDeliveriesColumnProps
> = (props) => {
  const hour = dayjs().hour();
  const secondsPerHour = 60 * 60;
  const [startHour, setStartHour] = useState<0 | 6 | 17>(
    hour < 5 ? 0 : hour < 17 ? 6 : 17,
  );

  const positionedDeliveries: PositionedDeliveryType[] = useMemo(
    () =>
      layoutBlocks(
        props.deliveries,
        secondsPerHour * (props.keepTimeLineInView ? 0 : startHour),
        secondsPerHour *
          (props.keepTimeLineInView
            ? 24
            : startHour === 0
            ? 5
            : startHour === 6
            ? 17
            : 24),
        props.date,
      ),
    [props.deliveries, startHour],
  );

  useEffect(() => {
    if (!props.keepTimeLineInView) {
      const interval = setInterval(() => {
        const currentHour = dayjs().hour();
        currentHour < 5
          ? setStartHour(0)
          : currentHour < 17
          ? setStartHour(6)
          : setStartHour(17);
      }, 60000);
      return () => clearInterval(interval);
    }
  }, []);

  return (
    <>
      {positionedDeliveries.map(({ delivery, bottom, offset, top, width }) => (
        <div
          key={delivery.id}
          className="absolute rounded-0.25 left-0"
          style={{
            top: `${top}%`,
            bottom: `${bottom}%`,
            left: `${offset}%`,
            width: `${width}%`,
            // right: 300 - (offset + width),
          }}
        >
          <GCProjectCalendarSitedeliveryDeliveriesBlock
            deliveryInfo={delivery}
            handleDeliverySelect={props.handleDeliverySelect}
          />
        </div>
      ))}
    </>
  );
};

export default GCProjectCalendarSitedeliveryDeliveriesColumn;
