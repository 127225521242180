import { MenuOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useRelayEnvironment } from "react-relay/hooks";
import AddChecklistItemModal, {
  AddChecklistItemModalRef,
} from "src/common/components/dialogs/AddChecklistItemModal";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import BaseTable from "src/common/components/tables/basic/BaseTable";
import TableTitle from "src/common/components/tables/basic/TableTitle";
import DraggableTableWrapper from "src/common/components/tables/draggable/DraggableTableWrapper";

import noPropagation from "src/common/functions/noPropagation";
import ProjectProps from "src/common/types/manual/ProjectProps";
import * as uuid from "uuid";
import {
  Checklist_Item_Type_Enum,
  Checklist_Item_Config_Type_Enum,
  ChecklistItemFragmentFragment,
  GcProjectReportsDailySettingsDocument,
  GcProjectReportsDailySettingsQuery,
  GcProjectReportsDailySettingsQueryVariables,
  useDeleteChecklistItemConfigMutation,
  useDeleteChecklistItemMutation,
  useInsertChecklistItemConfigMutation,
  useInsertChecklistItemMutation,
  useUpdateChecklistItemMutation,
  useUpdateChecklistItemNotifyeeMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useSuspenseQuery } from "@apollo/client";
import AddDRWithPTPSettings from "./basic/AddDRWithPTPSettings";
import compareTwoLists from "src/common/components/ComparingTwoLists";

interface Props extends ProjectProps {}

const CHECKLIST_CONFIGS = [
  {
    value: "option_yes",
    label: "Yes",
  },
  {
    value: "option_no",
    label: "No",
  },
  {
    value: "option_na",
    label: "N/A",
  },
  // {
  //   value: 'response_required',
  //   label: 'Response Required',
  // },
  {
    value: "response_on_yes",
    label: "When Yes, response required",
  },
  {
    value: "response_on_no",
    label: "When No, response required",
  },
  {
    value: "notify_yes",
    label: "When Yes, Notify",
  },
  {
    value: "notify_no",
    label: "When No, Notify",
  },
];

type SelectEmailsModalValues = { notifyees: Array<string> };
type SelectEmailsModalRef = FModalRef<SelectEmailsModalValues> | null;
const SelectEmailsModal = forwardRef<
  SelectEmailsModalRef,
  {
    contacts: Array<{
      email: string;
      name: string;
    }>;

    onCancel: () => void;
    onCreate: (values: SelectEmailsModalValues) => void;
  }
>(({ contacts, onCreate, onCancel }, ref) => {
  const modal = useRef<SelectEmailsModalRef>(null);
  const [loading, setLoading] = useState(false);

  useImperativeHandle<SelectEmailsModalRef, SelectEmailsModalRef>(
    ref,
    () => modal.current,
  );

  return (
    <FModal
      title="Select Notifyees"
      ref={modal}
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        modal.current?.form.resetFields();
      }}
      onOk={() => {
        const form = modal.current?.form;
        if (!form) return;
        form
          .validateFields()
          .then(async (v) => {
            setLoading(true);
            await onCreate(v);
            modal.current?.close();
            modal.current?.form.resetFields();
            setLoading(false);
          })
          .catch((e) => {
            console.log("Validate Failed:", e);
          });
      }}
    >
      <FModal.Select
        name="notifyees"
        props={{
          mode: "tags",
          placeholder: "Select notifyees",
          options: [...contacts].map((c) => ({
            key: c.email + c.name,
            value: c.email,
            label: `${c.name}, ${c.email}`,
          })),
        }}
      />
    </FModal>
  );
});

interface PermitChecklistTableProps {
  checklistItems: Array<ChecklistItemFragmentFragment>;
  onReorderItem: (args: {
    item: ChecklistItemFragmentFragment;
    newSortIndex: number;
  }) => any;
  editing: boolean;
  onUpdateItem: (args: {
    key: Checklist_Item_Config_Type_Enum;
    val: boolean;
    item: ChecklistItemFragmentFragment;
  }) => any;
  onDeleteItem: (args: { item: ChecklistItemFragmentFragment }) => any;
  loading: boolean;
  onAddItemPress: () => any;
  onNotifyeesPress: (args: { item: ChecklistItemFragmentFragment }) => any;
  title: string;
}

const PermitChecklistTable = ({
  checklistItems,
  onReorderItem,
  editing,
  onUpdateItem,
  onDeleteItem,
  onAddItemPress,
  loading,
  onNotifyeesPress,
  title,
}: PermitChecklistTableProps) => {
  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <DraggableTableWrapper
        onMoveRow={async (dragIndex: number, hoverIndex: number) => {
          if (dragIndex === hoverIndex) {
            return;
          }

          const dragStep = checklistItems[dragIndex];
          const hoverStep = checklistItems[hoverIndex];

          let newSortIndex = 0;
          if (hoverIndex === 0) {
            newSortIndex = (hoverStep.sort_index as any) / 2;
          } else if (hoverIndex === checklistItems.length - 1) {
            newSortIndex = ((dragStep.sort_index as any) +
              hoverStep?.sort_index) as any;
          } else {
            const nextHoverStep =
              dragIndex > hoverIndex
                ? checklistItems[hoverIndex - 1]
                : checklistItems[hoverIndex + 1];
            newSortIndex =
              ((hoverStep.sort_index as any) + nextHoverStep.sort_index) / 2;
          }

          onReorderItem({
            item: dragStep,
            newSortIndex,
          });
        }}
      >
        {{
          table: (() => {
            const columns: Array<any> = [];

            if (editing) {
              columns.push({
                title: "Reorder",
                key: "reorder",
                width: 80,
                render: () => (
                  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
                ),
              });
            }

            columns.push(
              ...[
                {
                  title: "Description",
                  key: "description",
                  size: "sm",
                  dataIndex: ["description", "clientText"],
                },
                {
                  title: "Item Options",
                  key: "options",
                  size: "md",
                  render: (item: ChecklistItemFragmentFragment) => {
                    const availableOptions: {
                      label: string;
                      value: Checklist_Item_Config_Type_Enum;
                    }[] = [
                      {
                        value: Checklist_Item_Config_Type_Enum.OptionYes,
                        label: "Yes",
                      },
                      {
                        value: Checklist_Item_Config_Type_Enum.OptionNo,
                        label: "No",
                      },
                      {
                        value: Checklist_Item_Config_Type_Enum.OptionNa,
                        label: "N/A",
                      },
                    ];

                    if (
                      !!item.checklist_item_configs.find(
                        (c) => c.config === "option_yes",
                      )
                    ) {
                      availableOptions.push(
                        {
                          value: Checklist_Item_Config_Type_Enum.ResponseOnYes,
                          label: "When Yes, response required",
                        },
                        {
                          value: Checklist_Item_Config_Type_Enum.NotifyYes,
                          label: "When Yes, Notify",
                        },
                      );
                    }

                    if (
                      !!item.checklist_item_configs.find(
                        (c) =>
                          c.config === Checklist_Item_Config_Type_Enum.OptionNo,
                      )
                    ) {
                      availableOptions.push(
                        {
                          value: Checklist_Item_Config_Type_Enum.ResponseOnNo,
                          label: "When No, response required",
                        },
                        {
                          value: Checklist_Item_Config_Type_Enum.NotifyNo,
                          label: "When No, Notify",
                        },
                      );
                    }

                    return editing ? (
                      <Space>
                        {availableOptions.map((o) => (
                          <Checkbox
                            key={o.value}
                            checked={
                              !!item.checklist_item_configs.find(
                                (c) => c.config === o.value,
                              )
                            }
                            onChange={(e) => {
                              onUpdateItem({
                                key: o.value,
                                val: !!e.target.checked,
                                item,
                              });
                            }}
                          >
                            {o.label}
                          </Checkbox>
                        ))}
                        {!!item.checklist_item_configs.find(
                          (c) =>
                            c.config === "notify_yes" ||
                            c.config === "notify_no",
                        ) && (
                          <>
                            {editing ? (
                              <Button
                                onClick={() => {
                                  onNotifyeesPress({ item });
                                }}
                              >
                                Notify{" "}
                                {item.checklist_item_notify_emails.length}
                              </Button>
                            ) : (
                              <span>
                                Notify{" "}
                                {item.checklist_item_notify_emails.length}
                              </span>
                            )}
                          </>
                        )}
                      </Space>
                    ) : (
                      <Space size="large">
                        {[...item.checklist_item_configs]
                          .sort((a, b) =>
                            CHECKLIST_CONFIGS.findIndex(
                              (c) => c.value === a.config,
                            ) >
                            CHECKLIST_CONFIGS.findIndex(
                              (c) => c.value === b.config,
                            )
                              ? 1
                              : -1,
                          )
                          .map((o) => (
                            <span key="o.name">
                              {
                                CHECKLIST_CONFIGS.find(
                                  (c) => c.value === o.config,
                                )!.label
                              }
                            </span>
                          ))}
                      </Space>
                    );
                  },
                },
              ],
            );

            if (editing) {
              columns.push({
                title: "Actions",
                key: "actions",
                width: 100,
                render: (item: ChecklistItemFragmentFragment) => (
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={noPropagation(() => {
                      onDeleteItem({
                        item,
                      });
                    })}
                    onCancel={noPropagation()}
                    okText="Yes"
                    cancelText="Cancel"
                  >
                    <Button danger type="link" onClick={noPropagation()}>
                      Remove
                    </Button>
                  </Popconfirm>
                ),
              });
            }

            return (
              <BaseTable
                title={() => (
                  <TableTitle
                    title={title}
                    titleComponent={
                      <>
                        {editing ? (
                          <Button
                            type="primary"
                            loading={loading}
                            onClick={() => {
                              onAddItemPress();
                            }}
                          >
                            Add Item
                          </Button>
                        ) : undefined}
                      </>
                    }
                  />
                )}
                dataSource={[...checklistItems].sort((a, b) =>
                  (a.sort_index as any) > (b.sort_index as any) ? 1 : -1,
                )}
                rowKey={(item) => item.id}
                loading={loading}
                columns={columns}
                pagination={false}
              />
            );
          })(),
        }}
      </DraggableTableWrapper>
    </Space>
  );
};

interface GCProjectReportsDailySettingsProps {
  projectId: string;
}

const GCProjectReportsDailySettings: React.FC<
  GCProjectReportsDailySettingsProps
> = ({ projectId }) => {
  const [editing, setEditing] = useState(false);
  const [addingItemType, setAddingItemType] = useState<"worker" | "gc">();

  const environment = useRelayEnvironment();

  const { data, refetch } = useSuspenseQuery<
    GcProjectReportsDailySettingsQuery,
    GcProjectReportsDailySettingsQueryVariables
  >(GcProjectReportsDailySettingsDocument, { variables: { projectId } });
  const [updateChecklistItem] = useUpdateChecklistItemMutation();
  const [deleteChecklistItem] = useDeleteChecklistItemMutation();
  const [insertChecklistItem] = useInsertChecklistItemMutation();
  const [insertChecklistItemConfig] = useInsertChecklistItemConfigMutation();
  const [deleteChecklistItemConfig] = useDeleteChecklistItemConfigMutation();
  const [updateChecklistItemNotifyees] =
    useUpdateChecklistItemNotifyeeMutation();
  const project = data.project_by_pk;
  const checklistItems = data.checklist_item;
  const employeeContacts = (project?.project_employees || []).map((e) => ({
    name: e.employee.user.name,
    email: e.employee.user.email!,
  }));
  const gcChecklistItems = checklistItems.filter((c) => c.type === "gc_daily");
  const workerChecklistItems = checklistItems.filter(
    (c) => c.type === "gc_worker_daily_log",
  );
  const addItemModal = useRef<AddChecklistItemModalRef>(null);
  const selectEmailsModal = useRef<SelectEmailsModalRef>(null);
  const [selectingEmailsItem, setSelectingEmailsItem] =
    useState<ChecklistItemFragmentFragment>();

  const tableProps: Omit<
    PermitChecklistTableProps,
    "title" | "checklistItems" | "onAddItemPress"
  > = {
    loading: false,
    editing,
    onNotifyeesPress: ({ item }) => {
      setSelectingEmailsItem(item);
      selectEmailsModal.current?.form.setFieldsValue({
        notifyees: item.checklist_item_notify_emails.map((e) => e.email),
      });
      selectEmailsModal.current?.open();
    },
    onUpdateItem: ({ item, key, val }) => {
      if (val) {
        const id = uuid.v4();

        insertChecklistItemConfig({
          variables: {
            object: {
              id,
              checklist_item_id: item.id,
              config: key,
            },
          },
          optimisticResponse: {
            insert_checklist_item_config_one: {
              __typename: "checklist_item_config",
              config: key,
              id,
            },
          },
          update: (cache, result) => {
            const insertedItem = result.data?.insert_checklist_item_config_one;
            if (!insertedItem)
              throw new Error("Server return null for insertion");
            cache.modify<typeof item>({
              id: cache.identify(item),
              fields: {
                checklist_item_configs: (currConfigs, { toReference }) => {
                  const insertedItemRef = toReference(insertedItem);
                  if (!insertedItemRef) return currConfigs;
                  return [...currConfigs, insertedItemRef];
                },
              },
            });
          },
        });
      } else {
        const deletingKeys = [key];

        if (key === "option_yes") {
          deletingKeys.push(
            Checklist_Item_Config_Type_Enum.ResponseOnYes,
            Checklist_Item_Config_Type_Enum.NotifyYes,
          );
        } else if (key === "option_no") {
          deletingKeys.push(
            Checklist_Item_Config_Type_Enum.ResponseOnNo,
            Checklist_Item_Config_Type_Enum.NotifyNo,
          );
        }

        deleteChecklistItemConfig({
          variables: {
            where: {
              config: { _in: deletingKeys },
              checklist_item_id: { _eq: item.id },
            },
          },
          optimisticResponse: {
            delete_checklist_item_config: {
              affected_rows: deletingKeys.length,
            },
          },
          update: (cache, result) => {
            const deletedCount =
              result.data?.delete_checklist_item_config?.affected_rows || 0;
            if (deletedCount)
              cache.modify<typeof item>({
                id: cache.identify(item),
                fields: {
                  checklist_item_configs: (currItemConfigs, { readField }) =>
                    currItemConfigs.filter((config) => {
                      const configKey = readField("config", config) as
                        | Checklist_Item_Config_Type_Enum
                        | undefined
                        | null;
                      return configKey && !deletingKeys.includes(configKey);
                    }),
                },
              });
          },
        });
      }
    },
    onDeleteItem: ({ item }) => {
      deleteChecklistItem({
        variables: {
          id: item.id,
        },
        optimisticResponse: { delete_checklist_item_by_pk: { id: item.id } },
        update: (cache, result) => {
          const deletedItem = result.data?.delete_checklist_item_by_pk;
          if (!deletedItem)
            throw new Error("Server returned null for deleting item");
          cache.modify<typeof data>({
            fields: {
              checklist_item: (currList, { readField }) =>
                currList.filter((item) => {
                  const itemId = readField("id", item);
                  return itemId && itemId !== deletedItem.id;
                }),
            },
          });
        },
      });
    },
    onReorderItem: ({ item, newSortIndex }) => {
      const toSet = { sort_index: newSortIndex };
      updateChecklistItem({
        variables: {
          id: item.id,
          _set: toSet,
        },
        optimisticResponse: {
          update_checklist_item_by_pk: {
            __typename: "checklist_item",
            ...item,
            ...toSet,
          },
        },
      });
    },
  };

  const onEmailsChangeForSelectedItem = async (newEmails: string[]) => {
    if (selectingEmailsItem) {
      const [toInsert, toDelete] = compareTwoLists(
        newEmails,
        selectingEmailsItem.checklist_item_notify_emails.map((e) => e.email),
      );
      const objects = toInsert.map((email) => ({
        email,
        id: uuid.v4(),
        checklist_item_id: selectingEmailsItem.id,
      }));
      await updateChecklistItemNotifyees({
        variables: {
          deleteWhere: {
            email: { _in: toDelete },
            checklist_item_id: { _eq: selectingEmailsItem.id },
          },
          objects,
        },
        optimisticResponse: {
          insert_checklist_item_notify_email: {
            returning: objects.map((obj) => ({
              __typename: "checklist_item_notify_email",
              id: obj.id,
              email: obj.email,
            })),
          },
          delete_checklist_item_notify_email: {
            affected_rows: toDelete.length,
          },
        },
        update: (cache, result) => {
          const insertedItems =
            result.data?.insert_checklist_item_notify_email?.returning || [];
          cache.modify<typeof selectingEmailsItem>({
            id: cache.identify(selectingEmailsItem),
            fields: {
              checklist_item_notify_emails: (
                currList,
                { readField, toReference },
              ) => [
                ...currList.filter((curr) => {
                  const email = readField("email", curr);
                  return typeof email === "string" && !toDelete.includes(email);
                }),
                ...insertedItems.map((entry) => toReference(entry)!),
              ],
            },
          });
        },
      });
    } else {
      throw new Error("Id not found to be updated");
    }

    selectEmailsModal.current?.close();
  };
  return (
    <div className="flex flex-col w-full">
      <div>
        <PermitChecklistTable
          {...tableProps}
          checklistItems={gcChecklistItems}
          title={"GC Checklist"}
          onAddItemPress={() => {
            setAddingItemType("gc");
            addItemModal.current?.open();
          }}
        />
        <PermitChecklistTable
          {...tableProps}
          checklistItems={workerChecklistItems}
          title={"Subcontractor Checklist"}
          onAddItemPress={() => {
            setAddingItemType("worker");
            addItemModal.current?.open();
          }}
        />
        <Space>
          {editing ? (
            <Button type={"link"} onClick={() => setEditing(false)}>
              Done
            </Button>
          ) : (
            <Button
              type={"primary"}
              onClick={noPropagation(() => {
                setEditing(true);
              })}
            >
              Edit Checklists
            </Button>
          )}
        </Space>
      </div>

      <SelectEmailsModal
        ref={selectEmailsModal}
        contacts={employeeContacts}
        onCancel={() => selectEmailsModal.current?.close()}
        onCreate={async (values) =>
          await onEmailsChangeForSelectedItem(values.notifyees)
        }
      />
      <AddChecklistItemModal
        ref={addItemModal}
        contacts={employeeContacts}
        onCreate={async (values) => {
          const checklistItems =
            addingItemType === "gc" ? gcChecklistItems : workerChecklistItems;

          await insertChecklistItem({
            variables: {
              object: {
                type:
                  addingItemType === "gc"
                    ? Checklist_Item_Type_Enum.GcDaily
                    : Checklist_Item_Type_Enum.GcWorkerDailyLog,
                data_type: "base",
                project_id: projectId,
                checklist_item_configs: {
                  data: values.selectedOptions.map((o) => ({
                    config: o as any,
                  })),
                },
                checklist_item_notify_emails: {
                  data: (values.notifyees ?? []).map((email) => ({
                    email,
                  })),
                },
                sort_index:
                  (checklistItems.length > 0
                    ? Math.max(
                        ...checklistItems.map((c) => c.sort_index as number),
                      )
                    : 0) + 1,
                description: {
                  data: {
                    original: values.description,
                    en: values.description,
                  },
                },
              },
            },
            update: (cache, result) => {
              const newItem = result.data?.insert_checklist_item_one;
              if (!newItem)
                throw new Error(
                  "Server return null for add new checklist item",
                );
              cache.modify<typeof data>({
                fields: {
                  checklist_item: (currList, { toReference }) => {
                    const newItemRef = toReference(newItem);
                    if (!newItemRef) return currList;
                    return [...currList, newItemRef];
                  },
                },
              });
            },
          });
        }}
        onCancel={() => {
          setAddingItemType(undefined);
          addItemModal.current?.close();
        }}
      />
      {project && (
        <AddDRWithPTPSettings addDrWithPtpProjectDataFrag={project} />
      )}
    </div>
  );
};

export default withCustomSuspense(GCProjectReportsDailySettings);
