import React, { useState } from "react";
import ChartsDemoTable, { ChartsTableDemoDataType } from "./ChartsDemoTable";
import ChartWrapperCard from "../../../../../common/components/charts/ChartWrapperCard";
import ShowPieChart from "../../../../../common/components/charts/pie/ShowPieChart";
import chartColorList from "../../../../../common/components/lists/chartColorList";
import { Card, Typography } from "antd";

const PieChartDemo: React.FC = () => {
  const [dataSource, setDataSource] = useState<ChartsTableDemoDataType[]>([]);
  const [valueTitle, setValueTitle] = useState<string>("Value Title");

  return (
    <div className={`grid desktop:grid-cols-2 gap-1`}>
      <Card title={`Pie Chart Builder`}>
        <ChartsDemoTable
          dataSource={dataSource.map((d, i) => ({
            ...d,
            key: i.toString(),
          }))}
          onDataSourceChange={(newData) => {
            setDataSource(newData);
          }}
          onSharedValueTitleChange={setValueTitle}
          sharedValueTitle={valueTitle}
        />
      </Card>
      <ChartWrapperCard
        extraTop={<Typography.Text code>ShowPieChart.tsx</Typography.Text>}
        title={`Pie Chart`}
      >
        <ShowPieChart
          valueTitle={valueTitle}
          dataSource={dataSource.map((data, i) => ({
            name: data.name,
            color: chartColorList[i],
            value: data.value,
          }))}
        />
      </ChartWrapperCard>
    </div>
  );
};

export default PieChartDemo;
