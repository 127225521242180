import * as uuid from "uuid";
import {
  GetObservationRequirementsDataQuery,
  useUpdateObsRequirementEmployeeTitlesMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { canHaveDecorators } from "typescript";

const useUpdateReqEmpTitles = (
  allObsRequirements: GetObservationRequirementsDataQuery["observation_requirement"],
) => {
  const [updateObsReqEmpTitles, { loading: updatingTitles }] =
    useUpdateObsRequirementEmployeeTitlesMutation();
  const updateTitlesList = async ({
    editingObsReq,
    toDelete,
    toInsert,
  }: {
    toInsert: string[];
    toDelete: string[];
    editingObsReq: GetObservationRequirementsDataQuery["observation_requirement"][number];
  }) => {
    const toInsertObjs = toInsert.map((empTitleId) => ({
      id: uuid.v4(),
      employee_title_id: empTitleId,
      observation_requirement_id: editingObsReq.id,
    }));

    return await updateObsReqEmpTitles({
      variables: {
        deleteWhere: {
          _or: [
            {
              employee_title_id: { _in: toDelete },
              observation_requirement_id: { _eq: editingObsReq.id },
            },
            {
              employee_title_id: { _in: toInsert },
              observation_requirement: {
                id: { _neq: editingObsReq.id },
                archived_at: { _is_null: true },
              },
            },
          ],
        },
        objects: toInsertObjs,
      },
      optimisticResponse: {
        delete_observation_requirement_employee_title: {
          affected_rows: toDelete.length,
        },
        insert_observation_requirement_employee_title: {
          returning: toInsertObjs.map((obj) => ({
            ...obj,
            __typename: "observation_requirement_employee_title",
          })),
        },
      },
      update: (cache, result) => {
        const insertedItems =
          result.data?.insert_observation_requirement_employee_title;
        const insertItemsSameCount =
          !toInsertObjs.length ||
          insertedItems?.returning.length === toInsertObjs.length;
        if (!insertItemsSameCount) {
          throw new Error("failed to insert some titles");
        }
        allObsRequirements.forEach((req) => {
          cache.modify<typeof req>({
            id: cache.identify(req),
            fields: {
              employee_titles: (currList = [], { readField, toReference }) => {
                if (req.id === editingObsReq.id) {
                  return [
                    ...(insertedItems?.returning || []).map(
                      (obj) => toReference(obj)!,
                    ),
                    ...currList.filter((item) => {
                      const titleId = readField("employee_title_id", item);
                      return (
                        typeof titleId === "string" &&
                        !toDelete.includes(titleId)
                      );
                    }),
                  ];
                } else {
                  return currList.filter((item) => {
                    const titleId = readField("employee_title_id", item);
                    return (
                      typeof titleId === "string" && !toInsert.includes(titleId)
                    );
                  });
                }
              },
            },
          });
        });
      },
    });
  };
  return [updateTitlesList, updatingTitles] as const;
};
export default useUpdateReqEmpTitles;
