/**
 * @generated SignedSource<<5098739671c1709d59a4ad73af97d556>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_insert_input, project_local_city_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCNewProjectStepGeneralMutation$variables = {
  projectLocalCityobjects: ReadonlyArray<project_local_city_insert_input>;
  projectObject: project_insert_input;
};
export type GCNewProjectStepGeneralMutation$data = {
  readonly insert_project_local_city: {
    readonly returning: ReadonlyArray<{
      readonly city: {
        readonly name: string;
      };
      readonly city_id: string;
    }>;
  } | null | undefined;
  readonly insert_project_one: {
    readonly completed: boolean;
    readonly id: string;
    readonly name: string;
    readonly pk: string;
    readonly project_employees: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
};
export type GCNewProjectStepGeneralMutation = {
  response: GCNewProjectStepGeneralMutation$data;
  variables: GCNewProjectStepGeneralMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectLocalCityobjects"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectObject"
},
v2 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "projectObject"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "project_employee",
  "kind": "LinkedField",
  "name": "project_employees",
  "plural": true,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "projectLocalCityobjects"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCNewProjectStepGeneralMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "project",
        "kind": "LinkedField",
        "name": "insert_project_one",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "project_local_city_mutation_response",
        "kind": "LinkedField",
        "name": "insert_project_local_city",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_local_city",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "city",
                "kind": "LinkedField",
                "name": "city",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCNewProjectStepGeneralMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "project",
        "kind": "LinkedField",
        "name": "insert_project_one",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          },
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "project_local_city_mutation_response",
        "kind": "LinkedField",
        "name": "insert_project_local_city",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_local_city",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "city",
                "kind": "LinkedField",
                "name": "city",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5974c8ae4972b27baede66f117781fe2",
    "id": null,
    "metadata": {},
    "name": "GCNewProjectStepGeneralMutation",
    "operationKind": "mutation",
    "text": "mutation GCNewProjectStepGeneralMutation(\n  $projectObject: project_insert_input!\n  $projectLocalCityobjects: [project_local_city_insert_input!]!\n) {\n  insert_project_one(object: $projectObject) {\n    id\n    pk: id\n    completed\n    name\n    project_employees {\n      id\n    }\n  }\n  insert_project_local_city(objects: $projectLocalCityobjects) {\n    returning {\n      city_id\n      city {\n        name\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1fcf4bb01dc4a22b937fecd66257b901";

export default node;
