import { IconCheck, IconX } from "@tabler/icons";
import React from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import Switcher from "src/common/components/general/Switcher";
import { useSuspenseQuery } from "@apollo/client";
import {
  GetPtpSettingsQuery,
  GetPtpSettingsQueryVariables,
  GetPtpSettingsDocument,
  useUpdateProjSettingPtpSettingMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import AddDRWithPTPSettings from "../../../daily/components/settings/basic/AddDRWithPTPSettings";

const PTPSettings: React.FC<{ projectId: string }> = ({ projectId }) => {
  const { data } = useSuspenseQuery<
    GetPtpSettingsQuery,
    GetPtpSettingsQueryVariables
  >(GetPtpSettingsDocument, { variables: { projectId } });
  const proj = data.project_by_pk;
  if (!proj) throw new Error("project not found for given projectId");
  const [updateProjSetting] = useUpdateProjSettingPtpSettingMutation();
  const requireAllWorkersSign =
    proj.project_setting?.ptp_require_all_workers_to_sign;
  return (
    <div>
      <p className="inline-flex flex-row items-center gap-0.5">
        Require all workers to sign the Pre-Task Plan. Do not allow photo
        documentation of the crew.
        <Switcher
          options={[
            { label: "off", icon: IconX },
            { label: "on", icon: IconCheck },
          ]}
          optionIndex={requireAllWorkersSign ? 1 : 0}
          onChange={async (selectedOptionIndex) => {
            const toSet = {
              ptp_require_all_workers_to_sign: selectedOptionIndex === 1,
            };
            await updateProjSetting({
              variables: { projectId, _set: toSet },
              optimisticResponse: {
                update_project_setting_by_pk: {
                  __typename: "project_setting",
                  project_id: projectId,
                  ...toSet,
                },
              },
            });
          }}
        />
      </p>
      <AddDRWithPTPSettings addDrWithPtpProjectDataFrag={proj} />
    </div>
  );
};
export default withCustomSuspense(PTPSettings);
