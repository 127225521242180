/**
 * @generated SignedSource<<ebd199b95d7a574b3f0377491e1d3119>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { general_contractor_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProcoreSubConfigModal_updateProcoreGC_Mutation$variables = {
  _set: general_contractor_set_input;
  id: string;
};
export type ProcoreSubConfigModal_updateProcoreGC_Mutation$data = {
  readonly update_general_contractor: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
};
export type ProcoreSubConfigModal_updateProcoreGC_Mutation = {
  response: ProcoreSubConfigModal_updateProcoreGC_Mutation$data;
  variables: ProcoreSubConfigModal_updateProcoreGC_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "_eq",
                    "variableName": "id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "uid"
              }
            ],
            "kind": "ObjectValue",
            "name": "employees"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "general_contractor_mutation_response",
    "kind": "LinkedField",
    "name": "update_general_contractor",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "general_contractor",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProcoreSubConfigModal_updateProcoreGC_Mutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProcoreSubConfigModal_updateProcoreGC_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "81fcc72ca414c35b2166f2c6ae790563",
    "id": null,
    "metadata": {},
    "name": "ProcoreSubConfigModal_updateProcoreGC_Mutation",
    "operationKind": "mutation",
    "text": "mutation ProcoreSubConfigModal_updateProcoreGC_Mutation(\n  $id: uuid!\n  $_set: general_contractor_set_input!\n) {\n  update_general_contractor(where: {employees: {uid: {_eq: $id}}}, _set: $_set) {\n    returning {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "24cefd493fe9c3d91f7e52ad985297ae";

export default node;
