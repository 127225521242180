import { GetObsLeaderboardDataQuery } from "src/common/types/generated/apollo/graphQLTypes";

const getObsFindingsCount = (
  observations: GetObsLeaderboardDataQuery["user"][number]["created_observations"],
) =>
  observations.reduce(
    ({ oneOffFindings, auditFindings, safeFindings }, obs) => {
      if (obs.observation_inspection_id)
        auditFindings += obs.number_of_findings;
      else oneOffFindings += obs.number_of_findings;
      if (obs.risk_level_value === "safe")
        safeFindings += obs.number_of_findings;
      return { oneOffFindings, auditFindings, safeFindings };
    },
    { oneOffFindings: 0, auditFindings: 0, safeFindings: 0 },
  );
export default getObsFindingsCount;
