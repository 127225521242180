import { Form, Modal, notification, Switch } from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { graphql } from "babel-plugin-relay/macro";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import CustomSuspense from "src/common/components/general/CustomSuspense";
import ProcoreFolderSelect from "src/common/components/ProcoreFolderSelect";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { ProcoreFolderConfigModal_procoreProjectData_Query } from "src/common/types/generated/relay/ProcoreFolderConfigModal_procoreProjectData_Query.graphql";
import { ProcoreFolderConfigModal_updateProcorePorjectData_Mutation } from "src/common/types/generated/relay/ProcoreFolderConfigModal_updateProcorePorjectData_Mutation.graphql";

const switchFieldStyle = {
  marginBottom: "8px",
  marginRight: "10px",
};

type FormValues = {
  SafetyReportsEnabled: boolean;
  DailyReportsEnabled: boolean;
  ToolboxTalksEnabled: boolean;
  WorkerOrientationTranscriptEnabled: boolean;
  PermitsEnabled: boolean;
  InspectionEnabled: boolean;
  SafetyReportsFolderId: string | null;
  DailyReportsFolderId: string | null;
  ToolboxTalksFolderId: string | null;
  PermitsFolderId: string | null;
  WorkerOrientationTranscriptFolderId: string | null;
  InspectionReportFolderId: string | null;
};

export type ProcoreFolderConfigModalProps = {
  projectId: string;
  GCId: string;
};

export type ProcoreFolderConfigModalRef = {
  open: () => void;
};

const GetProcoreProjectDataQuery = graphql`
  query ProcoreFolderConfigModal_procoreProjectData_Query($projectId: uuid!) {
    procore_project_data_connection(
      where: { project_id: { _eq: $projectId } }
    ) {
      edges {
        node {
          procore_project_id
          procore_project_name
          upload_safety_reports_folder_id
          upload_daily_reports_folder_id
          upload_toolbox_talks_folder_id
          upload_worker_orientation_transcript_folder_id
          upload_permits_folder_id
          upload_safety_reports_enabled
          upload_daily_reports_enabled
          upload_toolbox_talks_enabled
          upload_worker_orientation_transcript_enabled
          upload_permits_enabled
          integration_enabled
          upload_inspection_report_enabled
          upload_inspection_report_folder_id
        }
      }
    }
  }
`;

const UpdateUploadFoldersMutation = graphql`
  mutation ProcoreFolderConfigModal_updateProcorePorjectData_Mutation(
    $project_id: uuid!
    $_set: procore_project_data_set_input!
  ) {
    update_procore_project_data_by_pk(
      pk_columns: { project_id: $project_id }
      _set: $_set
    ) {
      pk: id @__clientField(handle: "pk")
      project_id
      procore_project_id
      procore_project_name

      upload_permits_enabled
      upload_permits_folder_id

      upload_daily_reports_enabled
      upload_daily_reports_folder_id

      upload_toolbox_talks_enabled
      upload_toolbox_talks_folder_id

      upload_safety_reports_enabled
      upload_safety_reports_folder_id

      upload_worker_orientation_transcript_enabled
      upload_worker_orientation_transcript_folder_id

      upload_inspection_report_enabled
      upload_inspection_report_folder_id
    }
  }
`;

export type ModalFormRef<V extends object> = {
  form: FormInstance<V>;
};

interface ProcoreFolderConfigModalContentProps {
  projectId: string;
  GCId: string;
  saving: boolean;
  fetchKey: number | string;
}

const ProcoreFolderConfigModalContent = forwardRef<
  ModalFormRef<FormValues>,
  ProcoreFolderConfigModalContentProps
>(({ projectId, GCId, saving, fetchKey }, ref) => {
  const projectData =
    useLazyLoadQuery<ProcoreFolderConfigModal_procoreProjectData_Query>(
      GetProcoreProjectDataQuery,
      { projectId },
      { fetchKey, fetchPolicy: "network-only" },
    );

  const [formRef] = useForm();
  useImperativeHandle<ModalFormRef<FormValues>, ModalFormRef<FormValues>>(
    ref,
    () => ({
      form: formRef,
    }),
  );

  const edges = projectData.procore_project_data_connection.edges;
  const project_node = edges[0]?.node;
  if (!project_node)
      throw new Error("Procore data is missing");


  const [
    workerOrientationTranscriptEnabled,
    setWorkerOrientationTranscriptEnabled,
  ] = useState(project_node.upload_worker_orientation_transcript_enabled);
  const [safetyReportsEnabled, setSafetyReportsEnabled] = useState(
    project_node.upload_safety_reports_enabled,
  );
  const [dailyReportsEnabled, setDailyReportsEnabled] = useState(
    project_node.upload_daily_reports_enabled,
  );
  const [toolboxTalksEnabled, setToolboxTalksEnabled] = useState(
    project_node.upload_toolbox_talks_enabled,
  );
  const [permitsEnabled, setPermitsEnabled] = useState(
    project_node.upload_permits_enabled,
  );

  const [inspectionEnabled, setInspectionEnabled] = useState(
    project_node.upload_inspection_report_enabled ?? false,
  );

  //const [safetyReportsFolder, setSafetyReportsFolder] = useState(project_node.upload_safety_reports_folder || "");//"Sandbox Test Project/03 Safety"
  //const [dailyReportsFolder, setDailyReportsFolder] = useState(project_node.upload_daily_reports_folder || "");
  //const [toolboxTalksFolder, setToolboxTalksFolder] = useState(project_node.upload_toolbox_talks_folder || ""); //Sandbox Test Project/04 Subcontractor Files
  //const [permintsFolder, setPermitsFolder] = useState(project_node.upload_permits_folder || "");

  const procore_project_id = project_node.procore_project_id;
  if (!procore_project_id) {
    throw new Error("Procore project is not configured");
  }
  //  console.log("project_node", project_node);
  //  console.log("after rerender projid=", procore_project_id);
  //        initialValue={project_node.upload_safety_reports_enabled}
  return (
    <Form
      form={formRef}
      layout="horizontal"
      labelCol={{ span: 15 }}
      labelAlign="left"
    >
      <Form.Item noStyle>
        <Form.Item
          name="WorkerOrientationTranscriptEnabled"
          label="Upload Worker Orientation Transcript PDF:"
          valuePropName="checked"
          style={switchFieldStyle}
          initialValue={
            project_node.upload_worker_orientation_transcript_enabled
          }
        >
          <Switch
            disabled={saving}
            style={{ width: "max-content" }}
            checked={workerOrientationTranscriptEnabled}
            onChange={setWorkerOrientationTranscriptEnabled}
          />
        </Form.Item>
        <Form.Item
          name="WorkerOrientationTranscriptFolderId"
          rules={[
            {
              required: workerOrientationTranscriptEnabled,
              message: "Please choose procore folder",
            },
          ]}
          dependencies={["WorkerOrientationTranscriptEnabled"]}
          initialValue={
            project_node.upload_worker_orientation_transcript_folder_id
          }
        >
          <ProcoreFolderSelect
            disabled={!workerOrientationTranscriptEnabled || saving}
            procoreProjectId={procore_project_id}
            projectId={projectId}
            GCId={GCId}
          />
        </Form.Item>
        <Form.Item
          name="SafetyReportsEnabled"
          label="Upload Pre-Task Plans PDF:"
          valuePropName="checked"
          style={switchFieldStyle}
          initialValue={project_node.upload_safety_reports_enabled}
        >
          <Switch
            disabled={saving}
            checked={safetyReportsEnabled}
            onChange={setSafetyReportsEnabled}
          />
        </Form.Item>
        <Form.Item
          name="SafetyReportsFolderId"
          rules={[
            {
              required: safetyReportsEnabled,
              message: "Please choose procore folder",
            },
          ]}
          dependencies={["SafetyReportsEnabled"]}
          initialValue={project_node.upload_safety_reports_folder_id}
        >
          <ProcoreFolderSelect
            disabled={!safetyReportsEnabled || saving}
            procoreProjectId={procore_project_id}
            projectId={projectId}
            GCId={GCId}
          />
        </Form.Item>
        <Form.Item
          name="DailyReportsEnabled"
          label="Upload Daily Reports PDF:"
          style={switchFieldStyle}
          initialValue={project_node.upload_daily_reports_enabled}
        >
          <Switch
            disabled={saving}
            checked={dailyReportsEnabled}
            onChange={setDailyReportsEnabled}
          ></Switch>
        </Form.Item>
        <Form.Item
          name="DailyReportsFolderId"
          rules={[
            {
              required: dailyReportsEnabled,
              message: "Please choose procore folder",
            },
          ]}
          dependencies={["DailyReportsEnabled"]}
          initialValue={project_node.upload_daily_reports_folder_id}
        >
          <ProcoreFolderSelect
            disabled={!dailyReportsEnabled || saving}
            procoreProjectId={procore_project_id}
            projectId={projectId}
            GCId={GCId}
          />
        </Form.Item>
        <Form.Item
          name="ToolboxTalksEnabled"
          label="Upload Toolbox talks:"
          style={switchFieldStyle}
          initialValue={project_node.upload_toolbox_talks_enabled}
        >
          <Switch
            disabled={saving}
            checked={toolboxTalksEnabled}
            onChange={setToolboxTalksEnabled}
          ></Switch>
        </Form.Item>
        <Form.Item
          name="ToolboxTalksFolderId"
          rules={[
            {
              required: toolboxTalksEnabled,
              message: "Please choose procore folder",
            },
          ]}
          dependencies={["ToolboxTalksEnabled"]}
          initialValue={project_node.upload_toolbox_talks_folder_id}
        >
          <ProcoreFolderSelect
            disabled={!toolboxTalksEnabled || saving}
            procoreProjectId={procore_project_id}
            projectId={projectId}
            GCId={GCId}
          />
        </Form.Item>
        <Form.Item
          name="PermitsEnabled"
          label="Upload Permits PDF:"
          style={switchFieldStyle}
          initialValue={project_node.upload_permits_enabled}
        >
          <Switch
            disabled={saving}
            checked={permitsEnabled}
            onChange={setPermitsEnabled}
          ></Switch>
        </Form.Item>
        <Form.Item
          name="PermitsFolderId"
          rules={[
            {
              required: permitsEnabled,
              message: "Please choose procore folder",
            },
          ]}
          dependencies={["PermitsEnabled"]}
          initialValue={project_node.upload_permits_folder_id}
        >
          <ProcoreFolderSelect
            disabled={!permitsEnabled || saving}
            procoreProjectId={procore_project_id}
            projectId={projectId}
            GCId={GCId}
          />
        </Form.Item>
        <Form.Item
          name="InspectionEnabled"
          label="Upload Inspection PDF:"
          style={switchFieldStyle}
          initialValue={project_node.upload_inspection_report_enabled}
        >
          <Switch
            disabled={saving}
            checked={inspectionEnabled}
            onChange={setInspectionEnabled}
          ></Switch>
        </Form.Item>
        <Form.Item
          name="InspectionReportFolderId"
          rules={[
            {
              required: inspectionEnabled,
              message: "Please choose procore folder",
            },
          ]}
          dependencies={["InspectionEnabled"]}
          initialValue={project_node.upload_inspection_report_folder_id}
        >
          <ProcoreFolderSelect
            disabled={!inspectionEnabled || saving}
            procoreProjectId={procore_project_id}
            projectId={projectId}
            GCId={GCId}
          />
        </Form.Item>
      </Form.Item>
    </Form>
  );
});

const ProcoreFolderConfigModal = forwardRef<
  ProcoreFolderConfigModalRef,
  ProcoreFolderConfigModalProps
>(({ projectId, GCId }, ref) => {
  const modal = useRef<ModalFormRef<FormValues>>(null);
  //  console.log('Modal render progjid =', projectId,'GCid =', GCId, 'mc =', modal.current, 'loading =', loadingModal);
  const [key, setKey] = useState(0);
  const [visible, setVisible] = useState(false);
  const [saving, setSaving] = useState(false);
  useImperativeHandle<ProcoreFolderConfigModalRef, ProcoreFolderConfigModalRef>(
    ref,
    () => ({
      open: () => {
        console.log("OPEN MODAL");
        setKey((v) => v + 1);
        setVisible(true);
      },
    }),
  );

  const [updateProject] =
    useAsyncMutation<ProcoreFolderConfigModal_updateProcorePorjectData_Mutation>(
      UpdateUploadFoldersMutation,
    );

  return (
    <>
      <Modal
        title="Procore Folder Setup"
        okText="Save"
        cancelText="Cancel"
        confirmLoading={saving}
        open={visible}
        onCancel={() => setVisible(false)}
        onOk={async () => {
          const form = modal.current?.form;
          if (!form) return;
          //          console.log("onOk, getFieldValue = ", form.getFieldsValue());
          let values = await form.validateFields().catch(v => null);
          if (!values)
              return; // validation failed;
          setSaving(true);
          try {
            await updateProject({
              variables: {
                project_id: projectId,
                _set: {
                  upload_safety_reports_enabled: values.SafetyReportsEnabled,
                  upload_daily_reports_enabled: values.DailyReportsEnabled,
                  upload_toolbox_talks_enabled: values.ToolboxTalksEnabled,
                  upload_permits_enabled: values.PermitsEnabled,
                  upload_worker_orientation_transcript_enabled:
                    values.WorkerOrientationTranscriptEnabled,

                  upload_safety_reports_folder_id:
                    values.SafetyReportsFolderId,
                  upload_daily_reports_folder_id: values.DailyReportsFolderId,
                  upload_toolbox_talks_folder_id: values.ToolboxTalksFolderId,
                  upload_permits_folder_id: values.PermitsFolderId,
                  upload_worker_orientation_transcript_folder_id:
                    values.WorkerOrientationTranscriptFolderId,
                  upload_inspection_report_enabled: values.InspectionEnabled,
                  upload_inspection_report_folder_id:
                    values.InspectionReportFolderId,
                },
              },
            });
            setVisible(false);
          } finally {
            setSaving(false);
          }
        }}
      >
        <CustomSuspense>
          <ProcoreFolderConfigModalContent
            ref={modal}
            projectId={projectId}
            GCId={GCId}
            saving={saving}
            key={key}
            fetchKey={key}
          />
        </CustomSuspense>
      </Modal>
    </>
  );
});

export default ProcoreFolderConfigModal;
