import React, { useState } from 'react';
import { Modal, Form, Input, Switch, message, AutoComplete } from 'antd';
import { GCDefaultDescription, SubDefaultDescription, DefaultFooter } from './InvoiceDefaultSettings';

export interface InvoiceFormFields {
  description?: string | null;
  footer?: string | null;
  default_billing_email?: string | null;
}
interface Props {
  visible: boolean;
  companyName?: string;
  initValues: InvoiceFormFields;
  users: Array<{name: string; email: string}>;
  onSave: (values: InvoiceFormFields) => Promise<void>;
  onCancel: () => void;
  isGC?: boolean;
}

const EditInvoiceSettingsModal: React.FC<Props> = ({
  visible,
  companyName,
  onSave,
  onCancel,    
  isGC,
  initValues,
  users
}) => {
  const [saving, setSaving] = useState(false);

  const [form] = Form.useForm<InvoiceFormFields>();
  console.log('init values', initValues);
  return (
    (<Modal
      width={"50%"}
      open={visible}
      title={"Invoice settings for " + (companyName ?? "")}
      okText="Save"
      okButtonProps={{loading: saving}}
      cancelText="Cancel"
      onCancel={ () => {
        form.resetFields();
        onCancel();
      }}
      onOk={async () => {
        setSaving(true);
        try {
          const values = await form.validateFields().catch(values => null);
          if (!values)
            return;
          console.log('saving values', values);
          await onSave(values);
        } finally {
          setSaving(false);
        }
      }}      
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ 
          "description": initValues.description ?? (isGC? GCDefaultDescription : SubDefaultDescription),
          "footer": initValues.footer ?? DefaultFooter,
          "default_billing_email":  initValues.default_billing_email,
        }}
      >    
        <Form.Item>
          <Form.Item
            name="default_billing_email"
            label={"Billing email address for new projects:"}
            rules={[{ required: true, message: 'Stripe email address is required' }]}
          >
            <AutoComplete
              options={users.map(u => ({
                value: u.email,
                label: <div>{ u.name } ({ u.email })</div>
              }))}
              style={{ width: '100%' }}
              placeholder="Enter billing address"
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          name="description"
          label="Description:"
        >
          <Input.TextArea
            rows={7}
          ></Input.TextArea>
        </Form.Item>
        <Form.Item
          name="footer"
          label="Footer:"
        >
          <Input.TextArea></Input.TextArea>
        </Form.Item>
      </Form>
    </Modal>)
  );
};

export default EditInvoiceSettingsModal;