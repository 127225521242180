/**
 * @generated SignedSource<<7eb27d4b221dd42e16e71d884cae20a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { task_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type JHAReviewedAndAcceptedQuery$variables = {
  loggedInUserId: string;
  previousImageTaskWhere: task_bool_exp;
  taskId: ReadonlyArray<string>;
};
export type JHAReviewedAndAcceptedQuery$data = {
  readonly previousImageTask: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly created_at: string;
        readonly created_by_uid: string | null | undefined;
        readonly pk: string;
      };
    }>;
  };
  readonly task_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accepted_at_from_creation_request: string | null | undefined;
        readonly creation_request_id: string | null | undefined;
        readonly description: {
          readonly en: string;
        };
        readonly project: {
          readonly name: string;
        } | null | undefined;
        readonly project_id: string | null | undefined;
        readonly subcontractor: {
          readonly name: string;
        } | null | undefined;
        readonly task_creation_request: {
          readonly project_id: string | null | undefined;
          readonly subcontractor_id: string;
          readonly task_add_to_projects: ReadonlyArray<{
            readonly project_id: string;
          }>;
        } | null | undefined;
      };
    }>;
  };
  readonly user_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly role: string;
      };
    }>;
  };
};
export type JHAReviewedAndAcceptedQuery = {
  response: JHAReviewedAndAcceptedQuery$data;
  variables: JHAReviewedAndAcceptedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "loggedInUserId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "previousImageTaskWhere"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskId"
},
v3 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "loggedInUserId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "previousImageTaskWhere"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_by_uid",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "project_id": "asc_nulls_first"
    }
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_in",
            "variableName": "taskId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creation_request_id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accepted_at_from_creation_request",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = [
  (v14/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = [
  (v14/*: any*/),
  (v16/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "JHAReviewedAndAcceptedQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "previousImageTask",
        "args": (v5/*: any*/),
        "concreteType": "taskConnection",
        "kind": "LinkedField",
        "name": "task_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "taskEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "task",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id_pk",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "taskConnection",
        "kind": "LinkedField",
        "name": "task_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "taskEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "task",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "add_task_request",
                    "kind": "LinkedField",
                    "name": "task_creation_request",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_add_task_request",
                        "kind": "LinkedField",
                        "name": "task_add_to_projects",
                        "plural": true,
                        "selections": [
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "description",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": (v15/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": (v15/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "JHAReviewedAndAcceptedQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "previousImageTask",
        "args": (v5/*: any*/),
        "concreteType": "taskConnection",
        "kind": "LinkedField",
        "name": "task_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "taskEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "task",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "taskConnection",
        "kind": "LinkedField",
        "name": "task_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "taskEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "task",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "add_task_request",
                    "kind": "LinkedField",
                    "name": "task_creation_request",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_add_task_request",
                        "kind": "LinkedField",
                        "name": "task_add_to_projects",
                        "plural": true,
                        "selections": [
                          (v11/*: any*/),
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "description",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": (v17/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": (v17/*: any*/),
                    "storageKey": null
                  },
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "efc7007b2c4c84a6241ef9508fca855e",
    "id": null,
    "metadata": {},
    "name": "JHAReviewedAndAcceptedQuery",
    "operationKind": "query",
    "text": "query JHAReviewedAndAcceptedQuery(\n  $taskId: [uuid!]!\n  $loggedInUserId: uuid!\n  $previousImageTaskWhere: task_bool_exp!\n) {\n  user_connection(where: {id: {_eq: $loggedInUserId}}) {\n    edges {\n      node {\n        role\n        id\n      }\n    }\n  }\n  previousImageTask: task_connection(where: $previousImageTaskWhere) {\n    edges {\n      node {\n        pk: id\n        created_at\n        created_by_uid\n        id\n      }\n    }\n  }\n  task_connection(where: {id: {_in: $taskId}}, order_by: {project_id: asc_nulls_first}) {\n    edges {\n      node {\n        creation_request_id\n        accepted_at_from_creation_request\n        task_creation_request {\n          project_id\n          subcontractor_id\n          task_add_to_projects {\n            project_id\n            id\n          }\n          id\n        }\n        description {\n          en\n          id\n        }\n        project_id\n        project {\n          name\n          id\n        }\n        subcontractor {\n          name\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "932e838025d5b9ad726befdf11c75360";

export default node;
