/**
 * @generated SignedSource<<7fccdda90c997456af3494c648c5cc2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_delivery_sub_block_insert_input, user_project_filter_calendar_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation$variables = {
  deleteFilterCalendarWhere: user_project_filter_calendar_bool_exp;
  object: project_delivery_sub_block_insert_input;
};
export type SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation$data = {
  readonly delete_user_project_filter_calendar: {
    readonly returning: ReadonlyArray<{
      readonly calendar_id: string;
      readonly id: string;
    }>;
  } | null | undefined;
  readonly insert_project_delivery_sub_block_one: {
    readonly calendars: ReadonlyArray<{
      readonly calendar: {
        readonly color_hex: string;
        readonly id: string;
        readonly name: {
          readonly en: string;
        };
        readonly pk: string;
      };
    }>;
    readonly end_time: string;
    readonly id: string;
    readonly pk: string;
    readonly start_time: string;
    readonly subcontractors: ReadonlyArray<{
      readonly subcontractor: {
        readonly id: string;
        readonly name: string;
        readonly pk: string;
      };
    }>;
    readonly weekday: number;
  } | null | undefined;
};
export type SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation = {
  response: SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation$data;
  variables: SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleteFilterCalendarWhere"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "object"
},
v2 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "object"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start_time",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end_time",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weekday",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color_hex",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "deleteFilterCalendarWhere"
    }
  ],
  "concreteType": "user_project_filter_calendar_mutation_response",
  "kind": "LinkedField",
  "name": "delete_user_project_filter_calendar",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "user_project_filter_calendar",
      "kind": "LinkedField",
      "name": "returning",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "calendar_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "project_delivery_sub_block",
        "kind": "LinkedField",
        "name": "insert_project_delivery_sub_block_one",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "sub_block_calendar",
            "kind": "LinkedField",
            "name": "calendars",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "calendar",
                "kind": "LinkedField",
                "name": "calendar",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sub_block_subcontractor",
            "kind": "LinkedField",
            "name": "subcontractors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "subcontractor",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v11/*: any*/)
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "project_delivery_sub_block",
        "kind": "LinkedField",
        "name": "insert_project_delivery_sub_block_one",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "sub_block_calendar",
            "kind": "LinkedField",
            "name": "calendars",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "calendar",
                "kind": "LinkedField",
                "name": "calendar",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sub_block_subcontractor",
            "kind": "LinkedField",
            "name": "subcontractors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "subcontractor",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v11/*: any*/)
    ]
  },
  "params": {
    "cacheID": "ef3a4f99b4d30e4dbfacfa7f87d4d84b",
    "id": null,
    "metadata": {},
    "name": "SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation",
    "operationKind": "mutation",
    "text": "mutation SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation(\n  $object: project_delivery_sub_block_insert_input!\n  $deleteFilterCalendarWhere: user_project_filter_calendar_bool_exp!\n) {\n  insert_project_delivery_sub_block_one(object: $object) {\n    id\n    pk: id\n    start_time\n    end_time\n    weekday\n    calendars {\n      calendar {\n        id\n        pk: id\n        name {\n          en\n          id\n        }\n        color_hex\n      }\n      id\n    }\n    subcontractors {\n      subcontractor {\n        id\n        pk: id\n        name\n      }\n      id\n    }\n  }\n  delete_user_project_filter_calendar(where: $deleteFilterCalendarWhere) {\n    returning {\n      id\n      calendar_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a4fb19a0a5097b59cf75c4c798224322";

export default node;
